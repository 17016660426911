import { AuthErrors, inEnum } from "@technis/shared";
import { InMemoryCache, NormalizedCacheObject } from "apollo-cache-inmemory";
import { ApolloClient } from "apollo-client";
import { ApolloLink, Observable } from "apollo-link";
import { setContext } from "apollo-link-context";
import { onError } from "apollo-link-error";
import { createHttpLink } from "apollo-link-http";
import { OperationDefinitionNode } from "graphql";

import { fetchFragmentMatcher, fragmentMatcher } from "../graphql/fragmentMatcher";
import { actions, store } from "../redux/store";
import { omitDeep } from "../utils/utils";
import { fetchGQL } from "./fetch";

const {
  auth: { saveToken },
} = actions;

export interface ApolloError {
  graphQLErrors: GraphQLErrors[];
  networkError: GraphQLErrors[];
  message: string;
  extraInfo: Record<string, unknown>;
}

interface GraphQLErrors {
  extensions: Record<string, unknown>;
  locations: [];
  message: string;
  path: string[];
}

export namespace apollo {
  // @ts-ignore
  export let client: ApolloClient<NormalizedCacheObject> = undefined;

  const autoRenewTokenState: { renewing: boolean; error?: string } = {
    renewing: false,
    error: undefined,
  };

  const getCurrentToken = () => store.getState().auth.token;
  const getCurrentAuthorization = (token?: string) => `Bearer ${token || getCurrentToken()}`;

  const setHeaderWithToken = (headers: Record<string, unknown>, token?: string) => ({
    headers: {
      ...headers,
      authorization: getCurrentAuthorization(token),
    },
  });

  const renewTokenAndSave = (): Promise<string> =>
    autoRenewTokenState.renewing
      ? new Promise((resolve, reject) => {
          let i = 1;
          const interval = setInterval(() => {
            const clear = () => clearInterval(interval);
            if (i > 10) {
              clear();
              return reject("Token refresh timed out.");
            }
            if (!autoRenewTokenState.renewing) {
              if (autoRenewTokenState.error) {
                clear();
                return reject(autoRenewTokenState.error);
              }
              clear();
              return resolve("");
            }
            i += 1;
          }, 500);
        })
      : Promise.resolve().then(() => {
          autoRenewTokenState.renewing = true;
          return fetchGQL<{ renew: string }>("query { renew }", getCurrentToken())
            .then(async res => {
              console.log("RENEWED TOKEN", res);
              const { renew: newToken } = res;
              if (!newToken) {
                throw new Error(AuthErrors.INVALID_TOKEN);
              }
              await saveToken(newToken);
              autoRenewTokenState.renewing = false;
              return newToken;
            })
            .catch((e: Error) => {
              autoRenewTokenState.renewing = false;
              autoRenewTokenState.error = e.message;
              throw e;
            });
        });

  const authLink = setContext((_, { headers }: { headers: Record<string, unknown> }) => setHeaderWithToken(headers));

  const errorLink = onError(({ forward, graphQLErrors, networkError, operation }) => {
    console.log(graphQLErrors, networkError);
    if (graphQLErrors) {
      let hasTokenExpired = false;
      for (const err of graphQLErrors) {
        const { locations, message, path } = err;
        if (!hasTokenExpired) {
          hasTokenExpired = message === AuthErrors.TOKEN_EXPIRED;
        }
        console.log(err);
        console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`);
      }
      if (hasTokenExpired) {
        const prevHeaders = operation.getContext().headers;
        return new Observable(observer => {
          renewTokenAndSave()
            .then(newToken => {
              if (newToken) {
                operation.setContext(setHeaderWithToken(prevHeaders, newToken));
              }
            })
            .then(() => {
              const subscriber = {
                next: observer.next.bind(observer),
                error: observer.error.bind(observer),
                complete: observer.complete.bind(observer),
              };
              forward(operation).subscribe(subscriber);
            })
            .catch((err: Error) => {
              if (inEnum(AuthErrors, err.message)) {
                // loginErrorDispatch(store.dispatch, err.message);
              }
              observer.error(err);
            });
        });
      }
    }
    if (networkError) {
      console.log(`[Network error]: ${networkError}`);
      networkError = undefined;
    }
  });

  const omitTypenameLink = new ApolloLink((operation, forward) => {
    if (((operation.query.definitions[0] || {}) as OperationDefinitionNode).operation === "mutation") {
      operation.variables = omitDeep(operation.variables, "__typename");
    }

    return forward(operation);
  });

  const httpLink = createHttpLink({
    uri: `${process.env.APPLICATION_API_URL}/graphql`,
  });

  const link = ApolloLink.from([errorLink, authLink, omitTypenameLink, httpLink]);

  export const resetClient = () => {
    client?.cache.reset();
  };

  export const initClient = async () => {
    if (!client) {
      await fetchFragmentMatcher();
      client = new ApolloClient({
        link,
        cache: new InMemoryCache({
          fragmentMatcher: fragmentMatcher(),
          addTypename: true,
        }),
      });
    }
  };
}
