import React, { FunctionComponent } from "react";

import { isNavigatorFirefox } from "../../index";
import { Colors } from "../../utils/colors";
import { GradientIcon } from "./GradientIcon";

interface RingProps {
  radius: number;
  stroke: number;
  text: { firstLine: string; secondLine: string };
  classNameText: { firstLine: string; secondLine: string };
  colors?: Colors[];
}

export const Ring: FunctionComponent<RingProps> = props => {
  const { radius, stroke, text, classNameText, colors = [Colors.GREEN] } = props;
  const normalizedRadius = radius - stroke * 2;

  if (isNavigatorFirefox)
    return (
      <svg height={radius * 2} width={radius * 2}>
        <circle fill="transparent" stroke={colors[0]} strokeWidth={stroke} r={normalizedRadius} cx={radius} cy={radius} />
        <text x={radius} y={radius + 2} textAnchor="middle" className={classNameText.firstLine}>
          {text.firstLine}
        </text>
        <text x={radius} y={radius + 14} textAnchor="middle" className={classNameText.secondLine}>
          {text.secondLine}
        </text>
      </svg>
    );
  return (
    <GradientIcon
      iconComponent={
        <svg height={radius * 2} width={radius * 2}>
          <circle fill="transparent" strokeWidth={stroke} r={normalizedRadius} cx={radius} cy={radius} />
          <text x={radius} y={radius + 2} textAnchor="middle" className={classNameText.firstLine}>
            {text.firstLine}
          </text>
          <text x={radius} y={radius + 14} textAnchor="middle" className={classNameText.secondLine}>
            {text.secondLine}
          </text>
        </svg>
      }
      gradientColors={colors}
      endXCoordinate={0}
      endYCoordinate={100}
      startXCoordinate={0}
      startYCoordinate={0}
    />
  );
};
