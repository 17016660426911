import { EventMetrics, ID, UserRoles, Zone } from "@technis/shared";

import { MissingEventId, MissingInstallation, MissingKpiData, NoTokenError } from "../../components/common/error/Errors";
import { PassageSetting, ZoneSetting } from "../../components/common/Header";
import { EventInstallation, EventInstallationZones } from "../../graphql/event.gql";
import { AllZonesAllPassagesAnalyticsKpiQueryResult } from "../../graphql/zones.gql";
import { DateRange, Granularity, KpiAtmosphereWithDates, KpiCountingWithDates, StatusData } from "../../utils/aggregate";
import { getCountingAggregation } from "../../utils/utils";
import { AggregatedKpiAtmosphereWithName, AggregatedKpiCountingWithName } from "./types";

const KPI_PROPERTIES: (keyof KpiCountingWithDates)[] = ["dateBegin", "dateEnd", "affluenceMinIn", "affluenceMinOut", "inside"];
const KPI_ATMOSPHERE_PROPERTIES: (keyof KpiAtmosphereWithDates)[] = ["dateBegin", "dateEnd", "zoneId", "deviceId", "co2Min", "temperatureMin", "humidityMin"];

export const getErrorComponent = ({
  eventId,
  installation,
  installationKpis,
  loading,
  metrics,
  token,
}: {
  eventId?: number;
  installation?: EventInstallation;
  metrics?: EventMetrics[];
  loading?: boolean;
  installationKpis?: AllZonesAllPassagesAnalyticsKpiQueryResult;
  token?: string;
}) => {
  if (!eventId) {
    return MissingEventId;
  }

  if (!installation || !metrics) {
    return MissingInstallation;
  }

  if (!loading && !installationKpis) {
    return MissingKpiData;
  }

  // if (error) {
  //   // TODO: Error component
  // }

  if (!token) {
    return NoTokenError;
  }

  return null;
};

export const getPassageSettings = (zones: EventInstallationZones) =>
  zones
    .map(zone => zone.passages.map(passage => ({ id: passage.id, name: passage.name, activated: true })))
    .flat()
    .uniqBy("id")
    .sortBy("id");

export const getZoneSettings = (zones: Pick<Zone, "id" | "name" | "parentIds" | "childIds">[], zoneId?: ID, level = 0): ZoneSetting[] => {
  if (!zones.length) {
    return [];
  }
  const topZones = zones.filter(zone => (zoneId ? zone.parentIds.includes(zoneId) : !zone.parentIds.length));
  const rest = zones.filter(zone => (zoneId ? !zone.parentIds.includes(zoneId) : zone.parentIds.length));
  return [...topZones.map(zone => [{ id: zone.id, name: zone.name, level, activated: true }, ...getZoneSettings(rest, zone.id, level + 1)])].flat();
};

export const getAllAggregation = ({
  installationKpis,
  metrics,
  ...restProps
}: {
  installationKpis: AllZonesAllPassagesAnalyticsKpiQueryResult;
  metrics?: EventMetrics[];
  dateRange: DateRange;
  granularity: Granularity;
  passageSettings: PassageSetting[];
  zoneSettings: ZoneSetting[];
  isShifting: boolean;
}) => {
  // Passage Counting
  const passageKpiAggregated: AggregatedKpiCountingWithName[] = metrics?.includes(EventMetrics.COUNTING)
    ? getCountingAggregation({
        ...restProps,
        query: installationKpis.passagesByInstallation,
        kpiProperties: KPI_PROPERTIES,
        nameProperty: "passageName",
      })
    : [];

  // Zone Counting
  const zoneKpiAggregated: AggregatedKpiCountingWithName[] = metrics?.includes(EventMetrics.COUNTING)
    ? getCountingAggregation({
        ...restProps,
        query: installationKpis.zonesByInstallation,
        kpiProperties: KPI_PROPERTIES,
        nameProperty: "zoneName",
      })
    : [];

  // Zone Atmosphere
  const zoneKpiAtmosphereAggregated: AggregatedKpiAtmosphereWithName[] = metrics?.includes(EventMetrics.ATMOSPHERE)
    ? (getCountingAggregation({
        ...restProps,
        kpiProperties: KPI_ATMOSPHERE_PROPERTIES,
        query: installationKpis.zonesByInstallation,
        isAtmosphere: true,
        nameProperty: "zoneName",
      }) as AggregatedKpiAtmosphereWithName[])
    : [];

  return { passageKpiAggregated, zoneKpiAggregated, zoneKpiAtmosphereAggregated };
};

export const getDateBegin = (zoneKpiAggregated: AggregatedKpiCountingWithName[], zoneKpiAtmosphereAggregated: AggregatedKpiAtmosphereWithName[]) => {
  if (zoneKpiAggregated.isEmpty() && zoneKpiAtmosphereAggregated.isEmpty()) return [];
  if (zoneKpiAggregated.isEmpty()) return zoneKpiAtmosphereAggregated[0].dateBegins;
  return zoneKpiAggregated[0].dateBegins;
};

export const isSettingsReady = (passageSettings: PassageSetting[], zoneSettings: ZoneSetting[], metrics?: EventMetrics[]) => {
  if (!!metrics && metrics.includes(EventMetrics.COUNTING)) return !passageSettings.isEmpty() && !zoneSettings.isEmpty();
  if (!!metrics && metrics.includes(EventMetrics.ATMOSPHERE)) return !zoneSettings.isEmpty();
  return false;
};

export const isKpisEmpty = (isAllZoneKpisEmpty: boolean, isAllPassageKpisEmpty: boolean, metrics?: EventMetrics[]) => {
  if (!!metrics && metrics.includes(EventMetrics.COUNTING)) return isAllZoneKpisEmpty || isAllPassageKpisEmpty;
  if (!!metrics && metrics.includes(EventMetrics.ATMOSPHERE)) return isAllZoneKpisEmpty;
  return false;
};

export const isAllDataReady = (
  zoneKpiAggregated: AggregatedKpiCountingWithName[],
  passageKpiAggregated: AggregatedKpiCountingWithName[],
  zoneKpiAtmosphereAggregated: AggregatedKpiAtmosphereWithName[],
  aggregatedStatus: StatusData[],
  isAdmin?: boolean,
  metrics?: EventMetrics[],
) => {
  const isStatusReady = !isAdmin || !aggregatedStatus.isEmpty();
  if (!!metrics && metrics.includes(EventMetrics.COUNTING)) return !zoneKpiAggregated.isEmpty() && !passageKpiAggregated.isEmpty() && isStatusReady;
  if (!!metrics && metrics.includes(EventMetrics.ATMOSPHERE)) return !zoneKpiAtmosphereAggregated.isEmpty() && isStatusReady;
  return false;
};
