import { Maybe, NeededOnly, Organization, Zone } from "@technis/shared";
import gql from "graphql-tag";

export const OrganizationData = `
  id
  name
`;

export type OrganizationsByUserResult = { organizationsByUser: NeededOnly<Organization, "id" | "name">[] };
export const organizationsByUser = gql`
    query organizationsByUser {
        organizationsByUser {
            ${OrganizationData}
        }
    }
`;

export type TopZonesByOrganizationIdResult = {
  organizationById: NeededOnly<Organization, "id" | "name"> & {
    topZones: Maybe<Array<NeededOnly<Zone, "id" | "name">>>;
  };
};
export const topZonesByOrganizationId = gql`
    query organizationById($organizationId: Int!) {
        organizationById(organizationId: $organizationId) {
            ${OrganizationData}
            topZones {
                id,
                name,
                address,
                city,
                zip
            }
        }
    }
`;

export type OrganizationByIdResult = { organizationById: Organization };
export const organizationById = gql`
    query organizationById($organizationId: Int!) {
        organizationById(organizationId: $organizationId) {
            ${OrganizationData}
            apiCredits
            invitations {
                acceptedAt
                expiredAt
                issuedAt
                id
            }
        }
    }
`;
