export const MODULE_TITLE_ICON_SIZE = 26;
export const MODULE_QUESTION_ICON_SIZE = 14;
export const MODULE_LOADING_ICON_SIZE = 35;
export const GENERIC_ERROR_ICON_SIZE = 60;

export const CHART_HEIGHT = 250;
export const CHART_HEIGHT_AFFLUENCE = 350;
export const CHART_PIE_SIZE = 400;
export const CHART_BAR_SIZE_QUANTILE = 17;
export const CHART_BAR_SIZE_AFFLUENCE = 30;
export const CHART_Y_AXIS_TICK_ANGLE = 30;
export const CHART_X_AXIS_TICK_ANGLE = -45;
export const CHART_TICK_FONT_SIZE = 9;
export const EXPORT_CHART_TICK_FONT_SIZE = 15;
export const CHART_TICK_POSITION = 0;
export const EXPORT_CHART_TICK_POSITION = 10;

export const FIELD_TOUCHED_DELAY = 500;
export const DEFAULT_ICON_BUTTON_SIZE = 18;

export const TIMEPICKER_SMALL = "small";
export const TIMEPICKER_LARGE = "large";
