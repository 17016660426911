import * as moment from "moment";
import React, { FunctionComponent } from "react";

import { Dates } from "../../constants";
import { DAY_MONTH_YEAR_FORMAT, HOUR_MINUTE_FORMAT } from "../../constants/date";
import { translate } from "../../lang/i18n";
import { translation } from "../../lang/translation";

const { MILLISECONDS_IN_MINUTE } = Dates;
const PEAKTIME_FORMAT = `${DAY_MONTH_YEAR_FORMAT} [${translate(translation.common.at)}] ${HOUR_MINUTE_FORMAT}`;
const DAY_MONTH_SIMPLE_YEAR_FORMAT = "Do MMM Y";
interface DateDisplayProps {
  day: number;
  peaktime?: number;
}

export const DateDisplay: FunctionComponent<DateDisplayProps> = props => {
  const { day, peaktime } = props;
  const time = peaktime ? day + peaktime * MILLISECONDS_IN_MINUTE : day;
  const format = peaktime ? PEAKTIME_FORMAT : DAY_MONTH_SIMPLE_YEAR_FORMAT;
  const dayName = moment(time).format("dddd");
  const date = moment(time).format(format);

  return (
    <div className="date-display">
      <div className="day-name white-font">{`${dayName},`}</div>
      <div className="date white-font-80">{date}</div>
    </div>
  );
};
