import { EventMetrics, ID, UserRoles } from "@technis/shared";
import { isEmpty } from "lodash";
import React, { FunctionComponent } from "react";

import { AggregatedKpiAtmosphereWithName, AggregatedKpiCountingWithName, Module, ModuleKind } from "../../../pages/AnalyticsHomePage";
import { AggregatedKpiAtmosphere, DateRange, StatusData } from "../../../utils/aggregate";
import { PassageSetting, ZoneSetting } from "../Header";
import { AffluenceModule } from "./AffluenceModule";
import { AtmosphereModule } from "./AtmosphereModule";
import { ErrorLogModule } from "./ErrorLogModule";
import { GeneralInformationModule } from "./GeneralInformationModule";
import { InsideModule } from "./InsideModule";
import { MostUsedPassagesModule } from "./MostUsedPassagesModule";
import { MostUsedZonesModule } from "./MostUsedZonesModule";
import { OverviewModule } from "./OverviewModule";
import { QuantileModule } from "./QuantileModule";
import { SummaryAirModule } from "./SummaryAirModule";
import { WaitingTimeModule } from "./WaitingTimeModule";

const TIME_WINDOW = 10;
const TIME_WINDOW_FOR_GENERAL = 30;

interface ModuleIndexProps {
  module: Module;
  index: number;
  dateRange: DateRange;
  eventId: ID;
  zoneKpiAtmosphereAggregated: AggregatedKpiAtmosphereWithName[];
  topZoneWithKpi: AggregatedKpiCountingWithName & {
    zoneId: ID;
    zoneName: string;
  };
  topZoneWithKpiAtmosphere: AggregatedKpiAtmosphere & { zoneName: string };
  zoneKpiAggregated: AggregatedKpiCountingWithName[];
  passageKpiAggregated: AggregatedKpiCountingWithName[];
  zoneSettings: ZoneSetting[];
  passageSettings: PassageSetting[];
  metrics: EventMetrics[];
  aggregatedStatus: StatusData[];
  limit?: number;
  deleteModule: (index: number) => void;
  datesLimits: {
    begin: number;
    end: number;
  };
  userRole?: UserRoles;
  flowRate?: number;
}

export const SelectModule: FunctionComponent<ModuleIndexProps> = props => {
  const {
    aggregatedStatus,
    dateRange,
    datesLimits,
    deleteModule,
    eventId,
    flowRate,
    index,
    limit,
    metrics,
    module,
    passageKpiAggregated,
    passageSettings,
    topZoneWithKpi,
    topZoneWithKpiAtmosphere,
    userRole,
    zoneKpiAggregated,
    zoneKpiAtmosphereAggregated,
    zoneSettings,
  } = props;

  const renderModule = () => {
    if (metrics.includes(EventMetrics.COUNTING) && !isEmpty(zoneKpiAggregated)) {
      switch (module.moduleKind) {
        case ModuleKind.AFFLUENCE_MODULE:
          return (
            <AffluenceModule
              key={`module-${index}`}
              keyNumber={index}
              dateRange={dateRange}
              eventId={eventId}
              zoneId={topZoneWithKpi.zoneId}
              kpiData={topZoneWithKpi}
              zoneName={topZoneWithKpi.zoneName}
              onDelete={() => deleteModule(index)}
              aggregation={module.aggregation}
              datesLimits={datesLimits}
            />
          );
        case ModuleKind.INSIDE_MODULE:
          return (
            <InsideModule
              key={`module-${index}`}
              keyNumber={index}
              dateRange={dateRange}
              eventId={eventId}
              zoneId={topZoneWithKpi.zoneId}
              kpiData={topZoneWithKpi}
              zoneName={topZoneWithKpi.zoneName}
              onDelete={() => deleteModule(index)}
              datesLimits={datesLimits}
            />
          );
        case ModuleKind.WAITING_TIME_MODULE:
          return !!flowRate ? (
            <WaitingTimeModule
              key={`module-${index}`}
              keyNumber={index}
              dateRange={dateRange}
              eventId={eventId}
              zoneId={topZoneWithKpi.zoneId}
              kpiData={topZoneWithKpi}
              zoneName={topZoneWithKpi.zoneName}
              onDelete={() => deleteModule(index)}
              datesLimits={datesLimits}
              flowRate={flowRate}
            />
          ) : null;
        case ModuleKind.QUANTILE_MODULE:
          return (
            <QuantileModule
              key={`module-${index}`}
              keyNumber={index}
              dateRange={dateRange}
              eventId={eventId}
              zoneId={topZoneWithKpi.zoneId}
              kpiData={topZoneWithKpi}
              zoneName={topZoneWithKpi.zoneName}
              onDelete={() => deleteModule(index)}
              datesLimits={datesLimits}
            />
          );
        case ModuleKind.MOST_USED_ZONES_MODULE:
          return <MostUsedZonesModule key={`module-${index}`} zoneKpiAggregated={zoneKpiAggregated} zoneSettings={zoneSettings} onDelete={() => deleteModule(index)} />;
        case ModuleKind.MOST_USED_PASSAGES_MODULE:
          return (
            <MostUsedPassagesModule key={`module-${index}`} passageKpiAggregated={passageKpiAggregated} passageSettings={passageSettings} onDelete={() => deleteModule(index)} />
          );
        case ModuleKind.OVERVIEW_MODULE:
          return (
            <OverviewModule
              key={`module-${index}`}
              topZoneSettings={zoneSettings[0]}
              zoneSettings={zoneSettings}
              zonesKpis={zoneKpiAggregated}
              passageSettings={passageSettings}
              passagesKpis={passageKpiAggregated}
              timeWindow={TIME_WINDOW}
              limit={limit}
              onDelete={() => deleteModule(index)}
            />
          );
        case ModuleKind.GENERAL_INFORMATION:
          return <GeneralInformationModule key={`module-${index}`} kpis={topZoneWithKpi} timeWindow={TIME_WINDOW_FOR_GENERAL} onDelete={() => deleteModule(index)} />;
      }
    }
    if (metrics.includes(EventMetrics.ATMOSPHERE) && !isEmpty(zoneKpiAtmosphereAggregated)) {
      switch (module.moduleKind) {
        case ModuleKind.ATMOSPHERE_GRAPH_MODULE:
          return (
            <AtmosphereModule
              key={`module-${index}`}
              keyNumber={index}
              kpiAtmosphere={topZoneWithKpiAtmosphere}
              onDelete={() => deleteModule(index)}
              dateRange={dateRange}
              zoneName={topZoneWithKpiAtmosphere.zoneName}
              eventId={eventId}
              datesLimits={datesLimits}
            />
          );
        case ModuleKind.SUMMARY_AIR_MODULE:
          return <SummaryAirModule key={`module-${index}`} kpiAtmospheres={zoneKpiAtmosphereAggregated} onDelete={() => deleteModule(index)} />;
      }
    }

    if (module.moduleKind === ModuleKind.ERROR_LOG_MODULE && (!isEmpty(zoneKpiAggregated) || !isEmpty(zoneKpiAtmosphereAggregated)) && userRole === UserRoles.ADMIN)
      return (
        <ErrorLogModule
          key={`module-${index}`}
          statusData={aggregatedStatus}
          dateRange={dateRange}
          granularity={topZoneWithKpi?.granularity || topZoneWithKpiAtmosphere?.granularity}
          onDelete={() => deleteModule(index)}
        />
      );

    return null;
  };

  return renderModule();
};
