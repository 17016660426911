import { StatusCode } from "@technis/shared";

export enum Colors {
  WHITE = "#FFFFFF",
  GRAY_100 = "#EFEFEF",
  GRAY_200 = "#DBDBDB",
  GRAY_300 = "#B4B4B4",
  GRAY_400 = "#9E9E9E",
  GRAY_500 = "#8B8B8B",
  GRAY_600 = "#737373",
  GRAY_700 = "#5B5B5B",
  GRAY_800 = "#414141",
  GRAY_900 = "#212121",
  BLACK = "#131313",
  PURE_BLACK = "#000000",

  GREEN = "#08c191",
  PURPLE = "#8f4dea",
  RED = "#ff0000",

  YELLOW = "#ffc533",
  DARK_YELLOW = "#FFB700",
  LIGHT_YELLOW = "#FFD771",

  ORANGE = "#ee8245",
  DARK_ORANGE = "#BB4602",
  BROWN_ORANGE = "#FF9900B3",

  AQUA = "#1ed2e3",
  BLUE = "#0051ee",
  LIGHT_BLUE = "#588de7",
  DARK_BLUE = "#061030",
  MAGIC_BLUE = "#005aee",

  PRIMARY = GREEN,
  SECONDARY = DARK_BLUE,

  CO2 = GREEN,
  TEMPERATURE = YELLOW,
  HUMIDITY = BLUE,
  DEFAULT_COLOR = GREEN,

  LIGHT_THEME_FONT = BLACK,
  LIGHT_THEME_BACKGROUND = DARK_BLUE,

  DARK_THEME_FONT = WHITE,
  DARK_THEME_BACKGROUND = WHITE,

  LIGHT_PRIMARY_GRADIENT_START = LIGHT_BLUE,
  LIGHT_PRIMARY_GRADIENT_END = MAGIC_BLUE,

  LIGHT_SECONDARY_GRADIENT_START = "#5E9EDF",
  LIGHT_SECONDARY_GRADIENT_END = "#8F4DEA",

  LIGHT_RING_GRADIENT_START = PURPLE,
  LIGHT_RING_GRADIENT_MID = LIGHT_BLUE,
  LIGHT_RING_GRADIENT_END = AQUA,
  DARK_SHOW_MORE = "#424962",
  LINE_DEFAULT_COLOR = PURPLE,
  LINE_MEAN_COLOR = GREEN,

  PASTEL_DARK_BLUE = "#8aafee",
  PASTEL_BLUE = "#acc6f3",
  PASTEL_DARK_PURPLE = "#ee8ad0",
  PASTEL_PURPLE = "#f3acde",
  PASTEL_DARK_YELLOW = "#ffc533",
  PASTEL_YELLOW = "#ffd15c",
  PASTEL_DARK_GREEN = "#58E7B1",
  PASTEL_GREEN = "#8aeec8",
  PASTEL_DARK_AQUA = "#1ed2e3",
  PASTEL_AQUA = "#62e0eb",
  PASTEL_DARK_ORANGE = "#f19b6a",
  PASTEL_ORANGE = "#f7c1a2",
  PASTEL_DARK_RED = "#ff6666",
  PASTEL_RED = "#ff9999",
  PASTEL_DARK_MAUVE = "#e59dff",
  PASTEL_MAUVE = "#eab1ff",
  PASTEL_DARK_MINT_GREEN = "#86e686",
  PASTEL_MINT_GREEN = "#7bff7e",
  PASTEL_DARK_PALE_CANARY = "#e4e68b",
  PASTEL_PALE_CANARY = "#fdff9a",
  PASTEL_DARK_SALMON = "#e68b95",
  PASTEL_SALMON = "#ff9aa5",
  PASTEL_DARK_PINK = "#e65dbe",
  PASTEL_PINK = "#ff67d3",
  PASTEL_DARK_WATERMELON = "#cc5268",
  PASTEL_WATERMELON = "#ff6782",
  PASTEL_DARK_GREEN_YELLOW = "#b1e64c",
  PASTEL_GREEN_YELLOW = "#cbff65",
}

interface HSL {
  hue: number;
  saturation: number;
  lightness: number;
}

export const PASTEL_COLORS_LIST = [
  { lightPastel: Colors.PASTEL_BLUE, darkPastel: Colors.PASTEL_DARK_BLUE },
  { lightPastel: Colors.PASTEL_WATERMELON, darkPastel: Colors.PASTEL_DARK_WATERMELON },
  { lightPastel: Colors.PASTEL_GREEN_YELLOW, darkPastel: Colors.PASTEL_DARK_GREEN_YELLOW },
  { lightPastel: Colors.PASTEL_PURPLE, darkPastel: Colors.PASTEL_DARK_PURPLE },
  { lightPastel: Colors.PASTEL_MINT_GREEN, darkPastel: Colors.PASTEL_DARK_MINT_GREEN },
  { lightPastel: Colors.PASTEL_DARK_PINK, darkPastel: Colors.PASTEL_PINK },
  { lightPastel: Colors.PASTEL_YELLOW, darkPastel: Colors.PASTEL_DARK_YELLOW },
  { lightPastel: Colors.PASTEL_MAUVE, darkPastel: Colors.PASTEL_DARK_MAUVE },
  { lightPastel: Colors.PASTEL_GREEN, darkPastel: Colors.PASTEL_DARK_GREEN },
  { lightPastel: Colors.PASTEL_RED, darkPastel: Colors.PASTEL_DARK_RED },
  { lightPastel: Colors.PASTEL_AQUA, darkPastel: Colors.PASTEL_DARK_AQUA },
  { lightPastel: Colors.PASTEL_ORANGE, darkPastel: Colors.PASTEL_DARK_ORANGE },
  { lightPastel: Colors.PASTEL_SALMON, darkPastel: Colors.PASTEL_DARK_SALMON },
];

export const INTERVAL_BETWEEN_COLORS = 100;

export const CHART_LINE_TRANSPARENCY = 0.85;

export const transparentize = (color: Colors, transparency = 0): Colors => {
  if (color.length === 9) {
    color = color.slice(0, -2) as Colors;
  }
  const opacity = Math.round(Math.min(1 - transparency, 1) * 255);
  return (color + opacity.toString(16).toUpperCase()) as Colors;
};

const addZeros = (numberOfZeros: number, value: string) => {
  for (let index = 0; index < numberOfZeros; index++) {
    value = "0" + value;
  }
  return value;
};

export const lightenDarkenColor = (color: Colors, amount: number): Colors => {
  color = color.slice(1) as Colors;

  const colorBase10 = parseInt(color, 16);

  let r = (colorBase10 >> 16) + amount;
  if (r > 255) r = 255;
  else if (r < 0) r = 0;

  let b = ((colorBase10 >> 8) & 0x00ff) + amount;
  if (b > 255) b = 255;
  else if (b < 0) b = 0;

  let g = (colorBase10 & 0x0000ff) + amount;
  if (g > 255) g = 255;
  else if (g < 0) g = 0;

  const result = (g | (b << 8) | (r << 16)).toString(16);

  return (result.length < 6 ? `#${addZeros(6 - result.length, result)}` : `#${result}`) as Colors;
};

const normalizeRgbValue = (color: number, m: number) => {
  color = Math.floor((color + m) * 255);
  if (color < 0) {
    color = 0;
  }
  return color;
};

const rgbToHex = (red: number, green: number, blue: number): Colors => `#${((1 << 24) + (red << 16) + (green << 8) + blue).toString(16).slice(1)}` as Colors;

const rgbToHSL = (rgb: string) => {
  // strip the leading # if it's there
  rgb = rgb.replace(/^\s*#|\s*$/g, "");

  // convert 3 char codes --> 6, e.g. `E0F` --> `EE00FF`
  if (rgb.length == 3) {
    rgb = rgb.replace(/(.)/g, "$1$1");
  }

  const red = parseInt(rgb.substr(0, 2), 16) / 255;
  const green = parseInt(rgb.substr(2, 2), 16) / 255;
  const blue = parseInt(rgb.substr(4, 2), 16) / 255;
  const colorMax = Math.max(red, green, blue);
  const colorMin = Math.min(red, green, blue);
  const delta = colorMax - colorMin;
  const lightness = (colorMax + colorMin) / 2;
  let hue = 0;
  let saturation = 0;

  if (delta == 0) {
    hue = 0;
  } else if (colorMax == red) {
    hue = 60 * (((green - blue) / delta) % 6);
  } else if (colorMax == green) {
    hue = 60 * ((blue - red) / delta + 2);
  } else {
    hue = 60 * ((red - green) / delta + 4);
  }

  if (delta == 0) {
    saturation = 0;
  } else {
    saturation = delta / (1 - Math.abs(2 * lightness - 1));
  }

  return {
    hue,
    saturation,
    lightness,
  };
};

const hslToRGB = (hsl: HSL): Colors => {
  const hue = hsl.hue;
  const saturation = hsl.saturation;
  const lightness = hsl.lightness;
  const c = (1 - Math.abs(2 * lightness - 1)) * saturation;
  const x = c * (1 - Math.abs(((hue / 60) % 2) - 1));
  const m = lightness - c / 2;
  let red = 0;
  let green = 0;
  let blue = 0;

  if (hue < 60) {
    red = c;
    green = x;
    blue = 0;
  } else if (hue < 120) {
    red = x;
    green = c;
    blue = 0;
  } else if (hue < 180) {
    red = 0;
    green = c;
    blue = x;
  } else if (hue < 240) {
    red = 0;
    green = x;
    blue = c;
  } else if (hue < 300) {
    red = x;
    green = 0;
    blue = c;
  } else {
    red = c;
    green = 0;
    blue = x;
  }

  red = normalizeRgbValue(red, m);
  green = normalizeRgbValue(green, m);
  blue = normalizeRgbValue(blue, m);

  return rgbToHex(red, green, blue);
};

export const changeHue = (rgb: Colors, degree: number): Colors => {
  const hsl = rgbToHSL(rgb.toString());
  hsl.hue += degree;
  if (hsl.hue > 360) {
    hsl.hue -= 360;
  } else if (hsl.hue < 0) {
    hsl.hue += 360;
  }
  return hslToRGB(hsl);
};

export const getStatusColor = (statusCode: StatusCode, reconnected?: boolean | null) => {
  switch (statusCode) {
    default:
    case StatusCode.OK:
      return Colors.LIGHT_BLUE;
    case StatusCode.PAD_DISCONNECTED:
    case StatusCode.CONNECTION_LOST:
      if (reconnected) return Colors.YELLOW;
      return Colors.RED;
    case StatusCode.REBOOT:
    case StatusCode.INCOMPATIBLE_MATS:
    case StatusCode.INCOMPATIBLE_BLACK_BOX:
    case StatusCode.FIRMWARE_UPDATE:
      return Colors.GRAY_100;
  }
};
