import { AuthErrors } from "@technis/shared";
import React, { FunctionComponent } from "react";
import { IoIosAlert, IoIosPulse, IoIosSad } from "react-icons/io";
import { MdSignalWifiOff } from "react-icons/md";

import { translate } from "../../../lang/i18n";
import { translation } from "../../../lang/translation";
import { CommonError } from "./CommonError";

const { missingEventId, missingInstallation, missingKpiData, noAccess, noInternet, noServer, noToken } = translation.errors;

const inEnumReversed = (enu: Record<string, string>, value: string) => Object.values(enu).find(e => value.includes(e));
const NETWORK_ERROR_SUBSTRING = "Failed to fetch";

export const NoNetworkError: FunctionComponent<{ className?: string }> = ({ className }) => (
  <CommonError Icon={MdSignalWifiOff} iconSize={90} title={noInternet.title} subtitle={<span>{translate(noInternet.subtitle)}</span>} className={className} />
);

export const NotAuthorizedError: FunctionComponent<{ className?: string }> = ({ className }) => (
  <CommonError Icon={IoIosAlert} iconSize={90} title={noAccess.title} subtitle={<span>{translate(noAccess.subtitle)}</span>} className={className} />
);

export const NoTokenError = () => <CommonError Icon={IoIosSad} iconSize={90} title={noToken.title} subtitle={<span>{translate(noToken.subtitle)}</span>} />;

export const NoServerError: FunctionComponent<{ className?: string }> = ({ className }) => (
  <CommonError Icon={IoIosPulse} iconSize={80} title={noServer.title} subtitle={noServer.subtitle} className={className || "error-fullscreen"} />
);

export const ErrorSelection = ({
  DefaultError,
  errorClassName,
  message,
}: {
  message?: string;
  errorClassName?: string;
  DefaultError: React.ComponentType<Record<string, unknown>>;
}) => {
  if (!window.navigator.onLine) {
    return <NoNetworkError className={errorClassName} />;
  }
  if (message) {
    if (message.includes(NETWORK_ERROR_SUBSTRING)) {
      return <NoServerError className={errorClassName} />;
    }
    if (inEnumReversed(AuthErrors, message)) {
      return <NotAuthorizedError className={errorClassName} />;
    }
  }
  return <DefaultError />;
};

export const MissingInstallation = () => (
  <CommonError Icon={IoIosSad} iconSize={90} title={missingInstallation.title} subtitle={<span>{translate(missingInstallation.subtitle)}</span>} />
);

export const MissingKpiData = () => <CommonError Icon={IoIosSad} iconSize={90} title={missingKpiData.title} subtitle={<span>{translate(missingKpiData.subtitle)}</span>} />;

export const MissingEventId = () => <CommonError Icon={IoIosSad} iconSize={90} title={missingEventId.title} subtitle={<span>{translate(missingEventId.subtitle)}</span>} />;
