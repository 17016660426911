import { DeviceID, ID, Translation, UserID } from "@technis/shared";
import { ApolloQueryResult, MutationOptions, OperationVariables, QueryOptions, SubscriptionOptions } from "apollo-client";
import { DocumentNode } from "graphql";

import { apollo } from "../api/apollo";
import { FetchPolicies } from "../utils/constants";
import { removeUndefined } from "../utils/utils";
import { renewToken, RenewTokenResult } from "./auth.gql";
import { eventById, EventByIdResult } from "./event.gql";
import { organizationById, OrganizationByIdResult, organizationsByUser, OrganizationsByUserResult } from "./organization.gql";
import { padById, PadByIdResult, padsByOrganization, PadsByOrganizationResult, padsByUser, PadsByUserResult } from "./pad.gql";
import { passageById, PassageByIdResult } from "./passage.gql";
import { periodById, PeriodByIdResult, periodsByEvent, PeriodsByEventResult } from "./period.gql";
import { queryTranslationDetails, QueryTranslationDetailsResult, QueryTranslationResult, queryTranslations } from "./translation.gql";
import { userById, UserByIdResult, usersByOrganization, UsersByOrganizationResult } from "./user.gql";
import { zoneById, ZoneByIdResult, zonesByOrganization, ZonesByOrganizationResult, zonesByPlan, ZonesByPlanResult } from "./zones.gql";

const client = {
  refresh: (query: DocumentNode, variables: OperationVariables = {}, options?: Omit<QueryOptions<OperationVariables>, "query" | "variables" | "fetchPolicy">) =>
    apollo.client.query({
      query,
      variables: removeUndefined(variables),
      ...(options || {}),
      fetchPolicy: FetchPolicies.NETWORK_ONLY,
    }),
  query: (query: DocumentNode, variables: OperationVariables = {}, options?: Omit<QueryOptions<OperationVariables>, "query" | "variables">) =>
    apollo.client.query({ query, variables: removeUndefined(variables), ...(options || {}) }),
  mutate: (mutation: DocumentNode, variables: OperationVariables = {}, options?: Omit<MutationOptions<OperationVariables>, "variables">) =>
    apollo.client.mutate({ mutation, variables: removeUndefined(variables), ...(options || {}) }),
  subscribe: (query: DocumentNode, variables: OperationVariables = {}, options?: Omit<SubscriptionOptions<OperationVariables>, "variables">) =>
    apollo.client.subscribe({ query, variables: removeUndefined(variables), ...(options || {}) }),
};

type Query<T> = ApolloQueryResult<T>;

export namespace gql {
  export const Auth = {
    renew: (): Promise<Query<RenewTokenResult>> => client.query(renewToken, {}, { fetchPolicy: FetchPolicies.NETWORK_ONLY }),
  };
  export const Event = {
    get: (eventId: ID): Promise<Query<EventByIdResult>> => client.query(eventById, { eventId }),
  };
  export const Organization = {
    byUser: (): Promise<Query<OrganizationsByUserResult>> => client.query(organizationsByUser, {}, { fetchPolicy: FetchPolicies.NETWORK_ONLY }),
    get: (organizationId: ID): Promise<Query<OrganizationByIdResult>> => client.query(organizationById, { organizationId }),
  };
  export const Pad = {
    byUser: (): Promise<Query<PadsByUserResult>> => client.query(padsByUser, {}, { fetchPolicy: FetchPolicies.NETWORK_ONLY }),
    byOrganization: (organizationId: ID): Promise<Query<PadsByOrganizationResult>> => client.query(padsByOrganization, { organizationId }),
    get: (padId: DeviceID): Promise<Query<PadByIdResult>> => client.query(padById, { padId }),
  };
  export const Passage = {
    get: (passageId: ID): Promise<Query<PassageByIdResult>> => client.query(passageById, { passageId }),
    refresh: (passageId: ID): Promise<Query<PassageByIdResult>> => client.refresh(passageById, { passageId }),
  };
  export const Period = {
    byEvent: (eventId: ID): Promise<Query<PeriodsByEventResult>> => client.query(periodsByEvent, { eventId }),
    get: (periodId: ID): Promise<Query<PeriodByIdResult>> => client.query(periodById, { periodId }),
  };
  //TODO: Add template when backend ready
  export const Template = {};
  export const User = {
    byOrganization: (organizationId: ID): Promise<Query<UsersByOrganizationResult>> => client.query(usersByOrganization, { organizationId }),
    get: (userId?: UserID): Promise<Query<UserByIdResult>> => client.query(userById, { userId }),
  };
  export const Zone = {
    byOrganization: (organizationId: ID): Promise<Query<ZonesByOrganizationResult>> => client.query(zonesByOrganization, { organizationId }),
    byPlan: (zoneId: ID): Promise<Query<ZonesByPlanResult>> => client.query(zonesByPlan, { zoneId }),
    get: (zoneId: ID): Promise<Query<ZoneByIdResult>> => client.query(zoneById, { zoneId }),
  };
  export const Translation = {
    translationDetails: (): Promise<Query<QueryTranslationDetailsResult>> => client.query(queryTranslationDetails),
    translations: (): Promise<Query<QueryTranslationResult>> => client.query(queryTranslations),
  };
}
