import { useEffect } from "react";
import { useCookies } from "react-cookie";

import { ThemeVariant } from "../components/common/Theme";
import { SECONDS_IN_YEAR } from "../constants/date";
import { saveToken } from "../redux/auth/auth.actions";

const [domain, extension] = window.location.hostname.split(".").splice(-2);
const domainPrefix = extension ? "." : "";
const domainSuffix = extension ? `.${extension}` : "";

const COOKIE_PREFIX = process.env.LINK_ENV === "production" ? "" : `${process.env.LINK_ENV}-`;
export const COOKIE_DOMAIN = `${domainPrefix}${domain}${domainSuffix}`;
export const COOKIE_ANALYTICS = `${COOKIE_PREFIX}analytics`;
export const COOKIE_THEME = "themeTechnis";

export const useCookieToken = () => {
  const [cookies] = useCookies([]);
  const cookieToken: string = cookies[COOKIE_ANALYTICS];
  useEffect(() => {
    saveToken(cookieToken);
  }, [cookieToken]);
  return cookieToken;
};

export const useCookieTheme = () => {
  const [cookies, setCookie] = useCookies([]);
  const setThemeCookie = (theme: ThemeVariant) =>
    setCookie(COOKIE_THEME, theme, {
      path: "/",
      maxAge: SECONDS_IN_YEAR,
      domain: COOKIE_DOMAIN,
      secure: true,
    });
  return [cookies[COOKIE_THEME], setThemeCookie];
};
