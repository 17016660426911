import React, { FunctionComponent } from "react";
import { AiFillAppstore } from "react-icons/ai";

import { UI } from "../../../constants";
import { translate } from "../../../lang/i18n";
import { translation } from "../../../lang/translation";
import { AggregatedKpiCountingWithName } from "../../../pages/AnalyticsHomePage";
import { MostUsedChart } from "../../charts/MostUsedChart";
import { ZoneSetting } from "../Header";
import { CommonModule } from "./CommonModule";

const { MODULE_TITLE_ICON_SIZE } = UI;

const { analytics, common } = translation;

interface MostUsedZoneModuleProps {
  zoneKpiAggregated: AggregatedKpiCountingWithName[];
  zoneSettings: ZoneSetting[];
  onDelete: () => void;
}

export enum ChartType {
  PIE,
  BAR,
  SANKEY,
}
export const MostUsedZonesModule: FunctionComponent<MostUsedZoneModuleProps> = props => {
  const { onDelete, zoneKpiAggregated, zoneSettings } = props;
  const getVisitors = (aggregatedKpi: AggregatedKpiCountingWithName) => aggregatedKpi.affluenceMinIn?.flat().sum() || 0;

  return (
    <CommonModule
      iconSize={MODULE_TITLE_ICON_SIZE}
      title={translate(analytics.modules.titles.mostUsedzones)}
      tooltipText={translate(analytics.modules.tooltips.mostUsedzones)}
      Icon={AiFillAppstore}
      onDelete={onDelete}
      isExportable
    >
      <MostUsedChart
        getVisitors={getVisitors}
        kpisAggregated={zoneKpiAggregated}
        barHeader={translate(common.visitors)}
        labelHeader={translate(common.zones)}
        chartType={ChartType.BAR}
        zoneSettings={zoneSettings}
      />
    </CommonModule>
  );
};
