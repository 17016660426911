import { UserLanguage } from "@technis/shared";
import { Action, Dispatch } from "redux";

import { SupportedLanguages } from "../../lang/i18n";
import { TimeFormats } from "../../utils/time";

export enum UserOptionsActions {
  SAVE_LANGUAGE = "SAVE_LANGUAGE",
  SAVE_TIME_FORMAT = "SAVE_TIME_FORMAT",
  USE_TIME_FORMAT_FROM_LANGUAGE = "USE_LANGUAGE_AS_TIME_FORMAT",
}

export type UserOptionsAction = SaveLanguageAction | SaveTimeFormat | UseTimeFormatFromLanguage;

interface SaveLanguageAction extends Action<UserOptionsActions.SAVE_LANGUAGE> {
  language?: SupportedLanguages | UserLanguage;
}

interface SaveTimeFormat extends Action<UserOptionsActions.SAVE_TIME_FORMAT> {
  timeFormat?: TimeFormats;
}

interface UseTimeFormatFromLanguage extends Action<UserOptionsActions.USE_TIME_FORMAT_FROM_LANGUAGE> {
  useTimeFormatFromLanguage?: boolean;
}

export const saveLanguage = (language?: SupportedLanguages | UserLanguage) => (dispatch: Dispatch<SaveLanguageAction>) => {
  dispatch({ type: UserOptionsActions.SAVE_LANGUAGE, language });
};

export const saveTimeFormat = (timeFormat?: TimeFormats) => (dispatch: Dispatch<SaveTimeFormat>) => {
  dispatch({ type: UserOptionsActions.SAVE_TIME_FORMAT, timeFormat });
};

export const saveTimeFormatOptionFromLanguage = (useTimeFormatFromLanguage: boolean) => (dispatch: Dispatch<UseTimeFormatFromLanguage>) => {
  dispatch({ type: UserOptionsActions.USE_TIME_FORMAT_FROM_LANGUAGE, useTimeFormatFromLanguage });
};
