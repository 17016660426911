import classnames from "classnames";
import React, { FunctionComponent } from "react";

import { Granularity } from "../../../utils/aggregate";
import { Colors, getStatusColor } from "../../../utils/colors";
import { formatChartTime, getPeriodLength, rounding } from "../../../utils/utils";
import { DataByDay } from "../../charts/OverviewChart";
import { DateDisplay } from "../DateDisplay";

const BAR_MIN_DISPLAY_INSIDE = 0.12;

interface DateBarsProps {
  dataByDay: DataByDay;
  centerText?: boolean;
  peakTime?: number;
  max?: number;
}

interface RangeProps {
  value: number;
  range: { start: number; end: number };
  color: Colors;
}

const Range: FunctionComponent<RangeProps> = props => {
  const { color, range, value } = props;
  return (
    <div className="range">
      <div className="range-time white-font">{formatChartTime(range.start)}</div>
      <div className="range-text" style={{ background: color }}>{`${rounding(value * 100, 2)}%`}</div>
      <div className="range-time white-font">{formatChartTime(range.end)}</div>
    </div>
  );
};

export const RangeBars: FunctionComponent<DateBarsProps> = props => {
  const { dataByDay, peakTime } = props;
  const { data, day } = dataByDay;

  return (
    <div className="horizontal-bar">
      <div className="label">{<DateDisplay day={day} peaktime={peakTime} />}</div>
      <div className="vertical-line line-color" />
      {data.map((zoneData, index) => (
        <Range key={`day-${day}-range-${index}`} value={zoneData.value} range={zoneData.timeWindowRange || { start: 0, end: 0 }} color={zoneData.color} />
      ))}
    </div>
  );
};

const BAR_MIN_WITH_PERCENT = 0.02;

interface BarProps {
  text: string;
  widthInPercent: number;
  centerText?: boolean;
  color?: Colors;
  stacked?: boolean;
}

const Bar: FunctionComponent<BarProps> = props => {
  const { widthInPercent, text, color = Colors.GREEN, centerText, stacked } = props;

  const minWidth = widthInPercent <= BAR_MIN_WITH_PERCENT ? BAR_MIN_WITH_PERCENT : widthInPercent;

  const isBarSmall = !stacked && widthInPercent <= BAR_MIN_DISPLAY_INSIDE;

  const invisibleMode = widthInPercent <= 0 && !stacked ? "none" : "";
  const visibleMode = !centerText ? "inline" : "flex";
  const display = invisibleMode || visibleMode;

  return (
    <>
      <div
        className="bar"
        style={{
          width: `${minWidth * 100}%`,
          backgroundColor: !stacked && text === "0" ? "unset" : color,
          display,
        }}
      >
        {!isBarSmall && (
          <div className="bar-text" style={{ marginLeft: !centerText ? "26px" : "unset" }}>
            {text}
          </div>
        )}
      </div>
      {isBarSmall && <div className="outside-bar-text white-font">{text}</div>}
    </>
  );
};

export const DateBars: FunctionComponent<DateBarsProps> = props => {
  const { centerText = false, dataByDay, max = 1, peakTime } = props;
  const { data, day } = dataByDay;
  return (
    <div className="horizontal-bar">
      <div className="label">{<DateDisplay day={day} peaktime={peakTime} />}</div>
      <div className="vertical-line line-color" />
      <div className="bars">
        {data.map((zoneOrPassageData, index) => {
          const percentage = zoneOrPassageData.value ? zoneOrPassageData.value / max : 0;

          return (
            <Bar
              key={`day-${day}-bar-${index}`}
              widthInPercent={percentage}
              text={zoneOrPassageData.text}
              centerText={centerText}
              color={zoneOrPassageData.color}
              stacked={data.length > 1}
            />
          );
        })}
      </div>
    </div>
  );
};

interface BarByZoneOrPassageProps {
  label: string;
  value: number;
  widthInPercent: number;
  labelClassName?: string;
  color?: Colors;
}

export const LabelledBar: FunctionComponent<BarByZoneOrPassageProps> = props => {
  const { label, value, widthInPercent, color = Colors.GREEN, labelClassName = "" } = props;
  return (
    <div className="horizontal-bar">
      <div className={`label ${labelClassName}`}>{label}</div>
      <div className="vertical-line line-color" />
      <div className="bars">
        <Bar text={value.toString()} widthInPercent={widthInPercent} color={color} />
      </div>
    </div>
  );
};

interface TimelineProps {
  timeline: TimelineData[];
  onClick: () => void;
  keyString: string;
  granularity: Granularity;
  active: boolean;
}

interface TimelineData {
  duration: number;
  statusCode: number;
  connection?: boolean | null;
  date: number;
}

export const Timeline: FunctionComponent<TimelineProps> = props => {
  const { active, granularity, keyString, onClick, timeline } = props;
  const unitSize = 100 / getPeriodLength(granularity);

  return (
    <div className={classnames("timeline", { active })} onClick={onClick}>
      {timeline.map(bar => (
        <div
          key={`${keyString}-bar-${bar.date}`}
          className="timeline-segment"
          style={{ backgroundColor: `${getStatusColor(bar.statusCode, bar.connection)}`, width: `${unitSize * bar.duration}%` }}
        />
      ))}
    </div>
  );
};
