import { Passage } from "@technis/shared";
import gql from "graphql-tag";

const ZonePassageData = `
... on ZoneCommon {
    id
    name
}
`;

export const PassageData = `
  id
  name
  zoneIn {
      ${ZonePassageData}
  }
  zoneOut {
      ${ZonePassageData}
  }
`;

export type PassageByIdResult = { passageById: Passage };
export const passageById = gql`
    query passageById($passageId: Int!) {
        passageById(passageId: $passageId) {
            ${PassageData}
        }
    }
`;
