import { ID } from "@technis/shared";
import React, { FunctionComponent } from "react";
// eslint-disable-next-line import/named
import { Cell, Pie, PieChart, PieLabelRenderProps, ResponsiveContainer, Sector } from "recharts";

import { UI } from "../../constants";
import { useSelector } from "../../hooks";
import { Colors, transparentize } from "../../utils/colors";
import { ButtonLegend } from "../common/custom-chart/ChartLegend";
import { PassageSetting } from "../common/Header";
import { getTheme, isVariantDark, ThemeVariant, useTheme } from "../common/Theme";

const { CHART_PIE_SIZE } = UI;

const RADIAN = Math.PI / 180;
const MIN_ANGLE = 20;

interface PieGraphProps {
  activeIndex: number;
  sectors: {
    orderIndex: number;
    id: ID;
    name: string;
    visitors: number;
    color: Colors;
    active: boolean;
  }[];
  dataKey: string;
  onMouseEnter: (_: unknown, index: number) => void;
  onClickLegend: (index: number) => void;
  passageSettings?: PassageSetting[];
}

export const PieGraph: FunctionComponent<PieGraphProps> = props => {
  const { activeIndex, dataKey, onClickLegend, onMouseEnter, passageSettings, sectors } = props;

  const { themeVariant } = useTheme();

  const isExporting = useSelector(state => state.app.isExporting);

  const { font } = getTheme(isExporting ? ThemeVariant.LIGHT : themeVariant);
  const DEFAULT_COLOR = isVariantDark() ? Colors.LIGHT_BLUE : Colors.AQUA;
  const sectorsDataWithoutZero = sectors.filter(sector => sector.visitors > 0);

  const renderCustomizedLabel = (props: PieLabelRenderProps) => {
    const { cx, cy, endAngle, fill, innerRadius, midAngle, outerRadius, payload, percent, startAngle, value } = props;
    const radius = Number(innerRadius) + (Number(outerRadius) - Number(innerRadius)) * 0.5;
    const x = Number(cx) + radius * Math.cos(-(midAngle ? midAngle : 1) * RADIAN);
    const y = Number(cy) + radius * Math.sin(-(midAngle ? midAngle : 1) * RADIAN);

    const sin = Math.sin(-RADIAN * Number(midAngle));
    const cos = Math.cos(-RADIAN * Number(midAngle));
    const sx = Number(cx) + (Number(outerRadius) + 10) * cos;
    const sy = Number(cy) + (Number(outerRadius) + 10) * sin;
    const mx = Number(cx) + (Number(outerRadius) + 30) * cos;
    const my = Number(cy) + (Number(outerRadius) + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? "start" : "end";

    return (
      <>
        {Number(percent) > 0 ? (
          <g>
            <Sector cx={Number(cx)} cy={Number(cy)} innerRadius={Number(innerRadius)} outerRadius={Number(outerRadius)} startAngle={startAngle} endAngle={endAngle} fill={fill} />
            <Sector
              cx={Number(cx)}
              cy={Number(cy)}
              startAngle={startAngle}
              endAngle={endAngle}
              innerRadius={Number(outerRadius) + 6}
              outerRadius={Number(outerRadius) + 10}
              fill={transparentize(fill ? (fill as Colors) : Colors.DEFAULT_COLOR, 0.4)}
            />
            <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={transparentize(fill ? (fill as Colors) : Colors.DEFAULT_COLOR, 0.4)} fill="none" />
            <circle cx={ex} cy={ey} r={2} fill={transparentize(fill ? (fill as Colors) : Colors.DEFAULT_COLOR, 0.4)} stroke="none" />
            <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill={font}>
              {payload.name}
            </text>
            <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill={DEFAULT_COLOR}>
              {`${value}`}
            </text>
            <text x={x} y={y} fill="white" textAnchor="middle" dominantBaseline="central">
              {`${(Number(percent) * 100).toFixed(0)}%`}
            </text>
          </g>
        ) : null}
      </>
    );
  };

  return (
    <>
      <div className="module-chart">
        <ResponsiveContainer width="100%" height={CHART_PIE_SIZE}>
          <PieChart width={CHART_PIE_SIZE} height={CHART_PIE_SIZE}>
            <Pie
              isAnimationActive={false}
              minAngle={MIN_ANGLE}
              activeIndex={activeIndex}
              label={renderCustomizedLabel}
              labelLine={false}
              outerRadius="70%"
              data={sectorsDataWithoutZero.filter(sector => sector.active)}
              cx="50%"
              cy="50%"
              dataKey={dataKey}
              onMouseEnter={onMouseEnter}
            >
              {sectorsDataWithoutZero
                .filter(sector => sector.active)
                .map(sector => (
                  <Cell key={`cell-${sector.id}`} stroke={sector.color} fill={sector.color} />
                ))}
            </Pie>
          </PieChart>
        </ResponsiveContainer>
      </div>
      {!!passageSettings && (
        <ButtonLegend
          legendItems={sectors.map((sector, index) => ({
            text: sector.name,
            color: sector.color,
            onClick: () => onClickLegend(index),
            active: passageSettings.find(setting => setting.id === sector.id)?.activated,
          }))}
        />
      )}
    </>
  );
};
