import React from "react";

import { getTheme } from "../Theme";

interface Props {
  width?: string | number;
  height?: string | number;
  className?: string;
  viewBox?: string;
  scale?: number;
}

/* eslint-disable max-len */
export const TechnisLogo = (props: Props) => {
  const { scale = 0.5, ...svgProps } = props;
  const { logo } = getTheme();
  return (
    <svg width={`${174 * scale}px`} height={`${157 * scale}px`} viewBox="0 0 174 157" fill="none" {...svgProps}>
      <rect width="174" height="157" fill="url(#pattern0)" />
      <defs>
        <pattern id="pattern0" patternContentUnits="objectBoundingBox" width="1" height="1">
          <use xlinkHref="#image0" transform="translate(0 -0.00150675) scale(0.00179211 0.00198617)" />
        </pattern>
        <image id="image0" width="558" height="505" xlinkHref={"data:image/png;base64," + logo} />
      </defs>
    </svg>
  );
};
