import React, { FunctionComponent, useState } from "react";
import { Layer } from "recharts";

interface PayloadType {
  percent: number;
  source: {
    color?: string;
  };
  target: {
    color?: string;
  };
}
interface SankeyLinkProps {
  sourceX?: number;
  targetX?: number;
  sourceY?: number;
  targetY?: number;
  sourceControlX?: number;
  targetControlX?: number;
  sourceRelativeY?: number;
  targetRelativeY?: number;
  linkWidth?: number;
  index?: number;
  payload?: PayloadType;
}

interface State {
  fill: string;
  opacity: string;
}

export const SankeyLink: FunctionComponent<SankeyLinkProps> = props => {
  const { index = 0, linkWidth = 0, payload, sourceControlX = 0, sourceX = 0, sourceY = 0, targetControlX = 0, targetX = 0, targetY = 0 } = props;
  const [state, setState] = useState<State>({
    fill: `url(#linkGradient${index})`,
    opacity: "0.7",
  });
  const { fill, opacity } = state;

  const onMouseEvent = (index: number, opacity: string) => {
    setState({ fill: `url(#linkGradient${index})`, opacity });
  };

  const midX = sourceX + (targetX - sourceX) / 2;
  const midY = sourceY + (targetY - sourceY) / 2;

  return (
    <Layer key={`CustomLink${index}`}>
      <defs>
        <linearGradient id={`linkGradient${index}`}>
          <stop offset="0%" stopColor={payload?.source.color} />
          <stop offset="60%" stopColor={payload?.source.color} stopOpacity="0.5" />
          <stop offset="100%" stopColor={payload?.target.color} stopOpacity="0.3" />
        </linearGradient>
      </defs>
      <path
        d={`
        M${sourceX},${sourceY + linkWidth / 2}
        C${sourceControlX},${sourceY + linkWidth / 2}
          ${targetControlX},${targetY + linkWidth / 2}
          ${targetX},${targetY + linkWidth / 2}
        L${targetX},${targetY - linkWidth / 2}
        C${targetControlX},${targetY - linkWidth / 2}
          ${sourceControlX},${sourceY - linkWidth / 2}
          ${sourceX},${sourceY - linkWidth / 2}
        Z
      `}
        fill={fill}
        strokeWidth="0"
        opacity={opacity}
        onMouseEnter={() => onMouseEvent(index, "0.4")}
        onMouseLeave={() => onMouseEvent(index, "0.7")}
      />
      <text textAnchor="middle" x={midX} y={midY} fontSize="14" stroke="#fff" fill="#fff">
        {payload?.percent}
      </text>
    </Layer>
  );
};
