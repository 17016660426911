import * as moment from "moment";
import React, { FunctionComponent, useEffect, useState } from "react";

import { i18n } from "../../lang/i18n";
import { translation } from "../../lang/translation";
import { setIsExporting } from "../../redux/app/app.actions";
import { store } from "../../redux/store";
import { exportBenchmark } from "../../utils/benchmark";
import { exportPdf } from "../../utils/export/exportPdf";
import { getTheme } from "../common/Theme";

export const DEBUG = false;

interface ExportLoadingProps {
  exportFeedBack: () => void;
  exportDate: moment.Moment;
  eventName: string;
}

export enum ExportStage {
  PREPARATION,
  LAYOUT_GENERATION,
  PDF_GENERATION,
}

export const ExportLoading: FunctionComponent<ExportLoadingProps> = props => {
  const { eventName, exportDate, exportFeedBack } = props;

  setIsExporting(true)(store.dispatch);

  const [stage, setStage] = useState<ExportStage>(ExportStage.PREPARATION);

  const updateStage = (stage: ExportStage) => setStage(stage);

  useEffect(() => {
    setTimeout(() => {
      DEBUG
        ? exportBenchmark(updateStage, exportDate, eventName).then(() => {
            exportFeedBack();
          })
        : exportPdf(updateStage, exportDate, eventName).then(() => {
            exportFeedBack();
          });
    }, 400);
  }, []);

  const getLoadingMessage = (stage: ExportStage) => {
    switch (stage) {
      case ExportStage.PREPARATION:
        return i18n.t(translation.analytics.export.loadingMessages.preparing);
      case ExportStage.LAYOUT_GENERATION:
        return i18n.t(translation.analytics.export.loadingMessages.documentLayout);
      case ExportStage.PDF_GENERATION:
        return i18n.t(translation.analytics.export.loadingMessages.writting);
    }
  };
  const { loader } = getTheme();
  return (
    <div className="export-loading" data-html2canvas-ignore>
      <div className="loading-container">
        <img src={loader} alt="loading..." />
        <div className="loading-text">{getLoadingMessage(stage)}</div>
      </div>
    </div>
  );
};
