import moment from "moment";
import React, { FunctionComponent, useEffect, useState } from "react";
// eslint-disable-next-line import/named
import { CartesianGrid, Line as LineRechart, LineChart, ReferenceArea, ResponsiveContainer, Tooltip, TooltipProps, XAxis, YAxis } from "recharts";
import { AxisDomain } from "recharts/types/util/types";

import { UI } from "../../constants";
import { useSelector } from "../../hooks";
import { translate } from "../../lang/i18n";
import { translation } from "../../lang/translation";
import { AggregatedKpiCountingWithName } from "../../pages/AnalyticsHomePage";
import { DateRange } from "../../utils/aggregate";
import { CHART_LINE_TRANSPARENCY, Colors, transparentize } from "../../utils/colors";
import { getInterval, getTicks } from "../../utils/time";
import { ActivityMessages, trackClick } from "../../utils/tracking";
import { checkKpiData, getFontSize, getLines, getSeries, getTickPosition, isDefined, isRangeSmallerThanPeriod, tickFormater } from "../../utils/utils";
import { ButtonLegend } from "../common/custom-chart/ChartLegend";
import { getTheme, ThemeVariant, useTheme } from "../common/Theme";
import { ChartError, ChartTooltipLines, getAxisDomain, getZoomExtremities, Line, MIN_ZOOM, XRenderChartTick, YRenderChartTick, ZoomButtons } from "./index";

const { CHART_HEIGHT } = UI;

export const DEFAULT_COLOR = Colors.PURPLE;
export const MEAN_COLOR = Colors.GREEN;

interface InsideChartProps {
  kpiData: AggregatedKpiCountingWithName;
  dateRange: DateRange;
}

interface State {
  left: number;
  right: number;
  top: string;
  bottom: string;
  refAreaLeft: string;
  refAreaRight: string;
  isZoom: boolean;
  lines: Line[];
  error?: string;
}

export const InsideChart: FunctionComponent<InsideChartProps> = props => {
  const { dateRange, kpiData } = props;
  const { inside = [], dateBegins = [], dateEnds = [], granularity, countMinMax } = kpiData;
  const isCountMinNegative = !!countMinMax && !!countMinMax[0] && countMinMax[0] < 0;

  const [state, setState] = useState<State>({
    left: 0,
    right: 0,
    top: "auto",
    bottom: isCountMinNegative ? "auto" : "0",
    refAreaLeft: "",
    refAreaRight: "",
    isZoom: false,
    lines: getSeries(inside, dateBegins, granularity),
    error: checkKpiData(kpiData, ["dateBegins", "dateEnds", "inside"]),
  });
  const { bottom, error, isZoom, left, lines, refAreaLeft, refAreaRight, right, top } = state;

  const insideTickFormater = tickFormater();

  const { themeVariant } = useTheme();
  const isExporting = useSelector(state => state.app.isExporting);
  const timeFormat = useSelector(state => state.userOptions.timeFormat);
  const language = useSelector(state => state.userOptions.language);
  const useTimeFormatFromLanguage = useSelector(state => state.userOptions.useTimeFormatFromLanguage);
  const { font } = getTheme(isExporting ? ThemeVariant.LIGHT : themeVariant);
  const fontSize = getFontSize(isExporting);
  const tickPosition = getTickPosition(isExporting);
  const data = getLines(lines, kpiData.granularity);

  const leftValue = isRangeSmallerThanPeriod(kpiData.dateBegins.length, dateRange) ? moment(dateRange.dateBegin).diff(moment(kpiData.dateBegins[0]), "minute") : 0;
  const rightValue = isRangeSmallerThanPeriod(kpiData.dateBegins.length, dateRange) ? moment(dateRange.dateEnd).diff(moment(kpiData.dateBegins[0]), "minute") : data.length;

  useEffect(() => {
    setState({ ...state, error: checkKpiData(kpiData, ["dateBegins", "dateEnds", "inside"]) });
  }, [props]);

  useEffect(() => {
    setState({
      ...state,
      left: leftValue,
      right: rightValue,
      refAreaLeft: "",
      refAreaRight: "",
      top: "auto",
      bottom: "auto",
      isZoom: false,
      lines: getSeries(inside, dateBegins, granularity),
    });
  }, [kpiData]);

  const zoom = (from: number, to: number, bottom?: string) => {
    if (from === to || (refAreaLeft && refAreaRight === "")) {
      setState(() => ({ ...state, refAreaLeft: "", refAreaRight: "" }));
      return;
    }

    const domain = getAxisDomain(data, from, to, lines);

    const isZoom = from !== leftValue || to !== rightValue;
    setState({
      ...state,
      refAreaLeft: "",
      refAreaRight: "",
      left: domain.refAreaLeft,
      right: domain.refAreaRight,
      bottom: isZoom ? bottom || domain.bottom : "auto",
      top: isZoom ? domain.top : "auto",
      isZoom,
    });
  };

  const onClick = (index: number) => {
    setState({ ...state, lines: lines.map((line, i) => (index === i ? { ...line, isActive: !line.isActive } : line)) });
  };

  const onMouseDown = (e: { activeLabel: string }) => {
    if (isDefined(e)) setState({ ...state, refAreaLeft: e.activeLabel });
  };
  const onMouseMove = (e: { activeLabel: string }) =>
    refAreaLeft &&
    setState({
      ...state,
      refAreaRight: e.activeLabel,
    });
  const onMouseUp = () => zoom(Number(refAreaLeft), Number(refAreaRight));

  if (error) {
    // TODO: beautify error when data missing
    return <ChartError error={error} />;
  }

  if (!inside || !dateBegins || !dateEnds || !countMinMax) {
    return <ChartError error={translate(translation.errors.missingKpiProperty)} />;
  }

  const tickNumber = right - left;
  const tickBorder = { startTick: left, endTick: right };
  const yDomain: AxisDomain = [Number(bottom), isZoom ? Number(top) : "auto"];
  const xDomain: AxisDomain = [left, right];
  const isZoomInDisabled = tickNumber < MIN_ZOOM;
  const zoomExtremities = getZoomExtremities(left, right);

  const onClickZoomIn = () => {
    trackClick(ActivityMessages.zoomIn);
    zoom(zoomExtremities.left, zoomExtremities.right);
  };
  const onClickZoomOut = () => {
    trackClick(ActivityMessages.zoomOut);
    zoom(leftValue, rightValue, "0");
  };

  return (
    <>
      <div className="module-chart">
        <ZoomButtons zoomOutDisabled={!isZoom} zoomInDisabled={isZoomInDisabled} onClickZoomIn={onClickZoomIn} onClickZoomOut={onClickZoomOut} />
        <ResponsiveContainer width="100%" height={CHART_HEIGHT + tickPosition}>
          <LineChart data={data} margin={{ top: 30, right: 20, bottom: -10 + tickPosition, left: 20 }} onMouseDown={onMouseDown} onMouseMove={onMouseMove} onMouseUp={onMouseUp}>
            <CartesianGrid stroke={transparentize(font, CHART_LINE_TRANSPARENCY)} />
            <XAxis
              allowDataOverflow={true}
              dataKey="time"
              axisLine={false}
              ticks={getTicks(data.length, getInterval(tickNumber, granularity), tickBorder)}
              tick={tickObject => (
                <XRenderChartTick
                  tickObject={tickObject}
                  granularity={granularity}
                  dateBegin={dateBegins[0]}
                  fontColor={font}
                  fontSize={fontSize}
                  tickPosition={tickPosition}
                  timeFormat={timeFormat}
                  language={language}
                  useTimeFormatFromLanguage={useTimeFormatFromLanguage}
                />
              )}
              tickFormatter={insideTickFormater}
              stroke={transparentize(font, CHART_LINE_TRANSPARENCY)}
              tickMargin={12}
              domain={xDomain}
              scale="time"
              type="number"
            />
            <YAxis
              allowDataOverflow={true}
              tickLine={false}
              axisLine={false}
              tick={tickObject => <YRenderChartTick tickObject={tickObject} fontColor={font} fontSize={fontSize} />}
              domain={yDomain}
              type="number"
              scale="linear"
            />
            <Tooltip content={(tooltipObject: TooltipProps<number, string>) => <ChartTooltipLines tooltip={tooltipObject} startDate={dateBegins[0]} decimal={0} />} />
            {lines.map(line => line.isActive && <LineRechart type="monotone" dataKey={line.name} key={line.name} stroke={line.color} dot={false} />)}
            {refAreaLeft && refAreaRight ? <ReferenceArea x1={refAreaLeft} x2={refAreaRight} strokeOpacity={0.1} /> : null}
          </LineChart>
        </ResponsiveContainer>
      </div>
      <ButtonLegend
        legendItems={lines.map((line, index) => ({
          onClick: () => onClick(index),
          color: line.color,
          text: line.name,
          active: line.isActive,
        }))}
      />
    </>
  );
};
