import { UserLanguage } from "@technis/shared";
import { Tooltip } from "antd";
import * as moment from "moment";
import React, { FunctionComponent, useRef } from "react";
import { AiOutlineFrown, AiOutlineZoomIn, AiOutlineZoomOut } from "react-icons/ai";
// eslint-disable-next-line import/named
import { TooltipProps } from "recharts";

import { Dates, UI } from "../../constants";
import { SupportedLanguages, translate } from "../../lang/i18n";
import { translation } from "../../lang/translation";
import { Granularity } from "../../utils/aggregate";
import { Colors } from "../../utils/colors";
import { getTimeFormat, TimeFormats } from "../../utils/time";
import { getDayLocale, isDefined, isGranularityDay, isNumber, rounding } from "../../utils/utils";
import { CustomButton } from "../common/buttons/CustomButton";
import { ChartTooltip } from "../common/custom-chart/ChartTooltip";

const { CHART_Y_AXIS_TICK_ANGLE } = UI;
const { max, mean, min } = translation.common;
const { HOUR_MINUTE_FORMAT, MILLISECONDS_IN_MINUTE } = Dates;
const { labels } = translation.analytics.modules;

export const ZOOM_PERCENT = 0.2;
export const MIN_ZOOM = 20;
const OFFSET_IN_PERCENT = 0.1;

export const ASCENDENT_DIRECTION: Direction = "asc";
export const DESCENDENT_DIRECTION: Direction = "desc";

export type Direction = "desc" | "asc";
export interface LineData {
  [k: string]: number;
}

export interface Line {
  isActive: boolean;
  name: string;
  lineType: LineType;
  data: {
    time: number;
    value: number;
  }[];
  color: Colors;
}

export enum LineType {
  MAX_LINE,
  MIN_LINE,
  MEAN_LINE,
  TODAY_LINE,
  OTHER,
}

export enum KpiAtmosphereType {
  CO2,
  TEMPERATURE,
  HUMIDITY,
}

export enum ViewType {
  BROWSER,
  EXPORT,
}

interface XRenderChartTickProps {
  tickObject: {
    index: number;
    x: number;
    y: number;
    visibleTicksCount: number;
    payload: {
      value: number;
    };
  };
  granularity: Granularity;
  fontSize: number;
  tickPosition: number;
  dateBegin?: number;
  fontColor?: Colors;
  timeFormat?: TimeFormats;
  language?: UserLanguage | SupportedLanguages;
  useTimeFormatFromLanguage?: boolean;
}

export const XRenderChartTick: FunctionComponent<XRenderChartTickProps> = props => {
  const { dateBegin, granularity, tickObject, fontColor = Colors.WHITE, fontSize, tickPosition, timeFormat, language, useTimeFormatFromLanguage } = props;
  let marginLeft = "0";
  let time = "";

  if (dateBegin) {
    if (granularity === Granularity.CONTINUOUS_WEEKS) {
      time = getDayLocale(dateBegin + tickObject.payload.value * MILLISECONDS_IN_MINUTE);
    } else {
      time = moment(dateBegin + tickObject.payload.value * MILLISECONDS_IN_MINUTE).format(getTimeFormat(timeFormat, language, useTimeFormatFromLanguage));
    }
  }

  if (tickObject.index === tickObject.visibleTicksCount - 1) {
    if (isGranularityDay(granularity)) {
      marginLeft = "-29";
    } else if (granularity === Granularity.CONTINUOUS_WEEKS) {
      time = " ";
    } else {
      marginLeft = "-55";
    }
  } else if (tickObject.index === 0) {
    marginLeft = "0";
  } else {
    if (isGranularityDay(granularity)) {
      marginLeft = "-15";
    }
  }
  return (
    <g transform={`translate(${marginLeft}, ${tickPosition})`}>
      <text fontSize={fontSize} fontWeight={400} x={tickObject.x} y={tickObject.y} fill={fontColor}>
        {time}
      </text>
    </g>
  );
};

interface YRenderChartTickProps {
  tickObject: {
    index: number;
    x: number;
    y: number;
    payload: {
      value: number | string;
    };
  };
  fontSize: number;
  fontColor?: Colors;
}

export const YRenderChartTick: FunctionComponent<YRenderChartTickProps> = props => {
  const {
    tickObject: {
      payload: { value },
      x,
      y,
    },
    fontColor = Colors.WHITE,
    fontSize,
  } = props;
  return (
    <g transform={`translate(${x}, ${y})`}>
      <text fontSize={fontSize} fontWeight={400} x={0} y={0} dx={-5} textAnchor="end" transform={`rotate(${CHART_Y_AXIS_TICK_ANGLE})`} fill={fontColor}>
        {typeof value === "number" ? rounding(value, 0) : value}
      </text>
    </g>
  );
};

interface ChartTooltipLinesProps {
  tooltip: TooltipProps<number, string>;
  startDate: number;
  decimal: number;
}

export const ChartTooltipLines: FunctionComponent<ChartTooltipLinesProps> = props => {
  const { decimal, startDate, tooltip } = props;
  const payload = tooltip?.payload;

  if (!payload || !(payload.length > 0 && startDate) || !tooltip.label || !tooltip.active) {
    return null;
  }

  return (
    <ChartTooltip
      title={`${moment(startDate + payload[0].payload.time * MILLISECONDS_IN_MINUTE).format(HOUR_MINUTE_FORMAT)}`}
      tooltipData={payload.map(p => ({ label: p.name || "", value: rounding(p.value as number, decimal), color: p.color }))}
    />
  );
};

interface ChartTooltipMaxMeanMinProps {
  tooltip: TooltipProps<number, string>;
  startDate: number;
  decimal: number;
  colors: {
    maxColor: Colors;
    meanColor: Colors;
    minColor: Colors;
  };
}

export const ChartTooltipMaxMeanMin: FunctionComponent<ChartTooltipMaxMeanMinProps> = props => {
  const { colors, decimal, startDate, tooltip } = props;
  if (!tooltip.payload) {
    return null;
  }
  const payload = tooltip.payload && tooltip.payload[0] && tooltip.payload[0].payload;
  if (payload && startDate) {
    return (
      <ChartTooltip
        title={`${moment(startDate + payload.time * MILLISECONDS_IN_MINUTE).format(HOUR_MINUTE_FORMAT)}`}
        tooltipData={[
          {
            label: translate(max),
            value: isDefined(payload.max) ? rounding(payload.max, decimal) : "--",
            color: colors.maxColor,
          },
          {
            label: translate(mean),
            value: isDefined(payload.mean) && !isNaN(payload.mean) ? rounding(payload.mean, decimal) : "--",
            color: colors.meanColor,
          },
          {
            label: translate(min),
            value: isDefined(payload.min) ? rounding(payload.min, decimal) : "--",
            color: colors.minColor,
          },
        ]}
      />
    );
  }
  return null;
};

interface ZoomButtonsProps {
  onClickZoomIn: () => void;
  onClickZoomOut: () => void;
  zoomOutDisabled: boolean;
  zoomInDisabled: boolean;
}

export const ZoomButtons: FunctionComponent<ZoomButtonsProps> = props => {
  const { onClickZoomIn, onClickZoomOut, zoomInDisabled, zoomOutDisabled } = props;
  const ref = useRef(null);
  return (
    <div className="zoom-buttons-container ignore-export" ref={ref}>
      <CustomButton buttonDisplay={<AiOutlineZoomOut />} onClick={onClickZoomOut} buttonClassName="zoom-out-button" disabled={zoomOutDisabled} />
      <div className="division-line" />
      <CustomButton
        buttonDisplay={
          <Tooltip
            title={translate(translation.analytics.modules.tooltips.zoom)}
            getPopupContainer={() => ref.current || document.createElement("div")}
            placement={"topRight"}
            mouseEnterDelay={0.75}
          >
            <AiOutlineZoomIn />
          </Tooltip>
        }
        onClick={onClickZoomIn}
        buttonClassName="zoom-in-button"
        disabled={zoomInDisabled}
      />
    </div>
  );
};

interface ChartErrorProps {
  error: string;
}

export const ChartError: FunctionComponent<ChartErrorProps> = props => {
  const { error } = props;
  return (
    <div className="module-error">
      <AiOutlineFrown color={Colors.RED} />
      <div className="module-error-text">{error}</div>
    </div>
  );
};

const getAxisYDomain = (initialData: LineData[], from: number, to: number, dataKey: keyof LineData, offset?: number) => {
  const lineDatas = initialData.slice(Math.max(0, from - 1), to);
  const startValue = lineDatas[0][dataKey];

  const [bottom, top] = lineDatas
    .filter(lineData => isDefined(lineData[dataKey]))
    .reduce(
      ([bottom, top], lineData) => {
        const data = lineData[dataKey];
        return [Math.min(data, bottom), Math.max(data, top)];
      },
      isNumber(startValue) ? [startValue, startValue] : [NaN, NaN],
    );

  const upperOffset = offset ? offset : Math.round(Math.abs(top) * OFFSET_IN_PERCENT);
  const lowerOffset = offset ? offset : Math.round(Math.abs(bottom) * OFFSET_IN_PERCENT);

  return [isNumber(bottom) ? bottom - lowerOffset : NaN, isNumber(top) ? top + upperOffset : NaN];
};

export const getAxisDomain = (initialData: LineData[], refAreaLeft: number, refAreaRight: number, lines?: Line[]) => {
  // xAxis domain
  if (refAreaLeft > refAreaRight) [refAreaLeft, refAreaRight] = [refAreaRight, refAreaLeft];

  // yAxis domain
  if (!lines) {
    const [bottomMax, topMax] = getAxisYDomain(initialData, refAreaLeft, refAreaRight, "max", 10);
    const [bottomMean, topMean] = getAxisYDomain(initialData, refAreaLeft, refAreaRight, "mean", 10);
    const [bottomMin, topMin] = getAxisYDomain(initialData, refAreaLeft, refAreaRight, "min", 10);

    return {
      bottom: Math.min(bottomMax, bottomMean, bottomMin).toString(),
      top: Math.max(topMax, topMean, topMin).toString(),
      refAreaLeft,
      refAreaRight,
    };
  }

  const keys = Object.keys(initialData[0]).filter(key => key !== "time");
  const bottomTopPairs = keys.map(key => getAxisYDomain(initialData, refAreaLeft, refAreaRight, key));

  return {
    bottom: Math.min(...bottomTopPairs.filter(pair => isNumber(pair[0])).map(pair => pair[0])).toString(),
    top: Math.max(...bottomTopPairs.filter(pair => isNumber(pair[1])).map(pair => pair[1])).toString(),
    refAreaLeft,
    refAreaRight,
  };
};

export const getZoomExtremities = (left: number, right: number) => {
  const zoomStep = Math.floor((right - left) * ZOOM_PERCENT);
  return { left: left + zoomStep / 2, right: right - zoomStep / 2 };
};

export const getChartTitle = (chartType: KpiAtmosphereType) => {
  const chartTypes = {
    [KpiAtmosphereType.CO2]: labels.co2,
    [KpiAtmosphereType.HUMIDITY]: labels.humidity,
    [KpiAtmosphereType.TEMPERATURE]: labels.temperature,
  };

  return translate(chartTypes[chartType]);
};

export const createLegend = (onClickLegend: (index: number) => void) => (line: Line, index: number) => ({
  onClick: () => onClickLegend(index),
  color: line.color,
  text: line.name,
  active: line.isActive,
});
