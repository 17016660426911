import { ID, Maybe, NeededOnly, Period } from "@technis/shared";
import gql from "graphql-tag";

const PeriodData = `
... on PeriodCommon {
    id
    name
    startAt
    endAt
}
`;

export type PeriodsByEventResult = {
  periodsByEvent: Array<
    NeededOnly<Period, "id" | "name" | "dateBegin" | "dateEnd"> & {
      periodConfigPlanificationId: Maybe<ID>;
    }
  >;
};
export const periodsByEvent = gql`
    query periodsByEvent($eventId: Int!) {
        periodsByEvent(eventId: $eventId) {
            ${PeriodData}
            ... on PeriodPlanification {
                periodConfigPlanificationId
            }
        }
    }
`;

export type PeriodByIdResult = { periodById: Period };
export const periodById = gql`
    query periodById($periodId: Int!) {
        periodById(periodId: $periodId) {
            ${PeriodData}
        }
    }
`;
