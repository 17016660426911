import React, { FunctionComponent } from "react";

import { Colors } from "../../../utils/colors";

interface LegendSymbolProps {
  color: Colors;
}

export const LegendSymbol: FunctionComponent<LegendSymbolProps> = props => {
  const { color } = props;
  return <div className="module-legend-symbol" style={{ background: color }} />;
};
