import { Status } from "@technis/shared";
import gql from "graphql-tag";

export interface StatusWithReconnected extends Status {
  reconnected: boolean | null;
}

export type StatusByEventDatesResult = {
  statusByEventDates: Array<StatusWithReconnected>;
};

export const statusByEventDates = gql`
  query statusByEventDates($eventId: Int!, $dates: [Float!]!) {
    statusByEventDates(eventId: $eventId, dates: $dates) {
      msg
      statusCode
      padId
      dateReceived
      reconnected
    }
  }
`;
