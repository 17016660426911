import React, { FunctionComponent } from "react";
import { Layer, Rectangle } from "recharts";

interface PayloadType {
  name: string;
  visitors: string;
  color: string;
}
interface SankeyNodeProps {
  containerWidth: number;
  x?: number;
  y?: number;
  width?: number;
  height?: number;
  index?: number;
  payload?: PayloadType;
}

export const SankeyNode: FunctionComponent<SankeyNodeProps> = props => {
  const { containerWidth, height = 0, index = 0, payload, width = 0, x = 0, y = 0 } = props;
  const isOut = x + width + 6 > containerWidth;
  return (
    <Layer key={`CustomNode${index}`}>
      <Rectangle x={x} y={y} width={width} height={height} fill={payload?.color} fillOpacity="1" />
      <text textAnchor={isOut ? "end" : "start"} transform={`translate(${isOut ? x - 6 : x + width + 6}, ${y + height / 2})`} fontSize="14" stroke="#fff" fill="#fff">
        {payload?.name}
      </text>
      <text textAnchor={isOut ? "end" : "start"} transform={`translate(${isOut ? x - 6 : x + width + 6}, ${y + height / 2 + 13})`} fontSize="12" stroke="#fff" fill="#fff">
        {payload?.visitors}
      </text>
    </Layer>
  );
};
