import { TrackingActionCategory, TrackingActionMessages, TrackingUserActionTypes } from "@technis/shared";
import { debounce } from "lodash";

import { SegmentTracking } from "../namespaces/SegmentTracking";
import { TRACKING_DEBOUNCE_TIME } from "./constants";

const { ANALYTICS } = TrackingActionCategory;

const trackAction = (type: TrackingUserActionTypes) => (name: string, externalId?: string) => {
  SegmentTracking.saveAction({ type, name, externalId });
};

export const trackViewActivity = (topic: string, name: string) => {
  SegmentTracking.trackViewActivity(name, topic);
};

export const trackClick = trackAction(TrackingUserActionTypes.CLICK);
export const trackToggle = trackAction(TrackingUserActionTypes.TOGGLE);
export const trackTyping = debounce(trackAction(TrackingUserActionTypes.TYPING), TRACKING_DEBOUNCE_TIME);
export const trackCheckbox = trackAction(TrackingUserActionTypes.CHECKBOX);
export const trackFocus = trackAction(TrackingUserActionTypes.FOCUS);
export const trackSelect = trackAction(TrackingUserActionTypes.SELECT);

export const ActivityMessages = TrackingActionMessages[ANALYTICS];
