import { DeviceAtmosphere } from "@technis/shared";
import gql from "graphql-tag";

export type DevicesAtmosphereResult = {
  devicesAtmosphereByInstallation: Array<Pick<DeviceAtmosphere, "id" | "name">>;
};

export const devicesAtmosphere = gql`
  query devicesAtmosphere($installationId: Int!) {
    devicesAtmosphereByInstallation(installationId: $installationId) {
      id
      name
    }
  }
`;
