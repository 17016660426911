import { Radio } from "antd";
import { RadioChangeEvent } from "antd/lib/radio";
import React, { FunctionComponent, useState } from "react";
import { AiOutlineFork } from "react-icons/ai";

import { UI } from "../../../constants";
import { translate } from "../../../lang/i18n";
import { translation } from "../../../lang/translation";
import { AggregatedKpiCountingWithName } from "../../../pages/AnalyticsHomePage";
import { MostUsedChart } from "../../charts/MostUsedChart";
import { PassageSetting } from "../Header";
import { CommonModule } from "./CommonModule";
import { ChartType } from "./MostUsedZonesModule";

const { MODULE_TITLE_ICON_SIZE } = UI;
const RADIO_KEY_MOST_USED_PASSAGES = "most-used-passages";

const { analytics, common } = translation;
const { barChart, in: inTranslation, inAndOut: inAndOutTranslation, out: outTranslation, passages, pieChart, visitors } = common;

interface MostUsedPassagesModuleProps {
  passageKpiAggregated: AggregatedKpiCountingWithName[];
  passageSettings: PassageSetting[];
  onDelete: () => void;
}

export enum DataShown {
  IN,
  OUT,
  IN_AND_OUT,
}

export const MostUsedPassagesModule: FunctionComponent<MostUsedPassagesModuleProps> = props => {
  const { onDelete, passageKpiAggregated, passageSettings } = props;
  const [chartType, setChartType] = useState(ChartType.PIE);
  const [dataShown, setDataShown] = useState(DataShown.IN_AND_OUT);
  const getVisitors = (dataShown: DataShown) => (aggregatedKpi: AggregatedKpiCountingWithName) => {
    switch (dataShown) {
      case DataShown.IN:
        return aggregatedKpi.affluenceMinIn?.flat().sum() || 0;
      case DataShown.OUT:
        return aggregatedKpi.affluenceMinOut?.flat().sum() || 0;
      case DataShown.IN_AND_OUT:
        return (aggregatedKpi.affluenceMinIn?.flat().sum() || 0) + (aggregatedKpi.affluenceMinOut?.flat().sum() || 0);
    }
  };
  const onChangeChartType = (event: RadioChangeEvent) => {
    setChartType(event.target.value);
  };
  const onChangeDataShown = (event: RadioChangeEvent) => {
    setDataShown(event.target.value);
  };
  const key = "most-used-passage";
  return (
    <CommonModule
      iconSize={MODULE_TITLE_ICON_SIZE}
      title={translate(analytics.modules.titles.mostUsedPassages)}
      tooltipText={translate(analytics.modules.tooltips.mostUsedPassages)}
      Icon={AiOutlineFork}
      onDelete={onDelete}
      isExportable
      radio={{
        groups: [
          <Radio.Group key={`${key}-radio-group-0`} onChange={onChangeChartType} value={chartType}>
            <Radio value={ChartType.PIE}>{translate(pieChart)}</Radio>
            <Radio value={ChartType.BAR}>{translate(barChart)}</Radio>
          </Radio.Group>,
          <Radio.Group key={`${key}-radio-group-1`} onChange={onChangeDataShown} value={dataShown}>
            <Radio value={DataShown.IN}>{translate(inTranslation.other)}</Radio>
            <Radio value={DataShown.OUT}>{translate(outTranslation.other)}</Radio>
            <Radio value={DataShown.IN_AND_OUT}>{translate(inAndOutTranslation.other)}</Radio>
          </Radio.Group>,
        ],
        key: RADIO_KEY_MOST_USED_PASSAGES,
      }}
    >
      <MostUsedChart
        getVisitors={getVisitors(dataShown)}
        kpisAggregated={passageKpiAggregated}
        barHeader={translate(visitors)}
        labelHeader={translate(passages)}
        chartType={chartType}
        passageSettings={passageSettings}
      />
    </CommonModule>
  );
};
