import classNames from "classnames";
import React, { FunctionComponent } from "react";
import { AiOutlineCaretDown, AiOutlineCaretUp } from "react-icons/ai";

import { Direction } from "../../charts";

const SORT_BUTTON_SIZE = 18;

interface SortIconProps {
  direction?: Direction;
  focus: boolean;
}

export const SortIcon: FunctionComponent<SortIconProps> = props => {
  const { direction, focus } = props;

  const Icons = {
    asc: AiOutlineCaretUp,
    desc: AiOutlineCaretDown,
  };

  const Icon = Icons[!focus || !direction ? "asc" : direction];

  return <div className={classNames("sort-icon", "ignore-export", { "sort-icon-unfocus": !focus })}>{Icon && <Icon size={SORT_BUTTON_SIZE} />}</div>;
};
