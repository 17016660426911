import { select } from "d3-selection";
import * as moment from "moment";

import { DEBUG, ExportStage } from "../../components/export/ExportLoading";
import { createTimestamp } from "../benchmark";
import { multiChunkExport } from "./exportExperimental";
import {
  createPagesLayout,
  cutModuleInChunks,
  DeepArray,
  getChunks,
  getLayoutComponents,
  getNodeHierarchy,
  getPages,
  insertOversized,
  NodeToExport,
  restoreDom,
  setupDomCopy,
  setupDuplicateModuleElements,
} from "./nodeManipulation";

export const exportPdf = async (changeExportState: (stage: ExportStage) => void, exportDate: moment.Moment, eventName: string) => {
  const startExport = createTimestamp();
  const { domCopy, wrapper } = setupDomCopy();
  setupDuplicateModuleElements();

  changeExportState(ExportStage.LAYOUT_GENERATION);

  const layoutComponents = getLayoutComponents();
  const contentNodeHeightMax = layoutComponents.frontPage.height - layoutComponents.header.height - layoutComponents.footer.height;
  const nodeHierarchy = getNodeHierarchy(select(domCopy), contentNodeHeightMax) as DeepArray<NodeToExport>;

  const nodeCollection = cutModuleInChunks(nodeHierarchy.flat(Infinity) as NodeToExport[]);

  insertOversized(nodeCollection, layoutComponents.oversizedMessage.element);
  const pages = getPages(nodeCollection, layoutComponents);

  createPagesLayout(wrapper, pages, layoutComponents);
  const chunks = getChunks(wrapper, pages);
  changeExportState(ExportStage.PREPARATION);

  changeExportState(ExportStage.PDF_GENERATION);
  await multiChunkExport(chunks, exportDate, layoutComponents.frontPage.height * 2, layoutComponents.frontPage.width, pages.length + 1, eventName);

  restoreDom(domCopy);

  const endExport = createTimestamp();
  if (DEBUG) {
    return endExport - startExport;
  }
  return true;
};
