import { DeviceID, isDefined, isTechnisPad, Status, StatusCode } from "@technis/shared";
import { uniq } from "lodash";
import moment from "moment";

import { PassageSetting } from "../../components/common/Header";
import { Dates } from "../../constants";
import { DevicesAtmosphereResult } from "../../graphql/atmosphere.gql";
import { StatusWithReconnected } from "../../graphql/status.gql";
import { AllZonesAllPassagesAnalyticsKpiQueryResult } from "../../graphql/zones.gql";
import { startOfDay } from "../time";
import { getPeriodLength } from "../utils";
import { DateRange, Granularity } from "./types";

const { MILLISECONDS_IN_MINUTE } = Dates;

export enum DeviceType {
  BLACK_BOX = 1,
  CAMERA,
  ATMOSPHERE,
}

export interface StatusData {
  day: number;
  devices: Device[];
}

export interface StatusExpanded extends Status {
  reconnected: boolean | null;
  deviceType: DeviceType;
}

export interface PadDetail {
  id: DeviceID;
  name: string;
  deviceType: DeviceType;
}

export interface StatusDetail {
  name: string;
  deviceType: DeviceType;
  reconnected: boolean | null;
  msg: string;
  dateReceived: number;
  dateProcessed?: number;
  statusCode: StatusCode;
  padId: DeviceID;
}

export interface Device {
  deviceId: DeviceID;
  deviceType: DeviceType;
  initialStatus: StatusDetail;
  statusDetails: StatusDetail[];
}

export const aggregatePadList = (
  installation: AllZonesAllPassagesAnalyticsKpiQueryResult,
  passageSettings: PassageSetting[],
  deviceAtmosphere: DevicesAtmosphereResult,
): PadDetail[] => [
  ...installation?.passagesByInstallation
    .filter(passage => passageSettings.find(passageSetting => passage.id === passageSetting.id)?.activated && passage.kpis)
    .flatMap(passage => passage.pads.filter(Boolean).map(pad => ({ id: pad.id, name: pad.name, deviceType: isTechnisPad(pad) ? DeviceType.BLACK_BOX : DeviceType.CAMERA }))),
  ...deviceAtmosphere.devicesAtmosphereByInstallation.map(device => ({ id: device.id, name: device.name, deviceType: DeviceType.ATMOSPHERE })),
];

const filterDevices = (statuses: StatusDetail[], day: number, devices: PadDetail[], periodLength: number) => (deviceId: string) => {
  const initialStatus = statuses.filter(status => status.padId === deviceId && status.dateReceived < day).last();
  const deviceDetail = devices.find(device => device.id === deviceId);

  return {
    deviceId,
    deviceType: isDefined(deviceDetail) ? deviceDetail?.deviceType : DeviceType.ATMOSPHERE,
    initialStatus: initialStatus || {
      name: deviceDetail?.name || "",
      deviceType: deviceDetail?.deviceType || DeviceType.ATMOSPHERE,
      reconnected: null,
      msg: "",
      dateReceived: day,
      dateProcessed: undefined,
      statusCode: StatusCode.OK,
      padId: deviceDetail?.id || "0",
    },
    statusDetails: statuses.filter(status => status.padId === deviceId && status.dateReceived >= day && status.dateReceived < day + periodLength * MILLISECONDS_IN_MINUTE),
  };
};

export const aggregateStatus = (rawStatus: StatusWithReconnected[], devices: PadDetail[], dateRange: DateRange, granularity: Granularity, dateBegins: number[]): StatusData[] => {
  const periodLength = getPeriodLength(granularity);

  const deviceIds = uniq(devices.map(device => device.id));
  const days = moment(dateBegins[0]).hour() !== 0 ? dateBegins.map(dateBegin => startOfDay(dateBegin)) : dateBegins;

  const status = rawStatus.reduce((acc, curr) => {
    const index = devices.findIndex(pad => pad.id === curr.padId);
    if (index !== -1) {
      if (curr.statusCode === 2 && curr.reconnected === null) return [...acc, { ...curr, name: devices[index].name, deviceType: devices[index].deviceType, reconnected: false }];
      return [...acc, { ...curr, name: devices[index].name, deviceType: devices[index].deviceType }];
    }
    return acc;
  }, [] as StatusDetail[]);
  return days.map(day => {
    const filterDevicefunc = filterDevices(status, day, devices, periodLength);
    return {
      day,
      devices: deviceIds.map(filterDevicefunc),
    };
  });
};
