import React, { FunctionComponent } from "react";
import { DateRange } from "../../utils/aggregate";
import { getDateRangeCaption } from "../../utils/time";

interface PdfHeaderProps {
  eventName: string;
  zoneName: string;
  dateRange: DateRange;
}

export const PdfHeader: FunctionComponent<PdfHeaderProps> = props => {
  const { eventName, zoneName, dateRange } = props;
  return (
    <div className="export-header" id="pdf-header">
      <div className="container-header">
        <div className="title">
          <div className="event">{`${eventName},`}</div>
          <div className="zone">{zoneName}</div>
        </div>
        <div className="dates">{getDateRangeCaption(dateRange)}</div>
      </div>
    </div>
  );
};
