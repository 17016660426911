import { Button, Tooltip } from "antd";
import React, { FunctionComponent, ReactNode, useRef } from "react";
import { Field } from "react-final-form";

import { DateRange } from "../../../utils/aggregate";
import { DatePreset, getDateRangeByPreset } from "../../../utils/time";
import { noop } from "../../../utils/utils";

interface CustomButtonProps {
  buttonDisplay: string | ReactNode;
  buttonClassName?: string;
  buttonActivatedClassName?: string;
  onClick?: () => void;
  selected?: boolean;
  disabled?: boolean;
  tooltipTitle?: string;
}

interface FormProps extends Omit<CustomButtonProps, "buttonDisplay" | "selected"> {
  formPath: string;
  label: string;
  moduleDates: DateRange;
  preset: DatePreset;
}

export const CustomButton: FunctionComponent<CustomButtonProps> = props => {
  const { buttonDisplay, onClick = noop, buttonActivatedClassName = "", buttonClassName = "", selected = false, disabled = false, tooltipTitle } = props;
  const ref = useRef(null);

  return tooltipTitle ? (
    <div ref={ref}>
      <Tooltip
        getPopupContainer={() => ref.current || document.createElement("div")}
        overlayClassName="tooltip-custom "
        title={tooltipTitle}
        placement={"topLeft"}
        arrowPointAtCenter
      >
        <div className="tooltip-button-container">
          <Button className={`custom-button ${buttonClassName} ${selected ? buttonActivatedClassName : ""}`} onClick={onClick} disabled={disabled}>
            <div className="custom-button-text">{buttonDisplay}</div>
          </Button>
        </div>
      </Tooltip>
    </div>
  ) : (
    <Button className={`custom-button ${buttonClassName} ${selected ? buttonActivatedClassName : ""}`} onClick={onClick} disabled={disabled}>
      <div className="custom-button-text">{buttonDisplay}</div>
    </Button>
  );
};

export const FormCustomButton: FunctionComponent<FormProps> = props => {
  const { formPath, label, moduleDates, preset, onClick = noop, buttonActivatedClassName = "", buttonClassName = "", disabled = false } = props;
  const { dateBegin, dateEnd } = getDateRangeByPreset(preset);
  const isSelected = moduleDates.dateBegin === dateBegin && moduleDates.dateEnd === dateEnd;
  return (
    <Field
      name={formPath}
      type={"text"}
      render={props => (
        <CustomButton
          buttonDisplay={isSelected ? label.toUpperCase() : label}
          selected={isSelected}
          onClick={() => {
            props.input.onChange(true);
            onClick();
          }}
          buttonClassName={buttonClassName}
          buttonActivatedClassName={buttonActivatedClassName}
          disabled={disabled}
        />
      )}
      label={label}
    />
  );
};
