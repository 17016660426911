import { Tooltip as TooltipAntd } from "antd";
import { clone } from "lodash";
import React, { FunctionComponent, useCallback, useEffect, useState } from "react";
import { AiOutlineFrown } from "react-icons/ai";
// eslint-disable-next-line import/named
import { Sankey, Tooltip, TooltipProps } from "recharts";

import { translate } from "../../lang/i18n";
import { translation } from "../../lang/translation";
import { AggregatedKpiCountingWithName } from "../../pages/AnalyticsHomePage";
import { changeHue, Colors, INTERVAL_BETWEEN_COLORS, PASTEL_COLORS_LIST } from "../../utils/colors";
import { checkErrors, checkKpiData } from "../../utils/utils";
import { LabelledBar } from "../common/custom-chart/Bar";
import { ChartLegend } from "../common/custom-chart/ChartLegend";
import { HorizontalBarChart } from "../common/custom-chart/HorizontalBarChart";
import { PassageSetting, ZoneSetting } from "../common/Header";
import { ChartType } from "../common/module/MostUsedZonesModule";
import { ASCENDENT_DIRECTION, DESCENDENT_DIRECTION, Direction } from "./index";
import { PieGraph } from "./PieChart";
import { SankeyLink } from "./SankeyLink";
import { SankeyNode } from "./SankeyNode";

const DEFAULT_COLOR = Colors.LIGHT_BLUE;

enum Properties {
  NAME_PROPERTY = "name",
  VISITORS_PROPERTY = "visitors",
}

enum ListNames {
  MOST_USED = "mostUsed",
}

const DEFAULT_SORTING = { currentProperty: Properties.NAME_PROPERTY, currentDirection: ASCENDENT_DIRECTION };

export interface MostUsedChartProps {
  barHeader: string;
  kpisAggregated: AggregatedKpiCountingWithName[];
  zoneSettings?: ZoneSetting[];
  passageSettings?: PassageSetting[];
  labelHeader: string;
  getVisitors: (kpi: AggregatedKpiCountingWithName) => number;
  chartType: ChartType;
}

export interface DataMostUsed {
  name: string;
  visitors: number;
  visitorsNormalized: number;
}

interface CommonProps {
  cx: number;
  cy: number;
  midAngle: number;
  innerRadius: number;
  outerRadius: number;
  percent: number;
}

export interface ActiveShapeProps extends CommonProps {
  startAngle: number;
  endAngle: number;
  fill: Colors;
  payload: { name: string };
  value: number;
}

export interface CustomizedLabelProps {
  cx: number;
  cy: number;
  midAngle: number;
  innerRadius: number;
  outerRadius: number;
  percent: number;
}

interface MostUsedData {
  name: string;
  visitors: number;
  visitorsNormalized: number;
}

interface State {
  errors: (string | undefined)[];
  activeIndex: number;
  localPassageSettings?: PassageSetting[];
}

interface SortParameters {
  [ListNames.MOST_USED]: { currentProperty: Properties; currentDirection?: Direction };
}

export const MostUsedChart: FunctionComponent<MostUsedChartProps> = props => {
  const { barHeader, chartType, getVisitors, kpisAggregated, labelHeader, passageSettings, zoneSettings } = props;

  const [state, setState] = useState<State>({
    errors: kpisAggregated.map(kpiAggregated => checkKpiData(kpiAggregated, ["dateBegins", "dateEnds", "affluenceMinIn"])),
    activeIndex: 0,
    localPassageSettings: clone(passageSettings),
  });

  const { activeIndex, errors, localPassageSettings } = state;

  const [sorts, setSorts] = useState<SortParameters>({
    [ListNames.MOST_USED]: DEFAULT_SORTING,
  });

  useEffect(() => {
    setState({ ...state, errors: kpisAggregated.map(kpiAggregated => checkKpiData(kpiAggregated, ["dateBegins", "dateEnds", "affluenceMinIn"])) });
  }, [props]);

  useEffect(() => {
    setState({ ...state, localPassageSettings: clone(passageSettings) });
  }, [passageSettings]);

  const onClickUpdatePassageSettings = (index: number) => {
    if (!localPassageSettings) return;
    setState({
      ...state,
      localPassageSettings: localPassageSettings.map((setting, indexSetting) => ({ ...setting, activated: index === indexSetting ? !setting.activated : setting.activated })),
    });
  };

  const onPieEnter = useCallback(
    (_, index: number) => {
      setState(prevState => ({ ...prevState, activeIndex: index }));
    },
    [setState],
  );

  const createBarData = (): MostUsedData[] => {
    if (kpisAggregated.every(kpiAggregated => !kpiAggregated.dateBegins || !kpiAggregated.affluenceMinIn || !kpiAggregated.zoneName == !kpiAggregated.passageName)) {
      return [];
    }

    const datas = kpisAggregated.map(kpiAggregated => ({
      name: kpiAggregated.zoneName || kpiAggregated.passageName || "ERR",
      visitors: getVisitors(kpiAggregated),
      visitorsNormalized: 0,
    }));

    const max = Math.max(...datas.map(data => data.visitors));

    return datas.map(data => ({ ...data, visitorsNormalized: data.visitors > 0 ? data.visitors / max : 0 }));
  };

  const createPieData = () => {
    if (kpisAggregated.every(kpiAggregated => !kpiAggregated.dateBegins || !kpiAggregated.affluenceMinIn || !kpiAggregated.zoneName == !kpiAggregated.passageName)) {
      return [];
    }

    if (!localPassageSettings) return [];
    const isSameSize = kpisAggregated.length === PASTEL_COLORS_LIST.length;
    return kpisAggregated.map((kpiAggregated, index) => ({
      orderIndex: index,
      id: kpiAggregated.passageId || 0,
      name: kpiAggregated.passageName || "ERR",
      visitors: getVisitors(kpiAggregated),
      color:
        isSameSize && index === kpisAggregated.length - 1
          ? PASTEL_COLORS_LIST[Math.floor(PASTEL_COLORS_LIST.length / 2)].lightPastel
          : PASTEL_COLORS_LIST[index % PASTEL_COLORS_LIST.length].lightPastel,
      active: localPassageSettings?.find(setting => setting.id === kpiAggregated.passageId)?.activated || false,
    }));
  };

  // eslint-disable-next-line sonarjs/cognitive-complexity
  const createSankeyData = () => {
    if (!zoneSettings) return { nodes: [], links: [] };

    const zones = zoneSettings
      .filter(setting => setting.activated)
      .map((setting, index) => {
        if (zoneSettings[index + 1] && setting.level === zoneSettings[index + 1].level - 1) {
          const { sum } = zoneSettings
            .slice(index + 1)
            .filter(s => s.level <= setting.level + 1)
            .reduce(
              (acc, curr, innerIndex, array) => {
                if (!acc.stop) {
                  if (innerIndex === array.length - 1 || (array[innerIndex + 1] && curr.level === array[innerIndex + 1].level))
                    return { sum: acc.sum + getVisitors(kpisAggregated[index + innerIndex + 1]), stop: false };
                  if (array[innerIndex + 1] && curr.level === array[innerIndex + 1].level + 1) return { sum: acc.sum, stop: true };
                }
                return acc;
              },
              { sum: 0, stop: false },
            );
          return [
            { ...setting, visitors: getVisitors(kpisAggregated[index]) },
            {
              ...setting,
              level: setting.level + 1,
              name: `${setting.name} ${translate(translation.common.exclusive).toLowerCase()}`,
              visitors: getVisitors(kpisAggregated[index]) - sum,
            },
          ];
        }
        return { ...setting, visitors: getVisitors(kpisAggregated[index]) };
      })
      .flat();

    const nodes = zones.map((zone, index) => ({ name: zone.name, color: changeHue(DEFAULT_COLOR, INTERVAL_BETWEEN_COLORS * index), visitors: zone.visitors }));

    const { links } = zones.reduce(
      (acc, curr, index) => {
        const percent = (nodes[index].visitors / nodes[acc.currentNode].visitors) * 100;
        if (curr.level > 0 && zones[index + 1]) {
          let currentNode = acc.currentNode;
          if (zones[index + 1].level - 1 === curr.level) {
            currentNode++;
          }
          if (zones[index + 1].level + 1 === curr.level) {
            currentNode--;
          }
          return { links: [...acc.links, { source: acc.currentNode, target: index, value: curr.visitors, percent: `${percent.toFixed(2)}%` }], currentNode };
        }
        if (curr.level > 0)
          return { links: [...acc.links, { source: acc.currentNode, target: index, value: curr.visitors, percent: `${percent.toFixed(2)}%` }], currentNode: acc.currentNode };
        return acc;
      },
      { links: [] as { source: number; target: number; value: number; percent: string }[], currentNode: 0 },
    );

    return { nodes, links };
  };

  const sortData = (data: MostUsedData[], sortParameter: keyof SortParameters) => {
    if (!sorts[sortParameter].currentDirection) return data;

    const property = {
      [Properties.NAME_PROPERTY]: data.sortBy(Properties.NAME_PROPERTY, sorts[sortParameter].currentDirection),
      [Properties.VISITORS_PROPERTY]: data.sortBy(Properties.VISITORS_PROPERTY, sorts[sortParameter].currentDirection),
    };
    return property[sorts[sortParameter].currentProperty];
  };

  const toggleDirection = (list: keyof SortParameters): Direction => {
    if (sorts[list].currentDirection === ASCENDENT_DIRECTION) return DESCENDENT_DIRECTION;
    return ASCENDENT_DIRECTION;
  };

  const updateSorting = (list: keyof SortParameters, headerButton: Properties) => () => {
    if (sorts[list].currentProperty !== headerButton) {
      setSorts(prevState => ({ ...prevState, [list]: { ...prevState[list], currentProperty: headerButton, currentDirection: ASCENDENT_DIRECTION } }));
    } else {
      setSorts(prevState => ({ ...prevState, [list]: { ...prevState[list], currentDirection: toggleDirection(list) } }));
    }
  };

  const renderChartTooltip = (tooltipObject: TooltipProps<number, string>) => {
    if (!tooltipObject.payload) {
      return null;
    }
    const payload = tooltipObject.payload && tooltipObject.payload[0];

    if (payload && payload.payload.payload.source && payload.payload.payload.target) {
      return (
        <TooltipAntd placement="rightBottom">
          <div className="chart-tooltip-cell">
            <div className="chart-tooltip-container">
              <div className="chart-tooltip-title">
                <span style={{ color: payload.payload.payload.source.color }}>{payload.payload.payload.source.name}</span>
                {" -> "}
                <span style={{ color: payload.payload.payload.target.color }}>{payload.payload.payload.target.name}</span>
              </div>
              <div className="chart-tooltip-value">{`${payload.value} visitors`}</div>
            </div>
          </div>
        </TooltipAntd>
      );
    }
  };

  const renderChart = () => {
    switch (chartType) {
      case ChartType.PIE:
        return (
          <PieGraph
            activeIndex={activeIndex}
            passageSettings={localPassageSettings}
            onClickLegend={onClickUpdatePassageSettings}
            sectors={createPieData()}
            dataKey="visitors"
            onMouseEnter={onPieEnter}
          />
        );
      case ChartType.BAR:
        const data = createBarData();
        return (
          <HorizontalBarChart
            barHeader={barHeader}
            labelHeader={labelHeader}
            barChartClassName="most-used-chart"
            labelClassName="most-used-zone-header-label"
            headerClassName="most-used-zone-header-bar"
            labelSort={{
              onClick: updateSorting(ListNames.MOST_USED, Properties.NAME_PROPERTY),
              direction: sorts.mostUsed.currentDirection,
              focus: sorts.mostUsed.currentProperty === Properties.NAME_PROPERTY,
            }}
            barSort={{
              onClick: updateSorting(ListNames.MOST_USED, Properties.VISITORS_PROPERTY),
              direction: sorts.mostUsed.currentDirection,
              focus: sorts.mostUsed.currentProperty === Properties.VISITORS_PROPERTY,
            }}
          >
            {sortData(data, ListNames.MOST_USED).map((entry, index) => (
              <LabelledBar
                key={`chart-entry-${index}`}
                label={entry.name}
                widthInPercent={entry.visitorsNormalized}
                value={entry.visitors}
                labelClassName="most-used-zone-label white-font"
                color={DEFAULT_COLOR}
              />
            ))}
          </HorizontalBarChart>
        );
      case ChartType.SANKEY:
        const sankeyData = createSankeyData();
        return (
          <>
            <Sankey
              width={1062}
              height={500}
              margin={{ top: 20, bottom: 20 }}
              data={sankeyData}
              nodeWidth={10}
              nodePadding={60}
              linkCurvature={0.61}
              iterations={64}
              node={<SankeyNode containerWidth={1062} />}
              link={<SankeyLink />}
            >
              <Tooltip content={renderChartTooltip} />
            </Sankey>
            <ChartLegend legendItems={sankeyData.nodes.map(node => ({ text: node.name, color: node.color }))} />
          </>
        );
    }
  };

  if (checkErrors(errors)) {
    // TODO: beautify error when data missing
    return (
      <div className="module-error">
        <AiOutlineFrown color={Colors.RED} />
        {errors.map((error, index) => (
          <div key={`error-${index}`} className="module-error-text">
            {error ? error : "undefined"}
          </div>
        ))}
      </div>
    );
  }

  return renderChart();
};
