import { StatusCode } from "@technis/shared";
import Radio, { RadioChangeEvent } from "antd/lib/radio";
import * as moment from "moment";
import React, { FunctionComponent, useEffect, useState } from "react";
import { AiOutlineBug, AiOutlineEllipsis } from "react-icons/ai";

import { UI } from "../../../constants";
import { DAY_MONTH_YEAR_FORMAT } from "../../../constants/date";
import { i18n, translate } from "../../../lang/i18n";
import { translation } from "../../../lang/translation";
import { DateRange, Device, DeviceType, Granularity, StatusData, StatusDetail } from "../../../utils/aggregate";
import { getStatusColor } from "../../../utils/colors";
import { getTimeline } from "../../../utils/utils";
import { CustomButton } from "../buttons/CustomButton";
import { ChartLegend } from "../custom-chart/ChartLegend";
import { ChartList } from "../custom-chart/ChartList";
import { ChartTimeLine } from "../custom-chart/ChartTimeLine";
import { GradientIcon } from "../GradientIcon";
import { getTheme } from "../Theme";
import { CommonModule } from "./CommonModule";

const { MODULE_TITLE_ICON_SIZE } = UI;

const { export: exportText, uptime } = translation.common;

const ERROR_LIMIT = 10;
const RADIO_KEY_ERROR_LOG_MODULE = "error-log-module";
const KEYS = ["name", "msg", "dateReceived", "statusCode", "padId", "reconnected", "deviceType"];
const STATUS_DATE_FORMAT = `dddd ${DAY_MONTH_YEAR_FORMAT}`;
const EXPORT_FILE_NAME_START = "Error_history_from_";
const EXPORT_FILE_NAME_BETWEEN = "_to_";
const EXPORT_FILE_NAME_END = ".csv";
const EXPORT_FILE_DATE_FORMAT = "Do[_]MMM[_]YYYY";

const { labels, statusCode, titles } = translation.analytics.modules;
const { connectionLost, firmwareUpdating, incompatibleBlackBox, incompatibleMat, internetLost, operational, padRebooting, tilesMissing, waiting } = statusCode;

interface ErrorLogModuleProps {
  statusData: StatusData[];
  dateRange: DateRange;
  granularity: Granularity;
  onDelete: () => void;
}

export interface ActiveStatusData extends StatusData {
  isActive: boolean;
}

export enum MetricSelection {
  COUNTING,
  AIR_QUALITY,
  COUNTING_AND_AIR_QUALITY,
}

const statusTranslationMap = {
  [StatusCode.OK]: operational,
  [StatusCode.PAD_DISCONNECTED]: tilesMissing,
  [StatusCode.CONNECTION_LOST]: connectionLost,
  [StatusCode.INCOMPATIBLE_BLACK_BOX]: incompatibleBlackBox,
  [StatusCode.INCOMPATIBLE_MATS]: incompatibleMat,
  [StatusCode.REBOOT]: padRebooting,
  [StatusCode.FIRMWARE_UPDATE]: firmwareUpdating,
  [StatusCode.WAITING__STATUS]: waiting,
};

const getStatus = (status: { statusCode: StatusCode; reconnected: boolean | null }) => {
  const { statusCode } = status;
  const isConnectionLost = statusCode === StatusCode.CONNECTION_LOST;
  const connectionLostText = status.reconnected ? internetLost : connectionLost;

  return {
    color: getStatusColor(statusCode, isConnectionLost && status.reconnected),
    text: translate(isConnectionLost ? connectionLostText : statusTranslationMap[statusCode]),
  };
};

export const ErrorLogModule: FunctionComponent<ErrorLogModuleProps> = props => {
  const { dateRange, granularity, onDelete, statusData } = props;
  const { showMoreGradient } = getTheme();

  const [metricSelection, setMetricSelection] = useState(MetricSelection.COUNTING_AND_AIR_QUALITY);

  const filterDevice = (device: Device) => {
    switch (metricSelection) {
      case MetricSelection.AIR_QUALITY:
        return device.deviceType === DeviceType.ATMOSPHERE;
      case MetricSelection.COUNTING:
        return [DeviceType.BLACK_BOX, DeviceType.CAMERA].includes(device.deviceType);
      case MetricSelection.COUNTING_AND_AIR_QUALITY:
        return true;
    }
  };

  const [showAll, setShowAll] = useState(false);
  const [localStatusData, setLocalStatusData] = useState<ActiveStatusData[]>(
    statusData.map(status => ({ ...status, devices: status.devices.filter(filterDevice), isActive: false })),
  );

  useEffect(() => {
    setLocalStatusData(
      statusData.map(status => ({
        ...status,
        devices: status.devices.filter(filterDevice),
        isActive: false,
      })),
    );
  }, [statusData]);

  useEffect(() => {
    setLocalStatusData(
      localStatusData.map((status, index) => ({
        ...status,
        devices: statusData[index].devices.filter(filterDevice),
      })),
    );
  }, [metricSelection]);

  const getAggregatedStatus = () =>
    localStatusData.flatMap(status =>
      status.devices
        .reduce((acc, curr) => {
          if (!curr.statusDetails.isEmpty()) return [...acc, curr.statusDetails];
          return acc;
        }, [] as StatusDetail[][])
        .flat()
        .sortBy("date"),
    );

  const onClickTimeline = (day: number) => {
    const dayIndex = localStatusData.findIndex(data => data.day === day);
    const tempData = localStatusData.map((data, i) => ({ ...data, isActive: i === dayIndex ? !data.isActive : false }));
    setLocalStatusData(tempData);
  };

  const onClickShowMore = () => {
    setShowAll(!showAll);
  };

  const getUptime = () => {
    const { totalTime, upTime } = localStatusData.reduce(
      (acc, curr) => {
        const timeline = getTimeline(curr.devices, curr.day, granularity);
        return {
          totalTime: acc.totalTime + timeline.map(timeline => timeline.duration).sum(),
          upTime:
            acc.upTime +
            timeline
              .filter(timeline => timeline.statusCode === 0)
              .map(timeline => timeline.duration)
              .sum(),
        };
      },

      { totalTime: 0, upTime: 0 },
    );
    return ((upTime / totalTime) * 100).toFixed(2);
  };

  const onChangeSelectMetric = (event: RadioChangeEvent) => {
    setMetricSelection(event.target.value);
  };

  const statusDetails = getAggregatedStatus();
  const initialStatus = localStatusData[0].devices.map(device => device.initialStatus).filter(status => status.statusCode !== StatusCode.OK);
  const fullStatusDetails = [...initialStatus, ...statusDetails].sortBy("dateReceived");

  const sortedStatuses = statusData
    .flatMap(data =>
      data.devices.flatMap(device => [
        ...device.statusDetails.map(detail => ({ statusCode: detail.statusCode, reconnected: detail.reconnected })),
        { statusCode: device.initialStatus.statusCode, reconnected: device.initialStatus.reconnected },
      ]),
    )
    .reduce((acc, curr) => {
      const isDuplicate = !acc.find(p => curr.statusCode === p.statusCode && curr.reconnected === p.reconnected);
      if (isDuplicate) {
        return curr.statusCode === StatusCode.CONNECTION_LOST && curr.reconnected === null ? acc : [...acc, curr];
      }
      return acc;
    }, [] as { statusCode: StatusCode; reconnected: boolean | null }[])
    .sortBy("statusCode");

  function exportCSV(statusDetails: StatusDetail[], columnDelimiter = ",", lineDelimiter = "\n") {
    if (statusDetails == null || !statusDetails.length) {
      return null;
    }

    const dataWithReadableDate = statusDetails.map(status => ({ ...status, dateReceived: moment(status.dateReceived).format(STATUS_DATE_FORMAT) }));

    const csvStart = "data:text/csv;charset=utf-8,";

    const header = KEYS.join(columnDelimiter) + lineDelimiter;

    const csv =
      csvStart +
      header +
      dataWithReadableDate
        .map(
          item =>
            KEYS.reduce(
              (acc, curr, index) => acc + (index >= KEYS.length - 1 ? `${item[curr as keyof StatusDetail]} ` : `${item[curr as keyof StatusDetail]}${columnDelimiter} `),
              "",
            ) + lineDelimiter,
        )
        .join("");

    const filename =
      EXPORT_FILE_NAME_START +
      moment(dateRange.dateBegin).format(EXPORT_FILE_DATE_FORMAT) +
      EXPORT_FILE_NAME_BETWEEN +
      moment(dateRange.dateEnd).format(EXPORT_FILE_DATE_FORMAT) +
      EXPORT_FILE_NAME_END;

    const encodedUri = encodeURI(csv);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", filename);
    document.body.appendChild(link);

    link.click();
    link.remove();
  }

  const legendItems = sortedStatuses.map(status => getStatus(status)).filter(Boolean);

  return (
    <CommonModule
      iconSize={MODULE_TITLE_ICON_SIZE}
      title={translate(titles.errorLog)}
      Icon={AiOutlineBug}
      onDelete={onDelete}
      isExportable
      radio={{
        groups: [
          <Radio.Group key={`${RADIO_KEY_ERROR_LOG_MODULE}-radio-group`} onChange={onChangeSelectMetric} value={metricSelection}>
            <Radio value={MetricSelection.AIR_QUALITY}>{i18n.t(translation.common.airQuality)}</Radio>
            <Radio value={MetricSelection.COUNTING}>{i18n.t(translation.common.counting)}</Radio>
            <Radio value={MetricSelection.COUNTING_AND_AIR_QUALITY}>{i18n.t(translation.common.airQualityAndCounting)}</Radio>
          </Radio.Group>,
        ],
        key: RADIO_KEY_ERROR_LOG_MODULE,
      }}
    >
      <div className="export-1">
        <div className="title-container system-status">
          <div className="error-log-sub-title gradient">{translate(labels.systemStatus)}</div>
          <div className="uptime white-font-70">{`${translate(uptime)} ${getUptime()} %`}</div>
        </div>
        <ChartTimeLine statusList={localStatusData} onClick={onClickTimeline} granularity={granularity} />
        <ChartLegend legendItems={legendItems} />
      </div>
      {fullStatusDetails.isEmpty() ? null : (
        <div className="export-1">
          <div className="title-container">
            <div className="error-log-sub-title system-activity-title gradient">{translate(labels.systemActivity)}</div>
            <CustomButton buttonDisplay={translate(exportText)} onClick={() => exportCSV(fullStatusDetails)} buttonClassName="export-button ignore-export" />
          </div>
          <ChartList statusDetails={fullStatusDetails} showAll={showAll} limit={ERROR_LIMIT} onClickShowMore={onClickShowMore} className="all-activity white-font" />
          <div className="button-container ignore-export">
            <CustomButton
              buttonDisplay={<GradientIcon iconComponent={<AiOutlineEllipsis />} gradientColors={showMoreGradient} startXCoordinate={0} startYCoordinate={90} />}
              buttonClassName="show-more-button"
              onClick={onClickShowMore}
            />
          </div>
        </div>
      )}
    </CommonModule>
  );
};
