import { Omit } from "@technis/shared";
import { Checkbox } from "antd";
import React, { FunctionComponent } from "react";
import { Field } from "react-final-form";

interface CheckBoxProps {
  value: boolean;
  label: string;
  onChange: (value: boolean) => void;

  disabled?: boolean;
  isMandatory?: boolean;
  containerClassName?: string;
  style?: React.CSSProperties;
  error?: string;
}

interface FormProps extends Omit<CheckBoxProps, "error"> {
  formPath: string;
}

export const CustomCheckbox: FunctionComponent<CheckBoxProps> = props => {
  const { label, containerClassName = "", isMandatory, value, onChange, style = {}, error, disabled } = props;

  return (
    <div className={`modal-checkbox ${containerClassName}`} style={style}>
      <Checkbox checked={value} onChange={e => onChange(e.target.checked)} disabled={disabled}>
        <div className="modal-input-label">
          {label}
          {isMandatory && <span className="mandatory-star">*</span>}
        </div>
      </Checkbox>
      {error ? <div className="validation-error">{error}</div> : null}
    </div>
  );
};

export const FormCheckBox: FunctionComponent<FormProps> = props => {
  const { label, containerClassName = "", isMandatory, value, onChange, formPath, style = {}, disabled } = props;

  return (
    <Field
      name={formPath}
      type={"text"}
      render={props => (
        <CustomCheckbox
          value={value}
          label={label}
          isMandatory={isMandatory}
          onChange={value => {
            props.input.onChange(value);
            onChange(value);
          }}
          style={style}
          containerClassName={containerClassName}
          error={props.meta.error}
          disabled={disabled}
        />
      )}
      label={label}
    />
  );
};
