import { UserLanguage } from "@technis/shared";

import { SupportedLanguages } from "../../lang/i18n";
import { TimeFormats } from "../../utils/time";
import { UserOptionsAction, UserOptionsActions } from "./userOptions.actions";

export interface UserOptionsState {
  language?: SupportedLanguages | UserLanguage;
  timeFormat?: TimeFormats;
  useTimeFormatFromLanguage?: boolean;
}

export const DEFAULT_STATE: UserOptionsState = {
  language: undefined,
  timeFormat: undefined,
  useTimeFormatFromLanguage: undefined,
};

export const reducer = (state: UserOptionsState = DEFAULT_STATE, action: UserOptionsAction): UserOptionsState => {
  switch (action.type) {
    case UserOptionsActions.SAVE_LANGUAGE:
      return { ...state, language: action.language };
    case UserOptionsActions.SAVE_TIME_FORMAT:
      return { ...state, timeFormat: action.timeFormat };
    case UserOptionsActions.USE_TIME_FORMAT_FROM_LANGUAGE:
      return { ...state, useTimeFormatFromLanguage: action.useTimeFormatFromLanguage };
    default:
      return state;
  }
};
