import { LocaleProvider } from "antd";
import React, { FunctionComponent, useEffect } from "react";
import { useQuery } from "react-apollo";
import { useDispatch } from "react-redux";
import { Redirect, Route, RouteComponentProps, Switch } from "react-router-dom";

import { userMe, UserMeResult } from "../../graphql/user.gql";
import { useCookieToken } from "../../hooks/token";
import { setAntLocale, setLanguage } from "../../lang/i18n";
import { AnalyticsHomePage } from "../../pages/";
import { getTranslationDetails } from "../../redux/app/app.actions";
import { saveLanguage } from "../../redux/userOptions/userOptions.actions";
import { FetchPolicies } from "../../utils/constants";
import { setTimeFormat } from "../../utils/time";
import { LoadingHomePage } from "../common/Loading";
import { PageError404 } from "../page-errors/PageErrors";

export const MAIN_ROUTE_PATHS = {
  analysisLandingPage: "/:eventId",
  analysisHome: "/analysis/:eventId",
};

type TokenInCookieRedirectProps = RouteComponentProps<{ eventId: string }>;

const TokenInCookieRedirect: FunctionComponent<TokenInCookieRedirectProps> = (props) => {
  const {
    match: {
      params: { eventId },
    },
  } = props;
  useCookieToken();
  return <Redirect to={`/analysis/${eventId}`} />;
};

export const MainRoutes: FunctionComponent = () => {
  const userByIdQuery = useQuery<UserMeResult>(userMe, { errorPolicy: "all", fetchPolicy: FetchPolicies.NETWORK_ONLY });
  const user = userByIdQuery.data?.me;
  const { error: loadingUserError, loading: loadingUserDetails } = userByIdQuery;

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getTranslationDetails());
  }, []);

  useEffect(() => {
    dispatch(saveLanguage(user?.lang));
    //TODO: Add params when user options are extended with time formats
    setTimeFormat(dispatch);
  }, [user?.lang]);

  if (loadingUserDetails && !loadingUserError) {
    return <LoadingHomePage />;
  } else {
    setLanguage(user?.lang);
  }

  return (
    <LocaleProvider locale={setAntLocale(user?.lang)}>
      <Switch>
        <Route path={MAIN_ROUTE_PATHS.analysisLandingPage} exact component={TokenInCookieRedirect} />
        <Route path={MAIN_ROUTE_PATHS.analysisHome} exact component={AnalyticsHomePage} />
        <Route component={PageError404} />
      </Switch>
    </LocaleProvider>
  );
};
