import React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";

import { PageErrorTemplate } from "./PageErrorTemplate";

type Page404Props = RouteComponentProps & {
  organizationId?: number;
  isTechnisAdmin?: boolean;
};

export const PageError404: React.ComponentClass = withRouter((props: Page404Props) => <PageErrorTemplate {...props} error={404} msg={"errors.404"} />);
