import React, { FunctionComponent } from "react";

import { Colors } from "../../utils/colors";

type Props = React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
  iconComponent: React.ComponentElement<any, any>;
  gradientColors: Colors[];
  startXCoordinate?: number;
  startYCoordinate?: number;
  endXCoordinate?: number;
  endYCoordinate?: number;
};

const renderGradientStep = (color: string, index: number, totalSteps: number) => <stop key={index} stopColor={color} offset={`${(100 / totalSteps) * index}%`} />;

export const GradientIcon: FunctionComponent<Props> = ({
  endXCoordinate = 0,
  endYCoordinate = 0,
  gradientColors,
  iconComponent,
  startXCoordinate = 100,
  startYCoordinate = 100,
}) => {
  const gradientId = gradientColors.join("-");
  return (
    <>
      <svg width="0" height="0">
        <linearGradient id={gradientId} x1={`${startXCoordinate}%`} y1={`${startYCoordinate}%`} x2={`${endXCoordinate}%`} y2={`${endYCoordinate}%`}>
          {gradientColors.map((gradient, index) => renderGradientStep(gradient, index, gradientColors.length))}
        </linearGradient>
      </svg>
      {React.cloneElement(iconComponent, { style: { fill: `url(#${gradientId})`, stroke: `url(#${gradientId})` } })}
    </>
  );
};
