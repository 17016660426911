import React, { FunctionComponent } from "react";
import { AiOutlineInfoCircle } from "react-icons/ai";

import { UI } from "../../../constants";
import { translate } from "../../../lang/i18n";
import { translation } from "../../../lang/translation";
import { AggregatedKpiCountingWithName } from "../../../pages/AnalyticsHomePage";
import { OverviewChart } from "../../charts/OverviewChart";
import { PassageSetting, ZoneSetting } from "../Header";
import { CommonModule } from "./CommonModule";

const { MODULE_TITLE_ICON_SIZE } = UI;

interface OverviewModuleProps {
  topZoneSettings: ZoneSetting;
  zoneSettings: ZoneSetting[];
  zonesKpis: AggregatedKpiCountingWithName[];
  passageSettings: PassageSetting[];
  passagesKpis: AggregatedKpiCountingWithName[];
  limit?: number;
  timeWindow: number;
  onDelete: () => void;
}

export const OverviewModule: FunctionComponent<OverviewModuleProps> = props => {
  const { onDelete, ...restProps } = props;
  return (
    <CommonModule
      iconSize={MODULE_TITLE_ICON_SIZE}
      title={translate(translation.analytics.modules.titles.overview)}
      Icon={AiOutlineInfoCircle}
      onDelete={onDelete}
      className="module-overview"
      isExportable
    >
      <OverviewChart {...restProps} />
    </CommonModule>
  );
};
