import { Button } from "antd";
import React, { FunctionComponent } from "react";

import { translate } from "../../lang/i18n";
import { DateRange } from "../../utils/aggregate";
import { DatePreset, getDateRangeByPreset } from "../../utils/time";
import { ActivityMessages, trackClick } from "../../utils/tracking";

const HEADER_PRESETS_OPTIONS = [DatePreset.TODAY, DatePreset.LAST_TWO_DAYS, DatePreset.LAST_WEEK, DatePreset.LAST_TWO_WEEKS];
interface Props {
  dates: DateRange;
  onUpdateDate: (dates: DateRange) => void;
  containerClassName: string;
  buttonClassName: string;
  buttonActivatedClassName: string;
  buttonTextClassName: string;
  datesLimits?: {
    begin: number;
    end: number;
  };
}

interface AggregationButtonProps {
  selected: boolean;
  text: string;
  onClick: () => void;
  buttonClassName: string;
  buttonActivatedClassName: string;
  buttonTextClassName: string;
  disabled?: boolean;
}

export const DatePresetButton: FunctionComponent<AggregationButtonProps> = props => {
  const { buttonActivatedClassName, buttonClassName, buttonTextClassName, disabled, onClick, selected, text } = props;
  return (
    <Button disabled={disabled} onClick={onClick} className={`custom-button ${buttonClassName} ${selected ? buttonActivatedClassName : ""}`}>
      <div className={buttonTextClassName}>{text}</div>
    </Button>
  );
};

export const HeaderPresets: FunctionComponent<Props> = props => {
  const { buttonActivatedClassName, buttonClassName, buttonTextClassName, containerClassName, dates, datesLimits, onUpdateDate } = props;

  const onDatePresetClick = ({ dateBegin, dateEnd }: { dateBegin: number; dateEnd: number }) => () => {
    onUpdateDate({ dateBegin, dateEnd });
    trackClick(ActivityMessages.dateRangeChange);
  };

  return (
    <div className={containerClassName}>
      {HEADER_PRESETS_OPTIONS.map(preset => {
        const { dateBegin, dateEnd } = getDateRangeByPreset(preset);
        const isSelected = dates.dateBegin === dateBegin && dates.dateEnd === dateEnd;
        const textTranslated = translate(`time.timeRanges.${preset}`);
        return (
          <DatePresetButton
            key={preset}
            selected={isSelected}
            text={isSelected ? textTranslated.toUpperCase() : textTranslated}
            onClick={onDatePresetClick({ dateBegin, dateEnd })}
            buttonClassName={buttonClassName}
            buttonActivatedClassName={buttonActivatedClassName}
            buttonTextClassName={buttonTextClassName}
            disabled={!!(datesLimits && (dateEnd >= datesLimits.end || dateBegin < datesLimits.begin))}
          />
        );
      })}
    </div>
  );
};
