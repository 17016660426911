import { useEffect, useRef, useState } from "react";
import { useSelector as _useSelector } from "react-redux";

import { RootState } from "../redux/store";

export const usePrevious = <T extends unknown>(value: T) => {
  const ref = useRef<T>();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

export const useMounted = () => {
  const [mounted, setMounted] = useState(false);
  useEffect(() => {
    setMounted(true);
  }, []);
  return mounted;
};

export const useSelector = <T>(selector: (state: RootState) => T) => _useSelector<RootState, T>(selector);
