import React, { FunctionComponent } from "react";

import { i18n } from "../../lang/i18n";
import { translation } from "../../lang/translation";

interface PdfFooterProps {
  email: string;
  className?: string;
  id: string;
}

export const PdfFooter: FunctionComponent<PdfFooterProps> = props => {
  const { className = "", email, id } = props;
  return (
    <div className={`export-footer ${className}`} id={id}>
      <div className="container-footer">
        <div className="email">{`${i18n.t(translation.common.email)}: ${email}`}</div>
      </div>
    </div>
  );
};
