import { Omit } from "@technis/shared";
import { DatePicker } from "antd";
import moment from "antd/node_modules/moment";
import React, { FunctionComponent, useState } from "react";
import { Field } from "react-final-form";

import { Dates, UI } from "../../../constants";
import { translate } from "../../../lang/i18n";
import { translation } from "../../../lang/translation";
import { capitalize } from "../../../utils/translation";

const { FIELD_TOUCHED_DELAY } = UI;
const { MODAL_DATE_FORMAT } = Dates;

const { select } = translation.common;

interface DatePickerProps {
  value: number;
  onChange: (date: moment.Moment | null, dateString: string) => void;
  id: string;

  label?: string;
  placeholder?: string;
  isMandatory?: boolean;
  disabledDate?: (currentTimestampMS: number) => boolean;
  containerClassName?: string;
  onOpenChange?: () => void;
  error?: string;
}

interface FormProps extends Omit<DatePickerProps, "onOpenChange" | "error"> {
  formPath: string;
}

export const CustomDatePicker: FunctionComponent<DatePickerProps> = props => {
  const { id, value, label, placeholder = capitalize(translate(select)), containerClassName = "", isMandatory, onChange, onOpenChange, disabledDate, error } = props;

  return (
    <div className={`modal-datepicker-container ${containerClassName}`} id={id}>
      {label ? (
        <div className="modal-input-label">
          {label}
          {isMandatory && <span className="mandatory-star">*</span>}
        </div>
      ) : null}
      <DatePicker
        onOpenChange={onOpenChange}
        className="modal-datepicker"
        getCalendarContainer={() => document.getElementById(id) || document.createElement("div")}
        placeholder={placeholder}
        format={MODAL_DATE_FORMAT}
        onChange={onChange}
        value={value ? moment(value) : undefined}
        disabledDate={date => (disabledDate && date ? disabledDate(date.valueOf()) : false)}
      />
      <div className="modal-input-line" />
      {error ? <div className="validation-error">{error}</div> : null}
    </div>
  );
};

export const FormDatePicker: FunctionComponent<FormProps> = props => {
  const [touched, setTouched] = useState(false);
  const { label, containerClassName = "", isMandatory, value, placeholder = capitalize(translate(select)), onChange, id, disabledDate, formPath } = props;

  return (
    <Field
      name={formPath}
      type="dropdown"
      render={props => (
        <CustomDatePicker
          value={value}
          onChange={(date, dateString) => {
            props.input.onChange(date);
            onChange(date, dateString);
          }}
          onOpenChange={() => setTimeout(() => setTouched(true), FIELD_TOUCHED_DELAY)}
          id={id}
          disabledDate={disabledDate}
          containerClassName={containerClassName}
          isMandatory={isMandatory}
          placeholder={placeholder}
          label={label}
          error={touched ? props.meta.error : undefined}
        />
      )}
      label={label}
    />
  );
};
