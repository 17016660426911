import classnames from "classnames";
import React, { FunctionComponent } from "react";

import { Colors } from "../../../utils/colors";
import { legendClassname } from "../../../utils/utils";
import { ParsedText } from "../../lang/ParsedText";
import { legendParser } from "../../lang/Parsers";
import { CustomButton } from "../buttons/CustomButton";
import { LegendSymbol } from "./LegendSymbol";

interface ChartLegendProps {
  legendItems: LegendItem[];
  className?: string;
}

export interface LegendItem {
  color: Colors;
  text: string;
  symbol?: JSX.Element;
  onClick?: () => void;
  active?: boolean;
}

export const ChartLegend: FunctionComponent<ChartLegendProps> = props => {
  const { legendItems } = props;

  const legend = legendItems.map((legendItem, index) => {
    const { symbol = <LegendSymbol color={legendItem.color} />, text } = legendItem;
    return (
      <div className="legend-display-container" key={Math.random().toString()}>
        <div key={`chart-legend-row-${index}`} className="module-legend">
          {symbol}
          <div className="module-legend-text white-font">
            <ParsedText parsers={[legendParser()]}>{text}</ParsedText>
          </div>
        </div>
      </div>
    );
  });
  return <div className={legendClassname(legendItems.length)}>{legend}</div>;
};

export const ButtonLegend: FunctionComponent<ChartLegendProps> = props => {
  const { className = "", legendItems } = props;

  const legend = legendItems.map((legendItem, index) => {
    const { symbol = <LegendSymbol color={legendItem.color} />, text, onClick, active } = legendItem;
    const buttonDisplay = () => (
      <>
        {symbol}
        <div className="module-legend-text white-font">
          <ParsedText parsers={[legendParser()]}>{text}</ParsedText>
        </div>
      </>
    );

    return (
      <div key={`chart-legend-button-${index}`} className={classnames("legend-display-container", { "ignore-export": !active })}>
        <CustomButton
          buttonDisplay={buttonDisplay()}
          onClick={onClick}
          buttonClassName="module-legend-button"
          buttonActivatedClassName="module-legend-button-activated"
          selected={!active}
        />
      </div>
    );
  });
  return <div className={classnames(legendClassname(legendItems.length), className)}>{legend}</div>;
};

export const GradientLegend: FunctionComponent<ChartLegendProps> = props => {
  const { legendItems } = props;

  const gradientColors = legendItems.map(legendItem => legendItem.color).join(", ");

  const legend = legendItems.map((legendItem, index) => {
    const { text } = legendItem;
    return (
      <div key={`chart-legend-row-${index}`} className="module-legend">
        {<div className="module-legend-text white-font">{text}</div>}
      </div>
    );
  });
  return (
    <div className={"module-legend-container-gradient"} style={{ background: `linear-gradient(0.25turn, ${gradientColors})` }}>
      {legend}
    </div>
  );
};
