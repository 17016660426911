import { ID, UserRoles } from "@technis/shared";
import { RadioChangeEvent } from "antd/lib/radio";
// eslint-disable-next-line import/named
import React, { FunctionComponent, useEffect, useState } from "react";
import { useQuery } from "react-apollo";
import { FaMoon, FaSun } from "react-icons/all";
import { useSelector } from "react-redux";
// eslint-disable-next-line import/named
import { RouteComponentProps, useRouteMatch } from "react-router-dom";

import { AggregationType } from "../../components/charts/AffluenceChart";
import { ErrorSelection } from "../../components/common/error/Errors";
import { Header, PassageSetting, ZoneSetting } from "../../components/common/Header";
import { LoadingHomePage } from "../../components/common/Loading";
import { LoadErrorModule } from "../../components/common/module/ErrorModule";
import { SelectModule } from "../../components/common/module/SelectModule";
import { ThemeVariant, useTheme } from "../../components/common/Theme";
import { Dates } from "../../constants";
import { devicesAtmosphere, DevicesAtmosphereResult } from "../../graphql/atmosphere.gql";
import { EventByIdExtraInstallation, eventByIdExtraInstallation } from "../../graphql/event.gql";
import { statusByEventDates, StatusByEventDatesResult } from "../../graphql/status.gql";
import { userMe, UserMeResult } from "../../graphql/user.gql";
import { allZonesAllPassagesAnalyticsKpiQuery, AllZonesAllPassagesAnalyticsKpiQueryResult } from "../../graphql/zones.gql";
import { withTracking, WithTrackingProps } from "../../hoc/withTracking";
import { RouterParams } from "../../redux/router/router.reducer";
import { RootState } from "../../redux/store";
import { AggregatedKpiAtmosphere, AggregatedKpiCounting, aggregatePadList, aggregateStatus, DateRange, Granularity, StatusData } from "../../utils/aggregate";
import { ErrorPolicies, FetchPolicies, TrackingLogViewActivityName, TrackingLogViewActivityTopic } from "../../utils/constants";
import { endOfDay, startOfDay } from "../../utils/time";
import { ActivityMessages, trackCheckbox } from "../../utils/tracking";
import { getSevenLastDays, isDefined } from "../../utils/utils";
import { getAllAggregation, getDateBegin, getErrorComponent, getPassageSettings, getZoneSettings, isAllDataReady, isKpisEmpty, isSettingsReady } from "./helpers";
import { AggregatedKpiAtmosphereWithName, AggregatedKpiCountingWithName, Module, ModuleKind, ShiftState } from "./types";

const { MILLISECONDS_IN_WEEK } = Dates;

const QUERY_SETTINGS = {
  errorPolicy: ErrorPolicies.ALL,
  fetchPolicy: FetchPolicies.NETWORK_ONLY,
};

type Props = RouteComponentProps<{ eventId: string }> & WithTrackingProps;

interface State {
  dateRange: DateRange;
  modules: Module[];
  zoneSettings: ZoneSetting[];
  passageSettings: PassageSetting[];
  zoneKpiAggregated: AggregatedKpiCountingWithName[];
  passageKpiAggregated: AggregatedKpiCountingWithName[];
  zoneKpiAtmosphereAggregated: AggregatedKpiAtmosphereWithName[];
  aggregatedStatus: StatusData[];
  shiftState: ShiftState;
}

const {
  AFFLUENCE_MODULE,
  ATMOSPHERE_GRAPH_MODULE,
  ERROR_LOG_MODULE,
  GENERAL_INFORMATION,
  INSIDE_MODULE,
  MOST_USED_PASSAGES_MODULE,
  MOST_USED_ZONES_MODULE,
  OVERVIEW_MODULE,
  QUANTILE_MODULE,
  SUMMARY_AIR_MODULE,
  WAITING_TIME_MODULE,
} = ModuleKind;

const MODULES = [
  GENERAL_INFORMATION,
  SUMMARY_AIR_MODULE,
  OVERVIEW_MODULE,
  INSIDE_MODULE,
  WAITING_TIME_MODULE,
  ATMOSPHERE_GRAPH_MODULE,
  QUANTILE_MODULE,
  MOST_USED_ZONES_MODULE,
  MOST_USED_PASSAGES_MODULE,
  AFFLUENCE_MODULE,
  ERROR_LOG_MODULE,
];

export const AnalyticsHomePage: FunctionComponent<Props> = withTracking(props => {
  const { params } = useRouteMatch<RouterParams>();
  const eventId = params.eventId ? Number(params.eventId) : undefined;
  const { setThemeVariant, themeVariant } = useTheme();
  const { setTrackingUser, trackViewActivity } = props;

  const token = useSelector((state: RootState) => state.auth.token);

  const userByIdQuery = useQuery<UserMeResult>(userMe, QUERY_SETTINGS);
  const user = userByIdQuery.data?.me;

  setTrackingUser(user);

  const isAdmin = user?.role === UserRoles.ADMIN;
  const userLoading = userByIdQuery.loading;

  const [state, setState] = useState<State>({
    dateRange: getSevenLastDays(),
    modules: MODULES.filter(module => user?.role === UserRoles.ADMIN || module !== ERROR_LOG_MODULE).map(module => ({
      moduleKind: module,
      ...(module === ModuleKind.AFFLUENCE_MODULE && { aggregation: AggregationType.SUM }),
    })),
    passageSettings: [],
    zoneSettings: [],
    passageKpiAggregated: [],
    zoneKpiAggregated: [],
    zoneKpiAtmosphereAggregated: [],
    aggregatedStatus: [],
    shiftState: ShiftState.SHIFTED,
  });

  const { aggregatedStatus, dateRange, modules, passageKpiAggregated, passageSettings, shiftState, zoneKpiAggregated, zoneKpiAtmosphereAggregated, zoneSettings } = state;

  const granularity = Granularity.CONTINUOUS_DAYS;

  const { data: cartography, loading: loadingCartography } = useQuery<EventByIdExtraInstallation>(eventByIdExtraInstallation, {
    ...QUERY_SETTINGS,
    variables: { eventId },
    skip: !token,
  });

  const installation = cartography?.eventById?.installation;
  const limit = cartography?.eventById?.limit;
  const metrics = cartography?.eventById?.metrics;
  const eventDateEnd = cartography?.eventById?.dateEnd || endOfDay();
  const eventDateBegin = cartography?.eventById?.dateBegin || startOfDay();

  const eventName = cartography?.eventById?.name || "";

  const { data: installationKpis, loading } = useQuery<AllZonesAllPassagesAnalyticsKpiQueryResult>(allZonesAllPassagesAnalyticsKpiQuery, {
    ...QUERY_SETTINGS,
    variables: { installationId: installation?.id, dates: [dateRange.dateBegin, dateRange.dateEnd], eventId },
    skip: !token || !installation?.id,
  });

  const { data: status, loading: loadingStatus } = useQuery<StatusByEventDatesResult>(statusByEventDates, {
    ...QUERY_SETTINGS,
    variables: { dates: [dateRange.dateBegin, dateRange.dateEnd], eventId },
    skip: !token && !isAdmin,
  });

  const { data: devicesAtmospherelist, loading: loadingDevicesAtmosphere } = useQuery<DevicesAtmosphereResult>(devicesAtmosphere, {
    ...QUERY_SETTINGS,
    variables: { installationId: installation?.id },
    skip: !token || !installation?.id,
  });

  useEffect(() => {
    if (installation) {
      if (eventDateEnd && eventDateEnd < dateRange.dateEnd) {
        setState(prevState => ({
          ...prevState,
          dateRange:
            eventDateEnd - eventDateBegin >= MILLISECONDS_IN_WEEK
              ? getSevenLastDays(eventDateEnd)
              : {
                  dateBegin: eventDateBegin,
                  dateEnd: eventDateEnd,
                },
        }));
      }

      const { zones } = installation;

      const zoneSettings = getZoneSettings(zones);
      const passageSettings = getPassageSettings(zones);

      setState(prevState => ({ ...prevState, passageSettings, zoneSettings }));
    }
  }, [installation]);

  //log view activity
  useEffect(() => {
    trackViewActivity(TrackingLogViewActivityTopic.analytics, TrackingLogViewActivityName.analytics);
  }, []);

  useEffect(() => {
    if (installationKpis && devicesAtmospherelist && (!isAdmin || status)) {
      const { passageKpiAggregated, zoneKpiAggregated, zoneKpiAtmosphereAggregated } = getAllAggregation({
        metrics,
        dateRange,
        granularity,
        passageSettings,
        zoneSettings,
        installationKpis,
        isShifting: shiftState === ShiftState.SHIFTED,
      });

      const aggregatedStatus =
        isAdmin && status
          ? aggregateStatus(
              status.statusByEventDates,
              aggregatePadList(installationKpis, passageSettings, devicesAtmospherelist),
              dateRange,
              granularity,
              getDateBegin(zoneKpiAggregated, zoneKpiAtmosphereAggregated),
            )
          : [];

      setState({ ...state, passageKpiAggregated, zoneKpiAggregated, zoneKpiAtmosphereAggregated, aggregatedStatus });
    }
  }, [installationKpis, zoneSettings, passageSettings, status, devicesAtmospherelist, shiftState]);

  const deleteModule = (index: number) => {
    setState({ ...state, modules: modules.removeAt(index) });
  };

  const updateDates = (dateRange: DateRange) => {
    setState({ ...state, dateRange });
  };

  const updateZoneSetting = (setting: ZoneSetting) => {
    const indexSetting = zoneSettings.findIndex(zoneSetting => zoneSetting.id === setting.id);
    setState({
      ...state,
      zoneSettings: zoneSettings.replaceAt(setting, indexSetting),
    });
  };

  const updatePassageSetting = (setting: PassageSetting) => {
    const indexSetting = passageSettings.findIndex(passageSetting => passageSetting.id === setting.id);
    trackCheckbox(ActivityMessages.passageSettingsChange);
    setState({
      ...state,
      passageSettings: passageSettings.replaceAt(setting, indexSetting),
    });
  };

  const onChangeShiftState = (event: RadioChangeEvent) => {
    setState(prevState => ({ ...prevState, shiftState: event.target.value }));
  };

  const getFlowRate = (cartography?: EventByIdExtraInstallation) => {
    if (!cartography) return undefined;
    return cartography.eventById.installation.zones[0].flowRate;
  };

  if (loadingCartography || userLoading) {
    return <LoadingHomePage />;
  }

  const ErrorComponent = getErrorComponent({ eventId, installation, metrics, installationKpis, token, loading });

  if (ErrorComponent) {
    return <ErrorSelection DefaultError={ErrorComponent} />;
  }

  // TODO: Fix shitty type
  const topZoneWithKpi = zoneKpiAggregated[0] as AggregatedKpiCounting & { zoneId: ID; zoneName: string };
  const topZoneWithKpiAtmosphere = zoneKpiAtmosphereAggregated[0] as AggregatedKpiAtmosphere & { zoneName: string };
  const datesLimits = { begin: eventDateBegin, end: eventDateEnd };

  const zones = cartography?.eventById.installation.zones;

  const isTopReady = !!topZoneWithKpi || !!topZoneWithKpiAtmosphere;

  const isAllZoneKpisEmpty = !!installationKpis?.zonesByInstallation.every(zone => zone.kpis.isEmpty());

  const isAllPassageKpisEmpty = !!installationKpis?.passagesByInstallation.every(passage => passage.kpis.isEmpty());

  const canRenderModules =
    isDefined(zones) &&
    isAllDataReady(zoneKpiAggregated, passageKpiAggregated, zoneKpiAtmosphereAggregated, aggregatedStatus, isAdmin, metrics) &&
    isTopReady &&
    isSettingsReady(passageSettings, zoneSettings, metrics);

  return (
    <Header
      eventName={eventName}
      dateRange={dateRange}
      datesLimits={datesLimits}
      title={installation?.name || ""}
      onUpdateDate={updateDates}
      onChangePassageSettings={updatePassageSetting}
      onChangeZoneSettings={updateZoneSetting}
      passageSettings={passageSettings}
      zoneSettings={zoneSettings}
      isLoading={loading || loadingStatus || loadingDevicesAtmosphere}
      onChangeShiftState={onChangeShiftState}
      shiftState={shiftState}
      showShiftRadio={topZoneWithKpi?.isShifting || topZoneWithKpiAtmosphere?.isShifting}
    >
      <div className="theme-icon ignore-export" onClick={() => setThemeVariant(themeVariant === ThemeVariant.LIGHT ? ThemeVariant.DARK : ThemeVariant.LIGHT)}>
        {themeVariant === ThemeVariant.LIGHT ? <FaMoon /> : <FaSun />}
      </div>
      {canRenderModules && isDefined(zones) ? (
        modules.map((module, index) => (
          <SelectModule
            key={`module-index-${index}`}
            dateRange={dateRange}
            eventId={eventId || 0}
            index={index}
            module={module}
            passageKpiAggregated={passageKpiAggregated}
            passageSettings={passageSettings}
            topZoneWithKpi={topZoneWithKpi}
            topZoneWithKpiAtmosphere={topZoneWithKpiAtmosphere}
            zoneKpiAggregated={zoneKpiAggregated}
            zoneKpiAtmosphereAggregated={zoneKpiAtmosphereAggregated}
            zoneSettings={zoneSettings}
            deleteModule={deleteModule}
            limit={limit}
            datesLimits={datesLimits}
            metrics={metrics || []}
            aggregatedStatus={aggregatedStatus}
            userRole={user?.role}
            flowRate={getFlowRate(cartography)}
          />
        ))
      ) : (
        <LoadErrorModule isAllDataReady={!isKpisEmpty(isAllZoneKpisEmpty, isAllPassageKpisEmpty, metrics) && isSettingsReady(passageSettings, zoneSettings, metrics)} />
      )}
    </Header>
  );
});
