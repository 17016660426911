import { ID, Kpi, KpiAtmosphere } from "@technis/shared";

export enum DaylightSavingTime {
  WINTER_TO_SUMMER = "winterToSummer",
  SUMMER_TIME = "summerTime",
  SUMMER_TO_WINTER = "summerToWinter",
  WINTER_TIME = "winterTime",
  DEFAULT = "default",
}

export enum Granularity {
  CONTINUOUS_DAYS = "CONTINUOUS_DAYS",
  CONTINUOUS_WEEKS = "CONTINUOUS_WEEKS",
  CONTINUOUS_MONTHS = "CONTINUOUS_MONTHS",
  CONTINUOUS_YEARS = "CONTINUOUS_YEARS",
  DISCONTINUOUS_DAYS = "DISCONTINUOUS_DAYS",
  DISCONTINUOUS_WORKING_DAYS = "DISCONTINUOUS_WORKING_DAYS",
  DISCONTINUOUS_WEEKEND_DAYS = "DISCONTINUOUS_WEEKEND_DAYS",
}

export type KpiAtmosphereWithDates = KpiAtmosphere & { dateBegin: number; dateEnd: number; periodId: ID };

export type KpiCountingWithDates = Kpi & { dateBegin: number; dateEnd: number; periodId: ID };

export interface DateRange {
  dateBegin: number;
  dateEnd: number;
}

export interface AggregatedDates {
  dateBegins: number[];
  dateEnds: number[];
  granularity: Granularity;
  daylightSavingTimes: DaylightSavingTime[];
}
