import { ID } from "@technis/shared";
import { Radio } from "antd";
import { RadioChangeEvent } from "antd/lib/radio";
import classNames from "classnames";
import React, { FunctionComponent, useEffect, useState } from "react";
import { useQuery } from "react-apollo";
import { AiOutlineLineChart } from "react-icons/ai";

import { UI } from "../../../constants";
import { atmosphereKpiQuery, AtmosphereKpiQueryResult } from "../../../graphql/zones.gql";
import { translate } from "../../../lang/i18n";
import { translation } from "../../../lang/translation";
import { AggregatedKpiAtmosphereWithName } from "../../../pages/AnalyticsHomePage";
import { DateRange, KpiAtmosphereAggregator } from "../../../utils/aggregate";
import { updateModuleDate } from "../../../utils/time";
import { areObjectsEqual } from "../../../utils/utils";
import { KpiAtmosphereType, ViewType } from "../../charts";
import { AtmosphereChart } from "../../charts/AtmosphereChart";
import { ParsedText } from "../../lang/ParsedText";
import { labelParser } from "../../lang/Parsers";
import { ChartModule } from "./ChartModule";

const { MODULE_TITLE_ICON_SIZE } = UI;
const RADIO_KEY_ATMOSPHERE = "atmopshere-module";
const VIEW_KEY = "atmosphere-view";

const { labels, titles, tooltips } = translation.analytics.modules;
const { common } = translation;

interface AtmosphereModuleProps {
  dateRange: DateRange;
  kpiAtmosphere: AggregatedKpiAtmosphereWithName;
  keyNumber: number;
  zoneName: string;
  eventId: ID;
  onDelete: () => void;
  datesLimits: {
    begin: number;
    end: number;
  };
  timeWindow?: number;
}

export const AtmosphereModule: FunctionComponent<AtmosphereModuleProps> = props => {
  const { dateRange, datesLimits, eventId, keyNumber, kpiAtmosphere, onDelete, timeWindow, zoneName } = props;
  const [moduleDates, setModuleDates] = useState<DateRange>(dateRange);
  const [kpiAtmosphereType, setKpiAtmosphereType] = useState<KpiAtmosphereType>(KpiAtmosphereType.CO2);
  const [view, setView] = useState<ViewType>(ViewType.BROWSER);

  useEffect(() => {
    setModuleDates(dateRange);
  }, [dateRange]);

  const shouldHeaderOverride = areObjectsEqual(dateRange, moduleDates);

  const { granularity, zoneId, zoneName: kpiZoneName } = kpiAtmosphere;

  const { data: moduleKpi, loading } = useQuery<AtmosphereKpiQueryResult>(atmosphereKpiQuery, {
    errorPolicy: "all",
    variables: { zoneId, dates: [moduleDates.dateBegin, moduleDates.dateEnd], eventId },
    skip: shouldHeaderOverride,
  });

  const atmosphereKpiAggregated: AggregatedKpiAtmosphereWithName =
    !shouldHeaderOverride && moduleKpi
      ? {
          ...new KpiAtmosphereAggregator({ kpis: moduleKpi?.zoneById.kpisAtmosphere, granularity, timeWindow, dateRange, isDataShifting: true }).getAggregatedData(),
          zoneName: kpiZoneName,
        }
      : kpiAtmosphere;

  const updateDate = updateModuleDate(setModuleDates);

  const onChangeKpi = (event: RadioChangeEvent) => {
    setKpiAtmosphereType(event.target.value);
  };
  const onChangeView = (event: RadioChangeEvent) => {
    setView(event.target.value);
  };

  return (
    <ChartModule
      dateRange={moduleDates}
      iconSize={MODULE_TITLE_ICON_SIZE}
      keyNumber={keyNumber}
      title={translate(titles.airQuality)}
      tooltipText={translate(tooltips.airQuality)}
      zoneName={zoneName}
      Icon={AiOutlineLineChart}
      onDelete={onDelete}
      onUpdateDate={updateDate}
      loading={loading}
      radio={{
        groups: [
          <Radio.Group key={`${VIEW_KEY}-radio-group`} onChange={onChangeView} value={view}>
            <Radio value={ViewType.BROWSER}>{translate(common.browserView)}</Radio>
            <Radio value={ViewType.EXPORT}>{translate(common.exportView)}</Radio>
          </Radio.Group>,
          <Radio.Group key={`${RADIO_KEY_ATMOSPHERE}-radio-group`} className={classNames({ hide: view === ViewType.EXPORT })} onChange={onChangeKpi} value={kpiAtmosphereType}>
            <Radio value={KpiAtmosphereType.CO2}>
              <ParsedText parsers={[labelParser()]}>{translate(labels.co2)}</ParsedText>
            </Radio>
            <Radio value={KpiAtmosphereType.TEMPERATURE}>{translate(labels.temperature)}</Radio>
            <Radio value={KpiAtmosphereType.HUMIDITY}>{translate(labels.humidity)}</Radio>
          </Radio.Group>,
        ],
        key: RADIO_KEY_ATMOSPHERE,
      }}
      datesLimits={datesLimits}
      isExportable
    >
      <AtmosphereChart kpiAtmosphere={atmosphereKpiAggregated} chartType={kpiAtmosphereType} view={view} />
    </ChartModule>
  );
};
