import { isDefined } from "@technis/shared";
import React, { FunctionComponent, useEffect, useState } from "react";
// eslint-disable-next-line import/named
import { CartesianGrid, Line as LineRechart, LineChart, ReferenceArea, ResponsiveContainer, Tooltip, TooltipProps, XAxis, YAxis } from "recharts";
import { AxisDomain } from "recharts/types/util/types";

import { CHART_HEIGHT } from "../../constants/ui";
import { useSelector } from "../../hooks";
import { Granularity } from "../../utils/aggregate";
import { CHART_LINE_TRANSPARENCY, transparentize } from "../../utils/colors";
import { getInterval, getTicks } from "../../utils/time";
import { ActivityMessages, trackClick } from "../../utils/tracking";
import { getFontSize, getLines, getTickPosition, tickFormater } from "../../utils/utils";
import { ButtonLegend } from "../common/custom-chart/ChartLegend";
import { getTheme, ThemeVariant, useTheme } from "../common/Theme";
import { ParsedText } from "../lang/ParsedText";
import { labelParser } from "../lang/Parsers";
import {
  ChartTooltipLines,
  createLegend,
  getAxisDomain,
  getChartTitle,
  getZoomExtremities,
  KpiAtmosphereType,
  Line,
  MIN_ZOOM,
  XRenderChartTick,
  YRenderChartTick,
  ZoomButtons,
} from "./index";

interface GenericLineChartProps {
  dateBegin: number;
  chartType: KpiAtmosphereType;
  lines: Line[];
  isExport?: boolean;
  granularity: Granularity;
}

interface State {
  left: number;
  right: number;
  refAreaLeft: string;
  refAreaRight: string;
  top: string;
  bottom: string;
  isZoom: boolean;
  localLines: Line[];
}

export const GenericLineChart: FunctionComponent<GenericLineChartProps> = props => {
  const { chartType, dateBegin, granularity, isExport, lines } = props;

  const [state, setState] = useState<State>({
    left: 0,
    right: 0,
    refAreaLeft: "",
    refAreaRight: "",
    top: "auto",
    bottom: "0",
    isZoom: false,
    localLines: lines,
  });

  const { bottom, isZoom, left, localLines, refAreaLeft, refAreaRight, right, top } = state;

  useEffect(() => {
    setState(prevState => ({ ...prevState, localLines: lines }));
  }, [lines]);

  const data = getLines(localLines, granularity);

  const genericTickFormater = tickFormater();

  const { themeVariant } = useTheme();
  const isExporting = useSelector(state => state.app.isExporting);
  const timeFormat = useSelector(state => state.userOptions.timeFormat);
  const language = useSelector(state => state.userOptions.language);
  const useTimeFormatFromLanguage = useSelector(state => state.userOptions.useTimeFormatFromLanguage);
  const { font } = getTheme(isExporting ? ThemeVariant.LIGHT : themeVariant);
  const fontSize = getFontSize(isExporting);
  const tickPosition = getTickPosition(isExporting);

  const tickNumber = right - left;
  const tickBorder = { startTick: left, endTick: right };
  const yDomain: AxisDomain = [Number(bottom), isZoom ? Number(top) : "auto"];
  const xDomain: AxisDomain = [left, right];
  const isZoomInDisabled = tickNumber < MIN_ZOOM;

  useEffect(() => {
    setState(prevState => ({ ...prevState, left: 0, right: data.length, refAreaLeft: "", refAreaRight: "", top: "auto", bottom: "0", isZoom: false }));
  }, [dateBegin]);

  const zoom = (from: number, to: number, bottom?: string) => {
    if (from === to || (refAreaLeft && refAreaRight === "")) {
      setState(() => ({ ...state, refAreaLeft: "", refAreaRight: "" }));
      return;
    }
    const domain = getAxisDomain(data, from, to, localLines);

    const isZoom = from !== 0 || to !== data.length;
    setState({
      ...state,
      refAreaLeft: "",
      refAreaRight: "",
      left: domain.refAreaLeft,
      right: domain.refAreaRight,
      bottom: bottom ? bottom : domain.bottom,
      top: isZoom ? domain.top : "auto",
      isZoom,
    });
  };

  const zoomExtremities = getZoomExtremities(left, right);

  const onMouseDown = (e: { activeLabel: string }) => {
    if (isDefined(e)) setState({ ...state, refAreaLeft: e.activeLabel });
  };
  const onMouseMove = (e: { activeLabel: string }) => refAreaLeft && setState({ ...state, refAreaRight: e.activeLabel });
  const onMouseUp = () => zoom(Number(refAreaLeft), Number(refAreaRight));

  const onClickZoomIn = () => {
    zoom(zoomExtremities.left, zoomExtremities.right);
    trackClick(ActivityMessages.zoomIn);
  };
  const onClickZoomOut = () => {
    zoom(0, data.length, "0");
    trackClick(ActivityMessages.zoomOut);
  };

  const onClickLegend = (index: number) => {
    setState({ ...state, localLines: localLines.map((line, i) => (index === i ? { ...line, isActive: !line.isActive } : line)) });
  };

  const renderLegend = createLegend(onClickLegend);

  return (
    <>
      <ZoomButtons zoomOutDisabled={!isZoom} zoomInDisabled={isZoomInDisabled} onClickZoomIn={onClickZoomIn} onClickZoomOut={onClickZoomOut} />
      {(isExport || isExporting) && (
        <div className="atmosphere-kpi-title">
          <ParsedText parsers={[labelParser()]}>{getChartTitle(chartType)}</ParsedText>
        </div>
      )}
      <ResponsiveContainer width="100%" height={CHART_HEIGHT + tickPosition}>
        <LineChart data={data} margin={{ top: 30, right: 20, bottom: -10 + tickPosition, left: 20 }} onMouseDown={onMouseDown} onMouseMove={onMouseMove} onMouseUp={onMouseUp}>
          <CartesianGrid stroke={transparentize(font, CHART_LINE_TRANSPARENCY)} />
          <XAxis
            allowDataOverflow={true}
            dataKey="time"
            axisLine={false}
            ticks={getTicks(data.length, getInterval(tickNumber, granularity), tickBorder)}
            tick={tickObject => (
              <XRenderChartTick
                tickObject={tickObject}
                granularity={granularity}
                dateBegin={dateBegin}
                fontColor={font}
                fontSize={fontSize}
                tickPosition={tickPosition}
                timeFormat={timeFormat}
                language={language}
                useTimeFormatFromLanguage={useTimeFormatFromLanguage}
              />
            )}
            tickFormatter={genericTickFormater}
            stroke={transparentize(font, CHART_LINE_TRANSPARENCY)}
            tickMargin={12}
            domain={xDomain}
            scale="time"
            type="number"
          />
          <YAxis
            allowDataOverflow={true}
            tickLine={false}
            axisLine={false}
            tick={tickObject => <YRenderChartTick tickObject={tickObject} fontColor={font} fontSize={fontSize} />}
            domain={yDomain}
            stroke={transparentize(font, CHART_LINE_TRANSPARENCY)}
          />
          <Tooltip content={(tooltipObject: TooltipProps<number, string>) => <ChartTooltipLines tooltip={tooltipObject} startDate={dateBegin} decimal={0} />} />
          {localLines.map(line => line.isActive && <LineRechart type="monotone" dataKey={line.name} key={line.name} stroke={line.color} dot={false} />)}
          {refAreaLeft && refAreaRight && <ReferenceArea x1={refAreaLeft} x2={refAreaRight} strokeOpacity={0.1} />}
        </LineChart>
      </ResponsiveContainer>
      <ButtonLegend legendItems={localLines.map(renderLegend)} />
    </>
  );
};
