import { Indexable, Kpi, KpiAtmosphere, Maybe, Passage, Zone } from "@technis/shared";
import classnames from "classnames";
import * as crypto from "crypto";
import dateFormat from "dateformat";
import { isEmpty } from "lodash";
import * as moment from "moment";
import * as unset from "unset-value";

import { ApolloError } from "../api/apollo";
import { Line, LineType } from "../components/charts";
import { PRIORITIES } from "../components/common/custom-chart/ChartTimeLine";
import { PassageSetting, ZoneSetting } from "../components/common/Header";
import { Dates, FIREFOX, UI } from "../constants";
import { DAY_DATE_FORMAT, DAY_MONTH_YEAR_FORMAT } from "../constants/date";
import { translate } from "../lang/i18n";
import { translation } from "../lang/translation";
import { AggregatedKpiAtmosphereWithName, AggregatedKpiCountingWithName } from "../pages/AnalyticsHomePage";
import {
  AggregatedDates,
  AggregatedKpiAtmosphere,
  DateRange,
  DaylightSavingTime,
  Device,
  DeviceType,
  Granularity,
  KpiAtmosphereAggregator,
  KpiAtmosphereWithDates,
  KpiCountingAggregator,
  KpiCountingWithDates,
  StatusDetail,
} from "./aggregate";
import { changeHue, Colors } from "./colors";
import { endOfDay, getMinutesFromStartOfDay, startOfDay } from "./time";

const { DAYS_IN_WEEK, DAYS_IN_WORKING_WEEK, HOURS_IN_DAY, MILLISECONDS_IN_DAY, MINUTES_IN_DAY, MINUTES_IN_HOUR, MINUTES_IN_WEEK, MONTHS_IN_YEAR } = Dates;
const { CHART_TICK_FONT_SIZE, CHART_TICK_POSITION, EXPORT_CHART_TICK_FONT_SIZE, EXPORT_CHART_TICK_POSITION } = UI;

const { max, mean, min, today, week, year } = translation.common;
const { missingKpiData, missingKpiProperty } = translation.errors;
const { atmosphere, blackBox, camera } = translation.common.sensor;

export const allApolloErrorsToString = (err: ApolloError) =>
  [...err.networkError, ...err.graphQLErrors]
    .filter(e => e)
    .map(e => e.message)
    .join("\n");

export const clone = <T>(obj: T): T => JSON.parse(JSON.stringify(obj));

const isString = (value: string | number) => isNaN(Number(value));

export const enumToArray = <T>(e: Indexable<T>) =>
  Object.keys(e)
    .filter(isString)
    .map(key => e[key]);

export const isObject = (value: any) => value !== null && typeof value === "object";

// eslint:disable prefer-for-of
export const omit = <T>(value: T, keys: Array<keyof T>) => {
  if (typeof value === "undefined") {
    return {} as T;
  }

  if (typeof keys === "string") {
    keys = [keys];
  }

  if (Array.isArray(value) || !isObject(value) || !Array.isArray(keys)) {
    return value;
  }

  const res = clone(value);
  for (let j = 0; j < keys.length; j += 1) {
    unset(res, keys[j]);
  }
  return res;
};

// TODO: Make this <Templated>
export const omitDeep = (value: any, keys: string[] | string) => {
  if (typeof value === "undefined") {
    return {};
  }

  if (Array.isArray(value)) {
    for (let i = 0; i < value.length; i += 1) {
      value[i] = omitDeep(value[i], keys);
    }
    return value;
  }

  if (!isObject(value)) {
    return value;
  }

  if (typeof keys === "string") {
    keys = [keys];
  }

  if (!Array.isArray(keys)) {
    return value;
  }

  for (let j = 0; j < keys.length; j += 1) {
    unset(value, keys[j]);
  }

  for (const key in value) {
    if (value.hasOwnProperty(key)) {
      value[key] = omitDeep(value[key], keys);
    }
  }

  return value;
};
// eslint:enable

export const formatNextUrl = (currentUrl: string, nextUrl: string) => {
  if (currentUrl.slice(-1) === "/") {
    return `${currentUrl}${nextUrl}`;
  }
  return `${currentUrl}/${nextUrl}`;
};

export const currentPathMatchUrl = (pathname: string, url: string) => url === pathname || `${url}/` === pathname;

export const minToString = (minutes: number) => {
  const hour = Math.floor(minutes / 60);
  const min = minutes % 60;
  return `${hour < 10 ? "0" + hour : hour}:${min < 10 ? "0" + min : min}`;
};

export const getHumanDate = (d: Date) =>
  d.getDate() + "/" + (d.getMonth() + 1) + "/" + d.getFullYear() + " " + +d.getHours() + ":" + (d.getMinutes() < 10 ? "0" : "") + d.getMinutes();

export const createUID = (length = 30) =>
  crypto
    .randomBytes(Math.ceil(length / 2))
    .toString("hex")
    .slice(0, length);

export const numberize = (value: string) => {
  if (value[value.length - 1] === "-" && value.length > 1) {
    if (value[0] === "-") {
      return parseInt(value.slice(1));
    }
    return parseInt(`-${value}`);
  }
  return Number(value.replace(/[^0-9.\-]+/g, ""));
};

export const onClickPreventPropagation = (callback: () => void) => (event: { stopPropagation: () => void }) => {
  event.stopPropagation();
  return callback && callback();
};

export const mutationInputValue = <T>(oldValue: T, newValue: T) => {
  if (oldValue === newValue || (!oldValue && !newValue)) return undefined;
  if (oldValue && !newValue) {
    if (typeof oldValue === "number" && typeof newValue === "number" && newValue === 0) return newValue;
    return null;
  }
  return newValue;
};
// eslint:disable no-any
export const removeUndefined = (object: any) => {
  if (object) {
    Object.keys(object).forEach(key => {
      if (object[key] === undefined) {
        delete object[key];
      } else if (typeof object[key] === "object") {
        object[key] = removeUndefined(object[key]);
      }
    });
  }
  return object;
};

export const promiseLog = <T>(res: T) => {
  console.log(res);
  return res;
};

export const formatNumber = (num: number | string) => `${num}`.replace(/(?!^)(?=(?:\d{3})+(?:\.|$))/gm, " ");

export const formatChartTimeUnixTime = (unixTime: number) => {
  const hourAndMin = dateFormat(new Date(unixTime), "HHMM").toString();
  const hours = hourAndMin.charAt(0) !== "0" ? hourAndMin.substr(0, 2) : hourAndMin.substr(1, 1);
  return `${hours}h${hourAndMin.substr(2)}`;
};

export const formatChartTime = (time: number, granularity: Granularity = Granularity.CONTINUOUS_DAYS) => {
  if (granularity === Granularity.CONTINUOUS_WEEKS) {
    time = time % MINUTES_IN_DAY;
  }
  if (time < 0 || !time) time = 0;
  const hours = Math.floor(time / 60) >= 10 ? Math.floor(time / 60).toString() : "0" + Math.floor(time / 60).toString();
  const mins = time % 60 >= 10 ? (time % 60).toString() : "0" + (time % 60).toString();
  return `${hours}h${mins}`;
};

export const tickFormater = (granularity: Granularity = Granularity.CONTINUOUS_DAYS) => (value: number, index: number) => formatChartTime(index, granularity);

export const filterDropdownObject = (id: number, text: string, itemClassName?: string, separatorClassName?: string, url?: string, marginLeft?: number, hideLine?: boolean) => ({
  id,
  text,
  itemClassName,
  separatorClassName,
  url,
  marginLeft: `${(marginLeft || 0) * 20}px`,
  hideLine,
});

export const isPastEvent = (endAt?: Maybe<number>) => !!(endAt && endAt < Date.now());
export const isFutureEvent = (startAt: number) => startAt > Date.now();
export const isLiveEvent = (startAt: number, endAt: Maybe<number>) => !isFutureEvent(startAt) && !isPastEvent(endAt);

export const FIELD_VALIDATION_DELAY = 500;

export const debounce = (callback: () => void, immediate = false) => {
  let timeout: NodeJS.Timeout | undefined;

  return (...args: any[]) => {
    const later = () => {
      timeout = undefined;
      if (!immediate) {
        callback.apply(args);
      }
    };
    const callNow = immediate && !timeout;
    if (timeout) clearTimeout(timeout);
    timeout = global.setTimeout(later, FIELD_VALIDATION_DELAY);
    if (callNow) {
      callback.apply(args);
    }
  };
};

export const REAL_TIME_LOST_THRESHOLD = 15;
export const DEFAULT_PAGINATION_SIZE = 10;
export const THROTTLE_TIME = 200;

const setPath = (entity: any, [key, ...next]: string[], value: any): any => {
  if (isNaN(Number(key))) {
    return next.length === 0 ? { ...entity, [key]: value } : { ...entity, [key]: setPath(entity[key], next, value) };
  }
  if (Array.isArray(entity)) {
    return next.length === 0
      ? [...entity.slice(0, Number(key)), value, ...entity.slice(Number(key) + 1, entity.length)]
      : [...entity.slice(0, Number(key)), setPath(entity[Number(key)], next, value), ...entity.slice(Number(key) + 1, entity.length)];
  }
  return [value];
};

export const set = <T, O>(obj: O, path: string, value: T): O => setPath(obj, path.split("."), value);

const getPath = (entity: any, [key, ...next]: string[]): any => (next.length === 0 ? entity[key] : getPath(entity[key], next));

export const get = <T = any>(obj: any, path: string): T => getPath(obj, path.split("."));

export const gqlValue = (value: Maybe<string | number | boolean | Array<any> | Indexable<any>>) => {
  // eslint-disable-next-line sonarjs/no-small-switch
  switch (typeof value) {
    case "string":
      if (value === "") return null;
      if (!isNaN(Number(value))) return Number(value);
      return value;
    default:
      return value;
  }
};

// eslint-disable-next-line sonarjs/cognitive-complexity
export const diff = <U extends Indexable<any>, T extends Indexable<any>>(objFrom: T, objTo: U): Partial<U & T> => {
  let res: any = {};
  if (!objFrom) return objTo as any;
  if (!objTo) return (objFrom || res) as any;
  Object.entries(objFrom).map(([key, value]) => {
    if (Array.isArray(value) && Array.isArray(objTo[key])) {
      if (JSON.stringify(value) !== JSON.stringify(objTo[key])) {
        res[key] = objTo[key];
      }
    } else if (typeof value === "object" && typeof objTo[key] === "object") {
      const differences = diff(value, objTo[key]);
      if (differences && Object.entries(differences).length) {
        res = { ...res, [key]: differences };
      }
    } else if (objTo[key] != null && value !== objTo[key]) {
      res[key] = objTo[key];
    } else if ((!objTo[key] && value != null) || (value == null && !objTo.hasOwnProperty(key))) {
      res[key] = null;
    }
  });
  Object.entries(objTo).map(([key, value]) => {
    if (!objFrom.hasOwnProperty(key)) {
      res[key] = value;
    }
  });
  return res;
};

export type KpiPropertiesMinute =
  | "inside"
  | "affluenceMinIn"
  | "affluenceMinOut"
  | "affluenceMinInDeep"
  | "affluenceMinOutDeep"
  | "affluenceMinInCurrent"
  | "affluenceMinOutCurrent";

export type KpiPropertiesHour = "affluenceIn" | "affluenceOut" | "affluenceInCurrent" | "affluenceOutCurrent";
export type KpiPropertiesValue = KpiPropertiesMinute | KpiPropertiesHour;
export type KpiPropertiesDwell = "dwell" | "dwellCurrent";
export type KpiPropertiesDate = "dateBegin" | "dateEnd";
export type KpiPropertiesSingleValue = KpiPropertiesDwell | KpiPropertiesDate;

export enum DaysOfWeek {
  SUNDAY,
  MONDAY,
  TUESDAY,
  WEDNESDAY,
  THURSDAY,
  FRIDAY,
  SATURDAY,
}

const kpiPropertiesMinute = ["inside", "affluenceMinIn", "affluenceMinOut", "affluenceMinInDeep", "affluenceMinOutDeep", "affluenceMinInCurrent", "affluenceMinOutCurrent"];
const kpiPropertiesHour = ["affluenceIn", "affluenceOut", "affluenceInCurrent", "affluenceOutCurrent"];
const kpiPropertiesDwell = ["dwell", "dwellCurrent"];
const kpiPropertiesSingleValue = ["dateBegin", "dateEnd", "dwell", "dwellCurrent"];
const granularityDay = [Granularity.CONTINUOUS_DAYS, Granularity.DISCONTINUOUS_DAYS, Granularity.DISCONTINUOUS_WEEKEND_DAYS, Granularity.DISCONTINUOUS_WORKING_DAYS];

export const isKpiPropertyMinute = (property: keyof KpiCountingWithDates): property is KpiPropertiesMinute => kpiPropertiesMinute.includes(property);

export const isKpiPropertyHour = (property: keyof KpiCountingWithDates): property is KpiPropertiesHour => kpiPropertiesHour.includes(property);

export const isKpiPropertyValue = (property: keyof KpiCountingWithDates): property is KpiPropertiesValue => isKpiPropertyMinute(property) || isKpiPropertyHour(property);

export const isKpiPropertySingleValue = (property: keyof KpiCountingWithDates): property is KpiPropertiesSingleValue => kpiPropertiesSingleValue.includes(property);

export const isKpiPropertyDwell = (property: keyof KpiCountingWithDates): property is KpiPropertiesDwell => kpiPropertiesDwell.includes(property);

export const isGranularityDay = (granularity: Granularity) => granularityDay.includes(granularity);

export const getPeriodType = (granularity: Granularity) => {
  switch (granularity) {
    case Granularity.CONTINUOUS_DAYS:
    case Granularity.DISCONTINUOUS_DAYS:
    case Granularity.DISCONTINUOUS_WORKING_DAYS:
    case Granularity.DISCONTINUOUS_WEEKEND_DAYS:
      return "day";
    case Granularity.CONTINUOUS_WEEKS:
      return "week";
    case Granularity.CONTINUOUS_MONTHS:
      return "month";
    case Granularity.CONTINUOUS_YEARS:
      return "year";
  }
};

export const getWeek = (timeStamp: number): string => `${translate(week.one)} ${moment(new Date(timeStamp)).week()}`;

export const getTimeName = (dateBegin: number, periodNames: string[], granularity: Granularity) => {
  const date = new Date(dateBegin);
  switch (granularity) {
    case Granularity.CONTINUOUS_DAYS:
      return moment(dateBegin).format(DAY_DATE_FORMAT);
    case Granularity.CONTINUOUS_WEEKS:
      return getWeek(dateBegin);
    case Granularity.CONTINUOUS_MONTHS:
      return periodNames[date.getMonth()];
    case Granularity.CONTINUOUS_YEARS:
      return `${translate(year.one)} ${date.getFullYear()}`;
    case Granularity.DISCONTINUOUS_DAYS:
    case Granularity.DISCONTINUOUS_WORKING_DAYS:
    case Granularity.DISCONTINUOUS_WEEKEND_DAYS:
      return periodNames[date.getDay()];
  }
};

export const getPeriodLength = (granularity: Granularity) => {
  switch (granularity) {
    case Granularity.DISCONTINUOUS_DAYS:
    case Granularity.DISCONTINUOUS_WORKING_DAYS:
    case Granularity.DISCONTINUOUS_WEEKEND_DAYS:
    case Granularity.CONTINUOUS_DAYS:
      return MINUTES_IN_DAY;
    case Granularity.CONTINUOUS_WEEKS:
      return MINUTES_IN_WEEK;
    case Granularity.CONTINUOUS_MONTHS:
      // TODO
      return 0;
    case Granularity.CONTINUOUS_YEARS:
      // TODO
      return 0;
  }
};

export const getDiscontinuousPeriodLength = (granularity: Granularity) => {
  switch (granularity) {
    case Granularity.DISCONTINUOUS_DAYS:
    case Granularity.DISCONTINUOUS_WORKING_DAYS:
    case Granularity.DISCONTINUOUS_WEEKEND_DAYS:
    case Granularity.CONTINUOUS_DAYS:
      return DAYS_IN_WEEK;
    case Granularity.CONTINUOUS_WEEKS:
    case Granularity.CONTINUOUS_MONTHS:
    // TODO
    case Granularity.CONTINUOUS_YEARS:
      // TODO
      return 0;
  }
};

export const numberOfDays = (timeStamp: number): number => moment(new Date(timeStamp)).diff(moment().startOf("year"), "days");

export const getCardinalDay = (timeStamp: number) => moment(timeStamp).format("Do");

export const getDayLocale = (timeStamp: number): string => moment(new Date(timeStamp)).format("dddd");

export const stepCounter = (value: number, step: number): number => (value < step ? 1 : 1 + stepCounter(value - step, step));

// eslint-disable-next-line sonarjs/cognitive-complexity
export const createThresholds = (max: number) => {
  const baseThresholds = [5, 10, 25, 50, 100, 200];
  let iteration = 0;
  let createThreshold = true;
  let thresholds: number[] = [];

  do {
    const power = 10 ** iteration;
    const powerThresholds = baseThresholds.map(value => value * power);

    powerThresholds.forEach((value, index) => {
      if (createThreshold) {
        if (index < baseThresholds.length - 1) {
          if (max < value * 4) {
            thresholds = [value, value * 2, value * 4];
            createThreshold = false;
          }
          if (max > value * 4 && max < (powerThresholds[index + 1] || 0) * 4) {
            thresholds = [value, value * 2, value * 4];
            createThreshold = false;
          }
        } else {
          if (max < (powerThresholds[0] || 0) * 4 * power * 10) {
            thresholds = [value, value * 2, value * 4];
            createThreshold = false;
          } else {
            if (iteration < 10) {
              iteration++;
            } else {
              createThreshold = false;
            }
          }
        }
      }
    });
  } while (createThreshold);
  return thresholds;
};

export const getSevenLastDays = (date?: number): DateRange => {
  const end = endOfDay(date);
  const start = moment(date)
    .subtract(6, "day")
    .startOf("day")
    .valueOf();
  return { dateBegin: start, dateEnd: end };
};

export const checkKpiData = (kpiData: AggregatedKpiCountingWithName, kpiProperties: (keyof AggregatedKpiCountingWithName)[]) =>
  !kpiData ? translate(missingKpiData.title) : kpiProperties.some(kpiProperty => !kpiData[kpiProperty]) ? translate(missingKpiProperty) : undefined;

export const checkKpiAtmosphere = (kpiAtmosphere: AggregatedKpiAtmosphere, kpiProperties: (keyof AggregatedKpiAtmosphere)[]) => {
  if (!kpiAtmosphere) {
    return translate(missingKpiData.title);
  }
  return kpiProperties.some(kpiProperty => !kpiAtmosphere[kpiProperty]) ? translate(missingKpiProperty) : undefined;
};

export const isObjectEmpty = <T>(object: T) => !object || Object.keys(object).length === 0;

export const areObjectsEqual = <T, E>(object1: T, object2: E) => JSON.stringify(object1) === JSON.stringify(object2);

export const isNumberUndefined = (value?: number) => !value && value !== 0;

export const signedModulo = (number: number, mod: number) => {
  const c = number % mod;
  return c < 0 ? c + mod : c;
};

export function isDefined<T>(val: T | undefined | null): val is T {
  return val !== undefined && val !== null;
}

export function isNumber<T>(val: T | undefined | null | number): val is number {
  return isDefined(val) && typeof val === "number" && isFinite(val);
}

export const checkErrors = (errors: (string | undefined)[]) => errors.some(error => isDefined(error));

export const dateOffset = (dates: DateRange) => {
  if (dates.dateEnd - dates.dateBegin < MILLISECONDS_IN_DAY) {
    return dates;
  }
  if (
    dates.dateBegin !==
    moment(dates.dateBegin)
      .startOf("day")
      .valueOf()
  ) {
    return { dateBegin: dates.dateBegin - MILLISECONDS_IN_DAY, dateEnd: dates.dateEnd };
  }
  return dates;
};

export function assertDefined<T>(val: T | undefined | null) {
  if (isDefined(val)) {
    return val;
  }
  throw new Error("Value is not defined");
}

export const rounding = (number: number, decimal: number) => Math.round((number + Number.EPSILON) * Math.pow(10, decimal)) / Math.pow(10, decimal);

export const completeLine = (kpi: number[][], firstDateBegin: number, lastDateEnd: number) => {
  const kpiLengthMinute = moment(lastDateEnd).diff(firstDateBegin, "minutes");
  const fillerLength = kpiLengthMinute - kpi.flat().length;
  if (fillerLength > 0) kpi.last().push(...new Array(fillerLength).fill(NaN));
  return kpi;
};

export const getMeanLine = (kpi: number[][]) =>
  kpi.transpose().map(values => {
    const filteredArray = values.filter(value => isDefined(value));
    return filteredArray.sum() / filteredArray.length;
  });

export const getLineData = (kpi: number[][], granularity: Granularity) => {
  const meanLine = getMeanLine(kpi);

  const { maxLine, minLine } = kpi.reduce(
    (acc, curr, index) => {
      const sum = curr.sum();
      if (sum > acc.max && sum < acc.min)
        return {
          maxLine: [...curr],
          minLine: [...curr],
          max: sum,
          min: sum,
          dayMax: index,
          dayMin: index,
        };
      if (sum > acc.max)
        return {
          maxLine: [...curr],
          minLine: acc.minLine,
          max: sum,
          min: acc.min,
          dayMax: index,
          dayMin: acc.dayMin,
        };
      if (sum < acc.min)
        return {
          maxLine: acc.maxLine,
          minLine: [...curr],
          max: acc.max,
          min: sum,
          dayMax: acc.dayMax,
          dayMin: index,
        };
      return acc;
    },
    { maxLine: kpi[0], minLine: kpi[0], max: 0, min: kpi[0].sum(), dayMax: 0, dayMin: 0 },
  );

  const linesData = maxLine.map((value, index) => ({
    time: index,
    max: value,
    min: minLine[index],
    mean: meanLine[index],
  }));

  const last = linesData.last();
  if (kpi[0].length === getPeriodLength(granularity))
    linesData.push({
      time: linesData.length,
      max: last.max,
      min: last.min,
      mean: last.mean,
    });
  return linesData;
};

// eslint-disable-next-line sonarjs/cognitive-complexity
export const getSeries = (kpi: number[][], dateBegins: number[], granularity: Granularity): Line[] => {
  const todayIndex = dateBegins.findIndex(dateBegin => dateBegin >= startOfDay());
  const meanLine = kpi.averageArrays();

  const { dayMaxIndex, dayMinIndex } = kpi
    .filter((_, index) => index !== todayIndex)
    .reduce(
      (acc, curr, index) => {
        const sum = curr.sum();
        if (sum > acc.max && sum < acc.min) return { max: sum, min: sum, dayMaxIndex: index, dayMinIndex: index };
        if (sum > acc.max) return { max: sum, min: acc.min, dayMaxIndex: index, dayMinIndex: acc.dayMinIndex };
        if (sum < acc.min) return { max: acc.max, min: sum, dayMaxIndex: acc.dayMaxIndex, dayMinIndex: index };
        return acc;
      },
      { max: 0, min: kpi[0]?.sum() || 0, dayMaxIndex: 0, dayMinIndex: 0 },
    );

  const getPeriodicLength = (granularity: Granularity) => {
    switch (granularity) {
      case Granularity.DISCONTINUOUS_DAYS:
      case Granularity.CONTINUOUS_DAYS:
        return DAYS_IN_WEEK;
      case Granularity.DISCONTINUOUS_WORKING_DAYS:
        return DAYS_IN_WORKING_WEEK;
      case Granularity.DISCONTINUOUS_WEEKEND_DAYS:
        return DAYS_IN_WEEK - DAYS_IN_WORKING_WEEK;
      case Granularity.CONTINUOUS_WEEKS:
        return 4; // Placeholder until something better
      case Granularity.CONTINUOUS_MONTHS:
        return MONTHS_IN_YEAR;
      case Granularity.CONTINUOUS_YEARS:
        return 4; // Placeholder until something better
    }
  };

  const getColor = (index: number) => {
    const nbStep = getPeriodicLength(granularity);
    const stepSize = Math.floor(360 / nbStep);
    const colorIndex = index % nbStep;
    const colorAdjustement = colorIndex != nbStep - 1 ? 0 : 360 - stepSize * nbStep;
    return changeHue(Colors.LINE_DEFAULT_COLOR, (Math.floor(360 / getPeriodicLength(granularity)) + colorAdjustement) * colorIndex);
  };

  const getSerie = (line: { name: string; kpi: number[]; lineType: LineType }, index: number, meanColor?: Colors) => {
    const { kpi, lineType, name } = line;
    return {
      name,
      lineType,
      data: kpi.flatMap((value, index) => ({ time: index, value })),
      isActive: lineType != LineType.OTHER,
      color: meanColor ? meanColor : getColor(index),
    };
  };

  const getSerieName = (dateText: string, translationsKey: string) => `${dateText} [highLight|[${translate(translationsKey).toUpperCase()}]]`;

  const getDateFormat = (timestampt: number) => moment(timestampt).format(`ddd[, ]${DAY_MONTH_YEAR_FORMAT}`);

  const series = kpi.map((inside, index) => {
    const dateFormat = getDateFormat(dateBegins[index]);
    if (kpi.length > 1) {
      if (index === dayMaxIndex)
        return getSerie(
          {
            name: getSerieName(dateFormat, max),
            kpi: inside,
            lineType: LineType.MAX_LINE,
          },
          index,
        );
      if (index === dayMinIndex)
        return getSerie(
          {
            name: getSerieName(dateFormat, min),
            kpi: inside,
            lineType: LineType.MIN_LINE,
          },
          index,
        );
      if (index === todayIndex)
        return getSerie(
          {
            name: getSerieName(dateFormat, today),
            kpi: inside,
            lineType: LineType.TODAY_LINE,
          },
          index,
        );
      return getSerie({ name: dateFormat, kpi: inside, lineType: LineType.OTHER }, index);
    }
    return getSerie({ name: getSerieName(dateFormat, today), kpi: inside, lineType: LineType.TODAY_LINE }, index);
  });
  if (kpi.length > 1)
    series.unshift(
      getSerie(
        {
          name: `${translate(mean)}`,
          kpi: meanLine,
          lineType: LineType.MEAN_LINE,
        },
        0,
        Colors.LINE_MEAN_COLOR,
      ),
    );
  return series;
};

export const getDaylightSavingTimeFromKpi = (dateBegin: number, dateEnd: number) => {
  const isBeginDST = moment(dateBegin).isDST();
  const isEndDST = moment(dateEnd).isDST();
  if (!isBeginDST && isEndDST) {
    // Day with 23 hours when summer time start
    return DaylightSavingTime.WINTER_TO_SUMMER;
  } else if (isBeginDST && !isEndDST) {
    // Day with 25 hours when winter time start
    return DaylightSavingTime.SUMMER_TO_WINTER;
  } else if (!isBeginDST && !isEndDST) {
    // Day in winter daylight saving time period
    return DaylightSavingTime.WINTER_TIME;
  } else if (isBeginDST && isEndDST) {
    // Day in summer daylight saving time period
    return DaylightSavingTime.SUMMER_TIME;
  }
  return DaylightSavingTime.DEFAULT;
};

export const getDisabledBeginHours = (dateRange: DateRange) => () => {
  const beginHours = [];
  for (let i = new Date(dateRange.dateEnd).getHours() + 1; i < HOURS_IN_DAY; i++) {
    beginHours.push(i);
  }
  return beginHours;
};

export const getDisabledBeginMin = (dateRange: DateRange) => (selectedHour: number) => {
  const minutes = [];
  if (selectedHour === new Date(dateRange.dateEnd).getHours()) {
    for (let i = new Date(dateRange.dateEnd).getMinutes(); i < MINUTES_IN_HOUR; i++) {
      minutes.push(i);
    }
  }
  return minutes;
};

export const getDisabledEndHours = (dateRange: DateRange) => () => {
  const hours = [];
  for (let i = 0; i < new Date(dateRange.dateBegin).getHours(); i++) {
    hours.push(i);
  }
  return hours;
};

export const getDisabledEndMin = (dateRange: DateRange) => (selectedHour: number) => {
  const minutes = [];
  const begin = new Date(dateRange.dateBegin);
  if (selectedHour === begin.getHours()) {
    for (let i = 0; i < begin.getMinutes(); i++) {
      minutes.push(i);
    }
  }
  return minutes;
};

export const getTimeline = (devices: Device[], dayDate: number, granularity: Granularity) => {
  const dates = devices
    .flatMap(device => device.statusDetails.map(status => status.dateReceived))
    .sortBy()
    .uniq();

  const statusInPeriod: { date: number; status: StatusDetail[] }[] = dates.reduce(
    (acc1, date) => [
      ...acc1,
      {
        date,
        status: devices.map((device, index) =>
          device.statusDetails.reduce(
            (acc2, curr) => {
              if (curr.dateReceived === date) return curr;
              return acc2;
            },
            {
              name: acc1.last().status[index].name,
              padId: device.deviceId,
              dateReceived: date,
              statusCode: acc1.last().status[index].statusCode,
              deviceType: device.initialStatus.deviceType,
              msg: acc1.last().status[index].msg,
              reconnected: acc1.last().status[index].reconnected,
            },
          ),
        ),
      },
    ],
    [{ date: dayDate, status: devices.map(device => device.initialStatus) }],
  );

  return statusInPeriod.map((pointOfInterest, index, array) => {
    const { date, status } = pointOfInterest;
    const statusCode = PRIORITIES.find(p => status.map(s => s.statusCode).includes(p)) || 0;

    const timeline = {
      duration: array[index + 1] ? getMinutesFromStartOfDay(array[index + 1].date) - getMinutesFromStartOfDay(date) : getPeriodLength(granularity) - getMinutesFromStartOfDay(date),
      statusCode,
      connection: status.find(s => s.statusCode === statusCode)?.reconnected,
      date,
    };

    if (!array[index + 1] && dayDate + MILLISECONDS_IN_DAY > Date.now()) {
      return {
        ...timeline,
        duration: getMinutesFromStartOfDay(Date.now()) - getMinutesFromStartOfDay(date),
      };
    }
    return timeline;
  });
};

export const getLines = (lines: Line[], granularity: Granularity) => {
  if (lines.length > 1) {
    const cloneLines = clone(lines);
    const length = getPeriodLength(granularity);
    const lastDayMissingKpiIndex = cloneLines.findIndex(line => line.data.length < length);
    if (lastDayMissingKpiIndex != -1) {
      const diff = length - cloneLines[lastDayMissingKpiIndex].data.length;
      cloneLines[lastDayMissingKpiIndex].data.push(
        ...Array.from({ length: diff }).map((_, index) => ({
          time: index,
          value: NaN,
        })),
      );
    }
    const data = Array.from({ length }).map((_, index) =>
      Object.fromEntries(cloneLines.filter(line => line.isActive).map(line => [line.name, !isNaN(line.data[index].value) ? line.data[index].value : undefined])),
    );
    return data.map((d, index) => ({ ...d, time: index }));
  }
  const length = lines[0]?.data.length;
  const data = Array.from({ length }).map((_, index) => Object.fromEntries(lines.filter(line => line.isActive).map(line => [line.name, line.data[index].value])));
  return data.map((d, index) => ({ ...d, time: index }));
};

export const completeArray = (array: number[], index: number, diffTime: number, aggregatedDates: AggregatedDates, granularity: Granularity) => {
  const arraySize = (() => {
    if (diffTime < getPeriodLength(granularity)) return diffTime;
    return moment(aggregatedDates.dateEnds[index]).diff(aggregatedDates.dateBegins[index], "minutes") + 1;
  })();
  if (array.length < arraySize) return [...array, ...Array(arraySize - array.length)];
  return array;
};

export const getAggregatedArray = (aggregatedDates: AggregatedDates, dateRange: DateRange, fullArray: number[], diffTime: number, granularity: Granularity) =>
  aggregatedDates.dateBegins
    .map(dateBegin => {
      const kpiDiffWithDayBegin = moment(dateBegin).diff(dateRange.dateBegin, "minutes");
      return fullArray.slice(kpiDiffWithDayBegin, kpiDiffWithDayBegin + getPeriodLength(granularity));
    })
    .map((array, index) => completeArray(array, index, diffTime, aggregatedDates, granularity));
// eslint-disable-next-line @typescript-eslint/no-empty-function
export const noop = () => {};

type ZoneByInstallation = Pick<Zone, "id" | "name" | "parentIds" | "childIds" | "countMinMax"> & { passages: Array<Pick<Passage, "padIds">> } & {
  kpis: Array<KpiCountingWithDates>;
} & {
  kpisAtmosphere: Array<KpiAtmosphereWithDates>;
};

type PassageByInstallation = Pick<Passage, "id" | "name"> & { kpis: Array<KpiCountingWithDates> };

export const getCountingAggregation = (aggregationParam: {
  query: PassageByInstallation[] | ZoneByInstallation[];
  zoneSettings: ZoneSetting[];
  passageSettings: PassageSetting[];
  granularity: Granularity;
  dateRange: DateRange;
  kpiProperties: (keyof Kpi | "dateBegin" | "dateEnd")[] | ("dateEnd" | "dateBegin" | keyof KpiAtmosphere)[];
  nameProperty: string;
  isAtmosphere?: boolean;
  isShifting?: boolean;
}): AggregatedKpiCountingWithName[] | AggregatedKpiAtmosphereWithName[] => {
  const { dateRange, granularity, isAtmosphere = false, isShifting, nameProperty, passageSettings, query, zoneSettings } = aggregationParam;

  const passageFilter = (passage: PassageByInstallation) =>
    passageSettings.find(passageSetting => passage.id === passageSetting.id)?.activated && isDefined(passage.kpis) && !isEmpty(passage.kpis);

  const zoneFilter = (zone: ZoneByInstallation) => zoneSettings.find(zoneSetting => zone.id === zoneSetting.id)?.activated && isDefined(zone.kpis) && !isEmpty(zone.kpis);

  const atmosphereZoneFilter = (zone: ZoneByInstallation) =>
    zoneSettings.find(zoneSetting => zone.id === zoneSetting.id)?.activated && isDefined(zone.kpisAtmosphere) && !isEmpty(zone.kpisAtmosphere);

  const aggregate = (entry: PassageByInstallation | ZoneByInstallation) => {
    if (isAtmosphere) {
      return new KpiAtmosphereAggregator({
        kpis: (entry as ZoneByInstallation).kpisAtmosphere,
        granularity,
        dateRange,
        isDataShifting: isShifting,
      }).getAggregatedData();
    }
    return new KpiCountingAggregator({
      kpis: entry.kpis,
      granularity,
      dateRange,
      isDataShifting: isShifting,
      countMinMax: (entry as ZoneByInstallation).countMinMax,
    }).getAggregatedData();
  };

  const getName = (entry: PassageByInstallation | ZoneByInstallation) => {
    if ((entry as ZoneByInstallation).parentIds) return zoneSettings.find(zoneSetting => entry.id === zoneSetting.id)?.name;
    return passageSettings.find(passageSetting => entry.id === passageSetting.id)?.name;
  };

  return query
    .filter(entry => {
      if (isAtmosphere) return atmosphereZoneFilter(entry as ZoneByInstallation);
      if ((entry as ZoneByInstallation).parentIds) return zoneFilter(entry as ZoneByInstallation);
      return passageFilter(entry);
    })
    .map(entry => ({
      ...aggregate(entry),
      [nameProperty]: getName(entry),
    }));
};

export const getFontSize = (isExporting: boolean) => (isExporting ? EXPORT_CHART_TICK_FONT_SIZE : CHART_TICK_FONT_SIZE);

export const getTickPosition = (isExporting: boolean) => (isExporting ? EXPORT_CHART_TICK_POSITION : CHART_TICK_POSITION);

export const roundUp = (num: number) => {
  const intValue = parseInt(num.toString(), 10);

  if (intValue < num) {
    return intValue + 1;
  }

  return intValue;
};

export const getDevicetype = (deviceType: DeviceType) => {
  switch (deviceType) {
    case DeviceType.ATMOSPHERE:
      return translate(atmosphere);
    case DeviceType.BLACK_BOX:
      return translate(blackBox);
    case DeviceType.CAMERA:
      return translate(camera);
  }
};

export const legendClassname = (legendItemsLenght: number) =>
  classnames({
    "module-legend-container": legendItemsLenght >= 3,
    "module-legend-container-single-row": legendItemsLenght < 3,
  });

export const removeAllchildren = (node: HTMLElement) => {
  while (node.childNodes.length > 0) {
    node.lastChild?.remove();
  }
};

export const isRangeSmallerThanPeriod = (dateBeginsLength: number, dateRange: DateRange) => dateBeginsLength < 2 && dateRange.dateEnd - dateRange.dateBegin < MILLISECONDS_IN_DAY;

export const sumArrays = (...arrays: number[][]) => {
  const filteredArrays = arrays.filter(Array.isArray);
  const length = filteredArrays.reduce((max, innerArray) => Math.max(max, innerArray.length), 0);

  return Array.from({ length }).map((_, index) => {
    const newArray = filteredArrays.map(innerArray => innerArray[index]);
    if (newArray.every(value => !isDefined(value))) return NaN;
    return newArray.reduce((sum, curr) => sum + curr, 0);
  });
};

export const floor = (number: number) =>
  // Similar to Math.floor. /!\ could behave strangely with negative values.
  // isNaN(number) || !isDefined(number) ? 0 : Math.floor(number);
  ~~number;

export const isFirefox = () => navigator.userAgent.toLowerCase().includes(FIREFOX);

export const is2dArray = <T>(x: T[][] | T[]): x is T[][] => Array.isArray(x[0]);
