import React, { FunctionComponent } from "react";
import { IconType } from "react-icons";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { IoIosCloudy } from "react-icons/io";
import { IoWater } from "react-icons/io5";
import { WiThermometer } from "react-icons/wi";

import { UI } from "../../../constants";
import { translate } from "../../../lang/i18n";
import { translation } from "../../../lang/translation";
import { AggregatedKpiAtmosphere, KpiAtmospherePropertiesArrayValue } from "../../../utils/aggregate";
import { isDefined, isNumber } from "../../../utils/utils";
import { ParsedText } from "../../lang/ParsedText";
import { labelParser } from "../../lang/Parsers";
import { CommonModule } from "./CommonModule";

const { MODULE_TITLE_ICON_SIZE } = UI;

const ICON_SIZE = 26;

const { analytics, common } = translation;

interface SummaryAirModuleProps {
  kpiAtmospheres: AggregatedKpiAtmosphere[];
  onDelete: () => void;
}

const getMinMax = (property: KpiAtmospherePropertiesArrayValue, kpiAtmospheres: AggregatedKpiAtmosphere[]) => {
  let nanMaxCount = 0;
  let nanMinCount = 0;
  const { max, min } = kpiAtmospheres.reduce(
    (acc, curr) => {
      const max = curr[property].map(array => array.filter(isNumber)).max();
      const min = curr[property].map(array => array.filter(isNumber)).min();

      if (isNaN(max) && isNaN(min)) {
        nanMaxCount++;
        nanMinCount++;
      }
      if (isNaN(max)) nanMaxCount++;
      if (isNaN(min)) nanMinCount++;
      if (acc.min) {
        const shouldAddMax = (acc.max < max && acc.min > min) || acc.max < max;
        const shouldAddMin = (acc.max < max && acc.min > min) || acc.min > min;
        return { ...acc, ...(shouldAddMax && { max }), ...(shouldAddMin && { min }) };
      }
      return { ...acc, ...(acc.max ?  acc.max < max && { max } : {max}), ...(acc.min ?  acc.min > min && { min } : {min}) };
    },
    { min: NaN, max: NaN },
  );
  return [nanMinCount < kpiAtmospheres.length ? min : NaN, nanMaxCount < kpiAtmospheres.length ? max : NaN];
};

export const SummaryAirModule: FunctionComponent<SummaryAirModuleProps> = props => {
  const { kpiAtmospheres, onDelete } = props;

  const [minHumidity, maxHumidity] = getMinMax("humidityMin", kpiAtmospheres);
  const [minTemperature, maxTemperature] = getMinMax("temperatureMin", kpiAtmospheres);
  const [minCo2, maxCo2] = getMinMax("co2Min", kpiAtmospheres);

  const summaryParameters = [
    {
      Icon: IoWater,
      iconSize: ICON_SIZE,
      title: translate(analytics.modules.labels.humidity),
      minValue: minHumidity,
      maxValue: maxHumidity,
      unit: translate(common.unit.humidity),
      atmosphereClassName: "humidity",
    },
    {
      Icon: WiThermometer,
      iconSize: ICON_SIZE,
      title: translate(analytics.modules.labels.temperature),
      minValue: minTemperature,
      maxValue: maxTemperature,
      unit: translate(common.unit.temperature),
      atmosphereClassName: "temperature",
    },
    {
      Icon: IoIosCloudy,
      iconSize: ICON_SIZE,
      title: translate(analytics.modules.labels.co2),
      minValue: minCo2,
      maxValue: maxCo2,
      unit: translate(common.unit.co2),
      atmosphereClassName: "co2",
    },
  ];

  const renderSubSummary = (parameters: {
    key: string;
    Icon: IconType;
    iconSize: number;
    title: string;
    unit: string;
    atmosphereClassName: string;
    minValue?: number;
    maxValue?: number;
  }) => {
    const { atmosphereClassName, Icon, iconSize, key, maxValue, minValue, title, unit } = parameters;
    return (
      <div key={key} className={`summary-container ${atmosphereClassName}`}>
        <div className="summary-title">
          <div className="title-icon">
            <Icon size={iconSize} />
          </div>
          <div className="title-text white-font">
            <ParsedText parsers={[labelParser()]}>{title}</ParsedText>
          </div>
        </div>
        <div className="summary-min-max">
          <div className="min">
            <div className="min-value">
              <div className="value">{isNumber(minValue) ? minValue.toFixed(1) : "--"}</div>
              <div className="unit">{unit}</div>
            </div>
            <div className="min-label white-font-50">{translate(common.min).toUpperCase()}</div>
          </div>
          <div className="line" />
          <div className="max">
            <div className="max-value">
              <div className="value">{isNumber(maxValue) ? maxValue.toFixed(1) : "--"}</div>
              <div className="unit">{unit}</div>
            </div>
            <div className="max-label white-font-50">{translate(common.max).toUpperCase()}</div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <CommonModule iconSize={MODULE_TITLE_ICON_SIZE} title={translate(analytics.modules.titles.summaryAir)} Icon={AiOutlineInfoCircle} onDelete={onDelete} isExportable>
      <div className="summary-air">
        {summaryParameters.map((parameter, index) =>
          renderSubSummary({
            ...parameter,
            key: `summary-air-${index}`,
          }),
        )}
      </div>
    </CommonModule>
  );
};
