import { Omit } from "@technis/shared";
import { Select } from "antd";
import * as moment from "moment";
import React, { FunctionComponent, useRef, useState } from "react";
import { Field } from "react-final-form";

import { UI } from "../../../constants";
import { translate } from "../../../lang/i18n";
import { translation } from "../../../lang/translation";
import { capitalize } from "../../../utils/translation";
import { assertDefined } from "../../../utils/utils";

const { FIELD_TOUCHED_DELAY } = UI;

const { select } = translation.common;
const { noItems } = translation.analytics.modules.common;

export interface DropdownItem {
  id: number | string;
  text: string;
  timeUnit?: moment.unitOfTime.DurationConstructor;
}

interface DropdownProps {
  onChange: (dropdownItem: DropdownItem) => void;
  onFocus: () => void;
  menuItemsList: DropdownItem[];

  label?: string;
  value?: string;
  placeholder?: string;
  isMandatory?: boolean;
  containerClassName?: string;
  error?: string;
  disabled?: boolean;
}

interface FormProps extends Omit<DropdownProps, "error" | "onFocus"> {
  formPath: string;
}

export const CustomDropdown: FunctionComponent<DropdownProps> = props => {
  const { label, containerClassName = "", isMandatory, value, placeholder = capitalize(translate(select)), onChange, menuItemsList, onFocus, error, disabled } = props;

  const ref = useRef(null);

  return (
    <div className={`dropdown-container ${containerClassName}`} ref={ref}>
      {!!label && (
        <div className="input-label">
          {label}
          {isMandatory && <span className="mandatory-star">*</span>}
        </div>
      )}
      <div className="main-content-container">
        <Select
          showSearch
          value={value}
          onChange={(itemId: DropdownItem["id"]) => {
            const currentSelectedItem = assertDefined(menuItemsList.find(menuItem => menuItem.id === itemId));
            onChange(currentSelectedItem);
          }}
          onFocus={onFocus}
          placeholder={placeholder}
          optionFilterProp="children"
          filterOption={(input, option) =>
            option.props?.children
              ?.toString()
              ?.toLowerCase()
              ?.indexOf(input?.toLowerCase()) !== -1
          }
          getPopupContainer={() => ref.current || document.createElement("div")}
          notFoundContent={<span className="no-items-label">{translate(noItems)}</span>}
          disabled={disabled}
        >
          {menuItemsList.map(item => (
            <Select.Option key={`modal-item-${item.id}-${item.text}`} value={item.id}>
              {item.text}
            </Select.Option>
          ))}
        </Select>
        <div className="input-line" />
      </div>
      {error ? <div className="validation-error">{error}</div> : null}
    </div>
  );
};

export const FormDropdown: FunctionComponent<FormProps> = props => {
  const [touched, setTouched] = useState(false);
  const { label, containerClassName = "", isMandatory, value, placeholder = capitalize(translate(select)), onChange, formPath, menuItemsList, disabled } = props;

  return (
    <Field
      name={formPath}
      type="dropdown"
      render={props => (
        <CustomDropdown
          onChange={item => {
            onChange(item);
            props.input.onChange(item?.text);
          }}
          onFocus={() => setTimeout(() => setTouched(true), FIELD_TOUCHED_DELAY)}
          menuItemsList={menuItemsList}
          containerClassName={containerClassName}
          label={label}
          placeholder={placeholder}
          isMandatory={isMandatory}
          value={value}
          disabled={disabled}
          error={touched ? props.meta.error : undefined}
        />
      )}
      label={label}
    />
  );
};
