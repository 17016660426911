export const MILLISECONDS_IN_SECOND = 1000;
export const SECONDS_IN_MINUTE = 60;
export const SECONDS_IN_HOUR = 3600;
export const MINUTES_IN_HOUR = 60;
export const HOURS_IN_DAY = 24;
export const DAYS_IN_WEEK = 7;
export const DAYS_IN_YEAR = 365;

export const MONTHS_IN_YEAR = 12;

export const MILLISECONDS_IN_MINUTE = MILLISECONDS_IN_SECOND * SECONDS_IN_MINUTE;
export const MILLISECONDS_IN_HOUR = MILLISECONDS_IN_MINUTE * MINUTES_IN_HOUR;
export const MILLISECONDS_IN_DAY = MILLISECONDS_IN_HOUR * HOURS_IN_DAY;
export const MILLISECONDS_IN_WEEK = MILLISECONDS_IN_DAY * DAYS_IN_WEEK;
export const MILLISECONDS_IN_YEAR = MILLISECONDS_IN_DAY * DAYS_IN_YEAR;

export const MINUTES_IN_DAY = MINUTES_IN_HOUR * HOURS_IN_DAY;
export const MINUTES_IN_WEEK = MINUTES_IN_DAY * DAYS_IN_WEEK;
export const MINUTES_IN_YEAR = MINUTES_IN_DAY * DAYS_IN_YEAR;

export const SECONDS_IN_YEAR = MINUTES_IN_YEAR * SECONDS_IN_MINUTE;

export const DAYS_IN_WORKING_WEEK = 5;

export const MODAL_DATE_FORMAT = "DD.MM.YYYY";
export const TIME_FORMAT_EN = "hh:mm:A";
export const TIME_FORMAT_FR = "HH:mm";
export const DAY_MONTH_YEAR_FORMAT = "Do MMM YYYY";
export const HOUR_MINUTE_FORMAT = "HH[h]mm";
export const HOUR_FORMAT = "H[h]";
export const DAY_HOUR_FORMAT = `${MODAL_DATE_FORMAT}[, ]${HOUR_FORMAT}`;
export const DAY_DATE_FORMAT = "ddd[, ]D.MM.YYYY";
export const SHORT_MINUTE_SECOND_FORMAT = "m[m]s";
export const SHORT_HOUR_MINUTE_SECOND_FORMAT = "h[h]m[m]s";
