import { ProjectTypes, Translation } from "@technis/shared";
import gql from "graphql-tag";

export const queryTranslationDetails = gql`
  query translationDetailsByProject{
    translationDetailsByProject(project: ${ProjectTypes.ANALYTICS}) {
      version
      allowedLanguages
      languages
    }
  }
`;

export const queryTranslations = gql`
  query translationsByProject{
    translationsByProject(project: ${ProjectTypes.ANALYTICS})
  }
`;

export interface QueryTranslationDetailsResult {
  translationDetailsByProject: Translation;
}

export interface QueryTranslationResult {
  // eslint-disable-next-line @typescript-eslint/ban-types
  translationsByProject: object;
}
