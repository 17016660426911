import * as moment from "moment";

import { endOfDay, startOfDay } from "../time";
import { BaseAggregator } from "./BaseAggregator";
import {
  getAirQualityAverage,
  getDiscontinuousDaysForAtmosphere,
  getFirstDateBegin,
  getNbAirQualityDevice,
  isShifting,
  movingAverage,
  shouldDataShift,
  weekendDaysFilter,
  workingDaysFilter,
} from "./helpers";
import { DateRange, Granularity, KpiAtmosphereWithDates } from "./types";

export interface AggregatedKpiAtmosphere {
  granularity: Granularity;
  zoneId: number;
  dateBegins: number[];
  dateEnds: number[];
  co2Min: number[][];
  humidityMin: number[][];
  temperatureMin: number[][];
  shift: number;
  isShifting: boolean;
}

interface KpiAtmosphereAggregatorInterface {
  getAggregatedData(): AggregatedKpiAtmosphere;
  filterBy(): AggregatedKpiAtmosphere;
}

interface KpiAtmosphereAggregatorProps {
  granularity: Granularity;
  kpis: KpiAtmosphereWithDates[];
  timeWindow?: number;
  isDataShifting?: boolean;
  dateRange?: DateRange;
}

interface AggregationData {
  dateBegin: number;
  dateEnd: number;
  co2Min: number[];
  humidityMin: number[];
  temperatureMin: number[];
}

interface AggregatedData {
  [key: string]: AggregationData;
}

export type KpiAtmospherePropertiesArrayValue = "co2Min" | "humidityMin" | "temperatureMin";

type AggregateProps<T extends KpiAtmosphereWithDates | AggregationData> = Pick<T, KpiAtmospherePropertiesArrayValue>;

const AGGREGATE_PROPS: KpiAtmospherePropertiesArrayValue[] = ["co2Min", "humidityMin", "temperatureMin"];

export class KpiAtmosphereAggregator extends BaseAggregator implements KpiAtmosphereAggregatorInterface {
  private aggregatedData: AggregatedKpiAtmosphere;
  private kpisWithCorrectedDates: KpiAtmosphereWithDates[];
  private dateBegin: number;
  private kpis: KpiAtmosphereWithDates[];

  constructor({ dateRange, granularity, isDataShifting, kpis, timeWindow }: KpiAtmosphereAggregatorProps) {
    super({ granularity, timeWindow, dateRange, shouldDataShift: shouldDataShift(kpis, dateRange, isDataShifting), firstDateBegin: getFirstDateBegin(kpis, dateRange) });
    this.kpis = kpis;
    this.dateBegin = kpis[0].dateBegin;
    this.kpisWithCorrectedDates = this.getKpisWithCorrectedDate<KpiAtmosphereWithDates, keyof AggregateProps<KpiAtmosphereWithDates>>(
      getAirQualityAverage(this.kpis, AGGREGATE_PROPS, getNbAirQualityDevice(kpis)),
      AGGREGATE_PROPS,
      dateRange,
    ).sortBy("dateBegin");
    this.aggregatedData = this.aggregate(this.kpisWithCorrectedDates);
  }

  private aggregate = (kpis: KpiAtmosphereWithDates[]): AggregatedKpiAtmosphere => {
    const { zoneId } = (kpis && kpis[0]) || {};

    const aggregatedData: AggregatedData = {};
    kpis
      .filter(kpi => kpi.dateBegin < endOfDay())
      .forEach(kpi => {
        const { dateBegin: kpiDateBegin } = kpi;
        const key = this.getKey(kpiDateBegin);
        const periodSize = this.getPeriodSize(kpiDateBegin);
        const startIndex = this.getStartIndexByDate(kpiDateBegin);

        if (!aggregatedData[key]) {
          aggregatedData[key] = this.getEmptyAggregationData(kpiDateBegin, periodSize);
        }

        AGGREGATE_PROPS.forEach(property => {
          aggregatedData[key][property as keyof AggregateProps<AggregationData>].replaceRangeAt(kpi[property as keyof AggregateProps<KpiAtmosphereWithDates>] || [], startIndex);
        });
      });

    const shift = this.shouldDataShift ? moment(this.dateBegin).diff(moment(startOfDay(this.dateBegin)), "minute") : 0;

    return {
      zoneId,
      granularity: this.granularity,
      shift,
      isShifting: isShifting(this.kpis),
      ...this.getAggregatedKpiAtmosphere(aggregatedData),
    };
  };

  // TODO: filter, rechunk the data by the given parameters e.g.: every second week
  public filterBy = () => this.getAggregatedData();

  // return with aggregated data
  public getAggregatedData = () => {
    const { aggregatedData, granularity, timeWindow } = this;

    const data = timeWindow ? movingAverage<AggregatedKpiAtmosphere>({ data: aggregatedData, granularity, timeWindow, properties: AGGREGATE_PROPS }) : aggregatedData;

    const shiftedData = this.dateRangeFilter<AggregatedKpiAtmosphere>(this.shiftFilter<AggregatedKpiAtmosphere, KpiAtmosphereWithDates>(data));

    switch (this.granularity) {
      case Granularity.DISCONTINUOUS_DAYS:
        return getDiscontinuousDaysForAtmosphere(aggregatedData, AGGREGATE_PROPS);
      case Granularity.DISCONTINUOUS_WEEKEND_DAYS:
        return weekendDaysFilter<AggregatedKpiAtmosphere>(aggregatedData);
      case Granularity.DISCONTINUOUS_WORKING_DAYS:
        return workingDaysFilter<AggregatedKpiAtmosphere>(aggregatedData);
      default:
        return shiftedData;
    }
  };

  private getAggregatedKpiAtmosphere = (data: AggregatedData) =>
    Object.values(data)
      .sortBy("dateBegin")
      .reduce(
        (acc, { co2Min, dateBegin, dateEnd, humidityMin, temperatureMin }) => ({
          ...acc,
          dateBegins: [...acc.dateBegins, dateBegin],
          dateEnds: [...acc.dateEnds, dateEnd],
          co2Min: [...acc.co2Min, co2Min],
          humidityMin: [...acc.humidityMin, humidityMin],
          temperatureMin: [...acc.temperatureMin, temperatureMin],
        }),
        {
          dateBegins: [],
          dateEnds: [],
          co2Min: [],
          humidityMin: [],
          temperatureMin: [],
        } as Pick<AggregatedKpiAtmosphere, "dateBegins" | "dateEnds" | KpiAtmospherePropertiesArrayValue>,
      );

  private getEmptyAggregationData = (timeStamp: number, periodSize: number): AggregationData => {
    const emptyPeriod = new Array(periodSize);
    const date = moment(timeStamp);
    const dateBegin = date.startOf(this.granularityDuration).valueOf();
    const dateEnd = date.endOf(this.granularityDuration).valueOf();

    return {
      dateBegin,
      dateEnd,
      co2Min: [...emptyPeriod],
      humidityMin: [...emptyPeriod],
      temperatureMin: [...emptyPeriod],
    };
  };
}
