import React, { FunctionComponent, useEffect, useState } from "react";
// eslint-disable-next-line import/named
import { Bar, BarChart, CartesianGrid, Cell, ResponsiveContainer, Tooltip, TooltipProps, XAxis, YAxis } from "recharts";

import { UI } from "../../constants";
import { CHART_HEIGHT_AFFLUENCE, CHART_X_AXIS_TICK_ANGLE } from "../../constants/ui";
import { useSelector } from "../../hooks";
import { translate } from "../../lang/i18n";
import { translation } from "../../lang/translation";
import { AggregatedKpiCountingWithName } from "../../pages/AnalyticsHomePage";
import { CHART_LINE_TRANSPARENCY, Colors, transparentize } from "../../utils/colors";
import { getPeriodNames } from "../../utils/translation";
import { checkKpiData, getFontSize, getTickPosition, getTimeName } from "../../utils/utils";
import { ButtonLegend, LegendItem } from "../common/custom-chart/ChartLegend";
import { ChartTooltip } from "../common/custom-chart/ChartTooltip";
import { getTheme, ThemeVariant, useTheme } from "../common/Theme";
import { ChartError, YRenderChartTick } from "./index";

const { CHART_BAR_SIZE_AFFLUENCE } = UI;

const { in: countIn, out: countOut } = translation.common;

const IN_COLOR = Colors.LIGHT_BLUE;
const OUT_COLOR = Colors.AQUA;

interface AffluenceChartProps {
  aggregation: string;
  kpiData: AggregatedKpiCountingWithName;
}

export enum AggregationType {
  MEAN = "mean",
  SUM = "sum",
}

interface State {
  data: Array<{ time: string; in: number; out: number }>;
  legendItems: LegendItem[];
  currentTooltipIndex?: number;
  error?: string;
}

export const AffluenceChart: FunctionComponent<AffluenceChartProps> = props => {
  const { aggregation, kpiData } = props;

  const [state, setState] = useState<State>({
    data: [],
    legendItems: [
      {
        color: IN_COLOR,
        text: translate(countIn.one),
        active: true,
      },
      {
        color: OUT_COLOR,
        text: translate(countOut.one),
        active: true,
      },
    ],
    currentTooltipIndex: undefined,
    error: checkKpiData(kpiData, ["dateBegins", "dateEnds", "affluenceMinIn", "affluenceMinOut"]),
  });
  const { currentTooltipIndex, error, legendItems } = state;
  const { affluenceMinIn, affluenceMinOut, dateBegins, dateEnds, granularity } = kpiData;

  const { themeVariant } = useTheme();
  const isExporting = useSelector(state => state.app.isExporting);
  const { font } = getTheme(isExporting ? ThemeVariant.LIGHT : themeVariant);
  const fontSize = getFontSize(isExporting);
  const tickPosition = getTickPosition(isExporting);
  const onClickUpdateDisplayInOut = (index: number) => {
    const tempLegendItem = state.legendItems;
    tempLegendItem[index].active = !tempLegendItem[index].active;
    setState({ ...state, legendItems: tempLegendItem });
  };

  useEffect(() => {
    const tempLegendItem = state.legendItems;
    tempLegendItem.forEach((item, index) => {
      item.onClick = () => onClickUpdateDisplayInOut(index);
    });
    setState({ ...state, error: checkKpiData(kpiData, ["dateBegins", "dateEnds", "affluenceMinIn", "affluenceMinOut"]) });
  }, [props]);

  const processKpi = (kpis: number[][], aggregation: string) => kpis.map(values => (aggregation === AggregationType.SUM ? values.sum() : Math.floor(values.average())));

  const getAffluenceData = () => {
    if (!kpiData || !dateBegins || !dateEnds || !affluenceMinIn || !affluenceMinOut) {
      return [];
    }

    const dataIn = processKpi(affluenceMinIn, aggregation);
    const dataOut = processKpi(affluenceMinOut, aggregation);
    const maxLength = Math.max(dataIn.length, dataOut.length);

    return Array.from({ length: maxLength }).map((_, index) => ({
      time: getTimeName(dateBegins[index] || 0, getPeriodNames(kpiData.granularity), granularity),
      in: legendItems[0].active ? dataIn[index] : 0,
      out: legendItems[1].active ? dataOut[index] : 0,
    }));
  };

  useEffect(() => {
    setState({ ...state, data: getAffluenceData(), error: checkKpiData(kpiData, ["dateBegins", "dateEnds", "affluenceMinIn", "affluenceMinOut"]) });
  }, [props]);

  const xRenderChartTick = (tickObject: { index: number; x: number; y: number; payload: { value: string } }) => (
    <g transform={`translate(${tickObject.x},${tickObject.y})`}>
      <text fontSize={fontSize} x={0} y={-5} fill={`${font}`} transform={`rotate(${CHART_X_AXIS_TICK_ANGLE})`}>
        <tspan textAnchor="end" x="0" dy={tickPosition}>
          {tickObject.payload.value}
        </tspan>
      </text>
    </g>
  );

  const renderChartTooltip = (tooltipObject: TooltipProps<number, string>) => {
    const payload = tooltipObject?.payload?.[0]?.payload;

    if (!payload || !(payload.in > 0 || payload.out > 0)) {
      return null;
    }

    return (
      <ChartTooltip
        title={payload.time}
        tooltipData={[
          {
            label: translate(countIn.one),
            value: payload.in,
            color: transparentize(IN_COLOR, 0.02),
          },
          {
            label: translate(countOut.one),
            value: payload.out,
            color: transparentize(OUT_COLOR, 0.02),
          },
        ].filter((_, index) => legendItems[index].active)}
      />
    );
  };

  const renderChartBar = (
    dataSet: {
      time: string;
      in: number;
      out: number;
    }[],
    key: string,
    color: Colors,
    isAnimationActive: boolean,
  ) => (
    <Bar isAnimationActive={isAnimationActive} dataKey={key} barSize={CHART_BAR_SIZE_AFFLUENCE}>
      {dataSet.map((_, index: number) => (
        <Cell
          key={`cell-${index}`}
          fill={color}
          stroke={currentTooltipIndex === index ? Colors.WHITE : color}
          strokeOpacity={currentTooltipIndex === index ? 0.2 : 0}
          strokeWidth={currentTooltipIndex === index ? 3 : 0}
        />
      ))}
    </Bar>
  );

  const onMouseEnter = (barChartState: { activeTooltipIndex: number }) => {
    setState({ ...state, currentTooltipIndex: barChartState?.activeTooltipIndex });
  };

  const data = getAffluenceData();

  if (error) {
    return <ChartError error={error} />;
  }

  return (
    <>
      <div className="module-chart">
        <ResponsiveContainer width="100%" height={CHART_HEIGHT_AFFLUENCE + tickPosition}>
          <BarChart data={data} margin={{ top: 35, right: 20, bottom: 100 + tickPosition, left: 20 }} barGap={0} onMouseEnter={onMouseEnter}>
            <CartesianGrid vertical={false} stroke={transparentize(font, CHART_LINE_TRANSPARENCY)} />
            <XAxis dataKey="time" interval={0} tick={xRenderChartTick} stroke={transparentize(Colors.WHITE, CHART_LINE_TRANSPARENCY)} tickMargin={10} tickLine={false} />
            <YAxis tickLine={false} axisLine={false} tick={tickObject => <YRenderChartTick tickObject={tickObject} fontColor={font} fontSize={fontSize} />} height={200} />
            <Tooltip cursor={false} content={renderChartTooltip} />
            {renderChartBar(data, "in", transparentize(IN_COLOR, 0.02), !isExporting)}
            {renderChartBar(data, "out", transparentize(OUT_COLOR, 0.02), !isExporting)}
          </BarChart>
        </ResponsiveContainer>
      </div>
      <ButtonLegend legendItems={legendItems} className="module-legend-container-affluence" />
    </>
  );
};
