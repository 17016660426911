import React, { FunctionComponent } from "react";

import { Granularity, StatusDetail } from "../../../utils/aggregate";
import { getTimeline } from "../../../utils/utils";
import { DateDisplay } from "../DateDisplay";
import { ActiveStatusData } from "../module/ErrorLogModule";
import { Timeline } from "./Bar";
import { ChartList } from "./ChartList";

const SIX_HOURS = 6;
const NUMBER_OF_LINES = 5;
export const PRIORITIES = [2, 1, 3, 4, 0];

interface ChartTimeLineProps {
  statusList: ActiveStatusData[];
  granularity: Granularity;
  onClick: (index: number) => void;
}

export const ChartTimeLine: FunctionComponent<ChartTimeLineProps> = props => {
  const { granularity, onClick, statusList } = props;
  return (
    <div className="chart-timeline-container">
      {statusList.map((status, index) => {
        const { day, devices, isActive } = status;
        const key = `day-${day}`;
        const statusDetails = devices.reduce((acc, device) => acc.concat(device.statusDetails), [] as StatusDetail[]).sortBy("dateReceived");
        return (
          <React.Fragment key={key}>
            <div className="line-container">
              <div className="dates-container">
                <DateDisplay key={`status-day-${index}`} day={day} />
              </div>
              <div className="timeline-chart">
                {Array.from({ length: NUMBER_OF_LINES }).map((_, index) => {
                  const line = `line-${index}`;
                  return (
                    <div className={line} key={line}>
                      <div className="line line-color" />
                      <div className="time-text white-font">{`${index * SIX_HOURS}h`}</div>
                    </div>
                  );
                })}
                <div className="timeline-container">
                  <Timeline key={key} keyString={key} onClick={() => onClick(day)} timeline={getTimeline(devices, day, granularity)} granularity={granularity} active={isActive} />
                </div>
              </div>
            </div>
            {isActive && !!statusDetails.length && <ChartList statusDetails={statusDetails} className="single-day-activity white-font table-line" dateFormat="HH[:]mm[:]ss" />}
          </React.Fragment>
        );
      })}
    </div>
  );
};
