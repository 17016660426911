import * as moment from "moment";
import React, { FunctionComponent } from "react";

import logoCounting from "../../../public/technis-logo-counting-couleur.png";
import { i18n } from "../../lang/i18n";
import { translation } from "../../lang/translation";
import { DateRange } from "../../utils/aggregate";
import { getDateRangeCaption } from "../../utils/time";
import { PdfFooter } from "./PdfFooter";

interface FrontPageProps {
  eventName: string;
  zoneName: string;
  dateRange: DateRange;
  email: string;
  exportDate: moment.Moment;
}

export const FrontPage: FunctionComponent<FrontPageProps> = props => {
  const { dateRange, email, eventName, exportDate, zoneName } = props;
  return (
    <div className="export-front-page" id="pdf-front-page">
      <div className="export-title">
        <div className="technis-title">
          <img src={logoCounting} alt="technis counting" />
        </div>
        <div className="title">
          <div className="event">{`${eventName}, `}</div>
          <div className="zone">{zoneName}</div>
        </div>
        <div className="dates">{getDateRangeCaption(dateRange)}</div>
      </div>
      <div className="export-generation-time">
        <div className="text">{i18n.t(translation.analytics.export.generatedOn)}</div>
        <div className="day">{exportDate.format("D MMM Y")}</div>
        <div className="time">{exportDate.format("H[:]mm")}</div>
      </div>
      <PdfFooter email={email} className="footer-position" id="pdf-front-page-footer" />
    </div>
  );
};
