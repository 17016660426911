import React, { FunctionComponent, ReactNode } from "react";

import { IconButton } from "../buttons/IconButton";

interface ModuleBodyProps {
  children: ReactNode;
  onDelete: () => void;
  className?: string;
}

export const ModuleBody: FunctionComponent<ModuleBodyProps> = props => {
  const { children, className = "", onDelete } = props;
  return (
    <div className={`module-background ${className}`}>
      <IconButton onClick={onDelete} className={"module-delete-button ignore-export"} />
      {children}
    </div>
  );
};
