import { StatusCode } from "@technis/shared";
import React, { FunctionComponent } from "react";
import { AiOutlineEllipsis } from "react-icons/ai";

import { translate } from "../../../lang/i18n";
import { translation } from "../../../lang/translation";
import { DeviceType, StatusDetail } from "../../../utils/aggregate";
import { getStatusColor } from "../../../utils/colors";
import { getDateCaption } from "../../../utils/time";
import { getStatusName } from "../../../utils/translation";
import { CustomButton } from "../buttons/CustomButton";

const SHOW_MORE_ICON_SIZE = 40;

const { atmosphere, blackBox, camera } = translation.common.sensor;
const { device, deviceType, message, status, time } = translation.common;

interface ChartListProps {
  statusDetails: StatusDetail[];
  className: string;
  dateFormat?: string;
  limit?: number;
  showAll?: boolean;
  onClickShowMore?: () => void;
}

interface StatusCodeCellProps {
  statusCode: StatusCode;
  reconnected: boolean | null;
}

const StatusCodeCell: FunctionComponent<StatusCodeCellProps> = props => {
  const { reconnected, statusCode } = props;
  return (
    <td className="status-code status-code-column table-cell-line" style={{ color: getStatusColor(statusCode, reconnected) }}>
      <div>{getStatusName(statusCode, reconnected)}</div>
    </td>
  );
};

export const ChartList: FunctionComponent<ChartListProps> = props => {
  const { statusDetails, showAll = true, limit = Infinity, onClickShowMore, className, dateFormat = "D MMM Y HH[:]mm[:]ss" } = props;
  const getDevicetype = (deviceType: DeviceType) => {
    switch (deviceType) {
      case DeviceType.ATMOSPHERE:
        return translate(atmosphere);
      case DeviceType.BLACK_BOX:
        return translate(blackBox);
      case DeviceType.CAMERA:
        return translate(camera);
    }
  };
  return (
    <>
      <table className={className}>
        <thead>
          <tr>
            <th className="time-column table-head-line">{translate(time)}</th>
            <th className="status-code-column table-head-line">{translate(status)}</th>
            <th className="device-name-column table-head-line">{translate(device.one)}</th>
            <th className="device-type-column table-head-line">{translate(deviceType)}</th>
            <th className="message-column table-head-line">{translate(message)}</th>
          </tr>
        </thead>
        <tbody>
          {statusDetails
            .filter((_, index) => showAll || index < limit)
            .map((detail, index) => (
              <tr key={`table-line-${index}`}>
                <td className="time-column table-cell-line">
                  <div>{getDateCaption(detail.dateReceived, dateFormat)}</div>
                </td>
                {<StatusCodeCell statusCode={detail.statusCode} reconnected={detail.reconnected} />}
                <td className="device-name-column table-cell-line">
                  <div>{detail.name}</div>
                </td>
                <td className="device-type-column table-cell-line">
                  <div>{getDevicetype(detail.deviceType)}</div>
                </td>
                <td className="message-column table-cell-line">
                  <div>{detail.msg}</div>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
      {onClickShowMore && statusDetails.length > limit && (
        <div className="button-container">
          <CustomButton buttonDisplay={<AiOutlineEllipsis size={SHOW_MORE_ICON_SIZE} />} buttonClassName="show-more-button" onClick={onClickShowMore} />
        </div>
      )}
    </>
  );
};
