import { StatusCode } from "@technis/shared";

import { translate } from "../lang/i18n";
import { translation } from "../lang/translation";
import { Granularity } from "./aggregate";

const { connectionLost, firmwareUpdating, incompatibleBlackBox, incompatibleMat, internetLost, operational, padRebooting, tilesMissing } = translation.analytics.modules.statusCode;
const { friday, monday, saturday, sunday, thursday, tuesday, wednesday } = translation.common.weekDays;
const { april, august, december, february, january, july, june, march, may, november, october, september } = translation.common.yearMonths;
const { lastFifteenMinutes, lastHour, lastMonth, lastTwoDays, lastWeek, today } = translation.analytics.modules.aggregationsSettings.buttons;
const { lastTwoDays: lastTwoDaysTimeRange, lastTwoWeeks, lastWeek: lastWeekTimeRange, today: todayTimeRange } = translation.time.timeRanges;

export const weekDays = () => [sunday, monday, tuesday, wednesday, thursday, friday, saturday];

export const yearMonths = () => [january, february, march, april, may, june, july, august, september, october, november, december];

export const timeRanges = [todayTimeRange, lastTwoDaysTimeRange, lastWeekTimeRange, lastTwoWeeks];

export const commonlyUsedButtons = () => [lastFifteenMinutes, lastHour, today, lastTwoDays, lastWeek, lastMonth];

export const capitalize = (value: string) => value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();

export const getPeriodTranslations = (granularity: Granularity) => {
  switch (granularity) {
    case Granularity.CONTINUOUS_DAYS:
    case Granularity.DISCONTINUOUS_DAYS:
    case Granularity.DISCONTINUOUS_WORKING_DAYS:
    case Granularity.DISCONTINUOUS_WEEKEND_DAYS:
      return weekDays();
    case Granularity.CONTINUOUS_MONTHS:
      return yearMonths();
    default:
      return ["default"];
  }
};

export const getPeriodNames = (granularity: Granularity) => getPeriodTranslations(granularity).map(translations => translate(translations));

export const getTimeRangesNames = () => timeRanges.map(translation => capitalize(translate(translation)));

export const getStatusName = (statusCode: StatusCode, reconnection: boolean | null) => {
  switch (statusCode) {
    case StatusCode.OK:
      return translate(operational);
    case StatusCode.PAD_DISCONNECTED:
      return translate(tilesMissing);
    case StatusCode.CONNECTION_LOST:
      if (reconnection) return translate(internetLost);
      return translate(connectionLost);
    case StatusCode.REBOOT:
      return translate(padRebooting);
    case StatusCode.INCOMPATIBLE_MATS:
      return translate(incompatibleMat);
    case StatusCode.INCOMPATIBLE_BLACK_BOX:
      return translate(incompatibleBlackBox);
    case StatusCode.FIRMWARE_UPDATE:
      return translate(firmwareUpdating);
  }
};
