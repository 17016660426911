import classNames from "classnames";
import React, { FunctionComponent, useEffect, useState } from "react";

// eslint-disable-next-line import/named
import { translate } from "../../lang/i18n";
import { translation } from "../../lang/translation";
import { AggregatedKpiAtmosphereWithName } from "../../pages/AnalyticsHomePage";
import { checkKpiAtmosphere, getSeries } from "../../utils/utils";
import { GenericLineChart } from "./GenericLineChart";
import { ChartError, KpiAtmosphereType, Line, ViewType } from "./index";

const { title } = translation.errors.missingKpiData;

interface AtmosphereChartProps {
  kpiAtmosphere: AggregatedKpiAtmosphereWithName;
  chartType: KpiAtmosphereType;
  view: ViewType;
}

interface State {
  left: number;
  right: number;
  refAreaLeft: string;
  refAreaRight: string;
  top: string;
  bottom: string;
  isZoom: boolean;
  error?: string;
  lines: Line[];
}

export const AtmosphereChart: FunctionComponent<AtmosphereChartProps> = props => {
  const { chartType, kpiAtmosphere, view } = props;
  const { dateBegins, dateEnds, granularity } = kpiAtmosphere;

  const getActiveKpi = (chartType: KpiAtmosphereType, kpiAtmosphere: AggregatedKpiAtmosphereWithName) => {
    switch (chartType) {
      case KpiAtmosphereType.CO2:
        return kpiAtmosphere.co2Min;
      case KpiAtmosphereType.TEMPERATURE:
        return kpiAtmosphere.temperatureMin;
      case KpiAtmosphereType.HUMIDITY:
        return kpiAtmosphere.humidityMin;
    }
  };

  const activeKpi = getActiveKpi(chartType, kpiAtmosphere);

  const [state, setState] = useState<State>({
    error: checkKpiAtmosphere(kpiAtmosphere, ["dateBegins", "dateEnds", "co2Min", "temperatureMin", "humidityMin"]),
    left: 0,
    right: 0,
    refAreaLeft: "",
    refAreaRight: "",
    top: "auto",
    bottom: "0",
    isZoom: false,
    lines: getSeries(activeKpi, dateBegins, granularity),
  });

  const { error, lines } = state;

  useEffect(() => {
    setState(prevState => ({
      ...prevState,
      error: checkKpiAtmosphere(kpiAtmosphere, ["dateBegins", "dateEnds", "co2Min", "temperatureMin", "humidityMin"]),
    }));
  }, [kpiAtmosphere]);

  useEffect(() => {
    setState(prevState => ({
      ...prevState,
      lines: getSeries(getActiveKpi(chartType, kpiAtmosphere), dateBegins, granularity),
    }));
  }, [chartType, kpiAtmosphere]);

  if (error) {
    return <ChartError error={error} />;
  }

  const renderSpecificChart = (chartType: KpiAtmosphereType) => {
    const kpi = getActiveKpi(chartType, kpiAtmosphere);
    const lines = getSeries(kpi, dateBegins, granularity);
    return (
      <>
        <div className="module-chart">
          {activeKpi && dateBegins && dateEnds ? (
            <GenericLineChart chartType={chartType} dateBegin={dateBegins[0]} granularity={granularity} lines={lines} isExport />
          ) : (
            <ChartError error={translate(title)} />
          )}
        </div>
      </>
    );
  };

  return (
    <>
      <div className={classNames("module-chart", { hide: view !== ViewType.BROWSER })}>
        {activeKpi && dateBegins && dateEnds ? (
          <GenericLineChart chartType={chartType} dateBegin={dateBegins[0]} granularity={granularity} lines={lines} />
        ) : (
          <ChartError error={translate(title)} />
        )}
      </div>
      <div className={classNames({ hide: view !== ViewType.EXPORT })}>
        {renderSpecificChart(KpiAtmosphereType.CO2)}
        {renderSpecificChart(KpiAtmosphereType.TEMPERATURE)}
        {renderSpecificChart(KpiAtmosphereType.HUMIDITY)}
      </div>
    </>
  );
};
