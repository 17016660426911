import { Icon, Popover, Spin } from "antd";
import { TooltipPlacement } from "antd/lib/tooltip";
import React, { FunctionComponent, useRef, useState } from "react";
import { Form, FormSpy } from "react-final-form";

import { translate } from "../../lang/i18n";
import { translation } from "../../lang/translation";
import { areObjectsEqual, isObjectEmpty } from "../../utils/utils";
import { CustomButton } from "./buttons/CustomButton";
import { OutClicker } from "./OutClicker";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
interface InlineCreatePopoverProps<T extends Record<string, unknown> = any> {
  fields: React.ReactNode;
  onSubmit: (callback: () => void) => void;
  triggerContent: React.ReactNode;
  placement?: TooltipPlacement;
  inProgress?: boolean;
  onClickOutside?: () => void;
  validator?: (values: T) => Record<string, unknown>;
  initialValues?: Record<string, unknown>;
}

interface State {
  isVisible: boolean;
  isFormChanged: boolean;
  errors: Record<string, unknown>;
}

export const InlineCreatePopover: FunctionComponent<InlineCreatePopoverProps> = props => {
  const { fields, initialValues, inProgress, onClickOutside, onSubmit, placement = "bottomLeft", triggerContent, validator } = props;
  const [state, setState] = useState<State>({
    isVisible: false,
    isFormChanged: false,
    errors: {},
  });
  const { errors, isVisible } = state;

  const renderMainContent = () => (
    <Form
      onSubmit={() => undefined}
      initialValues={initialValues}
      validate={values => validator && validator(values)}
      render={() => (
        <div>
          {fields}
          <FormSpy
            onChange={formState => {
              if (!areObjectsEqual(formState.errors, errors)) {
                setTimeout(() =>
                  setState({
                    ...state,
                    errors: formState.errors,
                  }),
                );
              }
              if (initialValues && !areObjectsEqual(formState.values, initialValues)) {
                setState({ ...state, isFormChanged: true });
              }
            }}
          />
        </div>
      )}
    />
  );
  const closeModal = () => setState({ ...state, isVisible: false });
  const renderPopoverContent = () => (
    <div className="inline-create-popup-container">
      <div className="content">{renderMainContent()}</div>
      <div className="buttons">
        <CustomButton
          onClick={() => onSubmit(closeModal)}
          buttonClassName="inline-popover-submit-button"
          disabled={!isObjectEmpty(errors) || inProgress || (initialValues && !state.isFormChanged)}
          buttonDisplay={inProgress ? <Spin indicator={<Icon type="loading" className="inline-modal-spinner" spin />} /> : translate(translation.common.apply)}
        />
      </div>
    </div>
  );

  const ref = useRef(null);

  return (
    <OutClicker
      onClick={() => {
        onClickOutside && onClickOutside();
        closeModal();
      }}
    >
      <div className="inline-popover" ref={ref}>
        <Popover placement={placement} getPopupContainer={() => ref.current || document.createElement("div")} trigger="click" content={renderPopoverContent()} visible={isVisible}>
          <div onClick={() => setState({ ...state, isVisible: true })}>{triggerContent}</div>
        </Popover>
      </div>
    </OutClicker>
  );
};
