import { isDefined } from "@technis/shared";
import React, { FunctionComponent, useEffect, useState } from "react";
import { AiOutlineFrown } from "react-icons/ai";

import { translate } from "../../lang/i18n";
import { translation } from "../../lang/translation";
import { AggregatedKpiCountingWithName } from "../../pages/AnalyticsHomePage";
import { Colors, PASTEL_COLORS_LIST } from "../../utils/colors";
import { checkErrors, checkKpiData, formatChartTime, getPeriodLength } from "../../utils/utils";
import { DateBars, RangeBars } from "../common/custom-chart/Bar";
import { ChartLegend } from "../common/custom-chart/ChartLegend";
import { HorizontalBarChart } from "../common/custom-chart/HorizontalBarChart";
import { PassageSetting, ZoneSetting } from "../common/Header";
import { getTheme } from "../common/Theme";
import { ASCENDENT_DIRECTION, DESCENDENT_DIRECTION, Direction } from "./index";

const { day, in: inLabel, noData, out: outLabel } = translation.common;
const { dwellSub, dwellTop, maxFlowPassages, maxFlowTop, maxOccupationRate } = translation.analytics.modules.labels;

enum Properties {
  DAY_PROPERTY = "day",
  VALUE_PROPERTY = "totalValuePerDay",
}

enum ListNames {
  DWELL_TOP = "dwellTop",
  DWELL_SUB = "dwellSub",
  MAX_FLOW_TOP = "maxFlowTop",
  MAX_FLOW_PASSAGES = "maxFlowPassages",
  MAX_OCCUPATION_RATE = "maxOccupationRate",
}

const DEFAULT_SORTING = { currentProperty: Properties.DAY_PROPERTY, currentDirection: ASCENDENT_DIRECTION };

export interface OverviewChartProps {
  passageSettings: PassageSetting[];
  passagesKpis: AggregatedKpiCountingWithName[];
  topZoneSettings: ZoneSetting;
  zoneSettings: ZoneSetting[];
  zonesKpis: AggregatedKpiCountingWithName[];
  limit?: number;
  timeWindow: number;
}

export interface DataByDay {
  day: number;
  dataType: DataType;
  data: ChartData[];
  totalValuePerDay: number;
}

export interface ChartData {
  name: string;
  value: number;
  text: string;
  peakTime?: number;
  timeWindowRange?: { start: number; end: number };
  color: Colors;
}

interface State {
  zoneErrors: (string | undefined)[];
  passageErrors: (string | undefined)[];
}

interface SortParameters {
  [ListNames.DWELL_TOP]: { currentProperty: Properties; currentDirection?: Direction };
  [ListNames.DWELL_SUB]: { currentProperty: Properties; currentDirection?: Direction };
  [ListNames.MAX_FLOW_TOP]: { currentProperty: Properties; currentDirection?: Direction };
  [ListNames.MAX_FLOW_PASSAGES]: { currentProperty: Properties; currentDirection?: Direction };
  [ListNames.MAX_OCCUPATION_RATE]: { currentProperty: Properties; currentDirection?: Direction };
}

export type DataType = "dwellTime" | "maxFlow" | "maxOccupationRate";

export const OverviewChart: FunctionComponent<OverviewChartProps> = props => {
  const { limit, passageSettings, passagesKpis, timeWindow, topZoneSettings, zoneSettings, zonesKpis } = props;
  const [{ granularity, shift }] = zonesKpis;

  const { primaryGradient } = getTheme();
  const colorRate = (`linear-gradient(${primaryGradient.join()})` as unknown) as Colors;

  const labelHeader = translate(day.other);

  const [state, setState] = useState<State>({
    zoneErrors: zonesKpis.map(kpiData => checkKpiData(kpiData, ["dateBegins", "dateEnds", "dwell", "zoneId", "inside"])),
    passageErrors: passagesKpis.map(kpiData => checkKpiData(kpiData, ["dateBegins", "dateEnds", "dwell", "passageId"])),
  });

  const { passageErrors, zoneErrors } = state;

  const [sortParameters, setSortParameters] = useState<SortParameters>({
    [ListNames.DWELL_TOP]: DEFAULT_SORTING,
    [ListNames.DWELL_SUB]: DEFAULT_SORTING,
    [ListNames.MAX_FLOW_TOP]: DEFAULT_SORTING,
    [ListNames.MAX_FLOW_PASSAGES]: DEFAULT_SORTING,
    [ListNames.MAX_OCCUPATION_RATE]: DEFAULT_SORTING,
  });

  useEffect(() => {
    setState({
      ...state,
      zoneErrors: zonesKpis.map(kpiData => checkKpiData(kpiData, ["dateBegins", "dateEnds", "dwell", "zoneId", "inside"])),
      passageErrors: passagesKpis.map(kpiData => checkKpiData(kpiData, ["dateBegins", "dateEnds", "dwell", "passageId"])),
    });
  }, [zonesKpis, passagesKpis]);

  let dateBeginList = zonesKpis[0]?.dateBegins?.filter(date => date < Date.now());
  dateBeginList = dateBeginList?.length ? dateBeginList : [];
  const topZoneSetting = zoneSettings[0];
  const insideTopZone = zonesKpis.find(zoneKpis => zoneKpis.zoneId === topZoneSetting?.id)?.inside || [[]];
  const affluenceInTopZone = zonesKpis.find(zoneKpis => zoneKpis.zoneId === topZoneSetting?.id)?.affluenceMinIn || [[]];
  const affluenceOutTopZone = zonesKpis.find(zoneKpis => zoneKpis.zoneId === topZoneSetting?.id)?.affluenceMinOut || [[]];

  const dwellTopZone = zonesKpis.find(zoneKpis => zoneKpis.zoneId === topZoneSetting?.id)?.dwell || [];

  const createDwellDataByDay = (dateIndex: number, zoneSetting?: ZoneSetting) => {
    if (!zoneSettings?.length || !zonesKpis?.length) {
      return [];
    }

    if (!zoneSetting) {
      const value = dwellTopZone.length && dateIndex < dwellTopZone.length ? dwellTopZone[dateIndex] : 0;
      return [
        {
          name: zoneSettings[0].name,
          value,
          text: formatChartTime(Math.floor(value)),
          color: PASTEL_COLORS_LIST[0].lightPastel,
        },
      ];
    }
    const level = zoneSetting.level;
    let isSubZone = false;
    return zoneSettings
      .filter(zoneSetting => zoneSetting.activated && zoneSetting.level <= level + 1 && zoneSetting.level > level - 1)
      .reduce((zonesData, currentData, index) => {
        if (isSubZone) {
          isSubZone = zoneSettings[index + 1] && zoneSettings[index + 1]?.level === level;
          const value = (zonesKpis.find(zoneKpis => zoneKpis.zoneId === currentData.id)?.dwell || [])[dateIndex];
          return [
            ...zonesData,
            {
              name: currentData.name,
              value,
              text: formatChartTime(Math.floor(value)),
              color: PASTEL_COLORS_LIST[index % PASTEL_COLORS_LIST.length].lightPastel,
            },
          ];
        }
        isSubZone = zoneSettings[index + 1] && currentData.id === zoneSetting.id;
        return zonesData;
      }, [] as ChartData[]);
  };

  const createTopZoneMaxFlowDataByDay = (dateIndex: number): ChartData[] => {
    if (!zoneSettings || !zonesKpis) {
      return [];
    }

    const affluenceInAndOut = Array.from({ length: affluenceInTopZone[dateIndex].length }).map(
      (_, index) => affluenceInTopZone[dateIndex][index] + affluenceOutTopZone[dateIndex][index],
    );
    const maxFlowValue = affluenceInAndOut?.max() || 0;

    const peakTime = affluenceInAndOut?.findIndex(value => value === maxFlowValue);
    const maxFlowIn = affluenceInTopZone[dateIndex][peakTime] || 0;
    const maxFlowOut = affluenceOutTopZone[dateIndex][peakTime] || 0;

    if (affluenceInTopZone[dateIndex].every(value => !isDefined(value) && affluenceOutTopZone[dateIndex].every(value => !isDefined(value)))) {
      return [
        {
          name: translate(inLabel.one),
          value: 100,
          text: `${translate(noData)}`,
          peakTime: 0,
          color: PASTEL_COLORS_LIST[0].darkPastel,
        },
        {
          name: translate(outLabel.one),
          value: 100,
          text: `${translate(noData)}`,
          peakTime: 0,
          color: PASTEL_COLORS_LIST[0].lightPastel,
        },
      ];
    }

    return [
      {
        name: translate(inLabel.one),
        value: maxFlowIn,
        text: `${maxFlowIn}`,
        peakTime,
        color: PASTEL_COLORS_LIST[0].darkPastel,
      },
      {
        name: translate(outLabel.one),
        value: maxFlowOut,
        text: `${maxFlowOut}`,
        peakTime,
        color: PASTEL_COLORS_LIST[0].lightPastel,
      },
    ];
  };

  const createPassageMaxFlowDataByDay = (dateIndex: number, peakTime: number) => {
    if (!passageSettings || !passagesKpis) {
      return [];
    }
    return passageSettings
      .filter(passageSetting => passageSetting.activated)
      .reduce((passageData, currentData, index) => {
        const dataIn = (passagesKpis.find(passageKpis => passageKpis.passageId === currentData.id)?.affluenceMinIn || [])[dateIndex];
        const dataOut = (passagesKpis.find(passageKpis => passageKpis.passageId === currentData.id)?.affluenceMinOut || [])[dateIndex];
        const maxFlowIn = dataIn?.[peakTime] || 0;
        const maxFlowOut = dataOut?.[peakTime] || 0;

        return [
          ...passageData,
          {
            name: `${currentData.name} ${translate(inLabel.one)}`,
            value: maxFlowIn,
            text: `${maxFlowIn}`,
            peakTime,
            color: PASTEL_COLORS_LIST[index % PASTEL_COLORS_LIST.length].darkPastel,
          },
          {
            name: `${currentData.name} ${translate(outLabel.one)}`,
            value: maxFlowOut,
            text: `${maxFlowOut}`,
            peakTime,
            color: PASTEL_COLORS_LIST[index % PASTEL_COLORS_LIST.length].lightPastel,
          },
        ];
      }, [] as ChartData[]);
  };

  const createMaxOccupationRateDataByDay = (dateIndex: number) => {
    if (!zoneSettings?.length) {
      return [];
    }
    const data = insideTopZone[dateIndex].max() || 0;
    const peakTime = (insideTopZone[dateIndex]?.findIndex(value => value === data) + shift) % getPeriodLength(granularity);
    const start = Math.ceil(timeWindow / 2);
    const end = timeWindow - start;
    const value = data ? data / (limit || 1) : 0;
    return [
      {
        name: zoneSettings[0]?.name || "",
        value,
        text: `${value}`,
        timeWindowRange: { start: peakTime ? peakTime - start : 0, end: peakTime ? peakTime + end : 0 },
        color: colorRate,
      },
    ];
  };

  if (checkErrors(zoneErrors) || checkErrors(passageErrors)) {
    return (
      <div className="module-error">
        <AiOutlineFrown color={Colors.RED} />
        {[...zoneErrors, ...passageErrors].map(error => (
          <div key={error} className="module-error-text">
            {error}
          </div>
        ))}
      </div>
    );
  }

  const dwellTopData: DataByDay[] = dateBeginList.map((day, index) => {
    const chartData = createDwellDataByDay(index);
    return {
      day,
      dataType: "dwellTime",
      data: chartData,
      totalValuePerDay: chartData.map(data => data.value).sum(),
    };
  });

  const dwellSubData: DataByDay[] = dateBeginList.map((day, index) => {
    const chartData = createDwellDataByDay(index, topZoneSettings);
    return {
      day,
      dataType: "dwellTime",
      data: chartData,
      totalValuePerDay: chartData.map(data => data.value).sum(),
    };
  });

  const maxFlowTopZoneData: DataByDay[] = dateBeginList.map((day, index) => {
    const chartData = createTopZoneMaxFlowDataByDay(index);
    return {
      day,
      dataType: "maxFlow",
      data: chartData,
      totalValuePerDay: chartData.map(data => data.value).sum(),
    };
  });

  const maxFlowPassagesData: DataByDay[] = dateBeginList.map((day, index) => {
    const data = maxFlowTopZoneData[index]?.data;
    const peak = data?.length && data[0]?.peakTime ? data[0].peakTime : 0;
    const chartData = createPassageMaxFlowDataByDay(index, peak);
    return {
      day,
      dataType: "maxFlow",
      data: chartData,
      totalValuePerDay: chartData.map(data => data.value).sum(),
    };
  });

  const maxOccupationRateData: DataByDay[] = dateBeginList.map((day, index) => {
    const chartData = createMaxOccupationRateDataByDay(index);

    return {
      day,
      dataType: "maxOccupationRate",
      data: chartData,
      totalValuePerDay: chartData.map(data => data.value).sum(),
    };
  });

  const maxDwell = Math.max(...dwellSubData.map(data => data.totalValuePerDay), ...dwellTopData.map(data => data.totalValuePerDay));

  const maxFlow = Math.max(...maxFlowPassagesData.map(data => data.totalValuePerDay), ...maxFlowTopZoneData.map(data => data.totalValuePerDay));

  const subZoneSetting = zoneSettings.filter(setting => setting.id != topZoneSetting?.id && setting.activated);
  const isOnePassageActivated = passageSettings.some(setting => setting.activated);

  const sortData = (data: DataByDay[], sortParameter: keyof SortParameters) => {
    if (!sortParameters[sortParameter].currentDirection) return data;

    const property = {
      [Properties.DAY_PROPERTY]: data.sortBy(Properties.DAY_PROPERTY, sortParameters[sortParameter].currentDirection),
      [Properties.VALUE_PROPERTY]: data.sortBy(Properties.VALUE_PROPERTY, sortParameters[sortParameter].currentDirection),
    };
    return property[sortParameters[sortParameter].currentProperty];
  };

  const toggleDirection = (list: keyof SortParameters): Direction => {
    if (sortParameters[list].currentDirection === ASCENDENT_DIRECTION) return DESCENDENT_DIRECTION;
    return ASCENDENT_DIRECTION;
  };

  const updateSorting = (list: keyof SortParameters, headerButton: Properties) => () => {
    if (sortParameters[list].currentProperty !== headerButton) {
      setSortParameters(prevState => ({ ...prevState, [list]: { ...prevState[list], currentProperty: headerButton, currentDirection: ASCENDENT_DIRECTION } }));
    } else {
      setSortParameters(prevState => ({ ...prevState, [list]: { ...prevState[list], currentDirection: toggleDirection(list) } }));
    }
  };

  return (
    <>
      <div className="export-1 overview-export">
        <HorizontalBarChart
          barHeader={translate(dwellTop, { zoneName: zoneSettings[0]?.name })}
          labelHeader={labelHeader}
          barChartClassName="overview-chart"
          labelClassName="overview-header-label"
          headerClassName="overview-header-bar"
          labelSort={{
            onClick: updateSorting(ListNames.DWELL_TOP, Properties.DAY_PROPERTY),
            direction: sortParameters.dwellTop.currentDirection,
            focus: sortParameters.dwellTop.currentProperty === Properties.DAY_PROPERTY,
          }}
          barSort={{
            onClick: updateSorting(ListNames.DWELL_TOP, Properties.VALUE_PROPERTY),
            direction: sortParameters.dwellTop.currentDirection,
            focus: sortParameters.dwellTop.currentProperty === Properties.VALUE_PROPERTY,
          }}
        >
          {sortData(dwellTopData, ListNames.DWELL_TOP).map((dayData, index) => (
            <DateBars key={`top-dwell-${index}`} dataByDay={dayData} max={maxDwell} />
          ))}
        </HorizontalBarChart>
      </div>
      {!!subZoneSetting.length && (
        <div className="export-1 overview-export">
          <HorizontalBarChart
            barHeader={translate(dwellSub)}
            labelHeader={labelHeader}
            barChartClassName="overview-chart"
            labelClassName="overview-header-label"
            headerClassName="overview-header-bar"
            labelSort={{
              onClick: updateSorting(ListNames.DWELL_SUB, Properties.DAY_PROPERTY),
              direction: sortParameters.dwellSub.currentDirection,
              focus: sortParameters.dwellSub.currentProperty === Properties.DAY_PROPERTY,
            }}
            barSort={{
              onClick: updateSorting(ListNames.DWELL_SUB, Properties.VALUE_PROPERTY),
              direction: sortParameters.dwellSub.currentDirection,
              focus: sortParameters.dwellSub.currentProperty === Properties.VALUE_PROPERTY,
            }}
          >
            {sortData(dwellSubData, ListNames.DWELL_SUB).map((dayData, index) => (
              <DateBars key={`sub-dwell-${index}`} dataByDay={dayData} max={maxDwell} />
            ))}
          </HorizontalBarChart>
          <ChartLegend
            legendItems={
              dwellSubData?.length && dwellSubData[0]?.data
                ? dwellSubData[0].data.map(subZoneData => ({
                    color: subZoneData.color,
                    text: subZoneData.name,
                  }))
                : []
            }
          />
        </div>
      )}
      <div className="export-1 overview-export">
        <HorizontalBarChart
          barHeader={translate(maxFlowTop, { zoneName: zoneSettings[0]?.name })}
          labelHeader={labelHeader}
          barChartClassName="overview-chart"
          labelClassName="overview-header-label"
          headerClassName="overview-header-bar"
          labelSort={{
            onClick: updateSorting(ListNames.MAX_FLOW_TOP, Properties.DAY_PROPERTY),
            direction: sortParameters.maxFlowTop.currentDirection,
            focus: sortParameters.maxFlowTop.currentProperty === Properties.DAY_PROPERTY,
          }}
          barSort={{
            onClick: updateSorting(ListNames.MAX_FLOW_TOP, Properties.VALUE_PROPERTY),
            direction: sortParameters.maxFlowTop.currentDirection,
            focus: sortParameters.maxFlowTop.currentProperty === Properties.VALUE_PROPERTY,
          }}
        >
          {sortData(maxFlowTopZoneData, ListNames.MAX_FLOW_TOP).map((dayData, index) => (
            <DateBars key={`top-max-flow-${index}`} dataByDay={dayData} max={maxFlow} peakTime={dayData.data[0]?.peakTime || 0} centerText />
          ))}
        </HorizontalBarChart>
        <ChartLegend
          legendItems={
            maxFlowTopZoneData?.length && maxFlowTopZoneData[0]?.data ? maxFlowTopZoneData[0].data.map(zoneData => ({ color: zoneData.color, text: zoneData.name })) : []
          }
        />
      </div>

      {isOnePassageActivated && (
        <div className="export-1 overview-export">
          <HorizontalBarChart
            barHeader={translate(maxFlowPassages)}
            labelHeader={labelHeader}
            barChartClassName="overview-chart"
            labelClassName="overview-header-label"
            headerClassName="overview-header-bar"
            labelSort={{
              onClick: updateSorting(ListNames.MAX_FLOW_PASSAGES, Properties.DAY_PROPERTY),
              direction: sortParameters.maxFlowPassages.currentDirection,
              focus: sortParameters.maxFlowPassages.currentProperty === Properties.DAY_PROPERTY,
            }}
            barSort={{
              onClick: updateSorting(ListNames.MAX_FLOW_PASSAGES, Properties.VALUE_PROPERTY),
              direction: sortParameters.maxFlowPassages.currentDirection,
              focus: sortParameters.maxFlowPassages.currentProperty === Properties.VALUE_PROPERTY,
            }}
          >
            {sortData(maxFlowPassagesData, ListNames.MAX_FLOW_PASSAGES).map((dayData, index) => (
              <DateBars key={`sub-max-flow-${index}`} dataByDay={dayData} max={maxFlow} peakTime={dayData.data[0]?.peakTime || 0} centerText />
            ))}
          </HorizontalBarChart>
          <ChartLegend
            legendItems={
              maxFlowPassagesData?.length && maxFlowPassagesData[0]?.data
                ? maxFlowPassagesData[0].data.map(passageData => ({ color: passageData.color, text: passageData.name }))
                : []
            }
          />
        </div>
      )}
      {!!limit && (
        <div className="export-1 overview-export">
          <HorizontalBarChart
            barHeader={translate(maxOccupationRate, { zoneName: zoneSettings[0]?.name || "" })}
            labelHeader={labelHeader}
            barChartClassName="overview-chart"
            labelClassName="overview-header-label"
            headerClassName="overview-header-bar"
            labelSort={{
              onClick: updateSorting(ListNames.MAX_OCCUPATION_RATE, Properties.DAY_PROPERTY),
              direction: sortParameters.maxOccupationRate.currentDirection,
              focus: sortParameters.maxOccupationRate.currentProperty === Properties.DAY_PROPERTY,
            }}
            barSort={{
              onClick: updateSorting(ListNames.MAX_OCCUPATION_RATE, Properties.VALUE_PROPERTY),
              direction: sortParameters.maxOccupationRate.currentDirection,
              focus: sortParameters.maxOccupationRate.currentProperty === Properties.VALUE_PROPERTY,
            }}
          >
            {sortData(maxOccupationRateData, ListNames.MAX_OCCUPATION_RATE).map((dayData, index) => (
              <RangeBars key={`max-occupation-rate-${index}`} dataByDay={dayData} />
            ))}
          </HorizontalBarChart>
        </div>
      )}
    </>
  );
};
