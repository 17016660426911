import React, { FunctionComponent } from "react";

import { i18n } from "../../lang/i18n";
import { translation } from "../../lang/translation";

export const ExportErrorModuleTooBig: FunctionComponent = () => (
  <div className="export-error-message" id="pdf-module-too-big">
    <div className="container-error-message">
      <div className="text">{i18n.t(translation.analytics.export.errorMessages.moduleTooBig)}</div>
    </div>
  </div>
);
