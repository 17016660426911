import classNames from "classnames";
import flatten from "lodash/flatten";
import * as moment from "moment";
import React, { FunctionComponent } from "react";
import { AiOutlineInfoCircle } from "react-icons/ai";

import { Dates, UI } from "../../../constants";
import { translate } from "../../../lang/i18n";
import { translation } from "../../../lang/translation";
import { AggregatedKpiCountingWithName } from "../../../pages/AnalyticsHomePage";
import { getItemByMaxIndex, getTotal, Slope } from "../../../utils/kpi";
import { startOfDay } from "../../../utils/time";
import { areObjectsEqual, formatChartTime, getPeriodLength } from "../../../utils/utils";
import { ParsedText } from "../../lang/ParsedText";
import { generalInformationLabelParser } from "../../lang/Parsers";
import { LoadingModule } from "../Loading";
import { Ring } from "../Ring";
import { getTheme } from "../Theme";
import { CommonModule } from "./CommonModule";

const { MILLISECONDS_IN_MINUTE } = Dates;
const { MODULE_TITLE_ICON_SIZE } = UI;

const SLOPE_THRESHOLD = 0.05;
const INSIDE_MEAN_THRESHOLD = 0.1;

const { common } = translation;
const { labels, titles } = translation.analytics.modules;

const MAX_DATE_FORMAT = "dddd[,] Do MMMM YYYY";
const CROWD_DATE_FORMAT = "H[h]mm";

interface GeneralInformationModuleProps {
  timeWindow: number;
  kpis: AggregatedKpiCountingWithName;
  onDelete: () => void;
}

interface Range {
  start: number;
  end: number;
  singleInsideValue: number;
}

// eslint-disable-next-line sonarjs/cognitive-complexity
export const GeneralInformationModule: FunctionComponent<GeneralInformationModuleProps> = props => {
  const { kpis, onDelete, timeWindow } = props;

  const { affluenceMinIn, averageDwell, countMinMax, dateBegins, dateEnds, granularity, inside } = kpis;

  if (!averageDwell || !affluenceMinIn || !inside || !dateBegins || !dateEnds || !countMinMax) {
    return <LoadingModule />;
  }

  const [firstDateBegin] = dateBegins;
  const lastInsideIndex = inside.length - 1;
  const isSinglePeriod = (Date.now() < dateEnds[lastInsideIndex] && startOfDay() <= firstDateBegin) || inside.length === 1;
  const insideMeanArray = inside.averageArrays();
  const maxInsideMean = Math.max(...insideMeanArray);

  const getInterval = (midPointIndex: number, midPointValue: number) => {
    const getIndex = (acc: { index: number; stop: boolean }, value: number, index: number) => {
      if (!acc.stop) {
        if (Math.abs(midPointValue - value) / maxInsideMean < INSIDE_MEAN_THRESHOLD) return { index, stop: false };
        acc.stop = true;
      }
      return acc;
    };

    return {
      start:
        insideMeanArray.slice(0, midPointIndex + 1).reduceRight(getIndex, {
          index: midPointIndex,
          stop: false,
        }).index + 1,
      end:
        insideMeanArray.slice(midPointIndex).reduce(getIndex, {
          index: midPointIndex,
          stop: false,
        }).index + midPointIndex,
      singleInsideValue: midPointValue,
    };
  };

  const totalVisitors = getTotal(affluenceMinIn);
  const insideMean = Math.floor(flatten(inside).average());
  const maxVisitorsDate = getItemByMaxIndex(dateBegins, affluenceMinIn);
  const maxInsideDate = getItemByMaxIndex(dateBegins, inside);

  // Most/less crowded interval
  const slopes = insideMeanArray.reduce((acc, curr, index, array) => {
    const window = array.window(timeWindow, index);
    const opSide = window.last() - window[0];
    const slope = opSide / timeWindow;
    return [...acc, { index, inside: curr, slope }];
  }, [] as Slope[]);

  const minMaxIntervals = slopes.reduce((acc, curr, index, array) => {
    const detectMinimaAtStart = index === 0 && curr.slope < 0 && array[index + 1].slope > 0;
    const detectMaximaAndFlatCurveAtStart = index === 0 && curr.slope > -SLOPE_THRESHOLD && array[index + 1].slope < SLOPE_THRESHOLD;
    const detectMinima = index > 0 && index < array.length - 1 && array[index - 1].slope < 0 && array[index + 1].slope > 0;
    const detectMaximaAndFlatCurve = index > 0 && index < array.length - 1 && array[index - 1].slope > -SLOPE_THRESHOLD && array[index + 1].slope < SLOPE_THRESHOLD;
    const detectMinimaAtEnd = index === array.length - 1 && array[index - 1].slope < 0 && curr.slope > 0;
    const detectMaximaAndFlatCurveAtEnd = index === array.length - 1 && array[index - 1].slope > -SLOPE_THRESHOLD && curr.slope < SLOPE_THRESHOLD;

    if (detectMinimaAtStart || detectMaximaAndFlatCurveAtStart || detectMinima || detectMaximaAndFlatCurve || detectMinimaAtEnd || detectMaximaAndFlatCurveAtEnd)
      return [...acc, { index: curr.index, inside: curr.inside, slope: curr.slope }];
    return acc;
  }, [] as Slope[]);

  let startRange = minMaxIntervals[0]?.index || 0;
  const ranges = minMaxIntervals.length
    ? minMaxIntervals.reduce((acc, curr, index, array) => {
        const isEndOfArray = index === array.length - 1;
        const hasNextValue = array[index + 1] && curr.index + 1 !== array[index + 1].index;

        const currentStartRange = startRange;
        if (hasNextValue) startRange = array[index + 1].index;
        if (isEndOfArray || hasNextValue)
          return [
            ...acc,
            {
              start: currentStartRange,
              end: curr.index,
              singleInsideValue: curr.inside,
            },
          ];
        return acc;
      }, [] as Range[])
    : [{ start: 0, end: 0, singleInsideValue: 0 }];

  const globalMaximumInitialValues = isSinglePeriod
    ? { start: insideMeanArray.length - 1, end: insideMeanArray.length - 1, singleInsideValue: insideMeanArray.last() }
    : { start: 0, end: 0, singleInsideValue: 0 };

  const [firstRange] = ranges;
  const globalMinimumInitialValues = isSinglePeriod
    ? { start: 0, end: 0, singleInsideValue: insideMeanArray[0] }
    : { start: firstRange.start, end: firstRange.end, singleInsideValue: firstRange.singleInsideValue };

  const { globalMaximum: globalMaximum, globalMinimum: globalMinimum } = ranges.reduce(
    (acc, range) => {
      const isInsideSmaller = range.singleInsideValue < acc.globalMinimum.singleInsideValue;
      const isInsideBigger = range.singleInsideValue > acc.globalMaximum.singleInsideValue;
      const isLowerRangeBigger = range.singleInsideValue === acc.globalMinimum.singleInsideValue && range.end - range.start > acc.globalMinimum.end - acc.globalMinimum.start;
      const isHigherRangeBigger = range.singleInsideValue === acc.globalMaximum.singleInsideValue && range.end - range.start > acc.globalMaximum.end - acc.globalMaximum.start;
      if (isInsideSmaller || isLowerRangeBigger)
        return {
          globalMinimum: { start: range.start, end: range.end, singleInsideValue: range.singleInsideValue },
          globalMaximum: acc.globalMaximum,
        };
      if (isInsideBigger || isHigherRangeBigger)
        return {
          globalMinimum: acc.globalMinimum,
          globalMaximum: { start: range.start, end: range.end, singleInsideValue: range.singleInsideValue },
        };
      return acc;
    },
    { globalMinimum: globalMinimumInitialValues, globalMaximum: globalMaximumInitialValues },
  );

  const midMaximum = Math.floor(globalMaximum.start + (globalMaximum.end - globalMaximum.start) / 2);
  const midMinimum = Math.floor(globalMinimum.start + (globalMinimum.end - globalMinimum.start) / 2);

  const intervalMostCrowded = getInterval(midMaximum, insideMeanArray[midMaximum]);
  let intervalLessCrowded = getInterval(midMinimum, insideMeanArray[midMinimum]);

  // connect each side of the inside if possible
  if (
    insideMeanArray.length === getPeriodLength(granularity) &&
    ranges.length > 1 &&
    globalMinimum.singleInsideValue >= ranges[0].singleInsideValue &&
    Math.abs(ranges[0].singleInsideValue - ranges.last().singleInsideValue) / maxInsideMean < INSIDE_MEAN_THRESHOLD
  ) {
    const secondMinima = ranges.filter(interval => !areObjectsEqual(interval, globalMinimum) && (interval.end === insideMeanArray.length - 1 || interval.start === 0));
    const [secondMinimaFirstValue] = secondMinima;
    if (secondMinimaFirstValue) {
      const secondMidMinimum = Math.floor(secondMinimaFirstValue.start + (secondMinimaFirstValue.end - secondMinimaFirstValue.start) / 2);
      const secondIntervalLessCrowded = getInterval(secondMidMinimum, insideMeanArray[secondMidMinimum]);

      intervalLessCrowded = {
        start: intervalLessCrowded.start < midMaximum ? secondIntervalLessCrowded.start : intervalLessCrowded.start,
        end: intervalLessCrowded.end > midMaximum ? secondIntervalLessCrowded.end : intervalLessCrowded.end,
        singleInsideValue: Math.floor([intervalLessCrowded.singleInsideValue, secondIntervalLessCrowded.singleInsideValue].average()),
      };
    }
  }

  const mostCrowdedStart = firstDateBegin + intervalMostCrowded.start * MILLISECONDS_IN_MINUTE;
  const mostCrowdedEnd = firstDateBegin + intervalMostCrowded.end * MILLISECONDS_IN_MINUTE;
  const lessCrowdedStart = firstDateBegin + intervalLessCrowded.start * MILLISECONDS_IN_MINUTE;
  const lessCrowdedEnd = firstDateBegin + intervalLessCrowded.end * MILLISECONDS_IN_MINUTE;

  const { ringGradient } = getTheme();
  const isCountMinNegative = countMinMax?.[0] < 0;

  return (
    <CommonModule iconSize={MODULE_TITLE_ICON_SIZE} title={translate(titles.generalInformation)} Icon={AiOutlineInfoCircle} onDelete={onDelete} isExportable>
      <div className="general-information">
        <div className="mean-values">
          <div className="inside-time">
            <div className="inside-time-value">
              {formatChartTime(Math.floor(averageDwell))}
              <div className="inside-time-unit">{translate(common.minute.short).toLowerCase()}</div>
            </div>
            <div className="inside-time-text white-font">{translate(labels.insideTime).toUpperCase()}</div>
          </div>
          <div className="inside-mean">
            <Ring
              colors={ringGradient}
              radius={72.5}
              stroke={6}
              text={{
                firstLine: `${insideMean}`,
                secondLine: `${translate(labels.meanInside).toUpperCase()}`,
              }}
              classNameText={{ firstLine: "inside-mean-value svg-white-font", secondLine: "inside-mean-text svg-white-font" }}
            />
          </div>
          <div className="total-visitors-mean">
            <div className="total-visitors-mean-value">{totalVisitors}</div>
            <div className="total-visitors-mean-text white-font">{translate(common.visitors).toUpperCase()}</div>
          </div>
        </div>
        <div className="container">
          <div className="max-inside-time">
            {<ParsedText parsers={[generalInformationLabelParser()]}>{`${translate(labels.maxInsideTime)} ${moment(maxInsideDate).format(MAX_DATE_FORMAT)}`}</ParsedText>}
          </div>
        </div>
        <div className="container">
          <div className="max-visitors-time">
            <ParsedText parsers={[generalInformationLabelParser()]}>{`${translate(labels.maxVisitorsTime)} ${moment(maxVisitorsDate).format(MAX_DATE_FORMAT)} `}</ParsedText>
          </div>
        </div>
        <div className={classNames("container", { hide: isCountMinNegative })}>
          <div className="most-crowded">
            <ParsedText parsers={[generalInformationLabelParser()]}>
              {`${translate(labels.mostCrowded)} ${moment(mostCrowdedStart).format(CROWD_DATE_FORMAT)} - ${moment(mostCrowdedEnd).format(CROWD_DATE_FORMAT)}`}
            </ParsedText>
          </div>
        </div>
        <div className={classNames("container", { hide: isCountMinNegative })}>
          <div className="less-crowded">
            <ParsedText parsers={[generalInformationLabelParser()]}>
              {`${translate(labels.lessCrowded)} ${moment(lessCrowdedStart).format(CROWD_DATE_FORMAT)} - ${moment(lessCrowdedEnd).format(CROWD_DATE_FORMAT)}`}
            </ParsedText>
          </div>
        </div>
      </div>
    </CommonModule>
  );
};
