import { Maybe } from "@technis/shared";
import { isEmpty, isNumber, orderBy, slice, uniq, uniqBy } from "lodash";

import { isDefined } from "./utils";

// Lodash types
type PropertyName = string | number | symbol;
type PartialShallow<T> = {
  [P in keyof T]?: T[P] extends Record<string, unknown> ? Record<string, unknown> : T[P];
};
type IterateeShorthand<T> = PropertyName | PartialShallow<T>;
type ListIterator<T, TResult> = (value: T, index: number, collection: List<T>) => TResult;
type ListIteratee<T> = ListIterator<T, NotVoid> | IterateeShorthand<T>;
type ValueIteratee<T> = ((value: T) => NotVoid) | IterateeShorthand<T>;
type Many<T> = T | ReadonlyArray<T>;
type List<T> = ArrayLike<T>;
type NotVoid = unknown;

// Object auto typed keys
declare global {
  interface PromiseConstructor {
    sequential<T>(arr: (() => Promise<T>)[]): Promise<T[]>;
  }

  interface ObjectConstructor {
    typedKeys<T>(o: T): (keyof T)[];
  }

  interface Date {
    setDayMinute(min: Maybe<number>): number;

    getDayMinute(): number;

    setDayHour(hour: Maybe<number>): number;
  }

  interface Array<T> {
    average(): number;

    innerSum(): number[];

    innerMean(): number[];

    last(): T;

    max(): number;

    min(): number;

    movingAverage(timeWindow: number): T[];

    removeAt(index: number): T[];

    replaceAt(elem: T, index: number): T[];

    sortBy(properties?: Many<ListIteratee<T>>, orders?: Many<boolean | "asc" | "desc">): T[];

    uniqBy(properties: ValueIteratee<T>): T[];

    uniq(): T[];

    isEmpty(): boolean;

    sum(): number;

    transpose(): number[][];

    window(windowSize: number, index: number): T[];

    chunks(chunkSize: number): Array<Array<T>>;

    sumArrays(): number[];

    averageArrays(): number[];

    replaceRangeAt(insert: T[], index: number): T[];

    next(index: number): T;

    previous(index: number): T;
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
Object.typedKeys = Object.keys as any;

Promise.sequential = <T>(arr: (() => Promise<T>)[]) => {
  const res: T[] = [];
  return arr
    .reduce(
      (seq: Promise<T | null>, next, i) =>
        seq
          .then(r => {
            if (r) {
              res.push(r);
            }
            return i === arr.length - 1 ? res : r;
          })
          .then(next),
      Promise.resolve(null),
    )
    .then(() => res);
};

Array.prototype.sum = function() {
  return this.reduce((a: number, b: number) => a + (!isNaN(b) ? b : 0), 0);
};

Array.prototype.average = function() {
  const array = this.filter(isDefined);
  return array.sum() / (array.length || 1);
};

Array.prototype.max = function() {
  return this.reduce((acc, curr) => {
    const valueMax = Array.isArray(curr) ? Math.max(...curr.filter(isDefined)) : curr;
    if (acc != null) return valueMax > acc ? valueMax : acc;
    return valueMax;
  }, 0);
};

Array.prototype.min = function() {
  return this.reduce((acc, curr) => {
    const valueMin = Array.isArray(curr) ? Math.min(...curr.filter(isDefined)) : curr;
    if (acc != null) return valueMin < acc ? valueMin : acc;
    return valueMin;
  }, null);
};

Array.prototype.transpose = function() {
  if (!Array.isArray(this[0])) {
    return this;
  }
  return this[0].map((x: number[], i: number) => this.map(x => x[i]));
};

Array.prototype.innerSum = function() {
  if (!Array.isArray(this[0])) {
    return this;
  }
  return this.reduce((acc: number[], a: number[]) => a.map((b, index) => (acc[index] || 0) + b), []);
};

Array.prototype.innerMean = function() {
  if (!Array.isArray(this[0])) {
    return this;
  }
  const length = this[0].length;
  return this.innerSum().map(value => Math.floor(value / length));
};

Array.prototype.removeAt = function(index) {
  return [...this.slice(0, index), ...this.slice(index + 1, this.length)];
};

Array.prototype.replaceAt = function(elem, index) {
  return [...this.slice(0, index), elem, ...this.slice(index + 1, this.length)];
};

Array.prototype.sortBy = function(properties, orders) {
  return orderBy(this, properties, orders);
};

Array.prototype.uniqBy = function(properties) {
  return uniqBy(this, properties);
};

Array.prototype.uniq = function() {
  return uniq(this);
};

Array.prototype.isEmpty = function() {
  return isEmpty(this);
};

Array.prototype.movingAverage = function(timeWindow) {
  return this.map((value, index) => (!isDefined(value) || isNaN(value) ? NaN : Math.round(this.window(timeWindow, index).average())));
};

Array.prototype.window = function(windowSize, index) {
  const before = Math.ceil(windowSize / 2);
  const after = windowSize - before;
  return slice(this, Math.max(0, index - before), Math.min(this.length, index + after));
};

Array.prototype.last = function() {
  return this[this.length - 1];
};

Array.prototype.chunks = function(chunkSize) {
  const chunks = [];
  for (let i = 0; i < this.length; i += chunkSize) chunks.push(this.slice(i, i + chunkSize));
  return chunks;
};

Array.prototype.sumArrays = function() {
  if (!Array.isArray(this[0])) {
    return this;
  }
  const n = this.reduce((acc, cur) => Math.max(acc, cur.length), 0);
  return Array.from(Array(n)).map((_, i) => this.map(arr => arr[i] || 0).reduce((sum, cur) => sum + cur, 0));
};

Array.prototype.averageArrays = function() {
  if (!this.every(arr => Array.isArray(arr))) {
    throw new Error("Every elements should be an array!");
  }
  const n = this.map(arr => arr.length).max();

  return new Array(n).fill(0).map((_, index) =>
    this.map(arr => arr[index])
      .filter(isNumber)
      .average(),
  );
};

Array.prototype.replaceRangeAt = function(insert: [], index) {
  return this.splice(index, insert.length, ...insert);
};

Array.prototype.next = function(index) {
  if (index + 1 >= this.length) return this.last();
  return this[index + 1];
};

Array.prototype.previous = function(index) {
  if (index <= 0) return this[0];
  return this[index - 1];
};

Date.prototype.setDayMinute = function(min) {
  if (!isDefined(min)) {
    return this.getTime();
  }
  const H0 = new Date(this).setHours(0, 0);
  return this.setTime(H0 + min * 60000);
};

Date.prototype.getDayMinute = function() {
  return this.getHours() * 60 + this.getMinutes();
};

Date.prototype.setDayHour = function(hour) {
  if (!isDefined(hour)) {
    return this.getTime();
  }
  const H0 = new Date(this).setHours(0, 0);
  return this.setTime(H0 + hour * 3600000);
};
