import { Maybe, Model, NeededOnly, Pad, Passage, Zone } from "@technis/shared";
import gql from "graphql-tag";

import { KpiAtmosphereWithDates, KpiCountingWithDates } from "../utils/aggregate";
import { PadData } from "./pad.gql";
import { PassageData } from "./passage.gql";

export const TopZoneData = `
... on TopZone {
    address
    city
    country
    zip
    organizationId
    countingRange
    timezone
}
`;

export const CommonZoneData = `
... on ZoneCommon {
    id
    name
}
`;

export const BasicZoneData = `
...on BasicZone {
    parentId
}
`;

const KpiCountingData = `
  dateBegin
  dateEnd
  zoneId
  passageId
  inside
  affluenceMinIn
  affluenceMinOut
  dwell
`;

const kpiAtmosphereData = `
  zoneId
  dateBegin
  dateEnd
  deviceId
  co2Min
  humidityMin
  temperatureMin
`;

export const ZoneData = `
${CommonZoneData}
${TopZoneData}
${BasicZoneData}
`;

export type ZonesByOrganizationResult = { zonesByOrganization: Zone[] };
export const zonesByOrganization = gql`
    query zonesByOrganization($organizationId: Int!) {
        zonesByOrganization(organizationId: $organizationId) {
            ${ZoneData}
        }
    }
`;

export type ZonesByPlanResult = {
  zonesByPlan: Maybe<
    Array<
      NeededOnly<Zone, "id" | "name"> & {
        passages: Maybe<Array<NeededOnly<Passage, "id" | "name">>>;
      }
    >
  >;
};
export const zonesByPlan = gql`
    query zonesByPlan($zoneId: Int!) {
        zonesByPlan(zoneId: $zoneId) {
            ${ZoneData}
            ... on ZoneCommon {
                passages {
                    id
                    name
                }
            }
        }
    }
`;

export type ZonesByPlanWithPadsResult = {
  zonesByPlan: Maybe<
    Array<
      NeededOnly<Zone, "id" | "name"> & {
        pads: Maybe<Array<NeededOnly<Pad, "id" | "name">>>;
      }
    >
  >;
};
export const zonesByPlanWithPads = gql`
    query zonesByPlan($zoneId: Int!) {
        zonesByPlan(zoneId: $zoneId) {
            ${ZoneData}
            ... on ZoneCommon {
                pads{
                    ${PadData}
                }
            }
        }
    }
`;

export type ZoneByIdResult = {
  zoneById: Zone & {
    passages: Maybe<
      Array<
        NeededOnly<Passage, "id" | "name"> & {
          zoneIn: NeededOnly<Zone, "id" | "name">;
          zoneOut: NeededOnly<Zone, "id" | "name">;
          pads: Maybe<Array<NeededOnly<Pad, "id" | "name">>>;
        }
      >
    >;
    pads: Maybe<Array<NeededOnly<Pad, "id" | "name">>>;
  };
};
export const zoneById = gql`
    query zoneById($zoneId: Int!) {
        zoneById(zoneId: $zoneId) {
            ${ZoneData}
            ... on TopZone {
                pads{
                    ${PadData}
                }
                passages{
                    ${PassageData}
                    pads{
                        ${PadData}
                    }
                }
            }
        }
    }
`;

export type AnalyticsKpiQueryResult = {
  zoneById: Zone & {
    kpis: Array<KpiCountingWithDates>;
  };
};

export const analyticsKpiQuery = gql`
  query analyticsKpiQuery($zoneId: Int!, $dates: [Float!]!, $eventId: Int!) {
    zoneById(zoneId: $zoneId) {
      kpis(dates: $dates, eventId: $eventId) {
        ${KpiCountingData}
      }
    }
  }
`;

export type AllZonesAllPassagesAnalyticsKpiQueryResult = {
  zonesByInstallation: Array<
    Pick<Zone, "id" | "name" | "parentIds" | "childIds" | "countMinMax"> & { passages: Array<Pick<Passage, "padIds">> } & { kpis: Array<KpiCountingWithDates> } & {
      kpisAtmosphere: Array<KpiAtmosphereWithDates>;
    }
  >;
  passagesByInstallation: Array<
    Pick<Passage, "id" | "name"> & {
      kpis: Array<KpiCountingWithDates>;
      pads: Array<Pick<Pad, "id" | "name"> & { model: Model }>;
    }
  >;
};

export const allZonesAllPassagesAnalyticsKpiQuery = gql`
  query allZonesAllPassagesAnalyticsKpiQuery($installationId: Int!, $dates: [Float!]!, $eventId: Int!) {
    zonesByInstallation (installationId: $installationId) {
      name
      id
      parentIds
      childIds
      countMinMax
      passages {
        padIds
      }
      kpis(dates: $dates, eventId: $eventId) {
        ${KpiCountingData}
      }
      kpisAtmosphere(eventId: $eventId, dates: $dates) {
        ${kpiAtmosphereData}
      }
    }
    passagesByInstallation(installationId: $installationId) {
      name
      id
      kpis(dates: $dates, eventId: $eventId) {
        ${KpiCountingData}
      }
      pads {
        id
        name
        model {
          name
          connectivity
          typeOfSensor
          application
        }
      }
    }
  }
`;

export type AtmosphereKpiQueryResult = {
  zoneById: Zone & {
    kpisAtmosphere: Array<KpiAtmosphereWithDates>;
  };
};

export const atmosphereKpiQuery = gql`
  query atmosphereKpiQuery($zoneId: Int!, $dates: [Float!]!, $eventId: Int!) {
    zoneById(zoneId: $zoneId) {
      kpisAtmosphere(eventId: $eventId, dates: $dates) {
        zoneId
        dateBegin
        dateEnd
        deviceId
        co2Min
        humidityMin
        temperatureMin
      }
    }
  }
`;
