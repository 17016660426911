import React from "react";

import { Patterns } from "./Patterns";

export interface Parser {
  pattern: RegExp;
  render: (matched: string, key: number) => JSX.Element;
}

export const formItemTooltipParser: () => Parser = () => ({
  pattern: Patterns.richtext,
  render: (matched: string, key: number) => {
    const [styleTag, text] = matched.slice(1, -1).split("|");
    switch (styleTag) {
      case "title":
        return (
          <div key={key} className="tooltip-title">
            {text}
          </div>
        );
      case "tagTitle":
        return (
          <span key={key} className="tooltip-title tag">
            {text}
          </span>
        );
      case "info":
        return (
          <span key={key} className="tooltip-info">
            {text}
          </span>
        );
      case "tag":
        return (
          <span key={key} className="tooltip-info tag">
            {text}
          </span>
        );
      case "emphasis":
        return (
          <span key={key} className="tooltip-info emphasis">
            {text}
          </span>
        );
      case "lowExposant":
        return (
          <span key={key} className="tooltip-info low-exposant">
            {text}
          </span>
        );
      case "newLine":
        return <br key={key} />;
      default:
        return <div key={key}>{text}</div>;
    }
  },
});

export const chartHeaderParser: () => Parser = () => ({
  pattern: Patterns.richtext,
  render: (matched: string, key: number) => {
    const [styleTag, text] = matched.slice(1, -1).split("|");

    if (styleTag === "unit") {
      return (
        <span key={key} className="overview-header-bar-unit label-description">
          {text}
        </span>
      );
    }

    return <div key={key}>{text}</div>;
  },
});

export const generalInformationLabelParser: () => Parser = () => ({
  pattern: Patterns.richtext,
  render: (matched: string, key: number) => {
    const [styleTag, text] = matched.slice(1, -1).split("|");

    if (styleTag === "highlight") {
      return (
        <span key={key} className="general-information-highlight white-font">
          {text}
        </span>
      );
    }

    return <div key={key}>{text}</div>;
  },
});

export const labelParser: () => Parser = () => ({
  pattern: Patterns.richtext,
  render: (matched: string, key: number) => {
    const [styleTag, text] = matched.slice(1, -1).split("|");

    if (styleTag === "lowExposant") {
      return (
        <span key={key} className="low-exposant">
          {text}
        </span>
      );
    }

    return <div key={key}>{text}</div>;
  },
});

export const legendParser: () => Parser = () => ({
  pattern: Patterns.richtext,
  render: (matched: string, key: number) => {
    const [styleTag, text] = matched.slice(1, -1).split("|");

    if (styleTag === "highLight") {
      return (
        <span key={key} className="highlight">
          {text}
        </span>
      );
    }

    return <div key={key}>{text}</div>;
  },
});
