import React, { FunctionComponent, useEffect, useState } from "react";
import { useQuery } from "react-apollo";
import { AiOutlineAlignCenter } from "react-icons/ai";

import { UI } from "../../../constants";
import { analyticsKpiQuery, AnalyticsKpiQueryResult } from "../../../graphql/zones.gql";
import { translate } from "../../../lang/i18n";
import { translation } from "../../../lang/translation";
import { AggregatedKpiCountingWithName } from "../../../pages/AnalyticsHomePage";
import { DateRange, KpiCountingAggregator } from "../../../utils/aggregate";
import { updateModuleDate } from "../../../utils/time";
import { ActivityMessages, trackClick } from "../../../utils/tracking";
import { areObjectsEqual } from "../../../utils/utils";
import { QuantileChart } from "../../charts/QuantileChart";
import { ChartModule } from "./ChartModule";

const { MODULE_TITLE_ICON_SIZE } = UI;

const { titles, tooltips } = translation.analytics.modules;

interface QuantileModuleProps {
  dateRange: DateRange;
  datesLimits: {
    begin: number;
    end: number;
  };
  eventId: number;
  keyNumber: number;
  kpiData: AggregatedKpiCountingWithName;
  zoneId: number;
  zoneName: string;
  onDelete: () => void;
  timeWindow?: number;
}

export const QuantileModule: FunctionComponent<QuantileModuleProps> = props => {
  const { dateRange, datesLimits, eventId, keyNumber, kpiData, onDelete, timeWindow, zoneId, zoneName } = props;
  const [moduleDates, setModuleDates] = useState<DateRange>(dateRange);

  useEffect(() => {
    setModuleDates(dateRange);
  }, [dateRange]);

  const shouldHeaderOverride = areObjectsEqual(dateRange, moduleDates);

  const { data: moduleKpi, loading } = useQuery<AnalyticsKpiQueryResult>(analyticsKpiQuery, {
    errorPolicy: "all",
    variables: { zoneId, dates: [moduleDates.dateBegin, moduleDates.dateEnd], eventId },
    skip: shouldHeaderOverride,
  });

  const { granularity, zoneName: kpiZoneName } = kpiData;

  const affluenceKpiAggregated: AggregatedKpiCountingWithName =
    !shouldHeaderOverride && moduleKpi
      ? {
          ...new KpiCountingAggregator({ kpis: moduleKpi?.zoneById.kpis, granularity, timeWindow, dateRange: moduleDates, isDataShifting: true }).getAggregatedData(),
          zoneName: kpiZoneName,
        }
      : kpiData;

  const updateDate = updateModuleDate(setModuleDates);

  const updateDateHandler = (dates: DateRange) => {
    trackClick(ActivityMessages.dateRangeChange);
    updateDate(dates);
  };

  return (
    <ChartModule
      dateRange={moduleDates}
      datesLimits={datesLimits}
      iconSize={MODULE_TITLE_ICON_SIZE}
      keyNumber={keyNumber}
      title={translate(titles.quantileChart)}
      tooltipText={translate(tooltips.quantile)}
      zoneName={zoneName}
      Icon={AiOutlineAlignCenter}
      onDelete={onDelete}
      onUpdateDate={updateDateHandler}
      loading={loading}
      isExportable
    >
      <QuantileChart kpiData={affluenceKpiAggregated} dateRange={moduleDates} />
    </ChartModule>
  );
};
