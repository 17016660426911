import classnames from "classnames";
import React, { FunctionComponent, ReactNode } from "react";
import { IconType } from "react-icons";

import { ModuleBody } from "./ModulBody";
import { ModuleTitle } from "./ModuleTitle";

interface ModuleProps {
  children: ReactNode;
  Icon: IconType;
  iconSize: number;
  title: string;
  zoneName?: string;
  tooltipText?: string;
  onDelete: () => void;
  radio?: { groups: ReactNode[]; key: string };
  isExportable?: boolean;
  className?: string;
}

export const CommonModule: FunctionComponent<ModuleProps> = props => {
  const { children, className = "", Icon, iconSize, isExportable = false, onDelete, radio, title, tooltipText, zoneName } = props;

  return (
    <div className={classnames("module-container", { "export-0": isExportable })}>
      <ModuleTitle Icon={Icon} iconSize={iconSize} title={title} tooltipText={tooltipText} zoneName={zoneName} />
      {radio && (
        <div className="module-header ignore-export">
          {radio.groups.map((radioGroup, index) => (
            <div key={`${radio.key}-radio-${index}`} className="module-header-line">
              {radioGroup}
            </div>
          ))}
        </div>
      )}
      <ModuleBody onDelete={onDelete} className={classnames("common-module-background", className)}>
        {children}
      </ModuleBody>
    </div>
  );
};
