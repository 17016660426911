import { Omit, UserLanguage } from "@technis/shared";
import { TimePicker } from "antd";
import moment from "antd/node_modules/moment";
import React, { FunctionComponent, useRef, useState } from "react";
import { Field } from "react-final-form";

import { useSelector } from "../../../hooks";
import { SupportedLanguages, translate } from "../../../lang/i18n";
import { translation } from "../../../lang/translation";
import { getTimeFormat, getTimeFormatStyle, TimeFormats } from "../../../utils/time";
import { isNumberUndefined } from "../../../utils/utils";

const { selectTime } = translation.analytics.modules.aggregationsSettings.fieldsSelectors;

interface TimePickerProps {
  valueInMinutes: number;
  id: string;
  onChange: (time: number) => void;
  disabledMinutes?: (selectedHour: number) => number[];
  disabledHours?: () => number[];
  label?: string;
  error?: string;
  placeholder?: string;
  isMandatory?: boolean;
  onOpenChange?: () => void;
  containerClassName?: string;
}

interface FormProps extends Omit<TimePickerProps, "error" | "onOpenChange"> {
  formPath: string;
}

export const CustomTimePicker: FunctionComponent<TimePickerProps> = props => {
  const { label, containerClassName = "", isMandatory, placeholder = translate(selectTime), onChange, onOpenChange, valueInMinutes, error, disabledMinutes, disabledHours } = props;
  const timeFormat = useSelector(state => state.userOptions.timeFormat);
  const language = useSelector(state => state.userOptions.language);
  const useTimeFormatFromLanguage = useSelector(state => state.userOptions.useTimeFormatFromLanguage);

  const is12Hours = () => {
    if (useTimeFormatFromLanguage) {
      const langMap: Record<SupportedLanguages | UserLanguage, boolean> = {
        [SupportedLanguages.ENGLISH]: true,
        [SupportedLanguages.FRENCH]: false,
        [UserLanguage.EN]: true,
        [UserLanguage.FR]: false,
        [UserLanguage.ES]: false,
        [UserLanguage.DE]: false,
        [UserLanguage.AR]: false,
        [UserLanguage.IT]: false,
      };

      return language && langMap[language];
    }
    return !timeFormat || timeFormat === TimeFormats.ENGLISH_FORMAT;
  };
  const currentValue = !isNumberUndefined(valueInMinutes) ? moment(new Date(Date.now()).setHours(Math.floor(valueInMinutes / 60), valueInMinutes % 60, 0)) : undefined;
  const ref = useRef(null);
  return (
    <div className={`modal-timepicker-container ${getTimeFormatStyle(timeFormat, language, useTimeFormatFromLanguage)} ${containerClassName}`} ref={ref}>
      {!!label && (
        <div className="modal-input-label">
          {label}
          {isMandatory && <span className="mandatory-star">*</span>}
        </div>
      )}
      <TimePicker
        format={getTimeFormat(timeFormat, language, useTimeFormatFromLanguage)}
        value={currentValue}
        placeholder={placeholder}
        use12Hours={is12Hours()}
        getPopupContainer={() => ref.current || document.createElement("div")}
        onChange={data => onChange(data.hours() * 60 + data.minutes())}
        onOpenChange={onOpenChange}
        disabledMinutes={disabledMinutes}
        disabledHours={disabledHours}
      />
      <div className="modal-input-line" />
      {error ? <div className="validation-error">{error}</div> : null}
    </div>
  );
};

export const FormTimePicker: FunctionComponent<FormProps> = props => {
  const [touched, setTouched] = useState<boolean>(false);

  const { label, containerClassName = "", isMandatory, placeholder = translate(selectTime), onChange, id, valueInMinutes, formPath } = props;

  return (
    <Field
      name={formPath}
      type="dropdown"
      render={props => (
        <CustomTimePicker
          valueInMinutes={valueInMinutes}
          id={id}
          onChange={time => {
            props.input.onChange(time);
            onChange && onChange(time);
          }}
          onOpenChange={() => setTouched(true)}
          label={label}
          placeholder={placeholder}
          isMandatory={isMandatory}
          containerClassName={containerClassName}
          error={touched ? props.meta.error : undefined}
        />
      )}
      label={label}
    />
  );
};
