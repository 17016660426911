import * as moment from "moment";
import React, { FunctionComponent } from "react";

import { DateRange } from "../../utils/aggregate";
import { ZoneSetting } from "../common/Header";
import { ExportErrorModuleTooBig } from "./ExportErrorMessage";
import { ExportLoading } from "./ExportLoading";
import { FrontPage } from "./FrontPage";
import { PdfFooter } from "./PdfFooter";
import { PdfHeader } from "./PdfHeader";

const SUPPORT_EMAIL = "info@technis.com";

interface ExportProps {
  exportDate: moment.Moment;
  eventName: string;
  zoneSettings: ZoneSetting[];
  dateRange: DateRange;
  finishExport: () => void;
}

export const Export: FunctionComponent<ExportProps> = props => {
  const { dateRange, eventName, exportDate, finishExport, zoneSettings } = props;
  return (
    <div className="export-material">
      <ExportLoading exportFeedBack={finishExport} exportDate={exportDate} eventName={eventName} />
      <PdfFooter email={SUPPORT_EMAIL} id="pdf-footer" />
      <FrontPage eventName={eventName} zoneName={zoneSettings[0].name} dateRange={dateRange} email={SUPPORT_EMAIL} exportDate={exportDate} />
      <PdfHeader eventName={eventName} zoneName={zoneSettings[0].name} dateRange={dateRange} />
      <ExportErrorModuleTooBig />
    </div>
  );
};
