import React, { FunctionComponent, ReactNode } from "react";

import { Direction } from "../../charts";
import { HorizontalHeader } from "./HorizontalHeader";

interface HorizontalBarChartProps {
  barHeader: string;
  children: ReactNode;
  labelHeader: string;
  labelSort?: { onClick: () => void; direction?: Direction; focus: boolean };
  barSort?: { onClick: () => void; direction?: Direction; focus: boolean };
  barChartClassName?: string;
  headerClassName?: string;
  labelClassName?: string;
}

export const HorizontalBarChart: FunctionComponent<HorizontalBarChartProps> = props => {
  const { barChartClassName = "", barHeader, barSort, children, headerClassName = "", labelClassName = "", labelHeader, labelSort } = props;
  return (
    <div className={`custom-bar-chart ${barChartClassName}`}>
      <HorizontalHeader barHeader={barHeader} labelHeader={labelHeader} headerClassName={headerClassName} labelClassName={labelClassName} labelSort={labelSort} barSort={barSort} />
      <div className="horizontal-line line-color" />
      {children}
    </div>
  );
};
