import { AggregationType } from "../../components/charts/AffluenceChart";
import { AggregatedKpiAtmosphere, AggregatedKpiCounting } from "../../utils/aggregate";

export interface AggregatedKpiCountingWithName extends AggregatedKpiCounting {
  zoneName?: string;
  passageName?: string;
}

export interface AggregatedKpiAtmosphereWithName extends AggregatedKpiAtmosphere {
  zoneName?: string;
}

export enum ModuleKind {
  AFFLUENCE_MODULE,
  INSIDE_MODULE,
  QUANTILE_MODULE,
  MOST_USED_ZONES_MODULE,
  MOST_USED_PASSAGES_MODULE,
  OVERVIEW_MODULE,
  GENERAL_INFORMATION,
  ATMOSPHERE_GRAPH_MODULE,
  SUMMARY_AIR_MODULE,
  ERROR_LOG_MODULE,
  WAITING_TIME_MODULE,
}

export interface Module {
  moduleKind: ModuleKind;
  aggregation?: AggregationType;
}

export enum ShiftState {
  DEFAULT = "default",
  SHIFTED = "shifted",
}
