import React, { FunctionComponent, useEffect, useRef } from "react";

export type OutClickerProps = {
  containerClassName?: string;
  onClick: () => void;
  children: React.ReactNode;
  stopClickPropagation?: boolean;
};

export const OutClicker: FunctionComponent<OutClickerProps> = (props: OutClickerProps) => {
  const { children, containerClassName, onClick, stopClickPropagation } = props;

  const wrapperRef = useRef<HTMLDivElement>(null);

  const handleClickOutside = (event: DocumentEventMap["mouseup"]) => {
    if (stopClickPropagation) {
      event.preventDefault();
      event.stopPropagation();
    }
    if (wrapperRef && !wrapperRef.current?.contains(event.target as Node)) {
      onClick();
    }
  };

  useEffect(() => {
    document.addEventListener("mouseup", handleClickOutside);
    return () => document.removeEventListener("mouseup", handleClickOutside);
  });

  return (
    <div ref={wrapperRef} className={containerClassName}>
      {children}
    </div>
  );
};
