import { NeededOnly, TrackingAction, TrackingUserActionTypes, User } from "@technis/shared";
import { debounce } from "lodash";
import React, { ComponentType } from "react";

import { SegmentTracking } from "../namespaces/SegmentTracking";
import { TRACKING_DEBOUNCE_TIME } from "../utils/constants";

export interface WithTrackingProps {
  trackAction: (type: TrackingUserActionTypes, payload: Pick<TrackingAction, "category" | "name">) => void;
  trackViewActivity: (topic: string, name: string) => void;
  trackClick: (props: Pick<TrackingAction, "category" | "name">) => void;
  trackToggle: (props: Pick<TrackingAction, "category" | "name">) => void;
  trackTyping: (props: Pick<TrackingAction, "category" | "name">) => void;
  trackCheckbox: (props: Pick<TrackingAction, "category" | "name">) => void;
  setTrackingUser: (user?: NeededOnly<User, "id" | "organizationIds"> | null) => void;
}

export function withTracking<P>(WrappedComponent: ComponentType<P & WithTrackingProps>) {
  const trackAction = (type: TrackingUserActionTypes, payload: Pick<TrackingAction, "category" | "name">) => {
    SegmentTracking.saveAction({ type, ...payload });
  };
  const trackViewActivity = (topic: string, name: string) => {
    SegmentTracking.trackViewActivity(name, topic);
  };

  const trackClick = (props: Pick<TrackingAction, "category" | "name">) => trackAction(TrackingUserActionTypes.CLICK, props);
  const trackTyping = debounce((props: Pick<TrackingAction, "category" | "name">) => trackAction(TrackingUserActionTypes.TYPING, props), TRACKING_DEBOUNCE_TIME);
  const trackToggle = (props: Pick<TrackingAction, "category" | "name">) => trackAction(TrackingUserActionTypes.TOGGLE, props);
  const trackCheckbox = (props: Pick<TrackingAction, "category" | "name">) => trackAction(TrackingUserActionTypes.CHECKBOX, props);

  const setTrackingUser: WithTrackingProps["setTrackingUser"] = user => {
    if (user) {
      SegmentTracking.setUser(user.id, {
        company: {
          id: user.organizationIds[0],
        },
      });
    }
  };

  return (props: P) => (
    <WrappedComponent
      {...props}
      trackViewActivity={trackViewActivity}
      trackAction={trackAction}
      trackClick={trackClick}
      trackToggle={trackToggle}
      trackTyping={trackTyping}
      trackCheckbox={trackCheckbox}
      setTrackingUser={setTrackingUser}
    />
  );
}
