/* eslint-disable @typescript-eslint/ban-types */
import { UserLanguage } from "@technis/shared";
import arEG from "antd/lib/locale-provider/ar_EG";
import deDE from "antd/lib/locale-provider/de_DE";
import enUS from "antd/lib/locale-provider/en_US";
import esES from "antd/lib/locale-provider/es_ES";
import frFR from "antd/lib/locale-provider/fr_FR";
import itIT from "antd/lib/locale-provider/it_IT";
import * as I18n from "i18n-js";
import isEmpty from "lodash/isEmpty";
import merge from "lodash/merge";
import moment from "moment";

import de from "./de.json";
import en from "./en.json";
import es from "./es.json";
import fr from "./fr.json";
import it from "./it.json";

export const i18n = I18n;
export const translate = i18n.t;

i18n.locale = "en";
i18n.fallbacks = false;
i18n.translations = { en, fr, de, it, es };

const LANGUAGES = {
  en,
  fr,
  de,
  it,
  es,
};

export enum SupportedLanguages {
  ENGLISH = "en",
  FRENCH = "fr",
}

type EnLocale = typeof enUS;
type FrLocale = typeof frFR;
type ArLocale = typeof arEG;
type DeLocale = typeof deDE;
type EsLocale = typeof esES;
type ItLocale = typeof itIT;

export type SupportedLocales = EnLocale | FrLocale | ArLocale | DeLocale | EsLocale | ItLocale;

export function setLanguage(language?: SupportedLanguages | UserLanguage) {
  if (language) {
    const currentLanguage = i18n.translations[language as string] ? language : SupportedLanguages.ENGLISH;
    i18n.locale = currentLanguage;
    moment.locale(currentLanguage);
  }
}

export const setAntLocale = (language?: SupportedLanguages | UserLanguage) => {
  const languageMap: Record<SupportedLanguages | UserLanguage, SupportedLocales> = {
    [SupportedLanguages.ENGLISH]: enUS,
    [UserLanguage.EN]: enUS,
    [SupportedLanguages.FRENCH]: frFR,
    [UserLanguage.FR]: frFR,
    [UserLanguage.AR]: arEG,
    [UserLanguage.DE]: deDE,
    [UserLanguage.ES]: esES,
    [UserLanguage.IT]: itIT,
  };

  if (!language) return languageMap.en;
  return languageMap[language];
};

i18n.pluralization["pl"] = (count: number) => {
  const key = count === 1 ? "1" : count >= 2 && count <= 4 ? "2,3,4" : "many";
  return [key];
};

export const setTranslations = (translations: object | null) => {
  if (!isEmpty(translations)) {
    const mergedTranslations = Object.entries(translations!).reduce((acc, [language, translation]: [string, object]) => {
      const combined = merge(LANGUAGES[language as keyof typeof LANGUAGES] || {}, translation || {});

      return {
        ...acc,
        ...(!isEmpty(combined) && {
          [language]: combined,
        }),
      };
    }, {});

    i18n.translations = {
      ...i18n.translations,
      ...mergedTranslations,
    };
  }
};
