import { Tooltip } from "antd";
import React, { FunctionComponent, useRef } from "react";
import { IconType } from "react-icons";
import { IoIosHelpCircleOutline } from "react-icons/io";

import { isNavigatorFirefox } from "../../../index";
import { Colors } from "../../../utils/colors";
import { ParsedText } from "../../lang/ParsedText";
import { formItemTooltipParser } from "../../lang/Parsers";
import { GradientIcon } from "../GradientIcon";
import { getTheme } from "../Theme";

interface ModuleTitleProps {
  Icon: IconType;
  iconSize: number;
  title: string;
  tooltipText?: string;
  zoneName?: string;
}

interface ModuleTittleTooltipProps {
  tooltipText: string;
  colors?: Colors[];
}

const ModuleTittleTooltip: FunctionComponent<ModuleTittleTooltipProps> = props => {
  const { tooltipText, colors = [Colors.ORANGE] } = props;
  const id = useRef(null);
  const parsedText = <ParsedText parsers={[formItemTooltipParser()]}>{tooltipText}</ParsedText>;

  return (
    <div className="module-tooltip" ref={id}>
      <Tooltip title={parsedText} getPopupContainer={() => id.current || document.createElement("div")} placement={"topLeft"}>
        <div>
          <GradientIcon iconComponent={<IoIosHelpCircleOutline />} gradientColors={colors} startXCoordinate={-5} />
        </div>
      </Tooltip>
    </div>
  );
};

export const ModuleTitle: FunctionComponent<ModuleTitleProps> = props => {
  const { Icon, iconSize, title, tooltipText, zoneName } = props;
  const { iconColor, tooltipIconGradient } = getTheme();

  return (
    <div className="module-title export-1">
      <div className="module-title-icon">
        {isNavigatorFirefox ? (
          <Icon size={iconSize} color={iconColor[0]} />
        ) : (
          <GradientIcon iconComponent={<Icon size={iconSize} />} gradientColors={iconColor} startXCoordinate={50} startYCoordinate={0} endXCoordinate={50} endYCoordinate={100} />
        )}
      </div>
      <div className="module-title-text">
        {title}
        {!!zoneName && (
          <div className="zone-title">
            {" - "}
            <strong>
              <em>{zoneName}</em>
            </strong>
          </div>
        )}
      </div>
      {!!tooltipText && (
        <div className="module-title-tooltip ignore-export">
          <ModuleTittleTooltip tooltipText={tooltipText} colors={tooltipIconGradient} />
        </div>
      )}
    </div>
  );
};
