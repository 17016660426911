import { Indexable } from "@technis/shared";
import moment from "moment";
import React, { FunctionComponent, useEffect, useState } from "react";
import { Bar, BarChart, CartesianGrid, Cell, ResponsiveContainer, XAxis, YAxis } from "recharts";

import { Dates, UI } from "../../constants";
import { useSelector } from "../../hooks";
import { translate } from "../../lang/i18n";
import { translation } from "../../lang/translation";
import { AggregatedKpiCountingWithName } from "../../pages/AnalyticsHomePage";
import { DateRange, Granularity } from "../../utils/aggregate";
import { CHART_LINE_TRANSPARENCY, Colors, lightenDarkenColor, transparentize } from "../../utils/colors";
import { getInterval, getTicks } from "../../utils/time";
import {
  checkKpiData,
  clone,
  createThresholds,
  getCardinalDay,
  getDayLocale,
  getFontSize,
  getTickPosition,
  getWeek,
  isRangeSmallerThanPeriod,
  tickFormater,
} from "../../utils/utils";
import { GradientLegend, LegendItem } from "../common/custom-chart/ChartLegend";
import { getTheme, ThemeVariant, useTheme } from "../common/Theme";
import { ChartError, XRenderChartTick, YRenderChartTick } from "./index";

const { MILLISECONDS_IN_DAY, MILLISECONDS_IN_MINUTE, MINUTES_IN_DAY, MINUTES_IN_WEEK } = Dates;
const { CHART_BAR_SIZE_QUANTILE } = UI;

const DEFAULT_COLOR = Colors.AQUA;
const COLOR_STEP = 40;

const ONE_DAY_CHART_HEIGHT = 130;
const SINGLE_LINE_CHART_HEIGHT = 35;
const MUTIPLE_DAY_VHART_MIN_HEIGHT = 180;

export interface QuantileChartProps {
  kpiData: AggregatedKpiCountingWithName;
  dateRange: DateRange;
}

interface DataQuantile {
  name: string;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}

interface State {
  error?: string;
}

export const QuantileChart: FunctionComponent<QuantileChartProps> = props => {
  const { dateRange, kpiData } = props;

  const [state, setState] = useState<State>({
    error: checkKpiData(kpiData, ["dateBegins", "dateEnds", "inside"]),
  });

  const { error } = state;
  const { dateBegins, dateEnds, granularity, inside } = kpiData;

  const quantileTickFormater = tickFormater();

  const insideClone = clone(inside);
  const { themeVariant } = useTheme();

  const isExporting = useSelector(state => state.app.isExporting);
  const timeFormat = useSelector(state => state.userOptions.timeFormat);
  const language = useSelector(state => state.userOptions.language);
  const useTimeFormatFromLanguage = useSelector(state => state.userOptions.useTimeFormatFromLanguage);
  const { font } = getTheme(isExporting ? ThemeVariant.LIGHT : themeVariant);
  const fontSize = getFontSize(isExporting);
  const tickPosition = getTickPosition(isExporting);
  const max = kpiData?.inside?.max() || 0;
  const thresholds = createThresholds(max);

  useEffect(() => {
    setState({ ...state, error: checkKpiData(kpiData, ["dateBegins", "dateEnds", "inside"]) });
  }, [kpiData]);

  const insideLengthMinute = dateBegins?.length && dateEnds?.length ? Math.floor((dateEnds[dateEnds.length - 1] - dateBegins[0]) / MILLISECONDS_IN_MINUTE) : 0;
  const fillerLength = insideClone ? insideLengthMinute - insideClone.flat().length : 0;
  if (insideClone?.length && fillerLength > 0) insideClone[insideClone.length - 1].push(...new Array(fillerLength + 1).fill(0));

  // eslint-disable-next-line sonarjs/cognitive-complexity
  const chartData = () => {
    if (!dateBegins?.length || !insideClone?.length || !dateEnds?.length) {
      return [];
    }
    const thresholdsCopy = [...thresholds];
    thresholdsCopy.unshift(0);
    const dataQuantile: DataQuantile[] = [];
    let maxProperty = 0;

    insideClone.forEach((day, insideIndex) => {
      if (dateBegins) {
        let counter = 0;

        const { quantile } = day.reduce(
          (acc, curr, index) => {
            let actualThreshold = 0;
            thresholdsCopy.forEach(threshold => {
              if (threshold <= curr) {
                actualThreshold = threshold;
              }
            });
            if (actualThreshold !== acc.lastThreshold) {
              return {
                quantile: { ...acc.quantile, ["cell" + counter++]: { threshold: `>${acc.lastThreshold}`, time: index - acc.lastTimeValue } },
                lastThreshold: actualThreshold,
                lastTimeValue: index,
              };
            }
            return acc;
          },
          { quantile: {} as DataQuantile, lastThreshold: 0, lastTimeValue: 0 },
        );

        switch (granularity) {
          case Granularity.CONTINUOUS_DAYS:
            const day = insideIndex < dateBegins.length ? getDayLocale(dateBegins[insideIndex]).substr(0, 3) : "";
            const date = insideIndex < dateBegins.length ? getCardinalDay(dateBegins[insideIndex]) : "";
            dataQuantile.push({ ...quantile, name: `${day} ${date}` });
            break;
          case Granularity.CONTINUOUS_WEEKS:
            dataQuantile.push({ name: insideIndex < dateBegins.length ? getWeek(dateBegins[insideIndex]) : "" });
            break;
          case Granularity.CONTINUOUS_MONTHS:
          case Granularity.CONTINUOUS_YEARS:
            break;
          case Granularity.DISCONTINUOUS_DAYS:
          case Granularity.DISCONTINUOUS_WORKING_DAYS:
          case Granularity.DISCONTINUOUS_WEEKEND_DAYS:
            dataQuantile.push({ name: insideIndex < dateBegins.length ? getDayLocale(dateBegins[insideIndex]) : "" });
            break;
        }
      }
      maxProperty = Math.max(Object.keys(dataQuantile[insideIndex]).length - 1, maxProperty);
    });

    if (!(dateBegins.length <= 1 && dateEnds[0] - dateBegins[0] < MILLISECONDS_IN_DAY)) {
      dataQuantile
        .filter(dq => Object.keys(dq).length - 1 < maxProperty)
        .forEach(dq => {
          const objectSize = Object.keys(dq).length - 1;
          for (let j = maxProperty - 1; j >= objectSize; j--) {
            dq["cell" + j] = { threshold: ">0", time: 0 };
          }
        });
    }
    return dataQuantile;
  };

  const getVal = (obj: DataQuantile, key: string) => obj[key]?.time;

  const createDomainEnd = () => {
    if (insideClone?.length && dateBegins?.length && dateEnds?.length && dateBegins.length <= 1 && dateEnds[0] - dateBegins[0] < MILLISECONDS_IN_DAY) {
      return insideClone.flat().length;
    }
    switch (granularity) {
      case Granularity.CONTINUOUS_WEEKS:
        return MINUTES_IN_WEEK;
      case Granularity.CONTINUOUS_DAYS:
      case Granularity.DISCONTINUOUS_DAYS:
      case Granularity.DISCONTINUOUS_WORKING_DAYS:
      case Granularity.DISCONTINUOUS_WEEKEND_DAYS:
        return MINUTES_IN_DAY;
      default:
        return MINUTES_IN_DAY;
    }
  };

  const colorConfig: Indexable<Colors> = { ">0": Colors.PURE_BLACK };
  const legend: LegendItem[] = [];
  thresholds
    .slice()
    .reverse()
    .forEach((threshold: number, index: number) => {
      const color = lightenDarkenColor(DEFAULT_COLOR, (thresholds.length - index - 1) * -COLOR_STEP);
      colorConfig[`>${threshold}`] = color;
      legend.push({ color, text: `>${threshold}` });
    });
  legend.reverse();
  const data = chartData();

  const size = inside ? inside.length : 1;
  const multipleDaysChartHeight = SINGLE_LINE_CHART_HEIGHT * size < MUTIPLE_DAY_VHART_MIN_HEIGHT ? MUTIPLE_DAY_VHART_MIN_HEIGHT : SINGLE_LINE_CHART_HEIGHT * size;
  const chartHeight = size > 1 ? multipleDaysChartHeight : ONE_DAY_CHART_HEIGHT;

  const leftValue = isRangeSmallerThanPeriod(kpiData.dateBegins.length, dateRange) ? moment(dateRange.dateBegin).diff(moment(kpiData.dateBegins[0]), "minute") : 0;
  const rightValue = isRangeSmallerThanPeriod(kpiData.dateBegins.length, dateRange) ? moment(dateRange.dateEnd).diff(moment(kpiData.dateBegins[0]), "minute") : createDomainEnd();

  if (error) {
    // TODO: beautify error when data missing
    return <ChartError error={error} />;
  }

  if (!insideClone || !dateBegins || !dateEnds) {
    return <ChartError error={translate(translation.errors.missingKpiProperty)} />;
  }

  return (
    <div className="module-chart quantile-chart split-export">
      <ResponsiveContainer width="100%" height={chartHeight + tickPosition}>
        <BarChart data={data} layout="vertical" margin={{ top: 35, right: 20, bottom: -10 + tickPosition, left: 20 }}>
          <CartesianGrid stroke={transparentize(font, CHART_LINE_TRANSPARENCY)} />
          <XAxis
            allowDataOverflow={true}
            type="number"
            axisLine={false}
            domain={[leftValue, rightValue]}
            ticks={getTicks(rightValue - leftValue, getInterval(rightValue - leftValue, granularity), {
              startTick: leftValue,
              endTick: rightValue,
            })}
            tickMargin={12}
            tick={tickObject => (
              <XRenderChartTick
                tickObject={tickObject}
                granularity={granularity}
                dateBegin={dateBegins[0]}
                fontColor={font}
                fontSize={fontSize}
                tickPosition={tickPosition}
                timeFormat={timeFormat}
                language={language}
                useTimeFormatFromLanguage={useTimeFormatFromLanguage}
              />
            )}
            tickFormatter={quantileTickFormater}
            stroke={transparentize(font, CHART_LINE_TRANSPARENCY)}
            scale="time"
          />
          <YAxis
            type="category"
            dataKey="name"
            tick={tickObject => <YRenderChartTick tickObject={tickObject} fontColor={font} fontSize={fontSize} />}
            interval={0}
            axisLine={false}
            stroke={transparentize(font, CHART_LINE_TRANSPARENCY)}
          />
          {Object.keys(data[0])
            .filter(key => key !== "name")
            .map(key => (
              <Bar isAnimationActive={!isExporting} key={key} barSize={CHART_BAR_SIZE_QUANTILE} dataKey={val => getVal(val, key)} stackId="x">
                {data.map((entry, index) => (
                  <Cell key={`day-${index}`} fill={colorConfig[entry[key].threshold] !== Colors.PURE_BLACK ? colorConfig[entry[key].threshold] : "transparent"} />
                ))}
              </Bar>
            ))}
        </BarChart>
      </ResponsiveContainer>
      <GradientLegend legendItems={legend} />
    </div>
  );
};
