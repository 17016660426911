export const TRACKING_DEBOUNCE_TIME = 800;
export const TrackingLogViewActivityTopic = {
  home: "Home",
  event: "Event",
  analytics: "Analytics",
};

export const TrackingLogViewActivityName = {
  analysis: "Analysis",
  error: "Error",
  analytics: "Analytics",
};


export enum FetchPolicies {
  NETWORK_ONLY = "network-only",
  CACHE_FIRST = "cache-first",
  CACHE_ONLY = "cache-only",
  NO_CACHE = "no-cache",
  STANDBY = "standby",
  CACHE_AND_NETWORK = "cache-and-network",
}

export enum ErrorPolicies {
  ALL = "all",
  NONE = "none",
  IGNORE = "ignore",
}
