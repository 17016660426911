import React, { FunctionComponent } from "react";
import { AiOutlineClockCircle } from "react-icons/ai";

import { UI } from "../../constants";
import { translate } from "../../lang/i18n";
import { translation } from "../../lang/translation";

const { loading } = translation.common;

const { MODULE_LOADING_ICON_SIZE } = UI;

export const LoadingModule: FunctionComponent = () => (
  <div className="module-container">
    <div className="module-background">
      <div className="module-loading">
        <div className="module-loading-icon">
          <AiOutlineClockCircle size={MODULE_LOADING_ICON_SIZE} />
        </div>
        <div className="module-loading-text">{translate(loading)} ...</div>
      </div>
    </div>
  </div>
);

export const LoadingHomePage: FunctionComponent = () => (
  <div className="home-page-loading">
    <div className="home-page-loading-icon">
      <AiOutlineClockCircle size={MODULE_LOADING_ICON_SIZE} />
    </div>
    <div className="home-page-loading-text">{translate(loading)} ...</div>
  </div>
);

export const LoadingChart: FunctionComponent = () => (
  <div className="chart-loading">
    <div className="chart-loading-icon">
      <AiOutlineClockCircle size={MODULE_LOADING_ICON_SIZE} />
    </div>
    <div className="chart-loading-text">{translate(loading)} ...</div>
  </div>
);
