import { calculateWaitingTime, isDefined } from "@technis/shared";
import { formatDurationToHMS } from "@technis/shared/dist/utils/generic";
import * as moment from "moment";
import React, { FunctionComponent, useEffect, useState } from "react";
// eslint-disable-next-line import/named
import { Bar, BarChart, CartesianGrid, Cell, ResponsiveContainer, Tooltip, TooltipProps, XAxis, YAxis } from "recharts";

import { UI } from "../../constants";
import {
  DAY_HOUR_FORMAT,
  HOUR_FORMAT,
  HOURS_IN_DAY,
  MILLISECONDS_IN_DAY,
  MILLISECONDS_IN_HOUR,
  MILLISECONDS_IN_MINUTE,
  MINUTES_IN_HOUR,
  SECONDS_IN_HOUR,
  SECONDS_IN_MINUTE,
} from "../../constants/date";
import { CHART_HEIGHT_AFFLUENCE, CHART_X_AXIS_TICK_ANGLE } from "../../constants/ui";
import { useSelector } from "../../hooks";
import { translate } from "../../lang/i18n";
import { translation } from "../../lang/translation";
import { AggregatedKpiCountingWithName } from "../../pages/AnalyticsHomePage";
import { DateRange } from "../../utils/aggregate";
import { CHART_LINE_TRANSPARENCY, Colors, transparentize } from "../../utils/colors";
import { getHourMinSec } from "../../utils/time";
import { getPeriodNames } from "../../utils/translation";
import { checkKpiData, floor, getFontSize, getTickPosition, getTimeName } from "../../utils/utils";
import { ChartTooltip } from "../common/custom-chart/ChartTooltip";
import { getTheme, ThemeVariant, useTheme } from "../common/Theme";
import { ChartError, YRenderChartTick } from "./index";

const { CHART_BAR_SIZE_AFFLUENCE } = UI;

const { noData } = translation.common;
const { waitingTime: waitingTimeTooltip } = translation.analytics.modules.labels;

const BAR_COLOR = Colors.LIGHT_BLUE;

interface WaitingTimeChartProps {
  kpiData: AggregatedKpiCountingWithName;
  flowRate?: number;
  dateRange: DateRange;
}

interface State {
  data: Array<{ time: string; waitingTime: number }>;
  currentTooltipIndex?: number;
  error?: string;
}

export const WaitingTimeChart: FunctionComponent<WaitingTimeChartProps> = props => {
  const { dateRange, flowRate, kpiData } = props;

  const [state, setState] = useState<State>({
    data: [],
    currentTooltipIndex: undefined,
    error: checkKpiData(kpiData, ["dateBegins", "dateEnds", "affluenceMinIn", "affluenceMinOut"]),
  });
  const { currentTooltipIndex, error } = state;
  const { dateBegins, dateEnds, granularity, inside, isShifting, shift } = kpiData;

  const { themeVariant } = useTheme();
  const isExporting = useSelector(state => state.app.isExporting);
  const { font } = getTheme(isExporting ? ThemeVariant.LIGHT : themeVariant);
  const fontSize = getFontSize(isExporting);
  const tickPosition = getTickPosition(isExporting);

  useEffect(() => {
    setState({ ...state, error: checkKpiData(kpiData, ["dateBegins", "dateEnds", "affluenceMinIn", "affluenceMinOut", "inside"]) });
  }, [props]);

  const isHoursRound = (time: number) => {
    const date = new Date(time);
    date.setMinutes(0, 0, 0);
    return date.getTime() === time;
  };

  const getFormat = (index: number, isShifting: boolean, length: number) => {
    if (isShifting && (index === 0 || index === length || index === length / 2)) return DAY_HOUR_FORMAT;
    return HOUR_FORMAT;
  };

  const getWaitingTimeData = () => {
    if (!kpiData || !dateBegins || !dateEnds || !inside) {
      return [];
    }

    const shouldShift = isShifting && shift > 0;

    if (dateRange.dateEnd - dateRange.dateBegin > MILLISECONDS_IN_DAY) {
      return inside.map((array, index) => ({
        time: getTimeName(dateBegins[index] || 0, getPeriodNames(kpiData.granularity), granularity),
        waitingTime: calculateWaitingTime(array, flowRate || 1).sum() / Math.ceil((dateEnds[index] - dateBegins[index]) / MILLISECONDS_IN_MINUTE),
      }));
    }

    const startTime = isHoursRound(dateRange.dateBegin) ? dateRange.dateBegin : new Date(dateRange.dateBegin).setMinutes(0, 0, 0);

    return [
      ...(shouldShift ? inside.flat().slice(0, inside[0].length - shift) : []),
      ...new Array(Math.floor((dateRange.dateBegin - startTime) / MILLISECONDS_IN_MINUTE)).fill(undefined),
      ...inside.flat().filter((_, index) => dateBegins[0] + index * MILLISECONDS_IN_MINUTE >= dateRange.dateBegin),
    ]
      .chunks(MINUTES_IN_HOUR)
      .filter((_, index) => index < (shouldShift ? HOURS_IN_DAY * 2 : HOURS_IN_DAY))
      .map((array, index, day) => {
        // TODO:  shift is not necessary divisible by MINUTES_IN_HOUR
        const definedValues = array.filter(value => isDefined(value) && value >= 0);
        return {
          time: moment(startTime + index * MILLISECONDS_IN_HOUR + shift * MILLISECONDS_IN_MINUTE).format(getFormat(index + shift / MINUTES_IN_HOUR, shouldShift, day.length)),
          waitingTime: definedValues.length ? calculateWaitingTime(definedValues, flowRate || 1).sum() / definedValues.length : 0,
        };
      });
  };

  useEffect(() => {
    setState({ ...state, data: getWaitingTimeData(), error: checkKpiData(kpiData, ["dateBegins", "dateEnds", "affluenceMinIn", "affluenceMinOut"]) });
  }, [props]);

  const xRenderChartTick = (tickObject: { index: number; x: number; y: number; payload: { value: string } }) => (
    <g transform={`translate(${tickObject.x},${tickObject.y})`}>
      <text fontSize={fontSize} x={0} y={-5} fill={`${font}`} transform={`rotate(${CHART_X_AXIS_TICK_ANGLE})`}>
        <tspan textAnchor="end" x="0" dy={tickPosition}>
          {tickObject.payload.value}
        </tspan>
      </text>
    </g>
  );

  const renderChartTooltip = (tooltipObject: TooltipProps<number, string>) => {
    const payload = tooltipObject?.payload?.[0]?.payload;

    if (!payload || payload.waitingTime < 0 || isNaN(payload.waitingTime)) {
      return null;
    }

    const time = isDefined(payload.waitingTime) ? formatDurationToHMS(payload.waitingTime) : noData;
    return (
      <ChartTooltip
        title={payload.time}
        tooltipData={[
          {
            label: translate(waitingTimeTooltip),
            value: time,
            color: transparentize(BAR_COLOR, 0.02),
          },
        ]}
      />
    );
  };

  const renderChartBar = (
    dataSet: {
      time: string;
      waitingTime: number;
    }[],
    key: string,
    color: Colors,
    isAnimationActive: boolean,
  ) => (
    <Bar isAnimationActive={isAnimationActive} dataKey={key} barSize={CHART_BAR_SIZE_AFFLUENCE}>
      {dataSet.map((_, index: number) => (
        <Cell
          key={`cell-${index}`}
          fill={color}
          stroke={currentTooltipIndex === index ? Colors.WHITE : color}
          strokeOpacity={currentTooltipIndex === index ? 0.2 : 0}
          strokeWidth={currentTooltipIndex === index ? 3 : 0}
        />
      ))}
    </Bar>
  );

  const getTopDomain = (
    data: {
      time: string;
      waitingTime: number;
    }[],
  ) => {
    const maxWaitingTime = data.map(object => object.waitingTime).max();
    const { hours, minutes } = getHourMinSec(maxWaitingTime);
    if (hours > 0) return (hours + 1) * SECONDS_IN_HOUR;
    if (minutes > 0) return (minutes + 1) * SECONDS_IN_MINUTE;
    return SECONDS_IN_MINUTE;
  };

  const getTicks = (timeDomain: number) => {
    const getTickTimeInterval = () => {
      if (timeDomain > 0 && timeDomain <= SECONDS_IN_MINUTE) return SECONDS_IN_MINUTE / 6;
      if (timeDomain > 0 && timeDomain <= 30 * SECONDS_IN_MINUTE) return 2 * SECONDS_IN_MINUTE;
      if (timeDomain > 0 && timeDomain <= SECONDS_IN_HOUR) return SECONDS_IN_HOUR / 6;
      return SECONDS_IN_HOUR;
    };

    const tickTimeInterval = getTickTimeInterval();

    return Array.from({ length: floor(timeDomain / tickTimeInterval) + 1 }).map((_, index) => index * tickTimeInterval);
  };

  const onMouseEnter = (barChartState: { activeTooltipIndex: number }) => {
    setState({ ...state, currentTooltipIndex: barChartState?.activeTooltipIndex });
  };

  const data = getWaitingTimeData();

  const topDomain = getTopDomain(data);

  if (error) {
    return <ChartError error={error} />;
  }

  return (
    <>
      <div className="module-chart">
        <ResponsiveContainer width="100%" height={CHART_HEIGHT_AFFLUENCE + tickPosition}>
          <BarChart data={data} margin={{ top: 35, right: 20, bottom: 100 + tickPosition, left: 20 }} barGap={0} onMouseEnter={onMouseEnter}>
            <CartesianGrid vertical={false} stroke={transparentize(font, CHART_LINE_TRANSPARENCY)} />
            <XAxis dataKey="time" interval={0} tick={xRenderChartTick} stroke={transparentize(Colors.WHITE, CHART_LINE_TRANSPARENCY)} tickMargin={10} tickLine={false} />
            <YAxis
              domain={[0, topDomain]}
              ticks={getTicks(topDomain)}
              tickLine={false}
              axisLine={false}
              tick={tickObject => (
                <YRenderChartTick tickObject={{ ...tickObject, payload: { value: formatDurationToHMS(tickObject.payload.value) } }} fontColor={font} fontSize={fontSize} />
              )}
              height={200}
              scale="time"
              type="number"
            />
            <Tooltip cursor={false} content={renderChartTooltip} />
            {renderChartBar(data, "waitingTime", transparentize(BAR_COLOR, 0.02), !isExporting)}
          </BarChart>
        </ResponsiveContainer>
      </div>
    </>
  );
};
