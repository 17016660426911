import React, { FunctionComponent, ReactNode, useState } from "react";
import { IconType } from "react-icons";

import { DateRange } from "../../../utils/aggregate";
import { AggregationCreateAdvancedForm } from "../AggregationCreateAdvancedForm";
import { ModuleHeader } from "./ModuleHeader";

interface ChartModuleProps {
  children: ReactNode;
  dateRange: DateRange;
  iconSize: number;
  keyNumber: number;
  title: string;
  tooltipText: string;
  zoneName: string;
  Icon: IconType;
  onDelete: () => void;
  onUpdateDate: (dates: DateRange) => void;
  loading: boolean;
  datesLimits: {
    begin: number;
    end: number;
  };
  radio?: { groups: ReactNode[]; key: string };
  isExportable?: boolean;
}

export interface AdvancedAggregationSettings {
  dateBegin: number;
  dateEnd: number;
}

export const ChartModule: FunctionComponent<ChartModuleProps> = props => {
  const { children, dateRange, datesLimits, Icon, iconSize, isExportable, keyNumber, loading, onDelete, onUpdateDate, radio, title, tooltipText, zoneName } = props;

  const [moduleSetting, setModuleSettings] = useState<AdvancedAggregationSettings>({
    dateBegin: dateRange.dateBegin,
    dateEnd: dateRange.dateEnd,
  });

  const OnSubmitCustomDates = (callback: () => void) => {
    onUpdateDate({ dateBegin: moduleSetting.dateBegin, dateEnd: moduleSetting.dateEnd });
    callback();
  };

  const updateModuleSettings = (moduleDateBegin?: number, moduleDateEnd?: number) =>
    setModuleSettings({ dateBegin: moduleDateBegin || dateRange.dateBegin, dateEnd: moduleDateEnd || dateRange.dateEnd });

  const renderAdvancedMenuFields = () => (
    <AggregationCreateAdvancedForm datesLimits={datesLimits} moduleSetting={moduleSetting} onUpdateModuleSettings={updateModuleSettings} keyNumber={keyNumber} />
  );

  return (
    <ModuleHeader
      title={title}
      dates={dateRange}
      onUpdateDate={onUpdateDate}
      Icon={Icon}
      iconSize={iconSize}
      tooltipText={tooltipText}
      zoneName={zoneName}
      onDelete={onDelete}
      fields={renderAdvancedMenuFields()}
      onSubmit={OnSubmitCustomDates}
      initialValues={{ moduleDateBegin: dateRange.dateBegin, moduleDateEnd: dateRange.dateEnd }}
      isLoading={loading}
      datesLimits={datesLimits}
      radio={radio}
      isExportable={isExportable}
      moduleSetting={moduleSetting}
    >
      {children}
    </ModuleHeader>
  );
};
