import * as html2pdf from "html2pdf.js";
import jsPDF from "jspdf";

import { translate } from "../../lang/i18n";
import { translation } from "../../lang/translation";
import { Colors } from "../colors";

const SCALE = 4;
const PAGE_NUMBERS_COLOR = Colors.LIGHT_BLUE;
const PAGE_NUMBERS_FONT_SIZE = 10;
const PAGE_NUMBERS_RIGHT = 20.25;
const PAGE_NUMBERS_BOTTOM = 32;
const EXPORT_DATE_FORMAT = "D[-]MMM[-]Y[-]H[_]mm[_]ss";

export const multiChunkExport = async (elements: Element[], exportDate: moment.Moment, canvasHeight: number, canvasWidth: number, pageLength: number, eventName: string) => {
  const opt = {
    filename: `${translate(translation.common.analytics).toLowerCase()}-${eventName}-${exportDate.format(EXPORT_DATE_FORMAT)}.pdf`,
    html2canvas: { scale: SCALE, width: canvasWidth, height: canvasHeight - 1, logging: false },
  };

  let doc = html2pdf()
    .set(opt)
    .from(elements[0])
    .toPdf();

  elements.slice(1).forEach(element => {
    doc = doc
      .get("pdf")
      .then((pdf: jsPDF) => {
        pdf.addPage();
      })
      .from(element)
      .toContainer()
      .toCanvas()
      .toPdf();
  });

  // Remove last empty page when total of pages is odd, and add page numbers
  await doc.get("pdf").then((pdf: jsPDF) => {
    if (pageLength % 2 === 1) {
      pdf.deletePage(pageLength + 1);
    }

    Array.from({ length: pageLength }).forEach((_, index) => {
      pdf.setPage(index + 1);
      pdf.setFontSize(PAGE_NUMBERS_FONT_SIZE);
      pdf.setTextColor(PAGE_NUMBERS_COLOR);
      pdf.text(
        `${translate(translation.common.page)} ${index + 1} ${translate(translation.common.page).toLowerCase()} ${pageLength}`,
        pdf.internal.pageSize.getWidth() - PAGE_NUMBERS_BOTTOM,
        pdf.internal.pageSize.getHeight() - PAGE_NUMBERS_RIGHT,
      );
    });
  });
  doc.save();
};
