import { Maybe, NeededOnly, User } from "@technis/shared";
import gql from "graphql-tag";

const UserData = `
  id
  name
  email
  phone
  technisRole
  apiKeys {
      id
  }
  lang
  unreadNotifications
`;

export type UserMeResult = {
  me: Maybe<NeededOnly<User, "id" | "name" | "role" | "lang" | "organizationIds">>;
};

export const userMe = gql`
  query userMe {
    me {
      id
      name
      role
      lang
      organizationIds
    }
  }
`;

export type UsersByOrganizationResult = { usersByOrganization: User[] };
export const usersByOrganization = gql`
    query usersByOrganization($organizationId: Int!) {
        usersByOrganization(organizationId: $organizationId) {
            ${UserData}
        }
    }
`;

export type UserByIdResult = { userById: Maybe<NeededOnly<User, "id" | "name" | "email" | "phone" | "lang">> };
export const userById = gql`
  query userById($userId: String) {
    userById(userId: $userId) {
      id
      name
      email
      phone
      lang
    }
  }
`;

export type UserCreateResult = { userCreate: User };
export const userCreate = gql`
    mutation userCreate($user: UserCreateInput!, $invitationToken: String!) {
        userCreate(user: $user, invitationToken: $invitationToken) {
            ${UserData}
        }
    }
`;

export type UserUpdateResult = { userUpdate: NeededOnly<User, "id" | "email" | "phone" | "name" | "lang"> };
export const userUpdate = gql`
  mutation userUpdate($user: UserUpdateInput!) {
    userUpdate(user: $user) {
      id
      name
      email
      phone
      lang
    }
  }
`;
