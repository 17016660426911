import classNames from "classnames";
import React, { FunctionComponent } from "react";

import { Direction } from "../../charts";
import { ParsedText } from "../../lang/ParsedText";
import { chartHeaderParser } from "../../lang/Parsers";
import { SortIcon } from "../buttons/SortIcon";

interface HeaderCellProps {
  className: string;
  content: string | React.ReactNode;
  sort?: { onClick: () => void; direction?: Direction; focus: boolean };
}

export const HeaderCell: FunctionComponent<HeaderCellProps> = props => {
  const { className, content, sort } = props;

  return sort ? (
    <div className={className} onClick={sort.onClick}>
      {content}
      <SortIcon direction={sort.direction} focus={sort.focus} />
    </div>
  ) : (
    <div className={className}>{content}</div>
  );
};

interface HorizontalHeaderProps {
  barHeader: string;
  labelHeader: string;
  headerClassName: string;
  labelClassName: string;
  labelSort?: { onClick: () => void; direction?: Direction; focus: boolean };
  barSort?: { onClick: () => void; direction?: Direction; focus: boolean };
}

export const HorizontalHeader: FunctionComponent<HorizontalHeaderProps> = props => {
  const { barHeader, barSort, headerClassName, labelClassName, labelHeader, labelSort } = props;
  return (
    <div className="horizontal-header">
      <HeaderCell content={labelHeader} className={classNames("list-header", "header-label", "gradient", labelClassName)} sort={labelSort} />
      <div className="vertical-line line-color" />
      <HeaderCell
        content={<ParsedText parsers={[chartHeaderParser()]}>{barHeader}</ParsedText>}
        className={classNames("list-header", "header-bar", "gradient", headerClassName)}
        sort={barSort}
      />
    </div>
  );
};
