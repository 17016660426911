import { ID, MINUTE_IN_MILLISECONDS, prop } from "@technis/shared";
import * as moment from "moment";

import { Dates } from "../../constants";
import { MILLISECONDS_IN_DAY, MILLISECONDS_IN_MINUTE, MINUTES_IN_HOUR } from "../../constants/date";
import { getFormattedDate } from "../time";
import { clone, DaysOfWeek, getPeriodLength, sumArrays } from "../utils";
import { AggregatedKpiAtmosphere, KpiAtmospherePropertiesArrayValue } from "./KpiAtmosphereAggregator";
import { AggregatedKpiCounting } from "./KpiCountingAggregator";
import { DateRange, Granularity, KpiAtmosphereWithDates, KpiCountingWithDates } from "./types";

const { MILLISECONDS_IN_WEEK, MINUTES_IN_DAY, MINUTES_IN_WEEK } = Dates;

interface AggregatedArrays {
  inside: number[][];
  affluenceMinIn: number[][];
  affluenceMinOut: number[][];
}

interface DiscontinousDayFilter<T> {
  filter: DaysOfWeek[];
  kpi: T;
  validator?: (key: keyof T) => boolean;
}

interface MovingAverageProps<T> {
  data: T;
  granularity: Granularity;
  properties: string[];
  timeWindow: number;
}

const discontinuousDayFilter = <T extends AggregatedKpiCounting | AggregatedKpiAtmosphere>({ filter, kpi, validator }: DiscontinousDayFilter<T>): T => {
  const isInDateFilter = (date: number) => filter.includes(new Date(date).getDay());

  const indexFilter: number[] = kpi.dateBegins.reduce((acc, curr, index) => {
    if (isInDateFilter(curr)) return [...acc, index];
    return acc;
  }, [] as number[]);

  const validatorFn: (key: keyof T) => boolean = validator || (() => true);

  return Object.entries(kpi).reduce(
    (acc, [key, value]) => ({
      ...acc,
      ...(validatorFn(key as keyof T) &&
        Array.isArray(value) && {
          [key]: value.filter((_: unknown, index: number) => indexFilter.includes(index)),
        }),
    }),
    {} as T,
  );
};

const parseData = <T>(data: T) => (property: string) => {
  const elem = data[property as keyof T];

  if (Array.isArray(elem)) {
    const discontinuousDays = elem
      .flat()
      .chunks(MINUTES_IN_WEEK)
      .averageArrays()
      .chunks(MINUTES_IN_DAY);
    return { [property]: discontinuousDays };
  }

  return {};
};

export const getDiscontinuousDaysForCounting = (data: AggregatedKpiCounting, properties: string[]) => {
  if (data.dateEnds.last() - data.dateBegins[0] <= MILLISECONDS_IN_WEEK) {
    return data;
  }

  const aggregatedArraysObject: AggregatedArrays = properties.reduce(acc => ({ ...acc, ...parseData<AggregatedKpiCounting>(data) }), {} as AggregatedArrays);

  const aggregatedDwellObject = { dwell: data.dwell?.averageArrays() };

  return { ...data, ...aggregatedArraysObject, ...aggregatedDwellObject };
};

export const getDiscontinuousDaysForAtmosphere = (data: AggregatedKpiAtmosphere, properties: string[]) => {
  if (data.dateEnds.last() - data.dateBegins[0] <= MILLISECONDS_IN_WEEK) {
    return data;
  }

  const aggregatedArraysObject: AggregatedArrays = properties.reduce(acc => ({ ...acc, ...parseData<AggregatedKpiAtmosphere>(data) }), {} as AggregatedArrays);

  return { ...data, ...aggregatedArraysObject };
};

export const getInsideValue = (inArr: number[], outArr: number[]): number[] =>
  inArr.reduce((acc, val, idx) => (!idx ? [val - outArr[idx]] : [...acc, acc[idx - 1] + val - outArr[idx]]), [] as number[]);

export const hasMissingProperty = <T extends KpiCountingWithDates | KpiAtmosphereWithDates>(kpis: T[], properties: (keyof T)[]): boolean =>
  kpis.some(kpi => properties.some(property => !kpi.hasOwnProperty(property)));

export const movingAverage = <T>({ data, granularity, properties, timeWindow }: MovingAverageProps<T>): T => {
  const aggregatedArraysObject: T = properties
    .map(property => {
      const elem = data[property as keyof T];
      if (Array.isArray(elem)) {
        const discontinuousDays = elem
          .flat()
          .movingAverage(timeWindow)
          .chunks(getPeriodLength(granularity));
        return { [property]: discontinuousDays };
      }
      return false;
    })
    .filter(Boolean)
    .reduce((acc, elem) => ({ ...acc, ...elem }), {} as T);

  return { ...data, ...aggregatedArraysObject };
};

export const workingDaysFilter = <T extends AggregatedKpiCounting | AggregatedKpiAtmosphere>(kpi: T): T =>
  discontinuousDayFilter({
    kpi,
    filter: [DaysOfWeek.MONDAY, DaysOfWeek.TUESDAY, DaysOfWeek.WEDNESDAY, DaysOfWeek.THURSDAY, DaysOfWeek.FRIDAY],
  });

export const weekendDaysFilter = <T extends AggregatedKpiCounting | AggregatedKpiAtmosphere>(kpi: T): T =>
  discontinuousDayFilter({
    kpi,
    filter: [DaysOfWeek.SATURDAY, DaysOfWeek.SUNDAY],
  });

export const getTempArray = (diff: number, tempArray?: number[]) => (!!tempArray ? tempArray : Array(diff).fill(undefined));

export const checkDatesRegularity = (dateBegins: number[], dateEnds: number[]) => {
  const format = getFormattedDate("HH[:]mm");
  if (dateEnds[0] - dateBegins[0] > MILLISECONDS_IN_DAY) return false;
  const firstDate = dateBegins[0];
  const patern = dateBegins.reduce((acc, current) => {
    if (current < firstDate + MILLISECONDS_IN_DAY) return [...acc, format(current)];
    return acc;
  }, [] as string[]);

  const chunkSize = patern.length;

  if (patern.length === 0) return false;
  if (patern.length <= 1) return dateBegins.every(date => format(date) === patern[0]);

  const { chunks } = dateBegins.reduce(
    (acc, current, index) => {
      if (index % chunkSize === chunkSize - 1) {
        return { ...acc, chunks: [...acc.chunks, [...acc.temp, format(current)]], temp: [] };
      }
      return { ...acc, temp: [...acc.temp, format(current)] };
    },
    { temp: [] as string[], chunks: [] as string[][] },
  );

  return !chunks.some(chunk => JSON.stringify(chunk) !== JSON.stringify(patern));
};

export const shouldDataShift = <T extends { dateBegin: number; dateEnd: number }>(kpis: T[], dateRange?: DateRange, shouldDataShift?: boolean) =>
  !!shouldDataShift && checkDatesRegularity(kpis.map(prop("dateEnd")), kpis.map(prop("dateBegin")));

export const getFirstDateBegin = <T extends { dateBegin: number; dateEnd: number }>(kpis: T[], dateRange?: DateRange, granularity?: Granularity) => {
  if (!dateRange || moment(kpis[0].dateBegin).hour() === 0) return kpis[0].dateBegin;
  if (granularity && dateRange.dateBegin < kpis[0].dateBegin) return kpis[0].dateBegin - getPeriodLength(granularity) * MINUTE_IN_MILLISECONDS;
  const filteredKpis = kpis.filter(kpi => kpi.dateBegin >= dateRange.dateBegin);
  if (!filteredKpis.isEmpty()) return kpis.filter(kpi => kpi.dateBegin >= dateRange.dateBegin)[0].dateBegin;
  return kpis[0].dateBegin;
};

export const shiftArray = (params: { array: number[][]; arrayToConcat?: number[]; diff: number; chunkSize: number; dateBegin: number }) => {
  const { array, arrayToConcat = [], diff, chunkSize, dateBegin } = params;

  const timeBetweenDateBeginAnd2AM =
    (MINUTES_IN_DAY +
      moment(dateBegin)
        .hour(2)
        .minute(0)
        .millisecond(0)
        .diff(moment(dateBegin), "minute")) %
    MINUTES_IN_DAY;

  const flatArray = array
    // Quick fix for 25 hours day between summer and winter times
    //TODO: make a better fix for the daylight saving time
    .map(day => (day.length > chunkSize ? [...day.slice(0, timeBetweenDateBeginAnd2AM), ...day.slice(timeBetweenDateBeginAnd2AM + MINUTES_IN_HOUR)] : day))
    .flat()
    .concat(arrayToConcat)
    .slice(diff);
  const diffFromPeriodSize = chunkSize * array.length - flatArray.length;

  return flatArray.concat(Array(diffFromPeriodSize).fill(undefined)).chunks(chunkSize);
};

export const findPair = <K>(keyValuePairs: [string, number[][] | number[] | number | Granularity | ID][], property: keyof K) => keyValuePairs.find(pair => pair[0] === property);

export const getNbAirQualityDevice = (kpis: KpiAtmosphereWithDates[]) =>
  kpis.reduce((acc, curr) => {
    if (curr.dateBegin === kpis[0].dateBegin) return ++acc;
    return acc;
  }, 0);

export const getAirQualityAverage = (kpis: KpiAtmosphereWithDates[], aggregateProps: KpiAtmospherePropertiesArrayValue[], nbAirQualityDevice: number) =>
  kpis.chunks(nbAirQualityDevice).map(chunk => {
    const cloneKpi = clone(chunk[0]);
    aggregateProps.forEach(property => {
      const sum = sumArrays(...chunk.map(kpi => kpi[property]));
      cloneKpi[property] = sum.map(value => value / nbAirQualityDevice);
    });
    return cloneKpi;
  });

export type KeyKpi = [string, number[]];

export const getTruncatedKpi = (kpis: KeyKpi[], diff: number, periodSize: number) =>
  kpis.reduce((acc, [key, value]) => {
    if (Array.isArray(value) && value.every(Number)) {
      return [...acc, [key, value.slice(periodSize - diff)] as KeyKpi];
    }
    return acc;
  }, [] as KeyKpi[]);

export const getDiffDateRange = (dateRange: DateRange) => Math.ceil((dateRange?.dateEnd - dateRange?.dateBegin) / MILLISECONDS_IN_MINUTE);

export const isShifting = <K extends { dateBegin: number; dateEnd: number }>(kpis: K[]) => moment(kpis[0].dateBegin).hour() !== 0;

const changeArraySize = (array: number[], size: number): number[] => {
  if (array.length > size) return array.slice(0, size);
  if (array.length < size) return [...array, ...new Array(size - array.length).fill(undefined)];
  return array;
};

export const fixKpiArrayLength = (kpis: KpiCountingWithDates[]) =>
  kpis.map(kpi => ({
    ...kpi,
    affluenceMinIn: changeArraySize(kpi.affluenceMinIn, getDiffDateRange({ dateBegin: kpi.dateBegin, dateEnd: kpi.dateEnd })),
    affluenceMinOut: changeArraySize(kpi.affluenceMinOut, getDiffDateRange({ dateBegin: kpi.dateBegin, dateEnd: kpi.dateEnd })),
    inside: changeArraySize(kpi.inside, getDiffDateRange({ dateBegin: kpi.dateBegin, dateEnd: kpi.dateEnd })),
  }));

export const generatePlaceholderKpi = (kpis: KpiCountingWithDates[], periodSize: number, dateRange?: DateRange) => {
  if (dateRange && dateRange?.dateBegin < kpis[0].dateBegin) {
    const newArray = Array(periodSize).fill(null);
    const newKpi: KpiCountingWithDates = {
      ...kpis[0],
      dateBegin: kpis[0].dateBegin - periodSize * MINUTE_IN_MILLISECONDS,
      dateEnd: kpis[0].dateBegin - 1,
      affluenceMinIn: newArray,
      affluenceMinOut: newArray,
      inside: newArray,
      periodId: -1,
    };
    return [newKpi, ...kpis];
  }
  return kpis;
};

export const getAverageDwell = (kpis: KpiCountingWithDates[]) => {
  const totalVisitorNumber = kpis.map(kpi => kpi.affluenceMinIn.sum()).sum();
  const totalDwell = kpis
    .map(kpi => {
      const visitorNumber = kpi.affluenceMinIn.sum() || 1;
      return kpi.dwell * visitorNumber;
    })
    .sum();
  return totalDwell > 0 ? totalDwell / totalVisitorNumber : 0;
};
