import { Translation } from "@technis/shared";
import { REHYDRATE } from "redux-persist";

import { setTranslations } from "../../lang/i18n";
import { RootState } from "../store";
import { AppAction, AppActions } from "./app.actions";

export type AppState = {
  isExporting: boolean;
  details: Translation | null;
  // eslint-disable-next-line @typescript-eslint/ban-types
  translations: object | null;
};

export const DEFAULT_STATE: AppState = {
  isExporting: false,
  details: null,
  translations: null,
};

export const reducer = (state: AppState = DEFAULT_STATE, action: AppAction): AppState => {
  switch (action.type) {
    case REHYDRATE: {
      if (action?.payload) {
        const { app } = action.payload as RootState;

        setTranslations(app.translations);

        return { ...state, details: app.details, translations: app.translations };
      }

      return state;
    }
    case AppActions.SET_EXPORTING:
      return { ...state, isExporting: action.isExporting };
    case AppActions.RESET_APP:
      return { ...DEFAULT_STATE };
    case AppActions.TRANSLATION_DETAILS:
      return { ...state, details: action.details };
    case AppActions.TRANSLATIONS: {
      setTranslations(action.translations);
      return { ...state, translations: action.translations };
    }
    default:
      return state;
  }
};
