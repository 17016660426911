import React, { FunctionComponent, useEffect, useState } from "react";
import { useQuery } from "react-apollo";
import { AiOutlineBarChart } from "react-icons/ai";

import { UI } from "../../../constants";
import { analyticsKpiQuery, AnalyticsKpiQueryResult } from "../../../graphql/zones.gql";
import { translate } from "../../../lang/i18n";
import { translation } from "../../../lang/translation";
import { AggregatedKpiCountingWithName } from "../../../pages/AnalyticsHomePage";
import { DateRange, KpiCountingAggregator } from "../../../utils/aggregate";
import { updateModuleDate } from "../../../utils/time";
import { areObjectsEqual } from "../../../utils/utils";
import { AffluenceChart, AggregationType } from "../../charts/AffluenceChart";
import { ChartModule } from "./ChartModule";

const { MODULE_TITLE_ICON_SIZE } = UI;

const { titles, tooltips } = translation.analytics.modules;

interface AffluenceModuleProps {
  dateRange: DateRange;
  datesLimits: {
    begin: number;
    end: number;
  };
  eventId: number;
  keyNumber: number;
  kpiData: AggregatedKpiCountingWithName;
  zoneId: number;
  zoneName: string;
  onDelete: () => void;
  aggregation?: AggregationType;
  timeWindow?: number;
}

export const AffluenceModule: FunctionComponent<AffluenceModuleProps> = props => {
  const { dateRange, eventId, keyNumber, kpiData, zoneId, zoneName, onDelete, aggregation = AggregationType.SUM, datesLimits, timeWindow } = props;
  const [moduleDates, setModuleDates] = useState<DateRange>(dateRange);

  useEffect(() => {
    setModuleDates(dateRange);
  }, [dateRange]);

  const shouldHeaderOverride = areObjectsEqual(dateRange, moduleDates);

  const { data: moduleKpi, loading } = useQuery<AnalyticsKpiQueryResult>(analyticsKpiQuery, {
    errorPolicy: "all",
    variables: { zoneId, dates: [moduleDates.dateBegin, moduleDates.dateEnd], eventId },
    skip: shouldHeaderOverride,
  });

  const { granularity, zoneName: kpiZoneName } = kpiData;

  const affluenceKpiAggregated: AggregatedKpiCountingWithName =
    !shouldHeaderOverride && moduleKpi
      ? {
          ...new KpiCountingAggregator({ granularity, timeWindow, kpis: moduleKpi?.zoneById.kpis, dateRange: moduleDates, isDataShifting: true }).getAggregatedData(),
          zoneName: kpiZoneName,
        }
      : kpiData;

  const updateDate = updateModuleDate(setModuleDates);

  return (
    <ChartModule
      dateRange={moduleDates}
      datesLimits={datesLimits}
      iconSize={MODULE_TITLE_ICON_SIZE}
      keyNumber={keyNumber}
      title={translate(titles.affluenceChart)}
      tooltipText={translate(tooltips.affluence)}
      zoneName={zoneName}
      Icon={AiOutlineBarChart}
      onDelete={onDelete}
      loading={loading}
      onUpdateDate={updateDate}
      isExportable
    >
      <AffluenceChart kpiData={affluenceKpiAggregated} aggregation={aggregation} />
    </ChartModule>
  );
};
