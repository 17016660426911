import { AnyAction, applyMiddleware, createStore, Reducer } from "redux";
import { persistCombineReducers, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { createWhitelistFilter } from "redux-persist-transform-filter";
import { default as ReduxThunk } from "redux-thunk";

import { DEFAULT_STATE as initialAppState, reducer as app } from "./app/app.reducer";
import { saveToken } from "./auth/auth.actions";
import { DEFAULT_STATE as initialAuthState, reducer as auth } from "./auth/auth.reducer";
import { DEFAULT_STATE as initialRouterState, reducer as router } from "./router/router.reducer";
import { DEFAULT_STATE as initialUserOptionsState, reducer as userOptions } from "./userOptions/userOptions.reducer";

export const initialRootState = {
  auth: initialAuthState,
  router: initialRouterState,
  app: initialAppState,
  userOptions: initialUserOptionsState,
};

const rootReducer = {
  auth,
  router,
  app,
  userOptions,
};

const config = {
  key: "root",
  storage,
  transforms: [
    createWhitelistFilter("auth", ["token"]),
    createWhitelistFilter("modals", []),
    createWhitelistFilter("router", []),
    createWhitelistFilter("app", ["details", "translations"]),
  ],
};

// @ts-ignore
const reducer: Reducer<RootState, AnyAction> = persistCombineReducers(config, rootReducer);

export type RootState = typeof initialRootState;

const configureStore = () => {
  const store = createStore(reducer, initialRootState, applyMiddleware(ReduxThunk));
  const persistor = persistStore(store);
  return { store, persistor };
};

export const { persistor, store } = configureStore();

export const actions = {
  auth: { saveToken },
};
