import { slice } from "lodash";
import * as moment from "moment";

import { MILLISECONDS_IN_MINUTE } from "../../constants/date";
import { roundUp } from "../utils";
import { getDiffDateRange, getTruncatedKpi, shiftArray } from "./helpers";
import { DateRange, Granularity } from "./types";

export enum MomentDuration {
  DAY = "day",
  WEEK = "week",
  MONTH = "month",
  YEAR = "year",
}

const granularityDurationMap = {
  [Granularity.CONTINUOUS_DAYS]: MomentDuration.DAY,
  [Granularity.CONTINUOUS_WEEKS]: MomentDuration.WEEK,
  [Granularity.CONTINUOUS_MONTHS]: MomentDuration.MONTH,
  [Granularity.CONTINUOUS_YEARS]: MomentDuration.YEAR,
  [Granularity.DISCONTINUOUS_DAYS]: MomentDuration.DAY,
  [Granularity.DISCONTINUOUS_WORKING_DAYS]: MomentDuration.DAY,
  [Granularity.DISCONTINUOUS_WEEKEND_DAYS]: MomentDuration.DAY,
};

export class BaseAggregator {
  protected granularityDuration: moment.unitOfTime.DurationConstructor;
  protected granularity: Granularity;
  protected firstDateBegin: number;
  protected timeWindow?: number;
  protected dateRange?: DateRange;
  protected shouldDataShift?: boolean;

  constructor({
    dateRange,
    firstDateBegin,
    granularity,
    shouldDataShift,
    timeWindow,
  }: {
    granularity: Granularity;
    firstDateBegin: number;
    timeWindow?: number;
    dateRange?: DateRange;
    shouldDataShift?: boolean;
  }) {
    this.granularity = granularity;
    this.timeWindow = timeWindow;
    this.dateRange = dateRange;
    this.shouldDataShift = shouldDataShift;
    this.firstDateBegin = firstDateBegin;
    this.granularityDuration = granularityDurationMap[granularity] as moment.unitOfTime.DurationConstructor;
  }

  protected getKey = (timeStamp: number): string => {
    const date = moment(timeStamp);
    switch (this.granularityDuration) {
      case MomentDuration.YEAR:
        return date.format("YYYY");
      case MomentDuration.MONTH:
        return date.format("YYYY-MM");
      case MomentDuration.WEEK:
        return `${date.format("YYYY")}-${date.week()}`;
      case MomentDuration.DAY:
      default:
        return date.format("YYYY-MM-DD");
    }
  };

  // returns with the size of period by the comperator
  protected getPeriodSize = (timeStamp: number): number => {
    const start = moment(timeStamp).startOf(this.granularityDuration);
    const end = moment(timeStamp).endOf(this.granularityDuration);

    return end.diff(start, "minutes") + 1;
  };

  // returns with position of a specific date in period
  protected getStartIndexByDate = (timeStamp: number): number => Math.abs(moment(timeStamp).diff(moment(timeStamp).startOf(this.granularityDuration), "minutes"));

  // Split the kpi if the start and the end date are not in the same date
  protected getKpisWithCorrectedDate = <T extends { dateBegin: number; dateEnd: number }, K extends keyof T>(kpis: T[], propreties: K[], dateRange?: DateRange) =>
    kpis
      .reduce((acc, kpi) => {
        const { dateBegin, dateEnd } = kpi;
        const firstDate = moment(dateBegin);
        const lastDate = moment(dateEnd);

        if (firstDate.isSame(lastDate, MomentDuration.DAY)) {
          return [...acc, kpi];
        }

        const numberOfDays = roundUp(moment(dateEnd).diff(moment(dateBegin).startOf(MomentDuration.DAY), "days", true));

        const firstDateEnd = moment(dateBegin).endOf(MomentDuration.DAY);
        const firstDateDiffToDateEnd = moment(firstDateEnd).diff(firstDate, "minutes");
        const lastDateStart = moment(lastDate).startOf(this.granularityDuration);

        const elements = new Array(numberOfDays > 2 ? numberOfDays : 2).fill("").reduce((splittedAcc, _, index, arr) => {
          const dayKpi = { ...kpi };
          const periodSize = this.getPeriodSize(dayKpi.dateBegin);
          const startIndex = firstDateDiffToDateEnd + periodSize * (index - 1);

          if (index === 0) {
            dayKpi.dateEnd = firstDateEnd.valueOf();
          } else if (index === arr.length - 1) {
            dayKpi.dateBegin = lastDateStart.valueOf();
          } else {
            const nextDay = moment(dateBegin).add(index, MomentDuration.DAY);
            dayKpi.dateBegin = moment(nextDay)
              .startOf(MomentDuration.DAY)
              .valueOf();
            dayKpi.dateEnd = moment(nextDay)
              .endOf(MomentDuration.DAY)
              .valueOf();
          }

          propreties.forEach(key => {
            const elem = kpi[key];

            if (Array.isArray(elem)) {
              if (index === 0) {
                dayKpi[key] = (slice(elem, 0, firstDateDiffToDateEnd + 1) as unknown) as T[K];
              } else if (index === arr.length - 1) {
                dayKpi[key] = (slice(elem, startIndex + 1) as unknown) as T[K];
              } else {
                dayKpi[key] = (slice(elem, startIndex, startIndex + periodSize) as unknown) as T[K];
              }
            }
          });
          return [...splittedAcc, dayKpi];
        }, [] as T[]);
        return [...acc, ...elements];
      }, [] as T[])
      .filter(kpi => (dateRange ? kpi.dateBegin < dateRange.dateEnd : true));

  protected shiftFilter = <T extends { dateBegins: number[]; dateEnds: number[] }, K extends { dateBegin: number; dateEnd: number }>(aggregatedData: T) => {
    if (!this.shouldDataShift || !this.dateRange) return aggregatedData;
    const diff = moment(this.firstDateBegin).diff(moment(aggregatedData.dateBegins[0]), "minute") % this.getPeriodSize(aggregatedData.dateBegins[0]);

    const periodSize = this.getPeriodSize(this.firstDateBegin);

    const truncatedKpis = getTruncatedKpi(Object.entries(aggregatedData), diff, periodSize);

    const keyValuePairs = Object.entries(aggregatedData);

    const keyShiftedValuePairs = keyValuePairs.map(data => {
      const [key, value] = data;
      if (["dateBegins", "dateEnds"].includes(key)) {
        return [key, value.map(date => date + diff * MILLISECONDS_IN_MINUTE)];
      }
      if (["dwell", "countMaxMin"].includes(key)) {
        return [key, value];
      }
      if (Array.isArray(value)) {
        return [
          key,
          shiftArray({
            array: (value as unknown) as number[][],
            arrayToConcat: (truncatedKpis.find(pair => pair[0] === key) || [])[1],
            diff,
            chunkSize: periodSize,
            dateBegin: aggregatedData.dateBegins[0],
          }),
        ];
      }
      return [key, value];
    });

    return (Object.fromEntries(keyShiftedValuePairs) as unknown) as T;
  };

  protected dateRangeFilter = <T extends { dateBegins: number[]; dateEnds: number[] }>(aggregatedData: T) => {
    if (!this.dateRange) return aggregatedData;
    const diffFromStart = moment(this.dateRange?.dateBegin).diff(moment(aggregatedData.dateBegins[0]), "minute");
    const diffDateRange = getDiffDateRange(this.dateRange);
    const diffFromEnd = moment(aggregatedData.dateEnds.last()).diff(moment(this.dateRange?.dateEnd), "minute");

    const periodSize = this.getPeriodSize(this.dateRange?.dateBegin);
    const periodsToRemoveAtStart = Math.floor(diffFromStart / periodSize);
    const periodsToRemoveAtEnd = Math.floor(diffFromEnd / periodSize);

    const filteredKeyValuePairs = Object.entries(aggregatedData).map(data => {
      const [key, value] = data;
      if (["dateBegins", "dateEnds", "dwell"].includes(key)) {
        return [key, value.slice(periodsToRemoveAtStart, value.length - periodsToRemoveAtEnd)];
      }
      if (key === "countMaxMin") {
        return [key, value];
      }
      if (Array.isArray(value)) {
        return [
          key,
          value
            .flat()
            .map((data, index) => (index >= diffFromStart && index <= diffFromStart + diffDateRange - 1 ? data : undefined))
            .slice(periodsToRemoveAtStart * periodSize, value.flat().length - periodsToRemoveAtEnd * periodSize)
            .chunks(periodSize),
        ];
      }
      return [key, value];
    });

    return (Object.fromEntries(filteredKeyValuePairs) as unknown) as T;
  };
}
