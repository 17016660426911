import { Pad } from "@technis/shared";
import gql from "graphql-tag";

const PadConfigData = `
  config {
    actions {
        reboot
        update
        uploadLogs
    }
    activeLength
    data
    deactivationType
    notActivePoints
    notActiveX
    notActiveY
    padAngle
    realtime
    sizeX
    sizeY
    tiles
    tilesSplit
    updatedAt
    uTurnFilter
    wallDirection
    wheelsCount
    wheelsFiltering
}
`;

export const PadData = `
  id
  name
`;

export type PadsByOrganizationResult = { padsByOrganization: Pad[] };
export const padsByOrganization = gql`
    query padsByOrganization($organizationId: Int!) {
        padsByOrganization(organizationId: $organizationId) {
            ${PadData}
            ${PadConfigData}
        }
    }
`;

export type PadsByUserResult = { padsByUser: Array<Pad> };
export const padsByUser = gql`
    query padsByUser {
        padsByUser {
            ${PadData}
        }
    }
`;

export type PadByIdResult = { padById: Pad };
export const padById = gql`
    query padById($padId: String!) {
        padById(padId: $padId) {
            ${PadData}
            ${PadConfigData}
        }
    }
`;
