//TODO Alphabetize file
export const translation = {
  analytics: {
    export: {
      errorMessages: {
        moduleTooBig: "analytics.export.errorMessages.moduleTooBig",
      },
      generatedOn: "analytics.export.generatedOn",
      loadingMessages: {
        preparing: "analytics.export.loadingMessages.preparing",
        documentLayout: "analytics.export.loadingMessages.imageGeneration",
        writting: "analytics.export.loadingMessages.writting",
      },
    },
    modules: {
      aggregationsSettings: {
        buttons: {
          lastFifteenMinutes: "analytics.modules.aggregationsSettings.buttons.lastFifteenMinutes",
          lastHour: "analytics.modules.aggregationsSettings.buttons.lastHour",
          lastMonth: "analytics.modules.aggregationsSettings.buttons.lastMonth",
          lastTwoDays: "analytics.modules.aggregationsSettings.buttons.lastTwoDays",
          lastWeek: "analytics.modules.aggregationsSettings.buttons.lastWeek",
          today: "analytics.modules.aggregationsSettings.buttons.today",
        },
        field: {
          endDate: "analytics.modules.aggregationsSettings.field.endDate",
          startDate: "analytics.modules.aggregationsSettings.field.startDate",
        },
        fieldsSelectors: {
          quickSelect: "analytics.modules.aggregationsSettings.fieldsSelectors.quickSelect",
          selectTime: "analytics.modules.aggregationsSettings.fieldsSelectors.selectTime",
        },
        titles: {
          commonlyUsed: "analytics.modules.aggregationsSettings.titles.commonlyUsed",
          custom: "analytics.modules.aggregationsSettings.titles.custom",
          quickSelect: "analytics.modules.aggregationsSettings.titles.quickSelect",
        },
      },
      common: {
        chooseDate: "analytics.modules.common.chooseDate",
        noItems: "analytics.modules.common.noItems",
      },
      labels: {
        co2: "analytics.modules.labels.co2",
        dwellSub: "analytics.modules.labels.dwellSub",
        dwellTop: "analytics.modules.labels.dwellTop",
        humidity: "analytics.modules.labels.humidity",
        in: "analytics.modules.labels.in",
        inside: "analytics.modules.labels.inside",
        insideTime: "analytics.modules.labels.insideTime",
        lessCrowded: "analytics.modules.labels.lessCrowded",
        max: "analytics.modules.labels.max",
        maxFlowPassages: "analytics.modules.labels.maxFlowPassages",
        maxFlowTop: "analytics.modules.labels.maxFlowTop",
        maxInsideTime: "analytics.modules.labels.maxInsideTime",
        maxOccupationRate: "analytics.modules.labels.maxOccupationRate",
        maxVisitorsTime: "analytics.modules.labels.maxVisitorsTime",
        mean: "analytics.modules.labels.mean",
        meanInside: "analytics.modules.labels.meanInside",
        min: "analytics.modules.labels.min",
        mostCrowded: "analytics.modules.labels.mostCrowded",
        out: "analytics.modules.labels.out",
        systemActivity: "analytics.modules.labels.systemActivity",
        systemStatus: "analytics.modules.labels.systemStatus",
        temperature: "analytics.modules.labels.temperature",
        waitingTime: "analytics.modules.labels.waitingTime",
      },
      legends: {
        co2: "analytics.modules.legends.co2",
        countIn: "analytics.modules.legends.countIn",
        countOut: "analytics.modules.legends.countOut",
        humidity: "analytics.modules.legends.humidity",
        insideMax: "analytics.modules.legends.insideMax",
        insideMean: "analytics.modules.legends.insideMean",
        insideMin: "analytics.modules.legends.insideMin",
        temperature: "analytics.modules.legends.temperature",
      },
      statusCode: {
        operational: "analytics.modules.statusCode.operational",
        connectionLost: "analytics.modules.statusCode.connectionLost",
        firmwareUpdating: "analytics.modules.statusCode.firmwareUpdating",
        incompatibleBlackBox: "analytics.modules.statusCode.incompatibleBlackBox",
        incompatibleMat: "analytics.modules.statusCode.incompatibleMat",
        internetLost: "analytics.modules.statusCode.internetLost",
        padRebooting: "analytics.modules.statusCode.padRebooting",
        tilesMissing: "analytics.modules.statusCode.tilesMissing",
        waiting: "analytics.modules.statusCode.waiting",
      },
      titles: {
        affluenceChart: "analytics.modules.titles.affluenceChart",
        airQuality: "analytics.modules.titles.airQuality",
        errorLog: "analytics.modules.titles.errorLog",
        generalInformation: "analytics.modules.titles.generalInformation",
        insideChart: "analytics.modules.titles.insideChart",
        mostUsedPassages: "analytics.modules.titles.mostUsedPassages",
        mostUsedzones: "analytics.modules.titles.mostUsedzones",
        overview: "analytics.modules.titles.overview",
        quantileChart: "analytics.modules.titles.quantileChart",
        summaryAir: "analytics.modules.titles.summaryAir",
        waitingTime: "analytics.modules.titles.waitingTime",
      },
      tooltips: {
        affluence: "analytics.modules.tooltips.affluence",
        airQuality: "analytics.modules.tooltips.airQuality",
        inside: "analytics.modules.tooltips.inside",
        mostUsedPassages: "analytics.modules.tooltips.mostUsedPassages",
        mostUsedzones: "analytics.modules.tooltips.mostUsedzones",
        quantile: "analytics.modules.tooltips.quantile",
        zoom: "analytics.modules.tooltips.zoom",
        waitingTime: "analytics.modules.tooltips.waitingTime",
      },
    },
  },
  common: {
    advanced: "common.advanced",
    airQuality: "common.airQuality",
    airQualityAndCounting: "common.airQualityAndCounting",
    analytics: "common.analytics",
    apply: "common.apply",
    at: "common.at",
    barChart: "common.barChart",
    browserView: "common.browserView",
    cancel: "common.cancel",
    color: "common.color",
    commonlyUsed: "common.commonlyUsed",
    counting: "common.counting",
    day: {
      count: "common.day",
      one: "common.day.one",
      other: "common.day.other",
      zero: "common.day.zero",
    },
    device: {
      count: "common.device",
      one: "common.device.one",
      other: "common.device.other",
      zero: "common.device.zero",
    },
    deviceType: "common.deviceType",
    email: "common.email",
    english: "common.english",
    exclusive: "common.exclusive",
    export: "common.export",
    exportView: "common.exportView",
    flowChart: "common.flowChart",
    french: "common.french",
    from: "common.from",
    general: "common.general",
    hour: {
      count: "common.hour",
      one: "common.hour.one",
      other: "common.hour.other",
      zero: "common.hour.zero",
    },
    icon: "common.icon",
    in: {
      one: "common.in.one",
      other: "common.in.other",
      zero: "common.in.other",
    },
    inAndOut: {
      one: "common.inAndOut.one",
      other: "common.inAndOut.other",
      zero: "common.inAndOut.other",
    },
    language: "common.language",
    last: "common.last",
    loading: "common.loading",
    mandatoryField: "common.mandatoryField",
    max: "common.max",
    mean: "common.mean",
    message: "common.message",
    min: "common.min",
    minute: {
      count: "common.minute",
      one: "common.minute.one",
      other: "common.minute.other",
      short: "common.minute.short",
      zero: "common.minute.zero",
    },
    noData: "common.noData",
    of: "common.of",
    ok: "common.ok",
    or: "common.or",
    out: {
      one: "common.out.one",
      other: "common.out.other",
      zero: "common.out.other",
    },
    page: "common.page",
    passages: "common.passages",
    person: {
      count: "common.person",
      one: "common.person.one",
      other: "common.person.other",
      zero: "common.person.zero",
    },
    phone: "common.phone",
    pieChart: "common.pieChart",
    save: "common.save",
    saving: "common.saving",
    select: "common.select",
    sensor: {
      atmosphere: "common.sensor.atmosphere",
      blackBox: "common.sensor.blackBox",
      camera: "common.sensor.camera",
    },
    shift: "common.shift",
    shiftState: {
      defaultDisplay: "common.shiftState.defaultDisplay",
      shifted: "common.shiftState.shifted",
    },
    status: "common.status",
    submit: "common.submit",
    time: "common.time",
    title: "common.title",
    to: "common.to",
    today: "common.today",
    total: "common.total",
    type: "common.type",
    unit: {
      co2: "common.unit.co2",
      humidity: "common.unit.humidity",
      temperature: "common.unit.temperature",
    },
    unshift: "common.unshift",
    uptime: "common.uptime",
    url: "common.url",
    visitors: "common.visitors",
    visitorsByMinute: "common.visitorsByMinute",
    waitingTime: "common.waitingTime",
    week: {
      count: "common.week",
      one: "common.week.one",
      other: "common.week.other",
      zero: "common.week.zero",
    },
    weekDays: {
      friday: "common.weekDays.friday",
      monday: "common.weekDays.monday",
      saturday: "common.weekDays.saturday",
      sunday: "common.weekDays.sunday",
      thursday: "common.weekDays.thursday",
      tuesday: "common.weekDays.tuesday",
      wednesday: "common.weekDays.wednesday",
    },
    year: {
      count: "common.year",
      one: "common.year.one",
      other: "common.year.other",
      zero: "common.year.zero",
    },
    yearMonths: {
      april: "common.yearMonths.april",
      august: "common.yearMonths.august",
      december: "common.yearMonths.december",
      february: "common.yearMonths.february",
      january: "common.yearMonths.january",
      july: "common.yearMonths.july",
      june: "common.yearMonths.june",
      march: "common.yearMonths.march",
      may: "common.yearMonths.may",
      november: "common.yearMonths.november",
      october: "common.yearMonths.october",
      september: "common.yearMonths.september",
    },
    zones: "common.zones",
    zoomOut: "common.zoomOut",
  },
  errors: {
    "404": "errors.404",
    dataMissing: "errors.dataMissing",
    dateFormating: "errors.dateFormating",
    endDateTooLow: "errors.endDateTooLow",
    missingData: {
      subtitle: "errors.noToken.subtitle",
      title: "errors.noToken.title",
    },
    missingEventId: {
      subtitle: "errors.missingEventId.subtitle",
      title: "errors.missingEventId.title",
    },
    missingInstallation: {
      subtitle: "errors.missingInstallation.subtitle",
      title: "errors.missingInstallation.title",
    },
    missingKpiData: {
      subtitle: "errors.missingKpiData.subtitle",
      title: "errors.missingKpiData.title",
    },
    missingKpiProperty: "errors.missingKpiProperty",
    missingZoneId: "errors.missingZoneId",
    noAccess: {
      subtitle: "errors.noAccess.subtitle",
      title: "errors.noAccess.title",
    },
    noInternet: {
      subtitle: "errors.noInternet.subtitle",
      title: "errors.noInternet.title",
    },
    noKpisInSelectedPeriod: "errors.noKpisInSelectedPeriod",
    noServer: {
      subtitle: "errors.noServer.subtitle",
      title: "errors.noServer.title",
    },
    noToken: {
      subtitle: "errors.noToken.subtitle",
      title: "errors.noToken.title",
    },
  },
  time: {
    timeRanges: {
      lastTwoDays: "time.timeRanges.lastTwoDays",
      lastTwoWeeks: "time.timeRanges.lastTwoWeeks",
      lastWeek: "time.timeRanges.lastWeek",
      today: "time.timeRanges.today",
    },
    timeFormat: {
      hourMinSecUnit: "time.timeFormat.hourMinSecUnit",
      minSecUnit: "time.timeFormat.minSecUnit",
      secUnit: "time.timeFormat.secUnit",
    },
  },
};
