import * as moment from "moment";
import React, { FunctionComponent, useState } from "react";

import { translate } from "../../lang/i18n";
import { translation } from "../../lang/translation";
import { DatePreset, endOfDay, getDateRangeByPreset, isSameDay } from "../../utils/time";
import { ActivityMessages, trackClick } from "../../utils/tracking";
import { getDisabledBeginHours, getDisabledBeginMin, getDisabledEndHours, getDisabledEndMin } from "../../utils/utils";
import { FormCustomButton } from "./buttons/CustomButton";
import { FormDatePicker } from "./modals/DatePicker";
import { DropdownItem, FormDropdown } from "./modals/Dropdown";
import { FormTimePicker } from "./modals/TimePicker";
import { AdvancedAggregationSettings } from "./module/ChartModule";

interface TimeDropDownItem extends DropdownItem {
  timeUnit: moment.unitOfTime.DurationConstructor;
}

const { buttons, field, fieldsSelectors, titles } = translation.analytics.modules.aggregationsSettings;
const { lastFifteenMinutes, lastHour, lastMonth, lastTwoDays, lastWeek, today } = buttons;
const { custom, quickSelect } = titles;
const { commonlyUsed, hour, last, minute, week } = translation.common;

const QUICK_SELECT_GRANULARITY = () => [
  { id: "minute", text: translate(minute.other) },
  { id: "hour", text: translate(hour.other) },
  { id: "week", text: translate(week.other) },
];
const QUICK_SELECT_MINUTES: TimeDropDownItem[] = [
  { timeUnit: "minute", id: "15-minute", text: "15" },
  { timeUnit: "minute", id: "30-minute", text: "30" },
  { timeUnit: "minute", id: "45-minute", text: "45" },
  { timeUnit: "minute", id: "60-minute", text: "60" },
];
const QUICK_SELECT_HOURS: TimeDropDownItem[] = [
  { timeUnit: "hour", id: "1-hour", text: "1" },
  { timeUnit: "hour", id: "2-hour", text: "2" },
  { timeUnit: "hour", id: "3-hour", text: "3" },
  { timeUnit: "hour", id: "6-hour", text: "6" },
  { timeUnit: "hour", id: "24-hour", text: "24" },
];
const QUICK_SELECT_WEEKS: TimeDropDownItem[] = [
  { timeUnit: "week", id: "1-week", text: "1" },
  { timeUnit: "week", id: "2-week", text: "2" },
  { timeUnit: "week", id: "3-week", text: "3" },
  { timeUnit: "week", id: "4-week", text: "4" },
];

interface AggregationCreateAdvancedFormProps {
  moduleSetting: AdvancedAggregationSettings;
  onUpdateModuleSettings: (moduleDateBegin: number | undefined, moduleDateEnd: number | undefined) => void;
  keyNumber: number;
  datesLimits: {
    begin: number;
    end: number;
  };
}

interface State {
  dropdownList: TimeDropDownItem[];
  timeText: string;
  timeUnitText: string;
}

export const AggregationCreateAdvancedForm: FunctionComponent<AggregationCreateAdvancedFormProps> = props => {
  const { datesLimits, keyNumber, moduleSetting, onUpdateModuleSettings } = props;

  const [state, setState] = useState<State>({
    dropdownList: QUICK_SELECT_MINUTES,
    timeText: QUICK_SELECT_MINUTES[0].text,
    timeUnitText: QUICK_SELECT_GRANULARITY()[0].text,
  });
  const { dropdownList, timeText, timeUnitText } = state;

  const handleTimeOperation = (valueToSubtract: number, timeUnit: moment.unitOfTime.DurationConstructor) => {
    onUpdateModuleSettings(
      moment()
        .subtract(valueToSubtract, timeUnit)
        .valueOf(),
      moment().valueOf(),
    );
  };

  const onChangeQuickSelect = (quickSelectItem: DropdownItem) => {
    if (quickSelectItem.timeUnit) {
      setState({ ...state, timeText: quickSelectItem.text });
      handleTimeOperation(Number(quickSelectItem.text), quickSelectItem.timeUnit);
    } else {
      switch (quickSelectItem.id) {
        case "minute":
          handleTimeOperation(Number(QUICK_SELECT_MINUTES[0].text), QUICK_SELECT_MINUTES[0].timeUnit);
          return setState({ ...state, dropdownList: QUICK_SELECT_MINUTES, timeText: QUICK_SELECT_MINUTES[0].text, timeUnitText: QUICK_SELECT_GRANULARITY()[0].text });
        case "hour":
          handleTimeOperation(Number(QUICK_SELECT_HOURS[0].text), QUICK_SELECT_HOURS[0].timeUnit);
          return setState({ ...state, dropdownList: QUICK_SELECT_HOURS, timeText: QUICK_SELECT_HOURS[0].text, timeUnitText: QUICK_SELECT_GRANULARITY()[1].text });
        case "week":
          handleTimeOperation(Number(QUICK_SELECT_WEEKS[0].text), QUICK_SELECT_WEEKS[0].timeUnit);
          return setState({ ...state, dropdownList: QUICK_SELECT_WEEKS, timeText: QUICK_SELECT_WEEKS[0].text, timeUnitText: QUICK_SELECT_GRANULARITY()[2].text });
      }
    }
  };

  const onClickPresetButton = (preset: DatePreset) => {
    trackClick(ActivityMessages.filter);
    const dates = getDateRangeByPreset(preset);
    return onUpdateModuleSettings(dates.dateBegin, dates.dateEnd);
  };

  const getFormButtonProps = (datePreset: DatePreset) => ({
    buttonClassName: "advanced-aggregation-preset-button",
    buttonActivatedClassName: "advanced-aggregation-preset-button-activated",
    formPath: "preset",
    moduleDates: { dateBegin: moduleSetting.dateBegin, dateEnd: moduleSetting.dateEnd },
    preset: datePreset,
    onClick: () => onClickPresetButton(datePreset),
    disabled: getDateRangeByPreset(datePreset).dateBegin < datesLimits.begin || getDateRangeByPreset(datePreset).dateEnd > datesLimits.end,
  });

  const isBeginSameAsEnd = isSameDay(moduleSetting.dateBegin, moduleSetting.dateEnd);
  const isFormDropdownDisabled = datesLimits.end < Date.now();

  return (
    <>
      <div className="quick-select">
        <div className="quick-select-title">{translate(quickSelect)}</div>
        <div className="quick-select-dropdowns">
          <div className="last">{translate(last)}</div>
          <FormDropdown
            value={timeText}
            formPath={"timeText"}
            menuItemsList={dropdownList}
            onChange={onChangeQuickSelect}
            placeholder={dropdownList[0].text}
            containerClassName="numeral-select"
            disabled={isFormDropdownDisabled}
          />
          <FormDropdown
            value={timeUnitText}
            formPath={"timeUnitText"}
            menuItemsList={QUICK_SELECT_GRANULARITY()}
            onChange={onChangeQuickSelect}
            placeholder={translate(fieldsSelectors.quickSelect)}
            containerClassName="time-select"
            disabled={isFormDropdownDisabled}
          />
        </div>
      </div>
      <div className="preset">
        <div className="preset-title">{translate(commonlyUsed)}</div>
        <div className="preset-buttons-container">
          <div className="preset-buttons-column-container">
            <FormCustomButton {...getFormButtonProps(DatePreset.LAST_FIFTEEN_MINUTES)} label={translate(lastFifteenMinutes)} />
            <FormCustomButton {...getFormButtonProps(DatePreset.LAST_HOUR)} label={translate(lastHour)} />
            <FormCustomButton {...getFormButtonProps(DatePreset.TODAY)} label={translate(today)} />
          </div>
          <div className="preset-buttons-column-container">
            <FormCustomButton {...getFormButtonProps(DatePreset.LAST_TWO_DAYS)} label={translate(lastTwoDays)} />
            <FormCustomButton {...getFormButtonProps(DatePreset.LAST_WEEK)} label={translate(lastWeek)} />
            <FormCustomButton {...getFormButtonProps(DatePreset.LAST_MONTH)} label={translate(lastMonth)} />
          </div>
        </div>
      </div>
      <div className="custom">
        <div className="custom-title">{translate(custom)}</div>
        <div className="custom-datepicker">
          <FormDatePicker
            formPath="moduleDateBegin"
            id={`custom-start-dates-${keyNumber}`}
            label={translate(field.startDate)}
            value={moduleSetting.dateBegin}
            onChange={dateBegin => onUpdateModuleSettings(dateBegin?.valueOf(), moduleSetting.dateEnd)}
            disabledDate={timestamp => timestamp >= moduleSetting.dateEnd || timestamp >= Date.now() || timestamp < datesLimits.begin}
            containerClassName="aggregation"
          />
          <FormTimePicker
            formPath="moduleDateBegin"
            id={`custom-start-dates-minute-${keyNumber}`}
            valueInMinutes={new Date(moduleSetting.dateBegin).getDayMinute()}
            label="H:min"
            onChange={minutes => onUpdateModuleSettings(new Date(moduleSetting.dateBegin).setDayMinute(minutes), moduleSetting.dateEnd)}
            placeholder="00:00"
            containerClassName="aggregation"
            disabledHours={isBeginSameAsEnd ? getDisabledBeginHours(moduleSetting) : undefined}
            disabledMinutes={isBeginSameAsEnd ? getDisabledBeginMin(moduleSetting) : undefined}
          />
          <FormDatePicker
            formPath="moduleDateEnd"
            id={`custom-end-dates-${keyNumber}`}
            label={translate(field.endDate)}
            value={moduleSetting.dateEnd}
            onChange={dateEnd => onUpdateModuleSettings(moduleSetting.dateBegin, dateEnd?.valueOf())}
            disabledDate={timestamp => timestamp <= moduleSetting.dateBegin || timestamp >= datesLimits.end || timestamp > endOfDay()}
            containerClassName="aggregation"
          />
          <FormTimePicker
            formPath="moduleDateEnd"
            id={`custom-start-dates-minute-${keyNumber}`}
            valueInMinutes={new Date(moduleSetting.dateEnd).getDayMinute()}
            label="H:min"
            onChange={minutes => onUpdateModuleSettings(moduleSetting.dateBegin, new Date(moduleSetting.dateEnd).setDayMinute(minutes))}
            placeholder="00:00"
            containerClassName="aggregation"
            disabledHours={isBeginSameAsEnd ? getDisabledEndHours(moduleSetting) : undefined}
            disabledMinutes={isBeginSameAsEnd ? getDisabledEndMin(moduleSetting) : undefined}
          />
        </div>
      </div>
    </>
  );
};
