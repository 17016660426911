import React, { FunctionComponent } from "react";
import { AiFillFrown } from "react-icons/ai";

import { GENERIC_ERROR_ICON_SIZE } from "../../../constants/ui";
import { translate } from "../../../lang/i18n";
import { translation } from "../../../lang/translation";
import { LoadingModule } from "../Loading";

const { noKpisInSelectedPeriod } = translation.errors;

interface ErrorModuleProps {
  error: string;
}

export const GenericErrorModule: FunctionComponent<ErrorModuleProps> = props => {
  const { error } = props;
  return (
    <div className="module-container">
      <div className="module-background">
        <div className="module-no-kpi">
          <AiFillFrown size={GENERIC_ERROR_ICON_SIZE} />
          <div className="module-no-kpi-text">{translate(error)}</div>
        </div>
      </div>
    </div>
  );
};

export const NoKpiInSelectedPeriodError: FunctionComponent = () => <GenericErrorModule error={noKpisInSelectedPeriod} />;

interface LoadErrorModuleProps {
  isAllDataReady: boolean;
}

export const LoadErrorModule: FunctionComponent<LoadErrorModuleProps> = props => {
  const { isAllDataReady } = props;

  if (!isAllDataReady) return <NoKpiInSelectedPeriodError />;
  return <LoadingModule />;
};
