import { BaseType, select, Selection } from "d3-selection";

import { setIsExporting } from "../../redux/app/app.actions";
import { store } from "../../redux/store";
import { Colors } from "../colors";
import { isDefined } from "../utils";

const TITLE_CLASSNAME = "module-title";
const RELATIVE_POSITION = "relative";
const MODULE_MARGIN = 20;
const EXPORT_LVL_0_CLASS = ".export-0";
const EXPORT_LVL_1_CLASS = ".export-1";
const EXPORT_MATERIAL_CLASS = ".export-material";
const EXPORT_COMPONENTS = ["pdf-front-page", "pdf-header", "pdf-footer", "pdf-module-too-big"];

export type DeepArray<T> = Array<T | DeepArray<T>>;

export interface NodeToExport {
  node: HTMLElement;
  isOversized: boolean;
  title: boolean;
  areAllChildrenOversized?: boolean;
  height: number;
}

export interface LayoutComponent {
  element: HTMLElement;
  height: number;
  width: number;
}

export interface LayoutComponents {
  frontPage: LayoutComponent;
  header: LayoutComponent;
  footer: LayoutComponent;
  oversizedMessage: LayoutComponent;
}

export const changeThemeToReport = (themedNode: HTMLElement) => {
  const themeWrapper = themedNode.querySelector(".wrapper")?.parentElement as HTMLElement;
  themeWrapper?.classList.add("light");
  return themeWrapper;
};

export const setupDomCopy = () => {
  window.scrollTo(0, 0);
  const domCopy = select("#root")
    .clone(true)
    .attr("id", "copy")
    .node() as HTMLElement;

  const wrapper = changeThemeToReport(domCopy).childNodes.item(0) as HTMLElement;
  return { domCopy, wrapper };
};

const replaceClassName = (domSelection: Selection<BaseType, unknown, HTMLElement, any>, className: string) => {
  domSelection
    .selectAll(`.${className}`)
    .classed(className, false)
    .classed(`export-${className}`, true);
};

export const setupDuplicateModuleElements = () => {
  const domCopy = select("#copy");
  domCopy.selectAll(".ignore-export").classed("export-hide", true);
  domCopy.selectAll(".show-in-export").classed("export-show", true);
  domCopy
    .selectAll(EXPORT_LVL_0_CLASS)
    .style("margin-top", "0")
    .style("margin-bottom", "0")
    .style("margin-left", "0")
    .classed("export-title", true);

  domCopy
    .selectAll(".gradient")
    .classed("gradient", false)
    .classed("flat", true);

  domCopy
    .selectAll(".module-title-icon")
    .style("color", Colors.LIGHT_BLUE)
    .selectChildren()
    .filter((d, index) => index === 1)
    .attr("fill", Colors.LIGHT_BLUE)
    .attr("stroke", Colors.LIGHT_BLUE)
    .attr("style", null);

  domCopy
    .select(".inside-mean")
    .selectChildren()
    .filter((d, index) => index === 1)
    .attr("style", null)
    .select("circle")
    .attr("stroke", Colors.LIGHT_BLUE);

  const classesToReplace = [
    "white-font",
    "white-font-70",
    "white-font-80",
    "white-font-50",
    "svg-white-font",
    "label-description",
    "line-color",
    "table-head-line",
    "table-cell-line",
    "table-line",
  ];

  classesToReplace.forEach(className => replaceClassName(domCopy, className));
};

export const getNodeHierarchy = (selectedNode: Selection<HTMLElement, unknown, null, undefined>, contentHeightMax: number, depth = 0): DeepArray<NodeToExport> | NodeToExport => {
  const elementsSelection = selectedNode.selectAll(`.export-${depth}`);

  const node = selectedNode.node();

  if (!node) return [];

  if (elementsSelection.empty()) return { node, isOversized: true, title: false, height: node.clientHeight };

  const areAllChildrenOversized = elementsSelection
    .select(function(d, index) {
      return index !== 0 && this && (this as HTMLElement).clientHeight <= contentHeightMax ? this : null;
    })
    .empty();
  if (areAllChildrenOversized) return { node, isOversized: true, title: false, areAllChildrenOversized, height: node.clientHeight };

  const elements = elementsSelection.nodes() as HTMLElement[];
  return elements.map(element => {
    if (element.clientHeight > contentHeightMax) return getNodeHierarchy(select(element), contentHeightMax, depth + 1);
    if (element.classList.contains(TITLE_CLASSNAME)) return { node: element, isOversized: false, title: true, height: element.clientHeight };
    return { node: element, isOversized: false, title: false, height: element.clientHeight };
  });
};

export const cutModuleInChunks = (nodesToExport: NodeToExport[]) =>
  nodesToExport
    .map((nodeToExport, index) => {
      if (nodesToExport[index - 1] && nodesToExport[index - 1].title) {
        return;
      }
      if (nodeToExport.title || nodeToExport.node.classList.contains(EXPORT_LVL_1_CLASS.slice(1))) {
        const module = nodeToExport.node.closest(EXPORT_LVL_0_CLASS) as HTMLElement;
        const selectedModule = select(module);
        const clone = selectedModule.clone(true);
        let isOversized = nodeToExport.isOversized;
        let title = false;
        if (nodeToExport.title) {
          selectedModule
            .selectAll(EXPORT_LVL_1_CLASS)
            .filter((d, index) => index <= 1)
            .remove();

          clone
            .selectAll(EXPORT_LVL_1_CLASS)
            .filter((d, index) => index > 1)
            .remove();

          isOversized = nodesToExport[index + 1].isOversized;
          title = true;
        } else {
          selectedModule
            .selectAll(EXPORT_LVL_1_CLASS)
            .filter((d, index) => index < 1)
            .remove();

          clone
            .selectAll(EXPORT_LVL_1_CLASS)
            .filter((d, index) => index >= 1)
            .remove();
        }
        const node = clone.node();
        if (node?.previousElementSibling) node.parentNode?.insertBefore(node, node?.previousElementSibling);
        return { node, isOversized, title, height: node?.clientHeight };
      }
      return nodeToExport;
    })
    .filter(nodeToExport => isDefined(nodeToExport)) as NodeToExport[];

const copyAndInsert = (element: HTMLElement, position: number) => () =>
  select(element)
    .clone(true)
    .style("position", "absolute")
    .style("top", `${position}px`)
    .node();

export const insertOversized = (nodesToExport: NodeToExport[], messageOversized: HTMLElement) => {
  nodesToExport.forEach(nodeToExport => {
    if (nodeToExport.isOversized) {
      const nodeToClear = (() => {
        if (nodeToExport.areAllChildrenOversized || nodeToExport.node.classList.contains(EXPORT_LVL_0_CLASS.slice(1))) {
          return nodeToExport.node.lastChild as HTMLElement;
        }
        return nodeToExport.node;
      })();
      const selection = select(nodeToClear);
      selection.selectChildren().remove();
      selection
        .style("position", RELATIVE_POSITION)
        .style("height", `${messageOversized.clientHeight}px`)
        .style("padding", "inherit 0 0 0")
        .append(copyAndInsert(messageOversized, 0));
      selection.filter(":first-child").style("left", "0");
      nodeToExport.height = nodeToExport.node.clientHeight;
    }
  });
};

export const restoreDom = (domCopy: HTMLElement) => {
  domCopy.remove();
  setIsExporting(false)(store.dispatch);
  document.body.style.overflow = "visible";
};

export const getPages = (nodes: NodeToExport[], layoutComponents: LayoutComponents) => {
  const { footer, frontPage, header } = layoutComponents;
  return nodes.reduce((pages, node) => {
    if (!pages.length) {
      return [[node]];
    }

    const canvasHeight = pages.last().reduce((acc, curr) => acc + curr.height, 0) + node.height;

    if (header.height + footer.height + canvasHeight <= frontPage.height) {
      pages.last().push(node);
      return pages;
    }
    return [...pages, [node]];
  }, [] as NodeToExport[][]);
};

const copyAndInsertBefore = (insert: HTMLElement, nextNode: HTMLElement) => {
  const copy = insert.cloneNode(true) as HTMLElement;
  nextNode.parentNode?.insertBefore(copy, nextNode);
};

const copyAndInsertAfter = (insert: HTMLElement, nextNode: HTMLElement) => {
  const copy = insert.cloneNode(true) as HTMLElement;
  nextNode.parentNode?.insertBefore(copy, nextNode.nextElementSibling);
};

export const createPagesLayout = (wrapper: HTMLElement, pages: NodeToExport[][], layoutComponents: LayoutComponents) => {
  const { footer, frontPage, header } = layoutComponents;

  copyAndInsertBefore(frontPage.element, pages[0][0].node);

  pages.forEach(page => {
    page.forEach((nodeToExport, index) => {
      nodeToExport.node.style.margin = "0 inherit";

      if (index === page.length - 1) {
        if (page.length === 1) {
          copyAndInsertBefore(header.element, nodeToExport.node);
        }
        const totalModulesHeight = page.map(nodeToExport => nodeToExport.height).sum();
        const lastElementMarginBottom = frontPage.height - totalModulesHeight - header.height - footer.height - MODULE_MARGIN * (page.length - 1);

        nodeToExport.node.style.marginTop = `${MODULE_MARGIN}px`;
        nodeToExport.node.style.marginBottom = `${lastElementMarginBottom}px`;
        copyAndInsertAfter(footer.element, nodeToExport.node);
      } else if (index === 0) {
        copyAndInsertBefore(header.element, nodeToExport.node);
      } else {
        nodeToExport.node.style.marginTop = `${MODULE_MARGIN}px`;
      }
    });
  });
  wrapper.querySelector(EXPORT_MATERIAL_CLASS)?.remove();
};

export const getChunks = (wrapper: HTMLElement, pages: NodeToExport[][]) => {
  const chunks = pages
    .map((page, index) => {
      if ((index + 1) % 2 === 1 || index === pages.length - 1) {
        const footer = page.last().node.nextElementSibling;
        if (footer) {
          const parent = footer.parentNode;
          const nodeIndex = Array.prototype.indexOf.call(parent?.children, footer);
          const selectAll = select(wrapper);
          const clone = selectAll.clone(true);

          selectAll
            .selectChildren()
            .filter((d, index) => index <= nodeIndex)
            .remove();

          clone
            .selectChildren()
            .filter((d, index) => index > nodeIndex)
            .remove();

          return clone.style("background", Colors.WHITE).node();
        }
      }
    })
    .filter(chunk => isDefined(chunk)) as HTMLElement[];

  wrapper.remove();
  return chunks;
};

const getLayoutComponent = (idList: string[]): LayoutComponent[] =>
  idList.map(id => {
    const element = document.querySelector(`#${id}`) as HTMLElement;
    return { element, height: element.clientHeight, width: element.clientWidth };
  });

export const getLayoutComponents = () => {
  const [frontPage, header, footer, oversizedMessage] = getLayoutComponent(EXPORT_COMPONENTS);
  return { frontPage, header, footer, oversizedMessage };
};
