import { HOUR_IN_SECONDS, MINUTE_IN_SECONDS, UserLanguage } from "@technis/shared";
import * as moment from "moment";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";

import { Dates, UI } from "../constants";
import { TIME_FORMAT_EN, TIME_FORMAT_FR } from "../constants/date";
import { useSelector } from "../hooks";
import { SupportedLanguages, translate } from "../lang/i18n";
import { saveTimeFormat, saveTimeFormatOptionFromLanguage } from "../redux/userOptions/userOptions.actions";
import { AggregatedDates, DateRange, Granularity } from "./aggregate";
import { floor, getDaylightSavingTimeFromKpi, signedModulo } from "./utils";

const {
  DAYS_IN_WEEK,
  DAYS_IN_YEAR,
  MILLISECONDS_IN_DAY,
  MILLISECONDS_IN_MINUTE,
  MILLISECONDS_IN_SECOND,
  MILLISECONDS_IN_WEEK,
  MILLISECONDS_IN_YEAR,
  MINUTES_IN_DAY,
  MINUTES_IN_HOUR,
} = Dates;

const { TIMEPICKER_LARGE, TIMEPICKER_SMALL } = UI;

export enum DatePreset {
  LAST_FIFTEEN_MINUTES = "lastFifteenMinutes",
  LAST_HOUR = "lastHour",
  TODAY = "today",
  LAST_TWO_DAYS = "lastTwoDays",
  LAST_WEEK = "lastWeek",
  LAST_TWO_WEEKS = "lastTwoWeeks",
  LAST_MONTH = "lastMonth",
}

export enum TimeFormats {
  ENGLISH_FORMAT = "englishformat",
  FRENCH_FORMAT = "frenchFormat",
}

export const startOfDay = (time?: number) =>
  moment(time)
    .startOf("day")
    .valueOf();

export const endOfDay = (time?: number) =>
  moment(time)
    .endOf("day")
    .valueOf();

export const getDateRangeByPreset = (preset: DatePreset) => {
  switch (preset) {
    case DatePreset.LAST_FIFTEEN_MINUTES:
      // eslint-disable-next-line prettier/prettier
      return {
        dateBegin: moment()
          .startOf("minute")
          .subtract(15, "minute")
          .valueOf(),
        dateEnd: moment()
          .endOf("minute")
          .valueOf(),
      };
    case DatePreset.LAST_HOUR:
      // eslint-disable-next-line prettier/prettier
      return {
        dateBegin: moment()
          .startOf("minute")
          .subtract(1, "hour")
          .valueOf(),
        dateEnd: moment()
          .endOf("minute")
          .valueOf(),
      };
    case DatePreset.LAST_TWO_DAYS:
      // eslint-disable-next-line prettier/prettier
      return {
        dateBegin: moment(startOfDay())
          .subtract(1, "day")
          .valueOf(),
        dateEnd: endOfDay(),
      };
    case DatePreset.LAST_WEEK:
      // eslint-disable-next-line prettier/prettier
      return {
        dateBegin: moment(startOfDay())
          .subtract(6, "day")
          .valueOf(),
        dateEnd: endOfDay(),
      };
    case DatePreset.LAST_TWO_WEEKS:
      // eslint-disable-next-line prettier/prettier
      return {
        dateBegin: moment(startOfDay())
          .subtract(13, "day")
          .valueOf(),
        dateEnd: endOfDay(),
      };
    case DatePreset.LAST_MONTH:
      // eslint-disable-next-line prettier/prettier
      return {
        dateBegin: moment(startOfDay())
          .subtract(1, "month")
          .valueOf(),
        dateEnd: endOfDay(),
      };
    case DatePreset.TODAY:
    default:
      return { dateBegin: startOfDay(), dateEnd: endOfDay() };
  }
};
export const createModuleAggregatButtonList = (buttons: DatePreset[]) =>
  buttons.map(button => ({ buttonId: button, buttonName: translate(`time.timeRanges.${button.toString()}`), currentClass: "module-aggregation-button" }));
export const createHeaderAggregatButtonList = (buttons: DatePreset[]) =>
  buttons.map(button => ({ buttonId: button, buttonName: translate(`time.timeRanges.${button.toString()}`), currentClass: "header-aggregation-button" }));

export const updateModuleDate = (set: React.Dispatch<React.SetStateAction<DateRange>>) => (dates: DateRange) => {
  set(dates);
};

export const isSameDay = (dateBegin: number, dateEnd: number) => startOfDay(dateBegin) === startOfDay(dateEnd);

export const convertFromMilliToSec = (time: number) => Math.floor(time / MILLISECONDS_IN_SECOND);

export const getDayInterval = (tickNumber: number): number => {
  const fiveMinutesTickInterval = tickNumber > 0 && tickNumber <= MINUTES_IN_HOUR / 2;
  if (fiveMinutesTickInterval) return MINUTES_IN_HOUR / 12;

  const tenMinutesTickInterval = tickNumber <= MINUTES_IN_HOUR;
  if (tenMinutesTickInterval) return MINUTES_IN_HOUR / 6;

  const fifteenMinutesTickInterval = tickNumber <= 3 * MINUTES_IN_HOUR;
  if (fifteenMinutesTickInterval) return MINUTES_IN_HOUR / 4;

  const thirtyMinutesTickInterval = tickNumber <= 6 * MINUTES_IN_HOUR;
  if (thirtyMinutesTickInterval) return MINUTES_IN_HOUR / 2;

  const twoHoursTickInterval = tickNumber > 6 * MINUTES_IN_HOUR;
  if (twoHoursTickInterval) return Math.floor(MINUTES_IN_DAY / 12);

  return 1;
};

export const getInterval = (tickNumber: number, granularity: Granularity) => {
  switch (granularity) {
    case Granularity.CONTINUOUS_WEEKS:
      return tickNumber / 7 - 1;
    case Granularity.CONTINUOUS_DAYS:
    case Granularity.DISCONTINUOUS_DAYS:
    case Granularity.DISCONTINUOUS_WORKING_DAYS:
    case Granularity.DISCONTINUOUS_WEEKEND_DAYS:
      return getDayInterval(tickNumber);
  }
  return 1;
};

export const getTicks = (dataLength: number, interval: number, tickBorders: { startTick: number; endTick: number }) =>
  new Array(Math.round(dataLength / interval + 1))
    .fill(0)
    .map((_, index) => index * interval)
    .filter(tick => tick >= tickBorders.startTick && tick <= tickBorders.endTick);

export const getMinutesFromStartOfDay = (timestamp: number) => {
  const dateForDay = new Date(timestamp);
  return dateForDay.getHours() * MINUTES_IN_HOUR + dateForDay.getMinutes();
};

export const truncateDateRangeToMinute = (dateRange: DateRange) => {
  const truncatedDateBegin = moment(dateRange.dateBegin)
    .seconds(0)
    .milliseconds(0)
    .valueOf();
  const truncatedDateEnd = moment(dateRange.dateEnd)
    .seconds(0)
    .milliseconds(0)
    .valueOf();
  return { dateBegin: truncatedDateBegin, dateEnd: truncatedDateEnd + MILLISECONDS_IN_MINUTE };
};

// eslint-disable-next-line sonarjs/cognitive-complexity
export const aggregateDates = (kpi: { dateBegin: number; dateEnd: number }[], granularity: Granularity, dateRange: DateRange): AggregatedDates => {
  const diffDateBegin = dateRange.dateBegin - kpi[0].dateBegin;
  const dateBegins = kpi.reduce((acc, curr, index) => {
    switch (granularity) {
      case Granularity.DISCONTINUOUS_DAYS:
      case Granularity.DISCONTINUOUS_WEEKEND_DAYS:
      case Granularity.DISCONTINUOUS_WORKING_DAYS:
      case Granularity.CONTINUOUS_DAYS:
        return [...acc, curr.dateBegin + signedModulo(diffDateBegin, MILLISECONDS_IN_DAY)];
      case Granularity.CONTINUOUS_WEEKS:
        if (index % DAYS_IN_WEEK === DAYS_IN_WEEK) return [...acc, curr.dateBegin + signedModulo(diffDateBegin, MILLISECONDS_IN_WEEK)];
        break;
      case Granularity.CONTINUOUS_MONTHS:
        const daysMonth = moment(curr.dateBegin).daysInMonth();
        if (index % daysMonth) return [...acc, curr.dateBegin + signedModulo(diffDateBegin, daysMonth * MILLISECONDS_IN_DAY)];
        break;
      case Granularity.CONTINUOUS_YEARS:
        if (index % DAYS_IN_YEAR === 0) return [...acc, curr.dateBegin + signedModulo(diffDateBegin, MILLISECONDS_IN_YEAR)];
    }
    return acc;
  }, [] as number[]);

  const dateEnds = kpi.reduce((acc, curr, index) => {
    switch (granularity) {
      case Granularity.DISCONTINUOUS_DAYS:
      case Granularity.DISCONTINUOUS_WEEKEND_DAYS:
      case Granularity.DISCONTINUOUS_WORKING_DAYS:
      case Granularity.CONTINUOUS_DAYS:
        return [...acc, curr.dateEnd + signedModulo(diffDateBegin, MILLISECONDS_IN_DAY)];
      case Granularity.CONTINUOUS_WEEKS:
        if (index % DAYS_IN_WEEK === DAYS_IN_WEEK - 1) return [...acc, curr.dateEnd + signedModulo(diffDateBegin, MILLISECONDS_IN_WEEK)];
        break;
      case Granularity.CONTINUOUS_MONTHS:
        const daysMonth = moment(curr.dateBegin).daysInMonth();
        if (index % daysMonth) return [...acc, curr.dateEnd + signedModulo(diffDateBegin, daysMonth * MILLISECONDS_IN_DAY)];
        break;
      case Granularity.CONTINUOUS_YEARS:
        if (index % DAYS_IN_YEAR === DAYS_IN_YEAR - 1) return [...acc, curr.dateEnd + signedModulo(diffDateBegin, MILLISECONDS_IN_YEAR)];
    }
    return [...acc, curr.dateEnd];
  }, [] as number[]);

  const filteredDateBegins = dateBegins.filter(dateBegin => moment(dateBegin).isBetween(dateRange.dateBegin, dateRange.dateEnd, "minute", "[)"));
  const filteredDateEnds = dateEnds.filter(dateEnd => moment(dateEnd).isBetween(dateRange.dateBegin, dateRange.dateEnd, "minute", "[]"));

  if (filteredDateEnds.length < filteredDateBegins.length) filteredDateEnds.push(dateRange.dateEnd);

  if (filteredDateEnds.last() < filteredDateBegins.last()) {
    filteredDateEnds[filteredDateEnds.length - 2] = filteredDateEnds[filteredDateEnds.length - 1];
    filteredDateBegins.pop();
    filteredDateEnds.pop();
  }

  const daylightSavingTimes = filteredDateBegins.map((dateBegin, index) => getDaylightSavingTimeFromKpi(dateBegin, dateEnds[index]));

  return { dateBegins: filteredDateBegins, dateEnds: filteredDateEnds, granularity, daylightSavingTimes };
};

export const getDateRangeCaption = (dateRange: DateRange, format = "D MMM Y H[h]mm") =>
  `${moment(dateRange.dateBegin).format(format)} - ${moment(dateRange.dateEnd).format(format)}`;

export const getDateCaption = (timestamp: number, format = "D MMM Y HH[:]mm[:]ss") => moment(timestamp).format(format);

export const getFormattedDate = (format: string) => (timestamp: number) => moment(timestamp).format(format);

export const getHourMinSec = (durationInSeconds: number) => {
  const hours = floor(durationInSeconds / HOUR_IN_SECONDS);
  const minutes = floor((durationInSeconds % HOUR_IN_SECONDS) / MINUTES_IN_HOUR);
  const seconds = floor(durationInSeconds) % MINUTE_IN_SECONDS;
  return { hours, minutes, seconds };
};

const getTimeFormatFromLanguage = (language?: SupportedLanguages | UserLanguage) => {
  const timeFormatMap: Record<SupportedLanguages | UserLanguage, string | undefined> = {
    [SupportedLanguages.ENGLISH]: TIME_FORMAT_EN,
    [UserLanguage.EN]: TIME_FORMAT_EN,
    [SupportedLanguages.FRENCH]: TIME_FORMAT_FR,
    [UserLanguage.FR]: TIME_FORMAT_FR,
    [UserLanguage.AR]: undefined,
    [UserLanguage.ES]: undefined,
    [UserLanguage.DE]: undefined,
    [UserLanguage.IT]: undefined,
  };
  if (!language || !timeFormatMap[language]) return timeFormatMap[SupportedLanguages.ENGLISH];
  return timeFormatMap[language];
};

export const getTimeFormat = (userTimeFormat?: TimeFormats, language?: SupportedLanguages | UserLanguage, useTimeFormatFromLanguage?: boolean) => {
  const timeFormatMap: Record<TimeFormats, string> = {
    [TimeFormats.ENGLISH_FORMAT]: TIME_FORMAT_EN,
    [TimeFormats.FRENCH_FORMAT]: TIME_FORMAT_FR,
  };
  if (useTimeFormatFromLanguage) return getTimeFormatFromLanguage(language);
  if (!userTimeFormat) return timeFormatMap[TimeFormats.ENGLISH_FORMAT];
  return timeFormatMap[userTimeFormat];
};

export const setTimeFormat = (dispatch: Dispatch<any>, useTimeFormatFromLanguage?: boolean, userTimeFormat?: TimeFormats) => {
  if (!userTimeFormat || useTimeFormatFromLanguage) {
    dispatch(saveTimeFormatOptionFromLanguage(true));
    return;
  }
  dispatch(saveTimeFormat(userTimeFormat));
};

export const getTimeFormatStyleFromLanguage = (language?: SupportedLanguages | UserLanguage) => {
  const timeFormatMap: Record<SupportedLanguages | UserLanguage, string | undefined> = {
    [SupportedLanguages.ENGLISH]: TIMEPICKER_LARGE,
    [UserLanguage.EN]: TIMEPICKER_LARGE,
    [SupportedLanguages.FRENCH]: TIMEPICKER_SMALL,
    [UserLanguage.FR]: TIMEPICKER_SMALL,
    [UserLanguage.AR]: undefined,
    [UserLanguage.ES]: undefined,
    [UserLanguage.DE]: undefined,
    [UserLanguage.IT]: undefined,
  };
  if (!language || !timeFormatMap[language]) return timeFormatMap[SupportedLanguages.ENGLISH];
  return timeFormatMap[language];
};

export const getTimeFormatStyle = (timeFormat?: TimeFormats, language?: SupportedLanguages | UserLanguage, useTimeFormatFromLanguage?: boolean) => {
  const timeFormatMap: Record<TimeFormats, string> = {
    [TimeFormats.ENGLISH_FORMAT]: TIMEPICKER_LARGE,
    [TimeFormats.FRENCH_FORMAT]: TIMEPICKER_SMALL,
  };

  if (useTimeFormatFromLanguage) return getTimeFormatStyleFromLanguage(language);
  if (!timeFormat) return timeFormatMap[TimeFormats.ENGLISH_FORMAT];
  return timeFormatMap[timeFormat];
};
