import { Indexable } from "@technis/shared";
import classnames from "classnames";
import React, { FunctionComponent, ReactNode, useState } from "react";
import { IconType } from "react-icons";

import { Dates } from "../../../constants";
import { translate } from "../../../lang/i18n";
import { translation } from "../../../lang/translation";
import { DateRange } from "../../../utils/aggregate";
import { startOfDay } from "../../../utils/time";
import { CustomButton } from "../buttons/CustomButton";
import { HeaderPresets } from "../HeaderPresets";
import { InlineCreatePopover } from "../InlineCreatePopover";
import { LoadingChart } from "../Loading";
import { AdvancedAggregationSettings } from "./ChartModule";
import { ModuleBody } from "./ModulBody";
import { ModuleTitle } from "./ModuleTitle";

const { MILLISECONDS_IN_MINUTE } = Dates;

interface ModuleHeaderProps {
  title: string;
  dates: DateRange;
  onUpdateDate: (dates: DateRange) => void;
  onDelete: () => void;
  onSubmit: (callback: () => void) => void;
  children: ReactNode;
  Icon: IconType;
  iconSize: number;
  tooltipText: string;
  fields: React.ReactNode;
  initialValues: {
    moduleDateBegin: number;
    moduleDateEnd: number;
  };
  zoneName?: string;
  isExportable?: boolean;
  isLoading: boolean;
  radio?: { groups: ReactNode[]; key: string };
  datesLimits?: {
    begin: number;
    end: number;
  };
  moduleSetting: AdvancedAggregationSettings;
}

export const ModuleHeader: FunctionComponent<ModuleHeaderProps> = props => {
  const {
    children,
    dates,
    datesLimits,
    fields,
    Icon,
    iconSize,
    initialValues,
    isExportable,
    isLoading,
    moduleSetting,
    onDelete,
    onSubmit,
    onUpdateDate,
    radio,
    title,
    tooltipText,
    zoneName,
  } = props;

  const [iAdvancedActivated, setAdvancedActivated] = useState(false);

  return (
    <div className={classnames("module-container", { "export-0": isExportable })}>
      <ModuleTitle Icon={Icon} iconSize={iconSize} title={title} tooltipText={tooltipText} zoneName={zoneName} />
      <div className="module-header ignore-export">
        <div className="module-header-line">
          <HeaderPresets
            dates={dates}
            datesLimits={datesLimits}
            onUpdateDate={onUpdateDate}
            containerClassName="module-header-preset"
            buttonClassName="module-aggregation-button"
            buttonActivatedClassName="module-activated-button"
            buttonTextClassName="aggregation-button-text"
          />
          <InlineCreatePopover
            fields={fields}
            onSubmit={callBack => {
              setAdvancedActivated(false);
              onSubmit(callBack);
            }}
            triggerContent={
              <CustomButton
                buttonDisplay={translate(translation.common.advanced)}
                buttonClassName="advanced-aggregation-button"
                onClick={() => setAdvancedActivated(true)}
                disabled={iAdvancedActivated}
              />
            }
            validator={(values: { moduleDateEnd: number; moduleDateBegin: number }) => {
              const errors: Indexable<string> = {};
              const dateBegin = values.moduleDateBegin < 1440 ? startOfDay(moduleSetting.dateBegin) + values.moduleDateBegin * MILLISECONDS_IN_MINUTE : values.moduleDateBegin;
              const dateEnd = values.moduleDateEnd < 1440 ? startOfDay(moduleSetting.dateBegin) + values.moduleDateEnd * MILLISECONDS_IN_MINUTE : values.moduleDateEnd;
              if (!datesLimits) {
                return errors;
              }
              if (dateEnd > datesLimits.end) {
                errors["moduleDateEnd"] = "";
              }
              if (dateBegin < datesLimits.begin) {
                errors["moduleDateBegin"] = "";
              }
              return errors;
            }}
            initialValues={initialValues}
            onClickOutside={() => setAdvancedActivated(false)}
          />
        </div>
        {radio &&
          radio.groups.map((radioGroup, index) => (
            <div key={`${radio.key}-radio-${index}`} className="module-header-line">
              {radioGroup}
            </div>
          ))}
      </div>
      <ModuleBody onDelete={onDelete}>{isLoading ? <LoadingChart /> : children}</ModuleBody>
    </div>
  );
};
