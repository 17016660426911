export interface Slope {
  index: number;
  inside: number;
  slope: number;
}

const getTotals = (data: number[][]) => data.map(affluence => affluence.sum());

export const getTotal = (data: number[][]) => getTotals(data).sum();

export const getItemByMaxIndex = (dates: number[], data: number[][]) => {
  const totals = getTotals(data);
  const max = totals.max();

  return dates[totals.findIndex(value => value === max)];
};
