import { ID } from "@technis/shared";
import * as moment from "moment";

import { endOfDay, startOfDay } from "../time";
import { BaseAggregator } from "./BaseAggregator";
import {
  fixKpiArrayLength,
  generatePlaceholderKpi,
  getAverageDwell,
  getDiscontinuousDaysForCounting,
  getFirstDateBegin,
  isShifting,
  movingAverage,
  weekendDaysFilter,
  workingDaysFilter,
} from "./helpers";
import { DateRange, Granularity, KpiCountingWithDates } from "./types";

export interface AggregatedKpiCounting {
  granularity: Granularity;
  dateBegins: number[];
  dateEnds: number[];
  eventId?: ID;
  inside?: number[][];
  affluenceIn?: number[][];
  affluenceOut?: number[][];
  affluenceMinIn?: number[][];
  affluenceMinOut?: number[][];
  dwell?: number[];
  zoneId?: ID;
  passageId?: ID;
  countMinMax?: number[];
  shift: number;
  isShifting: boolean;
  averageDwell?: number;
}

interface KpiCountingAggregatorInterface {
  getAggregatedData(): AggregatedKpiCounting;
  filterBy(): AggregatedKpiCounting;
}

interface KpiCountingAggregatorProps {
  granularity: Granularity;
  kpis: KpiCountingWithDates[];
  timeWindow?: number;
  isDataShifting?: boolean;
  dateRange?: DateRange;
  countMinMax?: number[];
}

interface AggregationData {
  dateBegin: number;
  dateEnd: number;
  affluenceMinIn: number[];
  affluenceMinOut: number[];
  inside: number[];
}

interface AggregatedData {
  [key: string]: AggregationData;
}

export type KpiPropertiesArrayValue = "inside" | "affluenceMinIn" | "affluenceMinOut";

type AggregateProps<T extends KpiCountingWithDates | AggregationData> = Pick<T, KpiPropertiesArrayValue>;

const AGGREGATE_PROPS: KpiPropertiesArrayValue[] = ["affluenceMinIn", "affluenceMinOut", "inside"];

export class KpiCountingAggregator extends BaseAggregator implements KpiCountingAggregatorInterface {
  private aggregatedData: AggregatedKpiCounting;
  private kpisWithCorrectedDates: KpiCountingWithDates[];
  private dateBegin: number;
  private kpis: KpiCountingWithDates[];
  private countMinMax?: number[];

  constructor({ countMinMax, dateRange, granularity, isDataShifting, kpis, timeWindow }: KpiCountingAggregatorProps) {
    super({
      granularity,
      timeWindow,
      dateRange,
      shouldDataShift: isDataShifting,
      firstDateBegin: getFirstDateBegin(kpis, dateRange, granularity),
    });

    this.kpis = generatePlaceholderKpi(fixKpiArrayLength(kpis), this.getPeriodSize(dateRange?.dateBegin || kpis[0].dateBegin), dateRange);

    this.countMinMax = countMinMax;
    this.dateBegin = this.kpis[0].dateBegin;
    this.kpisWithCorrectedDates = this.getKpisWithCorrectedDate<KpiCountingWithDates, keyof AggregateProps<KpiCountingWithDates>>(this.kpis, AGGREGATE_PROPS, dateRange).sortBy(
      "dateBegin",
    );

    this.aggregatedData = this.aggregate(this.kpisWithCorrectedDates, getAverageDwell(kpis));
  }

  private aggregate = (kpis: KpiCountingWithDates[], averageDwell: number): AggregatedKpiCounting => {
    const { eventId, passageId, zoneId } = (kpis && kpis[0]) || {};
    const aggregatedData: AggregatedData = {};

    kpis
      .filter(kpi => kpi.dateBegin < endOfDay())
      .forEach(kpi => {
        const { dateBegin: kpiDateBegin } = kpi;
        const key = this.getKey(kpiDateBegin);
        const periodSize = this.getPeriodSize(kpiDateBegin);
        const startIndex = this.getStartIndexByDate(kpiDateBegin);

        if (!aggregatedData[key]) {
          aggregatedData[key] = this.getEmptyAggregationData(kpiDateBegin, periodSize);
        }

        AGGREGATE_PROPS.forEach(property => {
          aggregatedData[key][property as keyof AggregateProps<AggregationData>].replaceRangeAt(kpi[property as keyof AggregateProps<KpiCountingWithDates>] || [], startIndex);
        });
      });

    const shift = this.shouldDataShift ? moment(this.dateBegin).diff(moment(startOfDay(this.dateBegin)), "minute") : 0;

    return {
      eventId,
      passageId,
      zoneId,
      granularity: this.granularity,
      shift,
      countMinMax: this.countMinMax,
      isShifting: isShifting(this.kpis),
      averageDwell,
      ...this.getAggregatedKpiCounting(aggregatedData, averageDwell),
    };
  };

  // TODO: filter, rechunk the data by the given parameters e.g.: every second week
  public filterBy = () => this.getAggregatedData();

  // return with aggregated data
  public getAggregatedData = () => {
    const { aggregatedData, granularity, timeWindow } = this;

    const data = timeWindow ? movingAverage<AggregatedKpiCounting>({ data: aggregatedData, granularity, timeWindow, properties: AGGREGATE_PROPS }) : aggregatedData;

    const shiftedData = this.dateRangeFilter<AggregatedKpiCounting>(this.shiftFilter<AggregatedKpiCounting, KpiCountingWithDates>(data));

    switch (this.granularity) {
      case Granularity.DISCONTINUOUS_DAYS:
        return getDiscontinuousDaysForCounting(aggregatedData, AGGREGATE_PROPS);
      case Granularity.DISCONTINUOUS_WEEKEND_DAYS:
        return weekendDaysFilter<AggregatedKpiCounting>(aggregatedData);
      case Granularity.DISCONTINUOUS_WORKING_DAYS:
        return workingDaysFilter<AggregatedKpiCounting>(aggregatedData);
      default:
        return shiftedData;
    }
  };

  private getAggregatedKpiCounting = (data: AggregatedData, averageDwell: number) =>
    Object.values(data)
      .sortBy("dateBegin")
      .reduce(
        (acc, { affluenceMinIn, affluenceMinOut, dateBegin, dateEnd, inside }) => ({
          ...acc,
          dateBegins: [...acc.dateBegins, dateBegin],
          dateEnds: [...acc.dateEnds, dateEnd],
          affluenceMinIn: [...(acc.affluenceMinIn || []), affluenceMinIn || []],
          affluenceMinOut: [...(acc.affluenceMinOut || []), affluenceMinOut || []],
          inside: [...(acc.inside || []), inside || []],
          dwell: [...(acc.dwell || []), averageDwell],
        }),
        {
          dateBegins: [],
          dateEnds: [],
          dwell: [],
          inside: [],
          affluenceMinIn: [],
          affluenceMinOut: [],
        } as Pick<AggregatedKpiCounting, "dateBegins" | "dateEnds" | "dwell" | "inside" | "affluenceMinIn" | "affluenceMinOut">,
      );

  private getEmptyAggregationData = (timeStamp: number, periodSize: number): AggregationData => {
    const emptyPeriod = new Array(periodSize);
    const date = moment(timeStamp);
    const dateBegin = date.startOf(this.granularityDuration).valueOf();
    const dateEnd = date.endOf(this.granularityDuration).valueOf();

    return {
      dateBegin,
      dateEnd,
      affluenceMinIn: [...emptyPeriod],
      affluenceMinOut: [...emptyPeriod],
      inside: [...emptyPeriod],
    };
  };
}
