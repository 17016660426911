import { Event, ID, Installation, Maybe, NeededOnly, Passage, Zone } from "@technis/shared";
import gql from "graphql-tag";

import { PadData } from "./pad.gql";
import { CommonZoneData, TopZoneData, ZoneData } from "./zones.gql";

export const EventData = `
    id
    name
    startAt
    endAt
`;

export const EventDataFull = `
    ${EventData}
    topZone {
        ${CommonZoneData}
        ${TopZoneData}
        ... on ZoneCommon {
            pads {
                ${PadData}
            }
        }
    }
    zones {
        ${ZoneData}
    }
    periods(dates: [${Date.now()}]) {
        ... on PeriodCommon {
            id
            startAt
            endAt
        }
    }
`;

export type EventsByOrganizationResult = {
  eventsByOrganization: Array<
    NeededOnly<Event, "id" | "name" | "dateBegin" | "dateEnd"> & {
      topZone: Maybe<NeededOnly<Zone, "name">>;
    }
  >;
};
export const eventsByOrganization = gql`
  query eventsByOrganization($organizationId: Int!) {
    eventsByOrganization(organizationId: $organizationId) {
      id
      name
      topZoneId
      startAt
      endAt
      topZone {
        id
        name
      }
    }
  }
`;

export type EventsByUserResult = { eventsByUser: Event[] };
export const eventsByUser = gql`
    query eventsByUser {
        eventsByUser {
            ${EventData}
        }
    }
`;

export type EventByIdResult = {
  eventById: NeededOnly<Event, "id" | "dateBegin" | "name"> & {
    zones: Maybe<NeededOnly<Zone, "id">[]>;
  };
};
export const eventById = gql`
  query eventById($eventId: Int!) {
    eventById(eventId: $eventId) {
      id
      name
      startAt
      endAt
      zones {
        ... on ZoneCommon {
          id
        }
      }
    }
  }
`;

export type EventDetailsByIdResult = {
  eventById: NeededOnly<Event, "id" | "dateBegin" | "dateEnd" | "name"> & {
    description: string;
    topZone: {
      id: ID;
      name: string;
    };
  };
};
export const eventDetailsById = gql`
  query eventById($eventId: Int!) {
    eventById(eventId: $eventId) {
      id
      name
      startAt
      endAt
      description
      topZone {
        id
        name
      }
    }
  }
`;

export type EventInstallationZones = Array<
  Pick<Zone, "id" | "name" | "childIds" | "parentIds" | "flowRate"> & {
    passages: Array<Pick<Passage, "id" | "name">>;
  }
>;

export type EventInstallation = Pick<Installation, "id" | "name"> & {
  zones: EventInstallationZones;
};

export type EventByIdExtraInstallation = {
  eventById: Pick<Event, "name" | "limit" | "dateEnd" | "dateBegin" | "metrics"> & {
    installation: EventInstallation;
  };
};

export const eventByIdExtraInstallation = gql`
  query eventById($eventId: Int!) {
    eventById(eventId: $eventId) {
      name
      dateBegin
      dateEnd
      limit
      metrics
      installation {
        id
        name
        zones {
          id
          name
          childIds
          parentIds
          flowRate
          passages {
            id
            name
          }
        }
      }
    }
  }
`;
