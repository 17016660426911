import classNames from "classnames";
import React, { FunctionComponent, PureComponent } from "react";

import { translate } from "../../lang/i18n";
import { TechnisLogo } from "../common/svg/TechnisLogo";

type TextSVGProps = {
  text: string;
  width: string;
  height: string;
  align: "left" | "right" | "middle";
  fontSize: number;
};

const TextSVG: FunctionComponent<TextSVGProps> = ({ align, fontSize, height, text, width }) => {
  let x;
  switch (align) {
    case "left":
      x = "40%";
      break;
    case "right":
      x = "60%";
      break;
    default:
      x = "50%";
  }
  return (
    <svg width={width} height={height} viewBox="0 -28 300 300" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
      <text fontSize={fontSize} textAnchor="middle" dominantBaseline="middle" x={x} y="50%">
        {text}
      </text>
    </svg>
  );
};

type Props = {
  error: number;
  buttons?: React.ReactNode[];
  msg?: string;
  className?: string;
  style?: React.CSSProperties;
};

export class PageErrorTemplate extends PureComponent<Props> {
  public static readonly defaultProps: Props = {
    error: 404,
    className: "",
  };

  private renderErrorNumber = () => {
    const { error } = this.props;
    const chars = `${error}`.split("");
    const middleIndex = Math.floor(chars.length / 2);
    const isMiddleZero = chars[middleIndex] === "0";
    const fontSize = 250;

    let view;
    if (isMiddleZero) {
      view = chars.map((c, i) => {
        const key = `${c}-${i}`;
        const isMiddle = middleIndex === i;
        if (isMiddle) {
          return <TechnisLogo key={key} className="technis-logo" scale={0.3} />;
        }

        let align: TextSVGProps["align"] = "middle";

        align = i < middleIndex ? "right" : "left";

        return <TextSVG key={key} fontSize={fontSize} text={c} width="30%" height="100%" align={align} />;
      });
    } else {
      view = <div className="normal-error-code">{error}</div>;
    }

    return <div className={classNames("error-number", { "has-technis-logo": isMiddleZero })}>{view}</div>;
  };

  public render() {
    const { buttons, className, msg, style } = this.props;
    const finalMsg = msg || "common.oops";

    return (
      <div className={classNames("error-template", className)} style={style}>
        {this.renderErrorNumber()}
        <div className="error-message">{translate(finalMsg)}</div>
        {buttons ? <div className="buttons">{buttons.map(b => b)}</div> : null}
      </div>
    );
  }
}
