import * as moment from "moment";

import { ExportStage } from "../components/export/ExportLoading";
import { exportPdf } from "./export/exportPdf";

const EXPORT_BENCHMARK_FILE_NAME = "exportBenchmark.txt";
const NUMBER_OF_ITERATION = 10;

export const createTimestamp = () => performance.now();

export const createLog = (timestampStart: number, timestampEnd: number, message = "time mesured : ") => {
  console.log(`${message} ${timestampEnd - timestampStart}. Log made at ${moment().format("D MMM Y H[h]mm[m]ss[s]")}`);
};

export const exportBenchmark = async (callBack: (stage: ExportStage) => void, exportDate: moment.Moment, eventName: string) => {
  const times = await Promise.sequential(Array.from({ length: NUMBER_OF_ITERATION + 1 }).map(() => () => exportPdf(callBack, exportDate, eventName)));
  const data = [...times.map((time, index) => `Iteration ${index + 1} : ${(time as number).toFixed(2)} ms\n`), `Mean time : ${(times.sum() / times.length).toFixed(2)}`].join("");
  const textToBLOB = new Blob([data], { type: "text/plain" });
  const newLink = document.createElement("a");
  newLink.download = EXPORT_BENCHMARK_FILE_NAME;
  if (window.webkitURL != null) {
    newLink.href = window.webkitURL.createObjectURL(textToBLOB);
  } else {
    newLink.href = window.URL.createObjectURL(textToBLOB);
    newLink.style.display = "none";
    document.body.appendChild(newLink);
  }
  newLink.click();
  return true;
};
