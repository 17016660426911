import React, { FunctionComponent } from "react";
import { IconType } from "react-icons";
import { AiOutlineCloseCircle } from "react-icons/ai";

import { UI } from "../../../constants";

const { DEFAULT_ICON_BUTTON_SIZE } = UI;

interface GenericButtonProps {
  Icon?: IconType;
  iconSize?: number;
  className?: string;
  onClick: () => void;
}

export const IconButton: FunctionComponent<GenericButtonProps> = props => {
  const { Icon = AiOutlineCloseCircle, iconSize = DEFAULT_ICON_BUTTON_SIZE, className = "", onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <Icon size={iconSize} />
    </div>
  );
};
