import { Tooltip } from "antd";
import React, { FunctionComponent } from "react";

import { Colors } from "../../../utils/colors";
import { ParsedText } from "../../lang/ParsedText";
import { legendParser } from "../../lang/Parsers";

interface TooltipData {
  label: string;
  value: number | string | readonly (string | number)[];
  color?: Colors | string;
}

interface Props {
  title: string;
  tooltipData: TooltipData[];
}

export const ChartTooltip: FunctionComponent<Props> = props => {
  const { title, tooltipData } = props;

  const renderTooltipText = () => {
    const tooltipInformation = tooltipData.map((tooltip, index: number) => {
      const { label, value, color = Colors.GREEN } = tooltip;
      return (
        <tr key={`chart-tooltip-row-${index}`} className="chart-tooltip-row">
          <td className="chart-tooltip-info-value" style={{ color }}>
            {value}
          </td>
          <td className="chart-tooltip-info-label">
            <ParsedText parsers={[legendParser()]}>{label}</ParsedText>
          </td>
        </tr>
      );
    });

    return (
      <div className="chart-tooltip-container">
        <div className="chart-tooltip-title">{title} </div>
        <table>
          <tbody>{tooltipInformation}</tbody>
        </table>
      </div>
    );
  };

  return (
    <Tooltip placement="rightBottom">
      <div className="chart-tooltip-cell">{renderTooltipText()}</div>
    </Tooltip>
  );
};
