import { ID } from "@technis/shared";
import { Radio } from "antd";
import { RadioChangeEvent } from "antd/lib/radio";
import * as moment from "moment";
import React, { FunctionComponent, ReactNode, useState } from "react";

import { useSelector } from "../../hooks";
import { translate } from "../../lang/i18n";
import { translation } from "../../lang/translation";
import { ShiftState } from "../../pages/AnalyticsHomePage/types";
import { DateRange } from "../../utils/aggregate";
import { endOfDay, getTimeFormatStyle, isSameDay } from "../../utils/time";
import { ActivityMessages, trackClick } from "../../utils/tracking";
import { getDisabledBeginHours, getDisabledBeginMin, getDisabledEndHours, getDisabledEndMin } from "../../utils/utils";
import { Export } from "../export/Export";
import { CustomButton } from "./buttons/CustomButton";
import { HeaderPresets } from "./HeaderPresets";
import { LoadingModule } from "./Loading";
import { CustomCheckbox } from "./modals/CheckBox";
import { CustomDatePicker } from "./modals/DatePicker";
import { CustomTimePicker } from "./modals/TimePicker";

const { export: exportTranslation, from, passages, shiftState, to, zones } = translation.common;
const { defaultDisplay, shifted } = shiftState;
const { chooseDate } = translation.analytics.modules.common;

export interface PassageSetting {
  id: ID;
  name: string;
  activated: boolean;
}

export interface ZoneSetting extends PassageSetting {
  level: number;
}

interface HeaderProps {
  title: string;
  eventName: string;
  dateRange: DateRange;
  datesLimits: {
    begin: number;
    end: number;
  };
  onUpdateDate: (dates: DateRange) => void;
  onChangePassageSettings: (setting: PassageSetting) => void;
  onChangeZoneSettings: (setting: ZoneSetting) => void;
  passageSettings: PassageSetting[];
  zoneSettings: ZoneSetting[];
  isLoading: boolean;
  children: ReactNode;
  onChangeShiftState: (event: RadioChangeEvent) => void;
  shiftState: ShiftState;
  showShiftRadio?: boolean;
}

export const Header: FunctionComponent<HeaderProps> = props => {
  const {
    children,
    dateRange,
    datesLimits,
    eventName,
    isLoading,
    onChangePassageSettings,
    onChangeShiftState,
    onChangeZoneSettings,
    onUpdateDate,
    passageSettings,
    shiftState,
    showShiftRadio,
    title,
    zoneSettings,
  } = props;

  const [isExporting, setIsExporting] = useState(false);

  const timeFormat = useSelector(state => state.userOptions.timeFormat);
  const language = useSelector(state => state.userOptions.language);
  const useTimeFormatFromLanguage = useSelector(state => state.userOptions.useTimeFormatFromLanguage);

  const finishExport = () => {
    setIsExporting(false);
  };

  const onExport = () => {
    setIsExporting(true);
    trackClick(ActivityMessages.export);
  };

  return (
    <>
      <div className="header ignore-export" id="header" data-html2canvas-ignore>
        <div className="header-title">{title}</div>
        <HeaderPresets
          dates={dateRange}
          datesLimits={datesLimits}
          onUpdateDate={onUpdateDate}
          containerClassName="header-button-container ignore-export"
          buttonClassName="header-aggregation-button"
          buttonActivatedClassName="header-activated-button"
          buttonTextClassName="aggregation-button-text"
        />
        <div className="header-date-picker">
          <div className="date-from">{translate(from)}</div>
          <CustomDatePicker
            id="global-start-dates"
            value={dateRange.dateBegin}
            onChange={dateBegin => onUpdateDate({ dateBegin: dateBegin?.valueOf() || dateRange.dateBegin, dateEnd: dateRange.dateEnd })}
            disabledDate={timestamp => timestamp >= dateRange.dateEnd || timestamp >= Date.now() || timestamp < datesLimits.begin}
            placeholder={translate(chooseDate)}
            containerClassName="global-aggregation-date"
          />
          <CustomTimePicker
            id="custom-start-dates-minute"
            valueInMinutes={new Date(dateRange.dateBegin).getDayMinute()}
            onChange={minutes => onUpdateDate({ dateBegin: new Date(dateRange.dateBegin).setDayMinute(minutes), dateEnd: dateRange.dateEnd })}
            placeholder="00:00"
            containerClassName={`global-aggregation-time ${getTimeFormatStyle(timeFormat, language, useTimeFormatFromLanguage)}`}
            disabledHours={isSameDay(dateRange.dateBegin, dateRange.dateEnd) ? getDisabledBeginHours(dateRange) : undefined}
            disabledMinutes={isSameDay(dateRange.dateBegin, dateRange.dateEnd) ? getDisabledBeginMin(dateRange) : undefined}
          />

          <div className="date-to">{translate(to).toLocaleLowerCase()}</div>
          <CustomDatePicker
            id="global-end-dates"
            value={dateRange.dateEnd}
            onChange={dateBegin => onUpdateDate({ dateEnd: dateBegin?.valueOf() || dateRange.dateEnd, dateBegin: dateRange.dateBegin })}
            disabledDate={timestamp => timestamp <= dateRange.dateBegin || timestamp > datesLimits.end || timestamp > endOfDay()}
            placeholder={translate(chooseDate)}
            containerClassName="global-aggregation-date"
          />
          <CustomTimePicker
            id="custom-end-dates-minute"
            valueInMinutes={new Date(dateRange.dateEnd).getDayMinute()}
            onChange={minutes => onUpdateDate({ dateEnd: new Date(dateRange.dateEnd).setDayMinute(minutes), dateBegin: dateRange.dateBegin })}
            placeholder="00:00"
            containerClassName={`global-aggregation-time ${getTimeFormatStyle(timeFormat, language, useTimeFormatFromLanguage)}`}
            disabledHours={isSameDay(dateRange.dateBegin, dateRange.dateEnd) ? getDisabledEndHours(dateRange) : undefined}
            disabledMinutes={isSameDay(dateRange.dateBegin, dateRange.dateEnd) ? getDisabledEndMin(dateRange) : undefined}
          />
        </div>
        {showShiftRadio && (
          <div className="header-shift-radio">
            <Radio.Group onChange={onChangeShiftState} value={shiftState}>
              <Radio value={ShiftState.DEFAULT}>{translate(defaultDisplay)}</Radio>
              <Radio value={ShiftState.SHIFTED}>{translate(shifted)}</Radio>
            </Radio.Group>
          </div>
        )}
        {/* Zone select */}
        <div className="header-zones-passages">
          {zoneSettings.length > 1 && (
            <div className="header-checkboxes-container">
              <div className="header-checkboxes-title">{translate(zones)}</div>
              {zoneSettings.map(zoneSetting => (
                <CustomCheckbox
                  key={zoneSetting.id}
                  label={zoneSetting.name}
                  value={zoneSetting.activated}
                  onChange={value => onChangeZoneSettings({ ...zoneSetting, activated: value })}
                  containerClassName="header-checkbox"
                  style={{ marginLeft: `${zoneSetting.level * 20}px` }}
                  disabled={!zoneSetting.level}
                />
              ))}
            </div>
          )}
          {passageSettings.length > 1 && (
            <div className="header-checkboxes-container">
              <div className="header-checkboxes-title">{translate(passages)}</div>
              {passageSettings.map(passageSetting => (
                <CustomCheckbox
                  key={passageSetting.id}
                  label={passageSetting.name}
                  value={passageSetting.activated}
                  onChange={value => onChangePassageSettings({ ...passageSetting, activated: value })}
                  containerClassName="header-checkbox"
                />
              ))}
            </div>
          )}
        </div>
        <div className="export-button-container">
          <CustomButton buttonDisplay={translate(exportTranslation)} buttonClassName="export-button" buttonActivatedClassName="" onClick={onExport} disabled={isExporting} />
        </div>
      </div>
      {isLoading ? <LoadingModule /> : children}
      {zoneSettings.length && isExporting && <Export dateRange={dateRange} eventName={eventName} exportDate={moment()} finishExport={finishExport} zoneSettings={zoneSettings} />}
    </>
  );
};
